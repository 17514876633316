import Fade from '@material-ui/core/Fade';
import React, {useEffect, useRef, useState} from "react";
import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';
import If, {Else} from 'if-else-react';
import {Grid} from "@material-ui/core";
import Utils from '../../../helpers/Utils';
import './lead_flow.css';
import { updateCampaignDetails } from '../../../api/marketingApi';

const staticCategories = [
    "Real_Estate",
    "Mortgage_And_Loans",
    "Insurance",
    "Financial_Services",
    "Home_Services",
    "Other"
];

const AssignContactSourceModal = props => {
    const [submitLoading, setSubmitLoading] = useState(false);
    const [contactSources, setContactSources] = useState(props.data);
    const [selectedContactSources, setSelectedContactSources] = useState(props.selectedProspectSource);
    
    const businessType=(Utils.getAccountData("agencyBusinessType") === "INSURANCE") &&
                        (Utils.getAccountData("agencyBusinessType") !== null )&&
                        (Utils.getAccountData("agencyBusinessType") !== undefined)?true:false

    const submitAddContactSource = () => {
            if (!submitLoading) {
                setSubmitLoading(true);
                updateCampaignDetails({
                    campaignId : props.campaignId,
                    key: 'prospect',
                    value : selectedContactSources
                }).then(response => {
                    if (response.data.success) {
                        setSubmitLoading(false);
                        // props.setOpenContactSourceModal(false);
                        props.closeModal(selectedContactSources)
                        window.showNotification('SUCCESS', response.data.message);
                    } else {
                        window.showNotification('ERROR', response.data.message);
                        setSubmitLoading(false);
                    }
                }).catch(error => {
                    window.showNotification('ERROR', 'Something wrong! Try again');
                    setSubmitLoading(false);
                });
            }
    }

    const contactSourceImageNotFound = e => {
        e.target.parentNode.children[1].style.display = 'block';
    }
    const renderContactSourcesForInsurance = () => {
      const insurance = contactSources.filter(
        (data) => data.parser_category === "Insurance"
      );

      return (
        <div style={{ width: "100%" }}>
          <h5 className="category_title accent--bg--text--color">
            Insurance
          </h5>
          <Grid container spacing={2}>
            <>
              {insurance.map((contactSource, j) => (
                <Grid item xs={3} spacing={2} key={j} className="leftSide">
                  <button
                    onClick={() => setSelectedContactSources(contactSource.id)}
                    className={
                      "single_contact_source" +
                      (contactSource.id === selectedContactSources
                        ? " active"
                        : "")
                    }
                  >
                    <img
                      className="contact_source_img"
                      src={`${Utils.getAccountData("baseUrl")}/parser/${
                        contactSource.logo
                      }`}
                      alt={`${contactSource.name} Image`}
                      onError={(e) => contactSourceImageNotFound(e)}
                    />
                    <p>{contactSource.name}</p>
                  </button>
                </Grid>
              ))}
            </>
          </Grid>
        </div>
      );
    };


    const renderContactSources = () => {
            const contactSourcesByCategory = {};
            contactSources.forEach((contactSource) => {
                const category = contactSource?.parser_category;
        
                if (staticCategories.includes(category)) {
                    if (!contactSourcesByCategory[category]) {
                        contactSourcesByCategory[category] = [];
                    }
        
                    contactSourcesByCategory[category].push(contactSource);
                }
            });
            
        return staticCategories.map((category, i) => (
            <div key={i} style={{width:"100%"}}>
                 <h5 className='category_title accent--bg--text--color'>{ category.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())}</h5>
                <Grid container spacing={2}>
                    {contactSourcesByCategory[category] && contactSourcesByCategory[category].map((contactSource, j) => (
                        <Grid item xs={3} spacing={2} key={j} className="leftSide">
                            <button
                                onClick={() => setSelectedContactSources(contactSource.id)}
                                className={"single_contact_source" + (contactSource.id === selectedContactSources ? ' active' : '')}
                            >
                                <img
                                    className="contact_source_img"
                                    src={`${Utils.getAccountData('baseUrl')}/parser/${contactSource.logo}`}
                                    alt={`${contactSource.name} Image`}
                                    onError={(e) => contactSourceImageNotFound(e)}
                                />
                                <p>{contactSource.name}</p>
                            </button>
                        </Grid>
                    ))}
                </Grid>
            </div>
        ));
    }

    

    
    

    return (
        <React.Fragment>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                // className={classes.modal}
                open={props.openContactSourceModal}
                onClose={props.setOpenContactSourceModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.openContactSourceModal}>
                    <div className="add_leadflow_modal_new">
                        <div className="top_content d-flex justify-content-between align-items-center p-4">
                            <div className="sub_title">
                                <h2>Connect your Lead Source</h2>
                                <p>We've built out integrations with the providers you see below.</p>
                            </div>
                            <div className="cross_button d-flex justify-content-center align-items-center modal-close" onClick={(e) => props.setOpenContactSourceModal(false)}>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.1252 17.0812L17.1357 15.0693L6.878 4.80485L4.86747 6.8167L15.1252 17.0812Z"
                                          fill="white"></path>
                                    <path d="M6.878 17.0838L17.1357 6.81934L15.1252 4.80749L4.86747 15.072L6.878 17.0838Z"
                                          fill="white"></path>
                                </svg>
                            </div>
                        </div>
                        <div className="contact_source_container my-4 px-4">
                            <Grid container spacing={3} className="accordionContain">
                                {businessType ? renderContactSourcesForInsurance() : renderContactSources()}
                            </Grid>
                        </div>
                        <div className="modal__buttons d-flex justify-content-center align-content-center">
                            <If condition={submitLoading}>
                                <button type="buton" className="add__group__btn">
                                    {/* <Loading /> */}
                                    <span className="mx-2">Add Contact Source</span>
                                </button>
                            <Else />
                                <button type="buton" className="add__group__btn accent--bg--color" onClick={() => submitAddContactSource()}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="10" cy="10" r="10" fill="white"/>
                                        <path
                                            d="M13.8447 9.9985C13.8447 10.353 13.8139 10.6395 13.4594 10.6395H10.6395V13.4594C10.6395 13.8132 10.353 13.8447 9.9985 13.8447C9.64401 13.8447 9.35747 13.8132 9.35747 13.4594V10.6395H6.5376C6.18375 10.6395 6.15234 10.353 6.15234 9.9985C6.15234 9.64401 6.18375 9.35747 6.5376 9.35747H9.35747V6.5376C9.35747 6.18311 9.64401 6.15234 9.9985 6.15234C10.353 6.15234 10.6395 6.18311 10.6395 6.5376V9.35747H13.4594C13.8139 9.35747 13.8447 9.64401 13.8447 9.9985Z"
                                            className={"accent--fill--color"}/>
                                    </svg>
                                    <span className="mx-2">Add Contact Source</span>
                                </button>
                            </If>

                            <button onClick={() => props.setOpenContactSourceModal(false)} type='button' className="modal-close modal__close__btn">
                                <svg width="21" height="22" viewBox="0 0 21 22" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M18.1016 3.90156C14.2016 0.0015626 7.80156 0.0015626 3.90156 3.90156C0.00156271 7.80156 0.00156271 14.2016 3.90156 18.1016C7.80156 22.0016 14.1016 22.0016 18.0016 18.1016C21.9016 14.2016 22.0016 7.80156 18.1016 3.90156ZM13.8016 15.2016L11.0016 12.4016L8.20156 15.2016L6.80156 13.8016L9.60156 11.0016L6.80156 8.20156L8.20156 6.80156L11.0016 9.60156L13.8016 6.80156L15.2016 8.20156L12.4016 11.0016L15.2016 13.8016L13.8016 15.2016Z"
                                        fill="white"/>
                                </svg>
                                <span className="mx-2">Close</span>
                            </button>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </React.Fragment>
    );
}

export default AssignContactSourceModal;
