import { useEffect } from "react";
import { connect } from "react-redux";
import { getCookieCampaign, setCookieCampaign } from "../..";
import { copyCampaign } from "../../../../actions/marketingAction";
import { useIsMounted } from "../../hanlders/hooks/IsMounted";
import useDelayCallback from "../../hanlders/hooks/useDelayCallback";
import Link from "../../Link";
import { handleStates, handleStoreCampaignList, handleStoreSearchText } from "../../reducer/Actions";
import { useMLv2Context } from "../../reducer/MLV2Provider";
import CampaignListSkeleton from "./CampaignListSkeleton";
import EachCampaignList from "./EachCampaignList";

const CampaignList = (props) => {
    const isMounted = useIsMounted()
    const { state, dispatch } = useMLv2Context();
    useEffect(() => {
        try {
            const data = getCookieCampaign('campaign_filter')
            if (data != null && data !== undefined && data !== "") {
                let parse_data = JSON.parse(data);
                if (parse_data.search === "" && parse_data.perPage === 10) {
                    getCampaignList()
                }
                else {
                    dispatch(handleStates({
                        searchText: parse_data.search,
                        perPage: parse_data.perPage
                    }))
                }
            }
        } catch (error) {
            getCampaignList()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useDelayCallback(() => {
        try {
            if (isMounted) {
                getCampaignList()
            } else {
                const data = getCookieCampaign('campaign_filter')
                if (data != null && data !== undefined && data !== "") {
                    /* no action */
                }
                else {
                    getCampaignList()
                }
            }
        } catch (error) {
            getCampaignList()
        }
        console.log("Called");
    }, [state.selectedFolder, state.currentPage, state.perPage, state.selectedStatus])

    useDelayCallback(() => {
        if (isMounted) {
            if (state.refreshList) {
                dispatch(handleStates({
                    refreshList: false
                }));
                getCampaignList()
            }
        }
    }, [state.refreshList])

    useDelayCallback(() => {
        if (isMounted) {
            if (state.currentPage !== 1) {
                dispatch(handleStates({
                    currentPage: 1, gettingList: true
                }));
            }
            else {
                dispatch(handleStates({
                    currentPage: 1, gettingList: true, refreshList: true
                }));
            }
            setCookieCampaign(
                'campaign_filter',
                JSON.stringify({
                    search: state.searchText,
                    perPage: state.perPage,
                    campaignStatus: state.selectedStatus.value
                }),
                '2 day'
            )
        }
    }, [state.searchText])

    const getCampaignList = () => {
        /* loading state to true */
        dispatch(handleStates({
            gettingList: true
        }));
        /* get campaign list */
        let folder_id = null;
        if (state.selectedFolder !== null && state.selectedFolder.id !== undefined && state.selectedFolder.id !== null) {
            folder_id = state.selectedFolder.id;
        }
        Link.API_LIST.fetchCampaign({
            'page': state.currentPage,
            'groupId': folder_id,
            'query': state.searchText,
            'perPage': state.perPage,
            "apiVersion": 1,
            "campaignStatus": state.selectedStatus.value
        }).then(res => {
            let response = res.data;
            dispatch(handleStoreCampaignList({
                campaignList: response.data,
                collapseId: null,
                gettingList: false,
                totalRecords: response.total
            }));
        }).catch(error => {
            /* loading state false */
            dispatch(handleStates({
                gettingList: false
            }));
        })
    }

    const renderList = () => {
        const view = [];
        state.campaignList.forEach((item, index) => {
            view.push(
                <EachCampaignList
                    key={index}
                    index={index}
                    item={item}
                    copyCampaign={props.copyCampaign}
                />
            );
        })
        return view;
    }

    return (
        <div className={`mlvc_body_container_right ${state.showMinimizeView ? 'mlvc_each_campaign_body_active' : ''}`}>
            {!state.gettingList && renderList()}
            {state.gettingList && <CampaignListSkeleton />}
        </div>
    );
}
const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
    copyCampaign: (params, callback) => dispatch(copyCampaign(params, callback)),

})

export default connect(mapStateToProps, mapDispatchToProps)(CampaignList);