export const OpenAiIcon = ({
     width = 50,
     height = 50,
     color = "#000"
 }) => (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" strokeWidth="1.5" viewBox="-0.17090198558635983 0.482230148717937 41.14235318283891 40.0339509076386">
        <path d="M37.532 16.87a9.963 9.963 0 0 0-.856-8.184 10.078 10.078 0 0 0-10.855-4.835A9.964 9.964 0 0 0 18.306.5a10.079 10.079 0 0 0-9.614 6.977 9.967 9.967 0 0 0-6.664 4.834 10.08 10.08 0 0 0 1.24 11.817 9.965 9.965 0 0 0 .856 8.185 10.079 10.079 0 0 0 10.855 4.835 9.965 9.965 0 0 0 7.516 3.35 10.078 10.078 0 0 0 9.617-6.981 9.967 9.967 0 0 0 6.663-4.834 10.079 10.079 0 0 0-1.243-11.813zM22.498 37.886a7.474 7.474 0 0 1-4.799-1.735c.061-.033.168-.091.237-.134l7.964-4.6a1.294 1.294 0 0 0 .655-1.134V19.054l3.366 1.944a.12.12 0 0 1 .066.092v9.299a7.505 7.505 0 0 1-7.49 7.496zM6.392 31.006a7.471 7.471 0 0 1-.894-5.023c.06.036.162.099.237.141l7.964 4.6a1.297 1.297 0 0 0 1.308 0l9.724-5.614v3.888a.12.12 0 0 1-.048.103l-8.051 4.649a7.504 7.504 0 0 1-10.24-2.744zM4.297 13.62A7.469 7.469 0 0 1 8.2 10.333c0 .068-.004.19-.004.274v9.201a1.294 1.294 0 0 0 .654 1.132l9.723 5.614-3.366 1.944a.12.12 0 0 1-.114.01L7.04 23.856a7.504 7.504 0 0 1-2.743-10.237zm27.658 6.437l-9.724-5.615 3.367-1.943a.121.121 0 0 1 .113-.01l8.052 4.648a7.498 7.498 0 0 1-1.158 13.528v-9.476a1.293 1.293 0 0 0-.65-1.132zm3.35-5.043c-.059-.037-.162-.099-.236-.141l-7.965-4.6a1.298 1.298 0 0 0-1.308 0l-9.723 5.614v-3.888a.12.12 0 0 1 .048-.103l8.05-4.645a7.497 7.497 0 0 1 11.135 7.763zm-21.063 6.929l-3.367-1.944a.12.12 0 0 1-.065-.092v-9.299a7.497 7.497 0 0 1 12.293-5.756 6.94 6.94 0 0 0-.236.134l-7.965 4.6a1.294 1.294 0 0 0-.654 1.132l-.006 11.225zm1.829-3.943l4.33-2.501 4.332 2.5v5l-4.331 2.5-4.331-2.5V18z" fill={color}/>
    </svg>
);

export const ImgCampaign = (
    <svg
        width='289'
        height='216'
        viewBox='0 0 289 216'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M289 189.44H0.456055V189.584H289V189.44Z' fill='#EBEBEB' />
      <path
          d='M260.088 198.725H240.975V198.869H260.088V198.725Z'
          fill='#EBEBEB'
      />
      <path
          d='M191.599 200.295H186.584V200.439H191.599V200.295Z'
          fill='#EBEBEB'
      />
      <path d='M240.398 193.37H229.323V193.514H240.398V193.37Z' fill='#EBEBEB' />
      <path
          d='M55.6549 194.339H30.7305V194.483H55.6549V194.339Z'
          fill='#EBEBEB'
      />
      <path
          d='M64.4498 194.339H60.7969V194.483H64.4498V194.339Z'
          fill='#EBEBEB'
      />
      <path
          d='M130.388 196.774H76.3262V196.919H130.388V196.774Z'
          fill='#EBEBEB'
      />
      <path
          d='M137.226 163.702H25.7962C24.9233 163.7 24.0866 163.352 23.4699 162.734C22.8532 162.117 22.5068 161.279 22.5068 160.406V3.76733C22.5144 2.8994 22.8642 2.06952 23.4801 1.45795C24.096 0.84637 24.9283 0.502494 25.7962 0.501007H137.226C138.1 0.501007 138.938 0.848176 139.556 1.46614C140.174 2.08411 140.521 2.92225 140.521 3.79618V160.406C140.521 161.28 140.174 162.118 139.556 162.736C138.938 163.354 138.1 163.702 137.226 163.702ZM25.7962 0.616425C24.9616 0.617954 24.1616 0.950596 23.5719 1.54134C22.9823 2.13208 22.6511 2.93265 22.6511 3.76733V160.406C22.6511 161.241 22.9823 162.042 23.5719 162.632C24.1616 163.223 24.9616 163.556 25.7962 163.557H137.226C138.061 163.556 138.862 163.223 139.453 162.633C140.043 162.042 140.376 161.242 140.377 160.406V3.76733C140.376 2.93212 140.043 2.13157 139.453 1.54099C138.862 0.950409 138.061 0.617951 137.226 0.616425H25.7962Z'
          fill='#EBEBEB'
      />
      <path
          d='M262.056 163.702H150.62C149.747 163.7 148.91 163.352 148.292 162.735C147.674 162.117 147.327 161.28 147.325 160.406V3.76733C147.334 2.89887 147.685 2.069 148.302 1.45758C148.919 0.846169 149.752 0.502478 150.62 0.501007H262.056C262.923 0.504011 263.754 0.848558 264.369 1.45997C264.983 2.07138 265.332 2.9004 265.34 3.76733V160.406C265.34 161.278 264.994 162.115 264.379 162.732C263.763 163.35 262.928 163.698 262.056 163.702ZM150.62 0.616425C149.785 0.617951 148.985 0.950409 148.394 1.54099C147.803 2.13157 147.471 2.93212 147.469 3.76733V160.406C147.471 161.242 147.803 162.042 148.394 162.633C148.985 163.223 149.785 163.556 150.62 163.557H262.056C262.891 163.556 263.692 163.223 264.282 162.633C264.873 162.042 265.205 161.242 265.207 160.406V3.76733C265.205 2.93212 264.873 2.13157 264.282 1.54099C263.692 0.950409 262.891 0.617951 262.056 0.616425H150.62Z'
          fill='#EBEBEB'
      />
      <path
          d='M227.298 12.7122H58.9092V91.4963H227.298V12.7122Z'
          fill='#E6E6E6'
      />
      <path
          d='M229.832 12.7122H60.0928V91.4963H229.832V12.7122Z'
          fill='#F0F0F0'
      />
      <path
          d='M227.298 91.4963H58.9092V95.5128H227.298V91.4963Z'
          fill='#E6E6E6'
      />
      <path
          d='M234.055 91.4963H64.3164V95.5128H234.055V91.4963Z'
          fill='#F0F0F0'
      />
      <path
          d='M226.282 87.9472V16.2671L63.6472 16.2671V87.9472L226.282 87.9472Z'
          fill='#FAFAFA'
      />
      <path
          d='M81.0928 87.9472L90.9783 16.2671H106.404L96.5183 87.9472H81.0928Z'
          fill='white'
      />
      <path
          d='M65.2861 84.4096C65.2188 84.4096 65.1542 84.3829 65.1066 84.3353C65.059 84.2877 65.0322 84.2231 65.0322 84.1557V18.5754C65.0322 18.5081 65.059 18.4435 65.1066 18.3959C65.1542 18.3483 65.2188 18.3215 65.2861 18.3215C65.3195 18.3215 65.3525 18.3281 65.3833 18.3408C65.4141 18.3536 65.4421 18.3723 65.4657 18.3959C65.4893 18.4195 65.508 18.4474 65.5207 18.4783C65.5335 18.5091 65.5401 18.5421 65.5401 18.5754V84.1788C65.5356 84.2426 65.5067 84.3022 65.4594 84.3452C65.4121 84.3882 65.3501 84.4113 65.2861 84.4096Z'
          fill='#F0F0F0'
      />
      <path
          d='M99.8311 87.9472L109.717 16.2671H115.73L105.844 87.9472H99.8311Z'
          fill='white'
      />
      <path
          d='M226.282 87.9472V16.2671H225.832V87.9472H226.282Z'
          fill='#E6E6E6'
      />
      <path
          opacity='0.6'
          d='M230.668 21.6224H62.285L61.9561 17.6463H230.345L230.668 21.6224Z'
          fill='#EBEBEB'
      />
      <path
          opacity='0.6'
          d='M230.668 28.1378H62.285L61.9561 24.1616H230.345L230.668 28.1378Z'
          fill='#EBEBEB'
      />
      <path
          opacity='0.6'
          d='M230.668 34.6588H62.285L61.9561 30.6827H230.345L230.668 34.6588Z'
          fill='#EBEBEB'
      />
      <path
          opacity='0.6'
          d='M230.668 41.1742H62.285L61.9561 37.1981H230.345L230.668 41.1742Z'
          fill='#EBEBEB'
      />
      <path
          opacity='0.6'
          d='M230.668 47.6953H62.285L61.9561 43.7191H230.345L230.668 47.6953Z'
          fill='#EBEBEB'
      />
      <path
          opacity='0.6'
          d='M230.668 54.2106H62.285L61.9561 50.2345H230.345L230.668 54.2106Z'
          fill='#EBEBEB'
      />
      <path d='M253.797 111.302H228.1V189.44H253.797V111.302Z' fill='#E6E6E6' />
      <path
          d='M246.937 189.44H253.798V178.654H239.74L246.937 189.44Z'
          fill='#FAFAFA'
      />
      <path d='M183.734 111.302H158.036V189.44H183.734V111.302Z' fill='#E6E6E6' />
      <path
          d='M253.798 111.302H177.743V185.729H253.798V111.302Z'
          fill='#FAFAFA'
      />
      <path
          d='M184.605 189.44H177.743V178.654H191.801L184.605 189.44Z'
          fill='#FAFAFA'
      />
      <path
          d='M248.472 139.037H183.064V157.659H248.472V139.037Z'
          fill='#F0F0F0'
      />
      <path
          d='M248.472 161.641H183.064V180.264H248.472V161.641Z'
          fill='#F0F0F0'
      />
      <path
          d='M193.342 137.421H238.216V138.327C238.216 139.042 237.932 139.727 237.427 140.233C236.921 140.738 236.236 141.022 235.521 141.022H196.043C195.328 141.022 194.642 140.738 194.137 140.233C193.632 139.727 193.348 139.042 193.348 138.327V137.421H193.342Z'
          fill='#FAFAFA'
      />
      <path
          d='M248.472 116.426H183.064V135.049H248.472V116.426Z'
          fill='#F0F0F0'
      />
      <path
          d='M193.342 114.816H238.216V115.722C238.216 116.437 237.932 117.123 237.427 117.628C236.921 118.134 236.236 118.417 235.521 118.417H196.043C195.328 118.417 194.642 118.134 194.137 117.628C193.632 117.123 193.348 116.437 193.348 115.722V114.816H193.342Z'
          fill='#FAFAFA'
      />
      <path
          d='M193.342 160.025H238.216V160.932C238.216 161.646 237.932 162.332 237.427 162.837C236.921 163.343 236.236 163.627 235.521 163.627H196.043C195.328 163.627 194.642 163.343 194.137 162.837C193.632 162.332 193.348 161.646 193.348 160.932V160.025H193.342Z'
          fill='#FAFAFA'
      />
      <path
          d='M31.2843 127.962C32.7154 123.848 43.9802 129.44 48.5969 136.249C51.6497 140.785 53.5137 145.627 54.3678 152.131C54.2639 144.71 54.1485 136.74 54.0331 129.215C53.104 127.339 50.986 123.011 49.4683 119.583C44.3899 108.145 32.3172 103.886 28.116 96.667C22.651 87.2027 22.6856 73.0064 27.337 67.8934C31.3766 63.4382 44.765 93.5334 46.277 96.8978C51.4708 108.44 52.9712 117.044 53.9407 125.36C53.8311 118.29 53.7272 111.804 53.6407 106.795C53.3579 90.4633 53.0001 85.2118 52.8385 83.5959C52.2614 82.8053 51.6208 81.8647 51.2342 81.3915C47.9967 77.2191 40.8062 72.2562 37.2167 66.191C31.971 57.3384 32.8193 46.7431 36.4954 42.7958C40.3734 38.6696 47.2003 48.3993 50.7841 58.0829C54.3678 67.7664 52.85 76.9883 53.8657 85.0156C54.0792 86.5853 54.2928 92.5639 54.5005 100.857C55.597 91.9349 57.7784 85.9562 61.818 80.3873C66.7925 73.5373 71.086 65.1984 75.1834 66.2775C79.2807 67.3567 77.0762 78.3964 73.833 86.1409C70.9475 93.0371 56.705 99.9218 54.6044 105.468C54.8525 116.709 55.0718 130.946 55.2334 144.202C56.353 137.352 55.5797 136.78 60.1848 126.312C62.9202 120.079 74.1331 112.398 75.4719 116.68C76.8107 120.962 74.7736 129.053 70.5436 134.72C67.1272 139.296 59.5789 139.291 57.7957 142.124C57.386 142.788 56.0644 145.327 55.2623 146.781C55.4412 162.969 55.5277 177.252 55.4354 182.122L54.6967 182.174C54.7371 180.916 54.6448 172.681 54.5005 161.786C52.6596 156.015 40.2753 152.61 36.4492 146.579C32.4442 140.254 29.5645 132.891 31.2843 127.962Z'
          fill='#E6E6E6'
      />
      <path
          d='M42.9298 189.44H66.7059C67.3358 189.439 67.9462 189.221 68.4341 188.823C68.9219 188.424 69.2573 187.87 69.3835 187.253L71.0686 178.931H38.5439L40.2752 187.253C40.4006 187.866 40.7326 188.417 41.2157 188.816C41.6988 189.214 42.3039 189.434 42.9298 189.44Z'
          fill='#F0F0F0'
      />
      <path
          d='M37.8856 182.157H71.7491C72.1418 182.156 72.5207 182.012 72.8134 181.75C73.1061 181.488 73.2922 181.127 73.3361 180.737L74.0286 174.585C74.0529 174.362 74.03 174.137 73.9613 173.923C73.8927 173.71 73.7799 173.513 73.6302 173.346C73.4805 173.179 73.2973 173.045 73.0925 172.953C72.8877 172.862 72.666 172.814 72.4416 172.814H37.1931C36.9687 172.814 36.747 172.862 36.5422 172.953C36.3374 173.045 36.1542 173.179 36.0045 173.346C35.8548 173.513 35.742 173.71 35.6734 173.923C35.6047 174.137 35.5818 174.362 35.6061 174.585L36.2986 180.737C36.3425 181.127 36.5286 181.488 36.8213 181.75C37.114 182.012 37.4929 182.156 37.8856 182.157Z'
          fill='#F0F0F0'
      />
      <path d='M103.086 152.056H82.7666V189.44H103.086V152.056Z' fill='#E0E0E0' />
      <path
          d='M154.873 189.44H86.8406L79.6904 138.714H147.723L154.873 189.44Z'
          fill='#E6E6E6'
      />
      <path
          d='M164.799 189.44H89.5408L82.3965 138.714H157.649L164.799 189.44Z'
          fill='#F0F0F0'
      />
      <path
          d='M96.9048 181.51L90.8857 146.643H150.285L156.304 181.51H96.9048Z'
          fill='white'
      />
      <path
          d='M122.43 181.51L102.243 146.643H108.574L128.766 181.51H122.43Z'
          fill='#FAFAFA'
      />
      <path
          d='M131.449 181.51L111.257 146.643H122.06L142.246 181.51H131.449Z'
          fill='#FAFAFA'
      />
      <path
          d='M144.729 215.501C206.525 215.501 256.62 212.576 256.62 208.968C256.62 205.36 206.525 202.436 144.729 202.436C82.9325 202.436 32.8369 205.36 32.8369 208.968C32.8369 212.576 82.9325 215.501 144.729 215.501Z'
          fill='#F5F5F5'
      />
      <path
          d='M45.0472 96.6035C44.9852 96.6038 44.9253 96.5808 44.8794 96.5392C44.8335 96.4975 44.8048 96.4402 44.799 96.3784L43.5179 80.9702C43.5147 80.9078 43.5357 80.8465 43.5765 80.7992C43.6174 80.7518 43.675 80.7221 43.7372 80.7163C43.8021 80.7115 43.8663 80.7324 43.916 80.7745C43.9657 80.8166 43.9968 80.8765 44.0027 80.9413L45.2838 96.3496C45.2886 96.4145 45.2677 96.4787 45.2256 96.5284C45.1835 96.578 45.1236 96.6092 45.0587 96.6151L45.0472 96.6035Z'
          fill='#D2CCFF'
      />
      <path
          d='M45.8088 105.756C45.7468 105.756 45.6869 105.733 45.641 105.692C45.5951 105.65 45.5664 105.593 45.5607 105.531L45.174 100.868C45.1708 100.836 45.1742 100.803 45.1839 100.772C45.1936 100.741 45.2094 100.713 45.2304 100.688C45.2513 100.663 45.2771 100.643 45.3061 100.628C45.3351 100.614 45.3667 100.605 45.3991 100.603C45.4314 100.6 45.464 100.603 45.495 100.613C45.526 100.622 45.5547 100.638 45.5794 100.659C45.6042 100.68 45.6245 100.706 45.6391 100.735C45.6537 100.764 45.6624 100.795 45.6645 100.828L46.0512 105.491C46.056 105.556 46.0351 105.62 45.993 105.669C45.9509 105.719 45.891 105.75 45.8261 105.756H45.8088Z'
          fill='#D2CCFF'
      />
      <path
          d='M175.637 149.603H56.4796C54.9783 149.574 53.54 148.995 52.437 147.976C51.3341 146.957 50.6432 145.569 50.4952 144.075L43.7836 63.4036C43.7119 62.699 43.7901 61.9872 44.013 61.3149C44.236 60.6426 44.5986 60.0252 45.0772 59.503C45.5557 58.9809 46.1393 58.5659 46.7896 58.2853C47.4399 58.0047 48.1423 57.865 48.8504 57.8751H168.002C169.504 57.9044 170.943 58.4832 172.047 59.502C173.151 60.5208 173.843 61.9089 173.992 63.4036L180.704 144.063C180.776 144.769 180.699 145.481 180.476 146.155C180.254 146.828 179.891 147.446 179.413 147.97C178.934 148.493 178.35 148.909 177.699 149.19C177.049 149.472 176.346 149.613 175.637 149.603Z'
          fill='white'
      />
      <path
          d='M175.637 149.834H56.4794C54.9196 149.809 53.4237 149.21 52.2773 148.152C51.131 147.094 50.4143 145.651 50.2642 144.098L43.5526 63.4325C43.4849 62.6965 43.5715 61.9544 43.807 61.2539C44.0425 60.5533 44.4217 59.9096 44.9203 59.364C45.4164 58.8148 46.023 58.3767 46.7003 58.0783C47.3776 57.7799 48.1102 57.6281 48.8503 57.6327H168.002C169.568 57.6576 171.07 58.2607 172.218 59.326C173.367 60.3913 174.081 61.8436 174.223 63.4036L180.928 144.063C181.003 144.799 180.92 145.542 180.687 146.243C180.454 146.945 180.075 147.589 179.575 148.134C179.076 148.679 178.467 149.112 177.788 149.405C177.109 149.698 176.376 149.844 175.637 149.834ZM48.8503 58.1175C48.1729 58.1065 47.5009 58.2393 46.8786 58.5072C46.2564 58.7751 45.698 59.1719 45.2404 59.6715C44.7828 60.1711 44.4364 60.762 44.2241 61.4054C44.0117 62.0487 43.9383 62.7298 44.0085 63.4036L50.7201 144.057C50.8628 145.497 51.5296 146.834 52.5936 147.814C53.6576 148.794 55.0446 149.348 56.491 149.372H175.637C176.312 149.382 176.983 149.249 177.603 148.982C178.224 148.714 178.781 148.318 179.238 147.82C179.695 147.322 180.041 146.733 180.254 146.091C180.466 145.45 180.541 144.77 180.473 144.098L173.761 63.4325C173.62 61.9925 172.953 60.655 171.889 59.6748C170.825 58.6946 169.437 58.1403 167.99 58.1175H48.8503Z'
          fill='#D2CCFF'
      />
      <path
          d='M168.002 57.8867H48.8503C48.1431 57.8767 47.4419 58.0162 46.7925 58.2961C46.143 58.576 45.56 58.9899 45.0817 59.5107C44.6033 60.0316 44.2404 60.6476 44.0167 61.3185C43.7929 61.9893 43.7135 62.6999 43.7834 63.4036H173.992C173.84 61.911 173.147 60.5256 172.043 59.5092C170.939 58.4928 169.502 57.9156 168.002 57.8867Z'
          fill='#D2CCFF'
      />
      <path
          d='M51.638 60.6509C51.6543 60.803 51.6382 60.9568 51.5906 61.1022C51.543 61.2475 51.4651 61.3811 51.362 61.4941C51.2588 61.6071 51.1328 61.6968 50.9924 61.7574C50.8519 61.818 50.7002 61.848 50.5473 61.8455C50.2231 61.8386 49.9126 61.7133 49.6744 61.4933C49.4363 61.2732 49.2869 60.9735 49.2546 60.6509C49.2391 60.499 49.2559 60.3456 49.3039 60.2006C49.3518 60.0556 49.4298 59.9224 49.5327 59.8097C49.6357 59.6969 49.7613 59.6072 49.9013 59.5463C50.0413 59.4854 50.1926 59.4547 50.3453 59.4563C50.6702 59.4607 50.9821 59.5851 51.2208 59.8056C51.4594 60.0262 51.6081 60.3273 51.638 60.6509Z'
          fill='white'
      />
      <path
          d='M56.485 60.6509C56.5014 60.803 56.4852 60.9568 56.4377 61.1022C56.3901 61.2475 56.3121 61.3811 56.209 61.4941C56.1058 61.6071 55.9799 61.6968 55.8394 61.7574C55.699 61.818 55.5472 61.848 55.3943 61.8455C55.0741 61.8331 54.7692 61.7054 54.5359 61.4858C54.3026 61.2663 54.1565 60.9697 54.1247 60.6509C54.1093 60.499 54.1261 60.3456 54.174 60.2006C54.2219 60.0556 54.2999 59.9224 54.4029 59.8097C54.5058 59.6969 54.6314 59.6072 54.7714 59.5463C54.9114 59.4854 55.0627 59.4547 55.2154 59.4563C55.5363 59.4662 55.8426 59.5932 56.0764 59.8132C56.3103 60.0332 56.4556 60.3312 56.485 60.6509Z'
          fill='white'
      />
      <path
          d='M61.3323 60.6509C61.3487 60.803 61.3325 60.9568 61.285 61.1022C61.2374 61.2475 61.1594 61.3811 61.0563 61.4941C60.9531 61.6071 60.8272 61.6968 60.6867 61.7574C60.5463 61.818 60.3945 61.848 60.2416 61.8455C59.9174 61.8386 59.6069 61.7133 59.3688 61.4933C59.1306 61.2732 58.9813 60.9735 58.9489 60.6509C58.9335 60.499 58.9503 60.3456 58.9982 60.2006C59.0461 60.0556 59.1241 59.9224 59.2271 59.8097C59.33 59.6969 59.4556 59.6072 59.5956 59.5463C59.7357 59.4854 59.8869 59.4547 60.0396 59.4563C60.3646 59.4607 60.6764 59.5851 60.9151 59.8056C61.1538 60.0262 61.3024 60.3273 61.3323 60.6509Z'
          fill='white'
      />
      <path
          d='M165.295 139.031H65.0896C63.896 139.009 62.7521 138.549 61.8746 137.74C60.9972 136.93 60.4471 135.827 60.3286 134.639L55.6196 78.4079C55.5633 77.8471 55.6264 77.2807 55.8046 76.746C55.9829 76.2113 56.2724 75.7204 56.6539 75.3056C57.0355 74.8908 57.5006 74.5614 58.0185 74.3392C58.5365 74.117 59.0956 74.0069 59.6592 74.0163H159.882C161.076 74.0386 162.22 74.498 163.097 75.3074C163.975 76.1169 164.525 77.22 164.643 78.4079L169.323 134.668C169.375 135.226 169.309 135.788 169.13 136.318C168.95 136.849 168.661 137.335 168.281 137.747C167.901 138.158 167.439 138.485 166.925 138.706C166.41 138.928 165.855 139.038 165.295 139.031Z'
          fill='#F0F0F0'
      />
      <path
          opacity='0.1'
          d='M134.294 83.8383L103.247 121.222C102.271 122.396 101.614 123.802 101.34 125.303C101.066 126.805 101.183 128.352 101.681 129.795C102.178 131.238 103.039 132.529 104.181 133.542C105.323 134.555 106.706 135.257 108.198 135.58L158.578 146.47C160.154 146.811 161.793 146.718 163.319 146.199C164.846 145.68 166.202 144.755 167.243 143.524C168.284 142.293 168.97 140.801 169.228 139.21C169.486 137.618 169.305 135.986 168.706 134.489L149.391 86.2102C148.82 84.7776 147.887 83.5183 146.682 82.5561C145.477 81.594 144.042 80.9621 142.518 80.7228C140.995 80.4835 139.436 80.6449 137.994 81.1912C136.552 81.7374 135.277 82.6498 134.294 83.8383Z'
          fill='#263238'
      />
      <path
          opacity='0.1'
          d='M65.1822 89.0783L54.3675 118.227C54.0437 119.117 53.9504 120.074 54.0963 121.01C54.2423 121.946 54.6229 122.829 55.2024 123.578C55.782 124.327 56.5418 124.917 57.411 125.293C58.2801 125.669 59.2303 125.819 60.173 125.729L91.7571 122.63C92.7621 122.532 93.7221 122.164 94.5355 121.566C95.349 120.968 95.9859 120.161 96.3789 119.23C96.7719 118.3 96.9065 117.281 96.7685 116.281C96.6305 115.28 96.2249 114.336 95.5947 113.547L74.8195 87.5201C74.1989 86.7454 73.3837 86.1493 72.4574 85.7927C71.531 85.436 70.5266 85.3316 69.5467 85.49C68.5667 85.6484 67.6464 86.0641 66.8796 86.6945C66.1128 87.3249 65.5271 88.1475 65.1822 89.0783Z'
          fill='#263238'
      />
      <path
          d='M145.005 74.426L115.977 116.553C115.071 117.869 114.517 119.396 114.369 120.987C114.221 122.578 114.484 124.18 115.133 125.641C115.781 127.101 116.794 128.371 118.073 129.328C119.353 130.285 120.857 130.898 122.441 131.108L175.798 138.212C177.477 138.435 179.184 138.198 180.738 137.526C182.291 136.854 183.633 135.772 184.62 134.396C185.606 133.021 186.2 131.402 186.338 129.715C186.475 128.028 186.152 126.335 185.401 124.817L161.071 75.5686C160.349 74.1097 159.258 72.8648 157.906 71.9576C156.554 71.0505 154.989 70.5124 153.365 70.3969C151.741 70.2815 150.116 70.5925 148.649 71.2993C147.183 72.006 145.926 73.0839 145.005 74.426Z'
          fill='#D2CCFF'
      />
      <path
          d='M123.705 127.345C122.741 127.214 121.825 126.847 121.037 126.276C120.25 125.705 119.616 124.948 119.192 124.073C118.754 123.246 118.549 122.316 118.597 121.381C118.644 120.446 118.944 119.542 119.463 118.764L148.566 76.5093C149.092 75.7556 149.819 75.1653 150.666 74.806C151.512 74.4467 152.442 74.3331 153.35 74.4779C154.314 74.608 155.23 74.9749 156.018 75.5459C156.805 76.1169 157.439 76.8742 157.862 77.75L182.239 127.12C182.676 127.947 182.882 128.877 182.834 129.812C182.786 130.746 182.487 131.651 181.967 132.429C181.442 133.184 180.715 133.776 179.869 134.137C179.022 134.497 178.092 134.611 177.183 134.466L123.705 127.345Z'
          fill='white'
      />
      <path
          d='M150.874 89.413L154.273 89.8631C155.336 90.013 156.306 90.5488 156.999 91.3684C157.692 92.1879 158.059 93.2339 158.03 94.3067L157.799 98.7387C157.794 98.8994 157.776 99.0595 157.747 99.2177L155.329 112.577C155.156 113.498 154.63 114.315 153.864 114.854C153.098 115.393 152.151 115.611 151.226 115.463C150.244 115.323 149.339 114.855 148.656 114.136C147.974 113.416 147.556 112.486 147.469 111.498L146.494 97.6942C146.482 97.5347 146.482 97.3747 146.494 97.2152L146.719 92.7774C146.743 92.2732 146.874 91.7799 147.102 91.3297C147.33 90.8796 147.651 90.4827 148.043 90.165C148.436 89.8474 148.891 89.6162 149.378 89.4866C149.866 89.357 150.376 89.3319 150.874 89.413ZM150.603 119.168L151.526 119.289C152.477 119.423 153.346 119.902 153.966 120.636C154.587 121.369 154.916 122.305 154.891 123.265C154.87 123.718 154.755 124.161 154.551 124.566C154.347 124.971 154.06 125.328 153.709 125.614C153.357 125.9 152.949 126.109 152.511 126.226C152.073 126.343 151.616 126.367 151.168 126.295L150.245 126.168C149.294 126.035 148.426 125.557 147.806 124.824C147.185 124.092 146.856 123.157 146.881 122.197C146.9 121.744 147.015 121.3 147.219 120.895C147.422 120.49 147.709 120.132 148.061 119.846C148.413 119.56 148.821 119.351 149.259 119.234C149.697 119.117 150.155 119.095 150.603 119.168Z'
          fill='#D2CCFF'
      />
      <path
          d='M76.3428 80.8663L68.3732 111.088C68.1328 112.007 68.1295 112.971 68.3635 113.891C68.5974 114.811 69.0611 115.657 69.7109 116.349C70.3608 117.041 71.1757 117.556 72.0792 117.847C72.9827 118.139 73.9454 118.196 74.877 118.013L105.792 111.983C106.772 111.787 107.683 111.333 108.429 110.667C109.176 110.001 109.73 109.148 110.036 108.195C110.341 107.243 110.386 106.226 110.167 105.25C109.947 104.275 109.471 103.375 108.787 102.646L85.8647 78.4368C85.1772 77.7063 84.3066 77.1732 83.3434 76.8932C82.3801 76.6131 81.3594 76.5962 80.3875 76.8442C79.4155 77.0922 78.5277 77.5961 77.8164 78.3034C77.105 79.0107 76.5962 79.8957 76.3428 80.8663Z'
          fill='#D2CCFF'
      />
      <path
          d='M74.8132 115.578C74.2456 115.681 73.6612 115.638 73.1144 115.455C72.5675 115.271 72.076 114.952 71.6854 114.528C71.2824 114.119 70.9912 113.613 70.8397 113.06C70.6881 112.506 70.6812 111.923 70.8198 111.365L78.8067 81.0625C78.9452 80.533 79.2314 80.0538 79.6319 79.6809C80.0325 79.3079 80.5309 79.0566 81.0689 78.9562C81.637 78.8547 82.2215 78.8982 82.7683 79.0829C83.3151 79.2675 83.8064 79.5871 84.1967 80.0122L107.199 104.25C107.603 104.659 107.895 105.164 108.047 105.718C108.2 106.271 108.208 106.855 108.071 107.412C107.931 107.941 107.645 108.419 107.244 108.791C106.844 109.163 106.346 109.413 105.809 109.513L74.8132 115.578Z'
          fill='white'
      />
      <path
          d='M82.5699 88.178L84.5377 87.7914C85.1731 87.6888 85.824 87.8248 86.3651 88.1732C86.9063 88.5215 87.2996 89.0576 87.4693 89.6784L88.1734 92.3157C88.2001 92.4126 88.2213 92.5108 88.2369 92.6101L89.4892 100.914C89.5868 101.473 89.4596 102.048 89.1354 102.514C88.8112 102.98 88.3161 103.299 87.7579 103.402C87.1726 103.499 86.5716 103.393 86.055 103.101C85.5384 102.809 85.1374 102.349 84.9186 101.797L81.7216 93.9027C81.6828 93.8107 81.65 93.7162 81.6234 93.62L80.8963 90.9769C80.8018 90.6831 80.771 90.3725 80.806 90.0658C80.8411 89.7592 80.9411 89.4635 81.0995 89.1986C81.2579 88.9337 81.471 88.7056 81.7246 88.5296C81.9781 88.3536 82.2663 88.2338 82.5699 88.178ZM88.1388 105.652L88.6755 105.548C89.2446 105.45 89.83 105.568 90.3161 105.88C90.8022 106.192 91.1537 106.675 91.3012 107.233C91.3825 107.494 91.4081 107.768 91.3764 108.038C91.3446 108.309 91.2561 108.57 91.1167 108.804C90.9773 109.038 90.7901 109.24 90.5674 109.397C90.3446 109.554 90.0913 109.662 89.8239 109.715L89.2872 109.819C88.7188 109.918 88.1341 109.8 87.6481 109.489C87.1621 109.178 86.8101 108.697 86.6614 108.139C86.5801 107.879 86.5545 107.604 86.5862 107.333C86.618 107.062 86.7064 106.801 86.8457 106.567C86.985 106.332 87.1722 106.129 87.395 105.972C87.6177 105.814 87.8712 105.705 88.1388 105.652Z'
          fill='#D2CCFF'
      />
      <path
          d='M224.574 87.6413L220.096 89.0956L223.697 93.3545C224.719 92.4689 225.604 91.4361 226.322 90.2902C226.508 89.9657 226.559 89.5812 226.464 89.2196C226.369 88.858 226.136 88.5482 225.815 88.3569L224.574 87.6413Z'
          fill='#7F3E3B'
      />
      <path
          d='M218.036 92.6158L220.72 95.8764L223.703 93.3776L220.096 89.1187L218.036 92.6158Z'
          fill='#7F3E3B'
      />
      <path
          d='M226.022 52.8314C227.113 55.8322 228.117 58.8331 229.069 61.8744C230.022 64.9156 230.899 67.9684 231.666 71.1655L231.938 72.3947L232.065 73.0352L232.128 73.3584V73.4392V73.5604L232.163 73.8028C232.289 74.9007 232.234 76.0119 232.001 77.0922C231.633 78.8254 231.076 80.513 230.339 82.1244C228.994 85.1355 227.339 87.998 225.399 90.6653L222.404 89.009C223.466 86.1236 224.516 83.1747 225.29 80.3181C225.671 78.9795 225.938 77.6106 226.086 76.2265C226.134 75.7487 226.111 75.2663 226.017 74.7954L225.769 73.8951L225.445 72.8275C224.539 69.942 223.506 66.97 222.485 64.0153C221.463 61.0607 220.442 58.0771 219.461 55.1166L226.022 52.8314Z'
          fill='#7F3E3B'
      />
      <path
          d='M201.099 34.9763C201.174 35.3398 201.047 35.6745 200.816 35.738C200.585 35.8015 200.331 35.5476 200.239 35.1609C200.146 34.7743 200.285 34.4627 200.522 34.405C200.758 34.3472 201.024 34.6185 201.099 34.9763Z'
          fill='#263238'
      />
      <path
          d='M201.081 35.6688C200.885 36.8774 200.532 38.0551 200.03 39.1717C200.314 39.2862 200.62 39.3363 200.925 39.3183C201.23 39.3003 201.528 39.2148 201.796 39.0678L201.081 35.6688Z'
          fill='#630F0F'
      />
      <path
          d='M200.129 32.8179C200.094 32.818 200.06 32.8096 200.028 32.7935C199.997 32.7775 199.97 32.7542 199.95 32.7256C199.934 32.7026 199.922 32.6765 199.916 32.6489C199.909 32.6212 199.908 32.5926 199.913 32.5647C199.918 32.5367 199.928 32.5101 199.944 32.4862C199.959 32.4623 199.979 32.4417 200.002 32.4255C200.292 32.2459 200.622 32.1386 200.962 32.1125C201.302 32.0864 201.644 32.1423 201.958 32.2755C201.985 32.2862 202.009 32.3021 202.029 32.3222C202.049 32.3423 202.065 32.3662 202.076 32.3926C202.086 32.4189 202.092 32.4471 202.092 32.4756C202.091 32.504 202.085 32.5322 202.074 32.5583C202.052 32.6115 202.01 32.6541 201.957 32.6768C201.904 32.6994 201.845 32.7004 201.791 32.6794C201.545 32.5709 201.277 32.5231 201.009 32.5402C200.741 32.5573 200.48 32.6387 200.25 32.7775C200.216 32.8046 200.173 32.8188 200.129 32.8179Z'
          fill='#263238'
      />
      <path
          d='M213.465 36.1189C213.869 40.3086 213.274 44.3655 214.209 48.0069C214.209 48.0069 213.17 51.3886 206.978 51.3886C200.169 51.3886 203.856 48.0069 203.856 48.0069C207.607 47.124 207.613 44.3655 207.071 41.7744L213.465 36.1189Z'
          fill='#7F3E3B'
      />
      <path
          d='M215.531 49.1669C216.062 48.0992 216.414 46.5815 215.318 46.2006C214.111 45.7794 207.665 45.6235 205.328 46.4315C204.705 46.5961 204.171 46.998 203.84 47.5511C203.509 48.1042 203.407 48.7649 203.557 49.3919L215.531 49.1669Z'
          fill='#263238'
      />
      <path
          d='M229.445 201.149C229.87 201.039 230.28 200.88 230.668 200.676C230.689 200.666 230.707 200.65 230.719 200.631C230.731 200.611 230.738 200.589 230.738 200.566C230.737 200.542 230.729 200.519 230.715 200.501C230.7 200.482 230.68 200.468 230.657 200.462C230.455 200.393 228.666 199.798 228.193 200.202C228.141 200.247 228.103 200.305 228.081 200.37C228.06 200.435 228.057 200.505 228.072 200.572C228.081 200.692 228.12 200.809 228.187 200.91C228.254 201.011 228.345 201.093 228.452 201.149C228.774 201.26 229.124 201.26 229.445 201.149ZM230.299 200.572C229.514 200.93 228.879 201.051 228.568 200.889C228.496 200.854 228.434 200.8 228.39 200.733C228.345 200.666 228.319 200.588 228.314 200.508C228.305 200.482 228.304 200.454 228.311 200.427C228.318 200.4 228.333 200.376 228.354 200.358C228.591 200.174 229.56 200.376 230.299 200.595V200.572Z'
          fill='#D2CCFF'
      />
      <path
          d='M230.645 200.687C230.661 200.683 230.676 200.676 230.688 200.666C230.701 200.657 230.712 200.644 230.72 200.63C230.728 200.616 230.733 200.601 230.735 200.585C230.737 200.569 230.736 200.553 230.732 200.537C230.732 200.479 230.264 199.106 229.578 198.806C229.497 198.764 229.409 198.739 229.319 198.733C229.228 198.727 229.138 198.74 229.052 198.771C228.966 198.791 228.889 198.841 228.836 198.912C228.782 198.982 228.754 199.069 228.758 199.158C228.758 199.735 230.005 200.554 230.57 200.71L230.645 200.687ZM229.203 198.956C229.3 198.933 229.402 198.946 229.491 198.991C229.932 199.349 230.256 199.831 230.42 200.376C229.843 200.116 229.024 199.458 229.012 199.123C229.012 199.094 229.012 199.025 229.151 198.967L229.203 198.956Z'
          fill='#D2CCFF'
      />
      <path
          d='M197.924 204.675C198.362 204.663 198.798 204.603 199.223 204.496C199.245 204.489 199.266 204.477 199.282 204.459C199.298 204.442 199.31 204.421 199.315 204.398C199.317 204.374 199.313 204.351 199.303 204.329C199.292 204.308 199.277 204.29 199.257 204.277C199.078 204.167 197.474 203.18 196.92 203.463C196.858 203.494 196.807 203.542 196.771 203.601C196.735 203.661 196.717 203.729 196.718 203.798C196.706 203.913 196.721 204.03 196.763 204.139C196.805 204.247 196.873 204.344 196.96 204.421C197.245 204.609 197.584 204.698 197.924 204.675ZM198.882 204.329C198.04 204.496 197.387 204.467 197.116 204.236C197.055 204.184 197.009 204.118 196.981 204.044C196.952 203.969 196.943 203.888 196.955 203.809C196.955 203.717 196.995 203.688 197.03 203.671C197.312 203.526 198.23 203.948 198.882 204.329Z'
          fill='#D2CCFF'
      />
      <path
          d='M199.194 204.496C199.217 204.501 199.241 204.501 199.263 204.496C199.281 204.483 199.294 204.467 199.303 204.448C199.312 204.429 199.317 204.407 199.315 204.386C199.315 204.329 199.188 202.892 198.611 202.418C198.544 202.358 198.463 202.314 198.377 202.288C198.29 202.262 198.199 202.255 198.109 202.268C198.021 202.268 197.935 202.298 197.867 202.354C197.798 202.41 197.752 202.488 197.734 202.574C197.63 203.151 198.623 204.219 199.142 204.502L199.194 204.496ZM198.19 202.482C198.289 202.48 198.386 202.515 198.461 202.58C198.807 203.031 199.008 203.576 199.038 204.144C198.525 203.751 197.884 202.932 197.953 202.603C197.953 202.603 197.953 202.499 198.127 202.482H198.178H198.19Z'
          fill='#D2CCFF'
      />
      <path
          d='M204.555 204.317H199.996L199.95 193.768H204.509L204.555 204.317Z'
          fill='#7F3E3B'
      />
      <path
          d='M236.289 200.416L231.903 201.218L228.758 190.836L233.144 190.034L236.289 200.416Z'
          fill='#7F3E3B'
      />
      <path
          d='M231.072 200.052L236.053 198.898C236.139 198.877 236.23 198.886 236.311 198.925C236.391 198.965 236.455 199.03 236.491 199.112L238.142 202.88C238.181 202.974 238.2 203.076 238.196 203.178C238.192 203.28 238.166 203.38 238.119 203.471C238.072 203.562 238.006 203.641 237.925 203.703C237.844 203.766 237.751 203.81 237.651 203.832C235.92 204.213 235.043 204.305 232.85 204.825C231.505 205.142 228.758 205.944 226.894 206.383C225.03 206.822 224.43 205.021 225.163 204.652C228.429 203.088 229.514 201.766 230.409 200.497C230.57 200.274 230.805 200.117 231.072 200.052Z'
          fill='#263238'
      />
      <path
          d='M200.048 203.786H205.022C205.111 203.786 205.198 203.817 205.266 203.874C205.335 203.931 205.381 204.01 205.397 204.098L206.142 208.137C206.16 208.237 206.155 208.34 206.129 208.437C206.103 208.535 206.055 208.626 205.989 208.703C205.924 208.781 205.842 208.842 205.749 208.884C205.657 208.926 205.556 208.947 205.455 208.945C203.66 208.916 201.075 208.813 198.819 208.813C196.181 208.813 195.241 208.957 192.147 208.957C190.278 208.957 189.839 207.064 190.624 206.897C194.213 206.112 195.783 206.031 198.94 204.132C199.27 203.918 199.654 203.798 200.048 203.786Z'
          fill='#263238'
      />
      <path
          d='M224.366 49.1842C228.619 52.1793 229.901 59.8892 229.901 59.8892L220.263 64.7713C218.739 61.7241 217.558 58.517 216.743 55.209C215.71 50.65 221.342 47.0605 224.366 49.1842Z'
          fill='#D2CCFF'
      />
      <path
          opacity='0.2'
          d='M219.548 54.101L223.345 63.1959L220.264 64.7713C219.308 62.8404 218.476 60.8513 217.771 58.8158L219.548 54.101Z'
          fill='black'
      />
      <path
          d='M196.026 69.1745C196.06 74.2587 196.366 80.5835 197.122 88.4319H221.481C221.775 84.854 220.281 67.9857 223.911 51.0943C223.975 50.7969 223.977 50.4895 223.919 50.1909C223.86 49.8924 223.74 49.6091 223.568 49.3584C223.395 49.1076 223.174 48.8947 222.916 48.7328C222.658 48.5709 222.37 48.4633 222.07 48.4166C220.158 48.1108 218.232 47.9047 216.299 47.7992C212.849 47.5999 209.39 47.5999 205.94 47.7992C204.548 47.9198 203.163 48.1124 201.791 48.3763C200.77 48.5836 199.829 49.075 199.076 49.7937C198.322 50.5125 197.787 51.4294 197.532 52.439C196.774 55.5513 196.327 58.7313 196.199 61.9321C196.089 64.0096 196.008 66.416 196.026 69.1745Z'
          fill='#D2CCFF'
      />
      <path
          opacity='0.2'
          d='M203.712 59.6295C202.102 60.7837 199.02 61.3607 196.21 61.5973C196.256 60.8529 196.314 60.1489 196.377 59.4852C197.133 59.087 197.86 58.7234 198.547 58.3887L203.712 59.6295Z'
          fill='black'
      />
      <g opacity='0.2'>
        <path
            d='M199.95 193.768L199.979 199.631H204.532L204.509 193.768H199.95Z'
            fill='black'
        />
        <path
            d='M233.145 190.034L228.759 190.842L230.386 196.192L234.772 195.384L233.145 190.034Z'
            fill='black'
        />
      </g>
      <path
          d='M212.825 31.2137C213.488 35.6976 214.106 38.283 212.374 41.1107C211.825 42.0303 211.034 42.782 210.088 43.2845C209.141 43.7871 208.076 44.0213 207.006 43.9619C205.936 43.9025 204.903 43.5516 204.019 42.9474C203.134 42.3431 202.431 41.5084 201.987 40.5336C200.204 36.8056 199.413 30.0941 203.32 27.2606C204.149 26.627 205.129 26.2226 206.164 26.088C207.198 25.9535 208.25 26.0935 209.213 26.4941C210.176 26.8947 211.017 27.5417 211.651 28.3702C212.285 29.1986 212.69 30.1793 212.825 31.2137Z'
          fill='#7F3E3B'
      />
      <path
          d='M203.499 34.4915C201.808 33.7471 201.97 31.0867 202.484 29.9671C200.527 30.6366 197.699 31.0232 197.192 29.0323C196.684 27.0413 198.409 26.4065 197.353 24.7041C196.297 23.0017 199.662 21.1492 201.231 23.5499C202.801 25.9506 206.679 24.8772 209.184 22.5862C211.007 20.9184 213.673 23.0709 212.617 25.2466C212.617 25.2466 218.1 24.8945 217.009 28.6168C215.918 32.339 214.377 32.4659 214.729 35.1148C214.972 36.9037 215.179 40.5394 213.033 41.053C210.436 41.6474 203.961 39.3448 203.499 34.4915Z'
          fill='#263238'
      />
      <path
          opacity='0.2'
          d='M203.465 39.3448C202.968 39.7026 202.455 40.0835 201.93 40.499L201.959 40.5625C202.471 41.6439 203.293 42.5495 204.319 43.1651C204.648 42.8477 204.832 41.7801 205.444 41.7974C207.274 41.8494 210.811 41.0819 211.4 39.0159L203.465 39.3448Z'
          fill='black'
      />
      <path
          d='M204.89 34.428C205.016 35.4251 204.864 36.4376 204.451 37.3539C203.932 38.4099 203.112 37.7347 202.795 36.4363C202.507 35.2821 202.432 33.3142 203.182 32.7372C203.932 32.1601 204.746 33.1123 204.89 34.428Z'
          fill='#7F3E3B'
      />
      <path
          d='M205.767 88.4492C205.767 88.4492 209.593 123.259 211.924 136.301C214.481 150.584 227.719 195.413 227.719 195.413L235.879 193.681C235.879 193.681 226.767 151.739 225.52 137.6C224.187 122.249 221.481 88.4492 221.481 88.4492H205.767Z'
          fill='#D2CCFF'
      />
      <path
          opacity='0.3'
          d='M205.767 88.4492C205.767 88.4492 209.593 123.259 211.924 136.301C214.481 150.584 227.719 195.413 227.719 195.413L235.879 193.681C235.879 193.681 226.767 151.739 225.52 137.6C224.251 123.011 224.251 123.04 221.481 88.4492H205.767Z'
          fill='black'
      />
      <path
          d='M225.896 192.671C225.862 192.671 227.281 195.649 227.281 195.649L236.486 193.681L235.834 190.9L225.896 192.671Z'
          fill='#263238'
      />
      <path
          opacity='0.2'
          d='M208.555 100.857C214.487 102.011 212.992 124.315 211.833 135.782C210.424 127.703 208.503 112.081 207.216 101.018C207.63 100.812 208.103 100.755 208.555 100.857Z'
          fill='black'
      />
      <path
          d='M197.122 88.4492C197.122 88.4492 195.183 122.497 195.143 136.4C195.097 150.827 198.317 198.148 198.317 198.148H206.038C206.038 198.148 208.116 151.687 209.005 137.496C209.974 122.024 213.142 88.4435 213.142 88.4435L197.122 88.4492Z'
          fill='#D2CCFF'
      />
      <path
          opacity='0.3'
          d='M197.122 88.4492C197.122 88.4492 195.183 122.497 195.143 136.4C195.097 150.827 198.317 198.148 198.317 198.148H206.038C206.038 198.148 208.116 151.687 209.005 137.496C209.974 122.024 213.142 88.4435 213.142 88.4435L197.122 88.4492Z'
          fill='black'
      />
      <path
          d='M196.955 195.084C196.92 195.084 197.266 198.286 197.266 198.286H206.65L207.013 195.401L196.955 195.084Z'
          fill='#263238'
      />
      <path
          d='M206.777 56.3285C205.853 56.4151 205.074 56.467 204.226 56.4901C203.378 56.5132 202.57 56.5305 201.745 56.5247C200.083 56.5247 198.421 56.4209 196.747 56.2362C195.048 56.061 193.363 55.7719 191.703 55.3706C189.945 54.9575 188.238 54.3517 186.613 53.5643L186.423 53.4662C186.066 53.2922 185.748 53.0494 185.486 52.7518C185.224 52.4542 185.023 52.1077 184.895 51.7322C184.768 51.3567 184.716 50.9597 184.742 50.564C184.768 50.1683 184.873 49.7817 185.049 49.4266C185.13 49.2628 185.225 49.1064 185.332 48.9591C186.021 48.0463 186.805 47.2087 187.669 46.4603C188.448 45.7678 189.228 45.1503 190.012 44.5617C191.582 43.3787 193.186 42.3341 194.796 41.3185C196.406 40.3028 198.051 39.3621 199.707 38.433C201.364 37.5039 203.031 36.6671 204.763 35.865C205.143 35.7128 205.566 35.7006 205.954 35.8306C206.343 35.9605 206.673 36.2241 206.886 36.5745C207.098 36.9249 207.18 37.3394 207.115 37.7442C207.051 38.1489 206.845 38.5178 206.534 38.785C205.086 39.8988 203.591 41.0011 202.143 42.1322C200.694 43.2632 199.257 44.377 197.82 45.5197C196.383 46.6623 195.016 47.828 193.706 48.9822C193.054 49.5593 192.425 50.1364 191.865 50.7654C191.361 51.2769 190.905 51.8332 190.503 52.4274L189.228 47.8107C190.485 48.2772 191.783 48.6231 193.106 48.8437C194.521 49.0842 195.949 49.2498 197.382 49.34C198.842 49.4323 200.319 49.4843 201.802 49.4843C202.547 49.4843 203.291 49.4843 204.03 49.4843C204.768 49.4843 205.548 49.4266 206.194 49.3919H206.269C207.142 49.391 207.983 49.7198 208.624 50.3125C209.264 50.9051 209.658 51.718 209.725 52.5882C209.792 53.4585 209.528 54.322 208.985 55.0059C208.443 55.6898 207.663 56.1436 206.8 56.2766L206.777 56.3285Z'
          fill='#7F3E3B'
      />
      <path
          d='M210.008 34.9647L207.123 35.6053C207.123 35.6053 203.262 35.1263 202.379 38.0348L204.687 39.9854L208.219 40.5625C208.778 40.6494 209.35 40.5565 209.852 40.297L210.752 39.8296C210.957 39.7242 211.132 39.5691 211.261 39.3786C211.39 39.188 211.469 38.968 211.491 38.7389L211.705 36.4305C211.726 36.2119 211.694 35.9914 211.613 35.7875C211.531 35.5837 211.402 35.4023 211.236 35.2587C211.069 35.1152 210.871 35.0135 210.658 34.9624C210.444 34.9112 210.221 34.912 210.008 34.9647Z'
          fill='#7F3E3B'
      />
      <path
          d='M204.376 59.6987C199.523 61.0491 193.988 59.364 193.988 59.364L196.025 48.4974C196.025 48.4974 200.844 48.0704 203.856 48.0069C211.607 47.8223 213.125 57.275 204.376 59.6987Z'
          fill='#D2CCFF'
      />
    </svg>
);