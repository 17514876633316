import { combineReducers } from 'redux'
import marketingReducer from "./marketingReducer";
import marketingTemplateReducer from "./marketingTemplateReducer"
import cardBroadcastReducer from '../components/marketing/DirectCardSend/redux/cardBroadcastReducer';
import globalReducer from "./globalReducer";
import videoEmailReducer from "../components/Video/redux/videoEmailReducer";
import quickRepliesReducer from './quickRepliesReducer';

export default combineReducers({
    marketingReducer: marketingReducer,
    cardBroadcastReducer: cardBroadcastReducer,
    marketingTemplateReducer: marketingTemplateReducer,
    globalReducer: globalReducer,
    videoEmailReducer: videoEmailReducer,
    quickRepliesReducer: quickRepliesReducer
})