/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from "react";
import { connect } from "react-redux";
import SMSModal from "./SMSModal";
import EmailModal from "./EmailModal";
import VoiceMailModal from "./VoiceMailModal";
import {
    getUserInfo,
    fetchCampaignDetails,
    updateTabValue,
    updateHour,
    updateMin,
    updateDay,
    updateRecurringDay,
    updateLaterHour,
    updateLaterMin,
    updateTime,
    updateStartDate,
    updateArrSlotTimeHour1,
    updateArrSlotTimeMin1,
    updateGreetingOption,
    updateGreetingsOptionTimeMin1,
    updateGreetingsOptionTimeHour1,
    updateRecurringTimeHour1,
    updateRecurringTimeMin1,
    reloadCampaignSettingsStatus,
    followupSave,
    updateEmailStepSetting,
    updateVideoEmailStepSetting,
    fetchCampaignTimelines, updateReducerState, deleteCampaignSetting, updateCampaignSetting
} from "../../../actions/marketingAction";
import moment from 'moment';

import CallBridgeModal from "./CallBridgeModal";
import WebHookModal from "./WebhookModal";
import Menu from '@material-ui/core/Menu';
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ActionModal from "./ActionModal";
import ActivityModal from "./ActivityModal";
import CampaignTimeline from "./CampaignTimeline";
import CardMultiStepForm from "../DirectCardSend/CardMultiStepForm"
import CoreConstants from "../../../constants/CoreConstants";
import Utils from "../../../helpers/Utils";
import {
    cloneSmartWebhookApi,
    collectionSmartWebhooksDetailsApi,
    editCollectionSmartWebhooksApi
} from "../../../api/smartWebhookApi";
import SmartWebhookDrawer from "../smartWebhookBuilder/SmartWebhookDrawer";
import {confirmAlert} from "react-confirm-alert";

// import Button from '@material-ui/core/Button';

const isEmpty = (obj) => {
    for (const prop in obj) {
        if (Object.hasOwn(obj, prop)) {
            return false;
        }
    }

    return true;
}

const POSTCARD = 6;
const GREETING_CARD = 7;
const GIFT = 8;
const LETTER = 9;
const THANKSIO_GREETINGCARD = 10;

const thanksIoActive = (Utils.getAccountData('customMenu')['63'] && Utils.getAccountData('customMenu')['63'] 
    && Utils.getAccountData('customMenu')['63'] !== null && Utils.getAccountData('customMenu')['63'] !== "") ? true :false

const zenDirectActive = (Utils.getAccountData('customMenu')['61'] && Utils.getAccountData('customMenu')['61'] 
    && Utils.getAccountData('customMenu')['61'] !== null && Utils.getAccountData('customMenu')['61'] !== "") ? true :false


const CampaignSettingSection = props => {
    const [openSMSModal, setOpenSMSModal] = useState(false);
    const [openEmailModal, setOpenEmailModal] = useState(false);
    const [openVoiceMailModal, setOpenVoiceMailModal] = useState(false);
    const [openCallBridgeModal, setOpenCallBridgeModal] = useState(false);
    const [openWebhookModal, setOpenWebhookModal] = useState(false);
    const [openActionModal, setOpenActionModal] = useState(false);
    const [openActivityModal, setOpenActivityModal] = useState(false);
    const [campaignDetails, setCampaignDetails] = useState(props.campaignDetails);
    const [anchorActionMenu, setAnchorActionMenu] = useState(null);
    const [mailOptionVisible, setMailOptionVisible] = useState(false);

    let updateSetting = false;
    const [editableCampaign, setEditableCampaign] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    //For Card
    const [viewCardModal, setViewCardModal] = useState(false);
    const [activeMailItemType, setActiveMailItemType] = useState('');

    //For Smart Webhook
    const [openSmartWebhookBuilder, setOpenSmartWebhookBuilder] = useState(false);
    const [isSubmittingAddSmartWebhookForm, setIsSubmittingAddSmartWebhookForm] = useState(false);
    const [selectedSmartWebhookTemplate, setSelectedSmartWebhookTemplate] = useState(null);

    useEffect(() => {
        if (props.userInfo === null) {
            props.getUserInfo();
        } 
    }, [props.userInfo]);

    useEffect(() => {
        setCampaignDetails(props.campaignDetails);
    }, [props.campaignDetails]);

    const selectActiveMailType = (type) => {

        // window.openGlobalDirectMailModal({
        //     messageType: type, contactId: props.row.id, contactDetails: props.row,
        //     from: 'inbox'
        // })
        setMailOptionVisible(false);
        // setEditableCampaign(null);
        // setActiveMailItemType(type);
        // setViewCardModal(true);
        // setMailOptionVisible(false);
        window.openGlobalDirectMailModal({
            messageType: type, from: 'campaign-details', directMailCampaign : campaignDetails.basic ,
            callBack :campaignSettingSubmitSuccess
        })
    }

    const handleActionClick = () => {
        setAnchorActionMenu(null);
        setOpenActionModal(true);
    }

    const handleActivityClick = () => {
        setAnchorActionMenu(null);
        setOpenActivityModal(true);
    }

    const handleWebhookClick = () => {
        setAnchorActionMenu(null);
        setOpenWebhookModal(true);
    }


    const updateScheduleTimeSettings = (each) => {
        if(each!= null){

            let selectedTab = 0;
            let mint = 0;
            let hour = 0;
            let time = '12:00';
            if(each.type > 1){
                if(each.type === 2 && each.day === 1){
                    selectedTab = 1;
                    let timeData = each.hour.split(":");
                    hour = parseInt(timeData[0]);
                    mint = parseInt(timeData[1]);
                }else{
                    selectedTab = each.type;
                }
                time = each.hour;
            }
            else{
                if(each.type === 1 ){
                    selectedTab = 0;
                }else {
                    selectedTab =  1;
                    let timeData =each.hour.split(":");
                    hour = parseInt(timeData[0]);
                    mint = parseInt(timeData[1]);
                }
            }

            if(selectedTab == 5){
                props.updateDay(each.day)
                props.updateRecurringTimeHour1(each.hour.split(":")[0]);
                props.updateRecurringTimeMin1(each.hour.split(":")[1]);
                props.updateTime(each.hour)
            }
            else if(selectedTab == 4){
                props.updateGreetingOption(each.greeting_option)
                props.updateGreetingsOptionTimeHour1(each.hour.split(":")[0]);
                props.updateGreetingsOptionTimeMin1(each.hour.split(":")[1]);
                props.updateTime(each.hour)
            }
            else if(selectedTab == 2){
                props.updateDay(each.day)
                props.updateLaterHour(each.hour.split(":")[0]);
                props.updateLaterMin(each.hour.split(":")[1]);
                props.updateTime(each.hour)
            }
            else if(selectedTab == 3){
                // props.updateStartDate(each.fixed_date)
                props.updateStartDate(window.globalTimezoneConversionToDifferentTimezone((each.fixed_date + ' ' + each.hour),'UTC', props.campaignTimezone, 'MM/DD/YYYY'))
                let eachHour = window.globalTimezoneConversionToDifferentTimezone((each.fixed_date + ' ' + each.hour),'UTC', props.campaignTimezone, 'hh:mm:ss A');
                props.updateArrSlotTimeHour1(eachHour.split(":")[0]);
                props.updateArrSlotTimeMin1(eachHour.split(":")[1]);
                // props.updateTime(each.hour)
                props.updateTime(window.globalTimezoneConversionToDifferentTimezone((each.fixed_date + ' ' + each.hour),'UTC', props.campaignTimezone, 'hh:mm:ss A'))
            }
            else{
                props.updateTime(time)
            }

            props.updateTabValue(selectedTab)
            props.updateHour(hour)
            props.updateMin(mint)
        }
        else{
            props.updateTabValue(0)
        }
    }

    const handleTimelineEdit = (each, type) => {
        if(type == 21 || type == 22 || type == 24){
            if(each.template_pdf_path === null){
                let type_text = 'postcard';
                if(type == 21){
                    type_text = 'postcard'
                }
                else if(type == 22){
                    // type_text = 'letter'
                    type_text = 'notecard'
                }
                else if(type == 23){
                    // type_text = 'notecard'
                    type_text = 'zendirect_gift'
                }
                else if(type == 24){
                    // type_text = 'zendirect_gift'
                    type_text = 'letter'
                }
                handleClickDirectMailV2(type_text, each)
                return
            }
        }
        else if(type == 23){
            handleClickDirectMailV2('zendirect_gift', each)
            return
        }

        updateScheduleTimeSettings(each)

        switch (type.toString()) {
            case '1':
                setEditableCampaign(each)
                setOpenSMSModal(true)
                break;
            case '2':
                // code block
                setEditableCampaign(each)
                setOpenSMSModal(true)
                break;
            case '3':
                setEditableCampaign(each)
                setOpenVoiceMailModal(true)
                break;
            case '4':
                setEditableCampaign(each)
                setOpenEmailModal(true)
                break;
            case '5':
                setEditableCampaign(each)
                setOpenCallBridgeModal(true)
                break;
            case '6':
                setEditableCampaign(each)
                setOpenActivityModal(true)
                break;
            case '7':
                setEditableCampaign(each)
                setOpenActionModal(true)
                break;
            case '9':
                setEditableCampaign(each)
                setOpenWebhookModal(true)
                break;
            case '14':
                setEditableCampaign(each)
                updateSetting = true;
                window.sendCommunication({
                    open: true,
                    from: 'campaign',
                    showAllTimeSettings: true,
                    currentClip: each,
                    campaignId: campaignDetails.basic.id,
                    handleRemoveCurrentClip: handleRemoveCurrentClip,
                    responseCallback: (response) => updateVideoEmailSetting(response)
                }, 'videoEmail')
                break;
            case '21':
                setEditableCampaign(each)
                editDirectMailSettings(POSTCARD,each)
                break;
            case '22':
                setEditableCampaign(each)
                // editDirectMailSettings(THANKSIO_GREETINGCARD,each)
                editDirectMailSettings(THANKSIO_GREETINGCARD,each)
                break;
            case '23':
                setEditableCampaign(each)
                // editDirectMailSettings(LETTER, each)
                editDirectMailSettings(GIFT, each)
                break;
            case '24':
                setEditableCampaign(each)
                // editDirectMailSettings(GIFT, each)
                editDirectMailSettings(LETTER, each)
                break;
            case '31':
                setEditableCampaign(each);
                onOpenWebhookBuilderModal(each);
            default:
            // code block
        }

    }

    const editDirectMailSettings=(messageType, editableCampaign)=>{
        // setActiveMailItemType(messageType);
        // setViewCardModal(true);
        // setMailOptionVisible(false);
        window.openGlobalDirectMailModal({
            messageType: messageType, from: 'campaign-details', directMailCampaign : campaignDetails.basic ,
            callBack :campaignSettingSubmitSuccess, editableCampaign : editableCampaign
        })
    }

    const handleCloseVoiceModal = () => {
        setOpenVoiceMailModal(false);
        setEditableCampaign(null);
    }

    const handleCloseSmsModal = () => {
        setOpenSMSModal(false);
        setEditableCampaign(null);
    }

    const handleCloseEmailModal = () => {
        setOpenEmailModal(false);
        setEditableCampaign(null);
    }

    // const handleCloseVideoEmailModal = () => {
    //     setEditableCampaign(null);
    // }

    const handleCloseCallBridgeModal = () => {
        setOpenCallBridgeModal(false)
        setEditableCampaign(null)
    }

    const handleCloseWebhookModal = () => {
        setOpenWebhookModal(false)
        setEditableCampaign(null)
    }

    const handleRemoveCurrentClip = () => {
        setEditableCampaign(null)
    }

    const closeActionModel = () => {
        handleRemoveCurrentClip();
        setOpenActionModal(false);
    }

    const closeActivityModel = () => {
        handleRemoveCurrentClip();
        setOpenActivityModal(false);
    }

    // const closeWebhookModel = () => {
    //     handleRemoveCurrentClip();
    //     setOpenWebhookModal(false);
    // }

    // const closeCallBridgeModel = () => {
    //     handleRemoveCurrentClip();
    //     setOpenCallBridgeModal(false);
    // }

    // const handleCloseActivityeModel  = () =>{
    //     handleRemoveCurrentClip();
    //     setOpenActivityModal(false);
    // }

    const saveCamapaignSettings=async(formInfo, editableCampaignSettingId=null)=>{
        // console.log(formInfo)

        let scheduleType;
        switch (props.tabValue) {
            case 1:
            case 2:
                scheduleType = CoreConstants.SENDING_TYPE_SCHEDULE;
            break;
            case 0:
                scheduleType = CoreConstants.SENDING_TYPE_IMMEDIATE;
                break;
            default:
                scheduleType = props.tabValue;
            break;
        }

        let formData = new FormData();
        for ( var key in formInfo ) {
            formData.append(key, formInfo[key]);
        }
        formData.append('nScheduleHours', props.hour);
        formData.append('nScheduleMinutes', props.min);
        formData.append('nScheduleDays', props.day);
        formData.append('followupScheduleTime', props.time);
        formData.append('campaign_id', campaignDetails.basic.id);
        formData.append('type', scheduleType);
        formData.append('day_type', (props.tabValue !== 0 ? props.tabValue : 1));
        formData.append('date', moment(props.startDate).format('YYYY-MM-DD'));
        formData.append('greeting_option', props.greetingOption);
        // for (let [key, value] of formData.entries()) {
        //     console.log(`${key}: ${value}`);
        //   }
        if (editableCampaignSettingId!=null) {

         formData.append('id', editableCampaignSettingId);
         await props.updateEmailStepSetting(formData, (response) => {
            // props.handleRemoveCurrentClip()
            if(response.status === 'success'){
                window.showNotification('SUCCESS', 'Campaign Settings updated successfully')
                campaignSettingSubmitSuccess()
            }else{
               window.showNotification('ERROR', 'Something went wrong')
            }
         })
      }
      else {
         await props.followupSave(formData, (response)=>{

            if(response.status === 'success'){
                window.showNotification('SUCCESS', 'Campaign Settings saved successfully')
                campaignSettingSubmitSuccess()
            }else{
               window.showNotification('ERROR', 'Something went wrong')
            }
         });

      }
    }

    const campaignSettingSubmitSuccess=()=>{
        setEditableCampaign(null);
        setActiveMailItemType('');
        setViewCardModal(false);
        setMailOptionVisible(false);
        props.reloadCampaignSettingsStatus({'status': true});
    }

    const updateVideoEmailSetting = campaignData => {
        if (updateSetting) {
            if (!submitting) {
                setSubmitting(true);
                props.updateVideoEmailStepSetting(campaignData, () => {
                    if (window.sendCommunication) {
                        window.sendCommunication({open: false}, 'videoEmail');
                    }
                    setSubmitting(false);
                    updateSetting = false;
                    handleRemoveCurrentClip()
                    props.fetchCampaignTimelines({
                        campaignId: campaignDetails.basic.id
                    });
                })
            }
        } else {
            setSubmitting(true);
            props.followupSave(campaignData, successFollowupAdd);
        }
    }

    const successFollowupAdd = (response) => {
        props.fetchCampaignTimelines({
            campaignId: campaignDetails.basic.id
        });
        setSubmitting(false);
        if (window.sendCommunication) {
            window.sendCommunication({open: false}, 'videoEmail');
        }
        window.showNotification('SUCCESS', 'Campaign setting saved successfully');
    }

    const renderDirectMailV2option = () => {
        if(Utils.getAccountData('userId') == 1){
            return(
                <div>
                    <div onClick={() => handleClickDirectMailV2('postcard')}>Postcard*</div>
                    <div onClick={() => handleClickDirectMailV2('letter')}>Letter*</div>
                    <div onClick={() => handleClickDirectMailV2('notecard')}>Notecard*</div>
                    <div onClick={() => handleClickDirectMailV2('zendirect_gift')}>Gift*</div>
                </div>
            )
        }
        return null;
    }
    
    const handleClickDirectMailV2 = (type = 'postcard', editData = null) => {
        setMailOptionVisible(false);
        let edit_data = {}
        if(editData != null){
            edit_data['edit'] = true;
            edit_data['editData'] = editData;
            edit_data['campaignSettingId'] = editData.id;
        }
        window.sendGlobalDirectMailV2({
            from: '/campaign/details/'+campaignDetails.basic.id,
            card_type: type,
            for: 'campaign-setting',
            campaignSetting: {
                campaignId: campaignDetails.basic.id,
                ...edit_data
            }
        })
    }

    const onOpenWebhookBuilderModal = (data=null) => {
        if (data){
            getWebhookDetails({ id: data.message });
        }else {
            setAnchorActionMenu(null);
            setOpenSmartWebhookBuilder(true);
        }
    }

    const onCloseWebhookBuilderModal = () => {
        setEditableCampaign(null);
        resetScheduleSetting();
        setSelectedSmartWebhookTemplate(null);
        setOpenSmartWebhookBuilder(false);
    }

    const handleSubmitWebhookForm = (payload) => {
        payload.isTemplate = false;

        let formData = new FormData();
        const settingTypeSmartWebhook = 31;

        let scheduleType;
        switch (props.tabValue) {
            case 1:
            case 2:
                scheduleType = CoreConstants.SENDING_TYPE_SCHEDULE;
                break;
            case 0:
                scheduleType = CoreConstants.SENDING_TYPE_IMMEDIATE;
                break;
            default:
                scheduleType = props.tabValue;
                break;
        }

        formData.append("message_type", JSON.stringify(settingTypeSmartWebhook));
        formData.append("nScheduleHours", props.hour);
        formData.append("nScheduleMinutes", props.min);
        formData.append("nScheduleDays", props.day);
        formData.append("followupScheduleTime", props.time);
        formData.append("campaign_id", campaignDetails.basic.id);
        formData.append("type", scheduleType);
        formData.append("day_type", (props.tabValue !== 0 ? props.tabValue : 1));
        formData.append("date", moment(props.startDate).format("YYYY-MM-DD"));
        formData.append("greeting_option", props.greetingOption);

        if (selectedSmartWebhookTemplate){
            formData.append("id", editableCampaign.id);
            formData.append("message", selectedSmartWebhookTemplate.id);

            editSmartWebhook(payload, (res)=>{
                if (res && res.status === "success"){
                    onCloseWebhookBuilderModal();
                    props.updateCampaignSetting(formData, successFollowupAdd);
                }
            });
        }else {
            setIsSubmittingAddSmartWebhookForm(true);
            props.followupSave(formData, (res)=>{
                if(res && res.status == "success"){
                    let settingId = res.data.id;
                    payload.webhookActionSourceId = settingId;
                    payload.webhookActionSourceModel = 1;

                    cloneSmartWebhookApi(payload)
                        .then((res)=>{
                            if(res.success){
                                let id = res.id;
                                formData.append("id", settingId);
                                formData.append("message", id);
                                props.updateCampaignSetting(formData, successFollowupAdd);
                                onCloseWebhookBuilderModal();
                            }else {
                                props.deleteCampaignSetting({ campaign_settings_id: settingId }, (response) => {
                                    setIsSubmittingAddSmartWebhookForm(false);
                                })
                                window.showNotification("Error",res.message);
                            }
                        }).catch((err)=>{
                        props.deleteCampaignSetting({ campaign_settings_id: settingId }, (response) => {
                            setIsSubmittingAddSmartWebhookForm(false);
                        })
                        window.showNotification("Error","Unable to create webhook template");
                    }).finally(()=>{
                        setIsSubmittingAddSmartWebhookForm(false);
                    });
                }else {
                    setIsSubmittingAddSmartWebhookForm(false);
                    window.showNotification("Error","Unable to create webhook template");
                }
            });
        }
    }

    const getWebhookDetails = (data) => {
        collectionSmartWebhooksDetailsApi({ id: data.id })
            .then((res)=>{
                if(res.success){
                    let cleanData = {};
                    if (res.bodyType !== undefined && res.bodyType !== null){
                        cleanData.bodyType = res.bodyType;
                    }

                    if (res.method !== undefined && res.method !== null){
                        cleanData.method = res.method;
                    }

                    if (res.authType !== undefined && res.authType !== null){
                        cleanData.authType = res.authType;
                    }

                    if (res.headers){
                        cleanData.headers = res.headers;
                    }

                    if (res.params){
                        cleanData.params = res.params;
                    }

                    if (res.authData){
                        cleanData.authData = res.authData;
                    }

                    if (res.bodyContent){
                        cleanData.bodyData = res.bodyContent;
                    }

                    if (res.title){
                        cleanData.title = res.title;
                    }

                    if (res.url){
                        cleanData.url = res.url;
                    }

                    if (data.index !== undefined && data.index !== null){
                        cleanData.index = data.index;
                    }

                    if(res.id){
                        cleanData.id = res.id;
                    }

                    if(res.collectionId){
                        cleanData.collectionId = res.collectionId;
                    }

                    if (!isEmpty(cleanData)){
                        setSelectedSmartWebhookTemplate(cleanData);
                    }

                    setOpenSmartWebhookBuilder(true);
                }else {
                    window.showNotification("Error","Something went wrong! Try again later.");
                }
            }).catch((err)=>{
            window.showNotification("Error","Something went wrong! Try again later.");
        });
    };


    const editSmartWebhook = (payload, callback) => {
        setIsSubmittingAddSmartWebhookForm(true);

        editCollectionSmartWebhooksApi({ ...payload, id: selectedSmartWebhookTemplate.id })
            .then((res)=>{
                if (res.success){
                    callback({ status: "success" });
                    window.showNotification("Success","Smart webhook template updated");
                }else {
                    callback({ status: "error" });
                    window.showNotification("Success",res.message);
                }
            }).catch((err)=>{
            callback({ status: "error" });
            window.showNotification("Error","Something went wrong");
        }).finally(()=>{
            setIsSubmittingAddSmartWebhookForm(false);
        });
    };

    const resetScheduleSetting =  () => {
        let resetValues = {
            tabValue: 0,
            hour: 0,
            min: 0,
            day: 2,
            recurringDay: 1,
            laterHour: '08',
            laterMin: '00',
            time: '08:00',
            startDate: new Date(),
            arrSlotTimeHour1: '08',
            arrSlotTimeMin1: '00',
            greetingOption: 1,
            greetingsOptionTimeMin1: '00',
            greetingsOptionTimeHour1: '08',
            recurringTimeHour1: '00',
            recurringTimeMin1: '00',
        };

        props.updateReducerState(resetValues);
    };

    return (
        <div className="col xl12 lead_flow_activity">
            <div className="add_new ml-5">
                <h1>Add New</h1>
                <div className="add_new_btn">
                    {props.campaignDetails?.basic?.type == 1 &&
                    <button className="cursor-pointer" type="button" onClick={() => setOpenSMSModal(true)}>
                        <span>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0 2.60875C0 1.91687 0.263392 1.25332 0.732233 0.764086C1.20107 0.27485 1.83696 0 2.5 0L17.5 0C18.163 0 18.7989 0.27485 19.2678 0.764086C19.7366 1.25332 20 1.91687 20 2.60875V13.0438C20 13.7356 19.7366 14.3992 19.2678 14.8884C18.7989 15.3777 18.163 15.6525 17.5 15.6525H14.375C14.1809 15.6525 13.9896 15.6997 13.816 15.7902C13.6424 15.8808 13.4914 16.0123 13.375 16.1743L11 19.4782C10.8836 19.6402 10.7326 19.7717 10.559 19.8623C10.3854 19.9529 10.1941 20 10 20C9.80594 20 9.61455 19.9529 9.44098 19.8623C9.26741 19.7717 9.11643 19.6402 9 19.4782L6.625 16.1743C6.50857 16.0123 6.35759 15.8808 6.18402 15.7902C6.01045 15.6997 5.81906 15.6525 5.625 15.6525H2.5C1.83696 15.6525 1.20107 15.3777 0.732233 14.8884C0.263392 14.3992 0 13.7356 0 13.0438V2.60875ZM6.25 7.82626C6.25 8.1722 6.1183 8.50397 5.88388 8.74859C5.64946 8.99321 5.33152 9.13063 5 9.13063C4.66848 9.13063 4.35054 8.99321 4.11612 8.74859C3.8817 8.50397 3.75 8.1722 3.75 7.82626C3.75 7.48031 3.8817 7.14854 4.11612 6.90392C4.35054 6.65931 4.66848 6.52188 5 6.52188C5.33152 6.52188 5.64946 6.65931 5.88388 6.90392C6.1183 7.14854 6.25 7.48031 6.25 7.82626ZM11.25 7.82626C11.25 8.1722 11.1183 8.50397 10.8839 8.74859C10.6495 8.99321 10.3315 9.13063 10 9.13063C9.66848 9.13063 9.35054 8.99321 9.11612 8.74859C8.8817 8.50397 8.75 8.1722 8.75 7.82626C8.75 7.48031 8.8817 7.14854 9.11612 6.90392C9.35054 6.65931 9.66848 6.52188 10 6.52188C10.3315 6.52188 10.6495 6.65931 10.8839 6.90392C11.1183 7.14854 11.25 7.48031 11.25 7.82626ZM15 9.13063C15.3315 9.13063 15.6495 8.99321 15.8839 8.74859C16.1183 8.50397 16.25 8.1722 16.25 7.82626C16.25 7.48031 16.1183 7.14854 15.8839 6.90392C15.6495 6.65931 15.3315 6.52188 15 6.52188C14.6685 6.52188 14.3505 6.65931 14.1161 6.90392C13.8817 7.14854 13.75 7.48031 13.75 7.82626C13.75 8.1722 13.8817 8.50397 14.1161 8.74859C14.3505 8.99321 14.6685 9.13063 15 9.13063Z" fill="#3C7EF3" />
                            </svg>
                        </span>Text
                    </button>
                    }
                    {openSMSModal &&
                        <SMSModal openSMSModal={openSMSModal} setOpenSMSModal={handleCloseSmsModal} showAllTimeSettings={true} currentClip={editableCampaign} handleRemoveCurrentClip={handleRemoveCurrentClip} />
                    }

                    <button className="cursor-pointer" type="button" onClick={() => setOpenEmailModal(true)}>
                        <span>
                            <svg width="20" height="20" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.1886 0.0380859H0.811005C0.67544 0.0382448 0.545476 0.0921818 0.449636 0.188059C0.353797 0.283937 0.299911 0.413922 0.299805 0.549486V1.57829C0.299805 1.62569 0.351205 1.68009 0.393205 1.70209L7.9504 6.01869C7.96976 6.02975 7.99171 6.03548 8.014 6.03528C8.03679 6.03535 8.05918 6.02927 8.0788 6.01769L15.4076 1.70629C15.4488 1.68329 15.558 1.62249 15.599 1.59449C15.6486 1.56069 15.6998 1.53009 15.6998 1.46949V0.549286C15.6996 0.413756 15.6457 0.283823 15.5499 0.187989C15.4541 0.0921545 15.3241 0.0382447 15.1886 0.0380859Z" fill="#3C7EF3" />
                                <path d="M15.6356 3.12922C15.6159 3.118 15.5936 3.11217 15.5709 3.11231C15.5483 3.11245 15.5261 3.11856 15.5066 3.13002L11.3592 5.57022C11.3426 5.57985 11.3285 5.59308 11.3178 5.60892C11.3071 5.62477 11.3 5.64282 11.2973 5.66174C11.2945 5.68067 11.296 5.69998 11.3017 5.71824C11.3074 5.7365 11.3171 5.75324 11.3302 5.76722L15.4782 10.2386C15.49 10.2515 15.5045 10.2618 15.5206 10.2689C15.5366 10.2759 15.554 10.2795 15.5716 10.2794C15.6054 10.2794 15.6379 10.2659 15.6619 10.2419C15.6858 10.218 15.6993 10.1855 15.6994 10.1516V3.24022C15.6994 3.21774 15.6936 3.19563 15.6824 3.17614C15.6712 3.15665 15.655 3.14046 15.6356 3.12922Z" fill="#3C7EF3" />
                                <path d="M10.1648 6.3898C10.1451 6.36836 10.1186 6.35432 10.0898 6.35003C10.061 6.34573 10.0316 6.35144 10.0066 6.3662L8.34415 7.3444C8.24675 7.40068 8.13639 7.43066 8.02391 7.43139C7.91142 7.43213 7.80068 7.4036 7.70255 7.3486L6.23955 6.5128C6.21593 6.49935 6.18865 6.49377 6.16164 6.49686C6.13464 6.49995 6.10932 6.51156 6.08935 6.53L0.533752 11.6832C0.519187 11.6968 0.508005 11.7136 0.501096 11.7323C0.494186 11.7511 0.491742 11.7711 0.493956 11.7909C0.496171 11.8107 0.502983 11.8298 0.513851 11.8465C0.524719 11.8632 0.539341 11.8771 0.556552 11.8872C0.642952 11.938 0.726352 11.9622 0.811152 11.9622H15.0418C15.0667 11.9623 15.091 11.955 15.1118 11.9413C15.1326 11.9276 15.1489 11.9081 15.1588 11.8852C15.1687 11.8624 15.1718 11.8373 15.1676 11.8128C15.1634 11.7883 15.1521 11.7655 15.1352 11.7474L10.1648 6.3898Z" fill="#3C7EF3" />
                                <path d="M4.86661 5.92053C4.88132 5.90692 4.89263 5.89005 4.89962 5.87127C4.90661 5.85249 4.90909 5.83233 4.90685 5.81241C4.90461 5.7925 4.89772 5.77339 4.88673 5.75663C4.87575 5.73987 4.86097 5.72593 4.84361 5.71593L0.490407 3.22953C0.470979 3.2185 0.448996 3.21276 0.426656 3.21289C0.404316 3.21302 0.382402 3.21902 0.363105 3.23028C0.343809 3.24153 0.327806 3.25766 0.316698 3.27704C0.30559 3.29643 0.299765 3.31839 0.299807 3.34073V9.86473C0.299666 9.88964 0.306834 9.91405 0.320425 9.93494C0.334016 9.95582 0.353433 9.97226 0.376272 9.98222C0.399111 9.99219 0.424369 9.99523 0.448922 9.99098C0.473474 9.98674 0.496241 9.97538 0.514407 9.95833L4.86661 5.92053Z" fill="#3C7EF3" />
                            </svg>
                        </span>Email
                    </button>
                    {openEmailModal &&
                        <EmailModal openEmailModal={openEmailModal} setOpenEmailModal={handleCloseEmailModal} currentClip={editableCampaign} showAllTimeSettings={true} handleRemoveCurrentClip={handleRemoveCurrentClip} />
                    }

                    <button className="cursor-pointer" type="button" onClick={() => {
                        window.sendCommunication({
                            open: true,
                            from: 'campaign',
                            showAllTimeSettings: true,
                            campaignId: campaignDetails.basic.id,
                            userInfo : props.userInfo,
                            responseCallback: (response) => updateVideoEmailSetting(response)
                        }, 'videoEmail')
                    }}>
                        <span>
                            <svg width="20" height="20" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.125 11.0152C17.95 11.0151 17.777 10.9782 17.6172 10.907C17.58 10.8905 17.5445 10.8704 17.5113 10.8469L14.2805 8.57266C14.1166 8.45731 13.9829 8.30427 13.8906 8.12643C13.7983 7.94859 13.75 7.75116 13.75 7.55078V4.44922C13.75 4.24884 13.7983 4.05141 13.8906 3.87357C13.9829 3.69573 14.1166 3.54269 14.2805 3.42734L17.5113 1.15312C17.5445 1.12963 17.58 1.10948 17.6172 1.09297C17.8075 1.00836 18.0159 0.97264 18.2236 0.989063C18.4312 1.00549 18.6314 1.07353 18.8061 1.18701C18.9807 1.30049 19.1242 1.45581 19.2236 1.63886C19.323 1.82191 19.375 2.02688 19.375 2.23516V9.76484C19.375 10.0964 19.2433 10.4143 19.0089 10.6487C18.7745 10.8831 18.4565 11.0148 18.125 11.0148V11.0152ZM10.4688 11.625H3.28125C2.57699 11.6243 1.90178 11.3442 1.4038 10.8462C0.90581 10.3482 0.625724 9.67301 0.625 8.96875V3.03125C0.625724 2.32699 0.90581 1.65178 1.4038 1.1538C1.90178 0.655811 2.57699 0.375724 3.28125 0.375H10.4875C11.1868 0.375827 11.8571 0.653971 12.3516 1.14842C12.846 1.64287 13.1242 2.31325 13.125 3.0125V8.96875C13.1243 9.67301 12.8442 10.3482 12.3462 10.8462C11.8482 11.3442 11.173 11.6243 10.4688 11.625Z" fill="#3C7EF3" />
                            </svg>
                        </span>
                        Video Email

                    </button>

                    {/*{openVideoEmailModal &&*/}
                    {/*    <VideoEmailModal openVideoEmailModal={openVideoEmailModal} setOpenVideoEmailModal={setOpenVideoEmailModal} showAllTimeSettings={true} currentClip={editableCampaign} handleRemoveCurrentClip={handleRemoveCurrentClip} />*/}
                    {/*}*/}

                    {props.campaignDetails?.basic?.type == 1 &&
                    <button className="cursor-pointer" type="button" onClick={() => setOpenVoiceMailModal(true)}>
                        <span>
                            <svg width="20" height="20" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.5187 10.2915C11.5187 9.93151 11.2434 9.65625 10.8834 9.65625C10.5235 9.65625 10.2482 9.93151 10.2482 10.2915C10.2482 12.7688 8.2367 14.7804 5.75934 14.7804C3.28197 14.7804 1.27044 12.7688 1.27044 10.2915C1.27044 9.93151 0.995179 9.65625 0.635221 9.65625C0.275262 9.65625 0 9.93151 0 10.2915C0 13.2347 2.2021 15.712 5.12411 16.0296V17.7236H2.81615C2.45619 17.7236 2.18093 17.9988 2.18093 18.3588C2.18093 18.7187 2.45619 18.994 2.81615 18.994H8.70253C9.06248 18.994 9.33775 18.7187 9.33775 18.3588C9.33775 17.9988 9.06248 17.7236 8.70253 17.7236H6.39456V16.0296C9.31657 15.712 11.5187 13.2347 11.5187 10.2915Z" fill="#3C7EF3" />
                                <path d="M5.7597 0.994141C3.81169 0.994141 2.22363 2.58219 2.22363 4.5302V10.2684C2.22363 12.2375 3.81169 13.8044 5.7597 13.8256C7.70771 13.8256 9.29576 12.2375 9.29576 10.2895V4.5302C9.29576 2.58219 7.70771 0.994141 5.7597 0.994141Z" fill="#3C7EF3" />
                            </svg>
                        </span>Voicemail

                    </button>
                    }
                    {openVoiceMailModal &&
                        <VoiceMailModal open={openVoiceMailModal} setOpenVoiceMailModal={handleCloseVoiceModal} showAllTimeSettings={true} currentClip={editableCampaign} handleRemoveCurrentClip={handleRemoveCurrentClip} />
                    }

                    {props.campaignDetails?.basic?.type == 1 &&
                    <button className="cursor-pointer" type="button" onClick={() => setOpenCallBridgeModal(true)}>
                        <span>
                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.5257 19.9998C15.6541 19.9998 14.4297 19.6846 12.5963 18.6605C10.3668 17.4105 8.64237 16.2565 6.42495 14.0453C4.28701 11.9091 3.24662 10.5261 1.79052 7.87691C0.145544 4.88581 0.425958 3.31793 0.739415 2.64783C1.11271 1.84693 1.66371 1.36791 2.37591 0.892454C2.78043 0.627466 3.20852 0.400313 3.65474 0.213874C3.6994 0.194677 3.74092 0.176374 3.77798 0.159856C3.99901 0.0603008 4.3339 -0.0901474 4.75809 0.0705688C5.04119 0.17682 5.29392 0.394233 5.68953 0.784863C6.50086 1.58487 7.60957 3.36659 8.01858 4.2416C8.29319 4.83134 8.47493 5.22063 8.47537 5.65724C8.47537 6.16841 8.21818 6.56261 7.90606 6.98806C7.84756 7.06797 7.78952 7.14431 7.73325 7.21842C7.39345 7.66486 7.31888 7.79388 7.368 8.02424C7.46758 8.48719 8.21014 9.86533 9.43048 11.0828C10.6508 12.3002 11.9895 12.9957 12.4543 13.0948C12.6945 13.1462 12.8263 13.0685 13.2871 12.7167C13.3532 12.6663 13.421 12.614 13.492 12.5618C13.968 12.2078 14.344 11.9573 14.8432 11.9573H14.8459C15.2803 11.9573 15.6523 12.1457 16.2685 12.4564C17.0722 12.8618 18.9079 13.956 19.7129 14.7681C20.1045 15.1627 20.3229 15.4145 20.4296 15.6971C20.5903 16.1225 20.439 16.456 20.3403 16.6793C20.3238 16.7163 20.3055 16.7569 20.2863 16.802C20.0983 17.2474 19.8698 17.6745 19.6035 18.0779C19.1289 18.7878 18.648 19.3373 17.8451 19.711C17.4329 19.9059 16.9817 20.0047 16.5257 19.9998Z" fill="#3C7EF3" />
                            </svg>
                        </span>Call Bridge
                    </button>
                    }
                    {openCallBridgeModal &&
                        <CallBridgeModal 
                            openCallBridgeModal={openCallBridgeModal} 
                            setOpenCallBridgeModal={handleCloseCallBridgeModal} 
                            showAll={true} 
                            currentClip={editableCampaign} 
                            handleRemoveCurrentClip={handleRemoveCurrentClip}
                        />

                    }
                    {/* <Modal
                        open={viewCardModal}
                        onClose={() => closeCardModal(false)}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        
                    > */}
                    {/*  <CardMultiStepForm
                            closeCardModal={closeCardModal}
                            messageType={activeMailItemType}
                            setViewCardModal={setViewCardModal}
                            editableCampaign={editableCampaign}
                            showAllTimeSettings={true}
                            from={'campaignSettings'}
                        />
 */}                    {viewCardModal &&

                        <CardMultiStepForm
                            open={viewCardModal}
                            onClose={() => {setEditableCampaign(null); setViewCardModal(false)}}
                            messageType={activeMailItemType}
                            setViewCardModal={setViewCardModal}
                            from={'create_campaign'}
                            showAllTimeSettings={true}
                            // setDirectMailSave={setDirectMailSave}
                            onSubmit={saveCamapaignSettings}
                            editableCampaign={editableCampaign}
                        />
                        }
                    {/* </Modal> */}
                    {(thanksIoActive || zenDirectActive) &&
                    <button className="cursor-pointer" type="button"
                        onClick={(e) => setMailOptionVisible(e.currentTarget)}
                    >
                        <span>
                            <span>
                                <svg width="20" height="20" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M5.83586 0.0126953H11.6366C12.2191 0.0126953 12.6912 0.484873 12.6912 1.06737V10.8232L8.99988 13.5916L5.30852 10.8233V0.540032C5.30852 0.248801 5.54463 0.0126953 5.83586 0.0126953ZM13.7459 10.0322V4.495L17.4724 7.26376L13.7459 10.0322ZM8.99988 14.7783C9.11111 14.7783 9.22234 14.7433 9.31607 14.6727L17.9998 8.18635V17.4853C17.9998 17.7768 17.7639 18.0126 17.4724 18.0126H0.527337C0.23586 18.0126 0 17.7768 0 17.4853V8.18653L8.68369 14.6727C8.77741 14.7433 8.88865 14.7783 8.99988 14.7783ZM4.25399 10.0322V4.495L0.527337 7.26383L4.25399 10.0322Z" fill="#3C7EF3" />
                                </svg>
                            </span>
                        </span>Direct Mail
                    </button>
                    }
                    <Menu
                        id="more-menu"
                        anchorEl={mailOptionVisible}
                        keepMounted
                        open={Boolean(mailOptionVisible)}
                        onClose={() => setMailOptionVisible(false)}
                    >
                        {thanksIoActive && 
                        <>
                        {/* <MenuItem className="menuitem-li-hover-color-with-border" onClick={() => selectActiveMailType(POSTCARD)}> */}
                        <MenuItem className="menuitem-li-hover-color-with-border" onClick={() => handleClickDirectMailV2('postcard')}>
                            <span>
                                <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.7334 0.275391L7.25879 4.06504V11.2876L16.7334 15.0772V0.275391Z" fill="#3C7EF3" />
                                    <path d="M6.36297 4.20508H5.59961V11.1387H6.36297V4.20508Z" fill="#3C7EF3" />
                                    <path d="M4.70876 10.9756V4.04199H1.62995C1.19766 4.04199 0.783079 4.21372 0.477402 4.51939C0.171726 4.82507 0 5.23966 0 5.67195V9.35293C0 9.78522 0.171726 10.1998 0.477402 10.5055C0.783079 10.8112 1.19766 10.9829 1.62995 10.9829L4.70876 10.9756Z" fill="#3C7EF3" />
                                    <path d="M20.0005 7.54223C19.9998 6.93244 19.7573 6.34784 19.3261 5.91665C18.8949 5.48546 18.3103 5.24291 17.7005 5.24219H17.6299V9.84229H17.7005C18.3103 9.84157 18.8949 9.59901 19.3261 9.16783C19.7573 8.73664 19.9998 8.15202 20.0005 7.54223Z" fill="#3C7EF3" />
                                    <path d="M4.30887 17.7239C4.55132 17.7239 4.79051 17.6679 5.0078 17.5603C5.2251 17.4528 5.41466 17.2966 5.56176 17.1038C5.70885 16.9111 5.8095 16.687 5.8559 16.4491C5.90229 16.2111 5.89318 15.9656 5.82926 15.7318L4.81145 12.0381H1.62669H1.53613L2.78577 16.5721C2.87936 16.9039 3.07883 17.196 3.35379 17.4039C3.62875 17.6119 3.96413 17.7242 4.30887 17.7239Z" fill="#3C7EF3" />
                                </svg>
                            </span>
                            &nbsp;      Post Card

                        </MenuItem>
                        {/* <MenuItem className="menuitem-li-hover-color-with-border" onClick={() => selectActiveMailType(GREETING_CARD)}>
                            <span>
                                <svg width="20" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.55469 9.19531V11.664L5.69266 10.4299L3.55469 9.19531Z" fill="#3C7EF3" />
                                    <path d="M10.2073 9.19531L8.06934 10.4299L10.2073 11.664V9.19531Z" fill="#3C7EF3" />
                                    <path d="M13.5629 3.16602H0.198033C0.0886657 3.16602 0 3.25468 0 3.36405V9.87219C0.140303 9.81707 0.0200766 9.83219 2.36608 9.83219V8.17449C2.36608 8.01201 2.42674 7.85299 2.54303 7.73948C2.7393 7.54786 3.03076 7.51747 3.25723 7.64828L6.88046 9.74028L10.5036 7.64828C10.7301 7.51747 11.0216 7.54786 11.2178 7.73945C11.3341 7.85295 11.3948 8.01193 11.3948 8.17445V9.83219H13.547C13.6223 9.83219 13.6944 9.84606 13.7609 9.87219V3.36405C13.7609 3.25464 13.6722 3.16602 13.5629 3.16602Z" fill="#3C7EF3" />
                                    <path d="M11.3947 12.6925C11.3947 12.9048 11.2814 13.1012 11.0977 13.2073C11.0058 13.26 10.9032 13.2866 10.8006 13.2866C10.698 13.2866 10.5955 13.26 10.5036 13.2073L9.12565 12.4117C9.5134 13.2137 9.78393 14.1773 9.70233 15.2463C9.67858 15.558 9.41836 15.7953 9.11061 15.7953C9.09557 15.7953 9.08011 15.7945 9.06468 15.7933C8.73755 15.7683 8.49277 15.4827 8.51772 15.1556C8.65357 13.382 7.50338 11.9119 6.88159 11.2552C6.26015 11.9146 5.108 13.3908 5.24307 15.1556C5.26803 15.4828 5.02324 15.7683 4.69612 15.7933C4.68069 15.7944 4.66522 15.7953 4.65018 15.7953C4.34243 15.7953 4.08221 15.558 4.05847 15.2463C3.97687 14.1773 4.2474 13.2137 4.63515 12.4117L3.25723 13.2073C3.16533 13.26 3.06275 13.2866 2.96018 13.2866C2.85761 13.2866 2.75504 13.26 2.66313 13.2073C2.47936 13.1012 2.36608 12.9048 2.36608 12.6925V11.0234C1.66227 11.0234 0.589724 11.025 0 11.0254V19.8018C0 19.9112 0.0886657 19.9999 0.198033 19.9999H13.5628C13.6722 19.9999 13.7609 19.9112 13.7609 19.8018V11.0235H11.3948V12.6925H11.3947Z" fill="#3C7EF3" />
                                    <path d="M10.9626 0.198206C10.9626 0.0669653 10.8374 -0.0280281 10.7111 0.00751629C9.22591 0.425768 1.69839 2.54535 1.59766 2.57374H10.9626V0.198206Z" fill="#3C7EF3" />
                                </svg>
                            </span>
                            &nbsp;    Greeting Card

                        </MenuItem> */}
                        {/* {(Utils.getAccountData('userId') === 1 || Utils.getAccountData('userId') === 2) && */}
                        {/* <MenuItem className="menuitem-li-hover-color-with-border" onClick={() => selectActiveMailType(THANKSIO_GREETINGCARD)}> */}
                        <MenuItem className="menuitem-li-hover-color-with-border" onClick={() => handleClickDirectMailV2('notecard')}>
                            <span>
                                <svg width="20" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.55469 9.19531V11.664L5.69266 10.4299L3.55469 9.19531Z" fill="#3C7EF3" />
                                    <path d="M10.2073 9.19531L8.06934 10.4299L10.2073 11.664V9.19531Z" fill="#3C7EF3" />
                                    <path d="M13.5629 3.16602H0.198033C0.0886657 3.16602 0 3.25468 0 3.36405V9.87219C0.140303 9.81707 0.0200766 9.83219 2.36608 9.83219V8.17449C2.36608 8.01201 2.42674 7.85299 2.54303 7.73948C2.7393 7.54786 3.03076 7.51747 3.25723 7.64828L6.88046 9.74028L10.5036 7.64828C10.7301 7.51747 11.0216 7.54786 11.2178 7.73945C11.3341 7.85295 11.3948 8.01193 11.3948 8.17445V9.83219H13.547C13.6223 9.83219 13.6944 9.84606 13.7609 9.87219V3.36405C13.7609 3.25464 13.6722 3.16602 13.5629 3.16602Z" fill="#3C7EF3" />
                                    <path d="M11.3947 12.6925C11.3947 12.9048 11.2814 13.1012 11.0977 13.2073C11.0058 13.26 10.9032 13.2866 10.8006 13.2866C10.698 13.2866 10.5955 13.26 10.5036 13.2073L9.12565 12.4117C9.5134 13.2137 9.78393 14.1773 9.70233 15.2463C9.67858 15.558 9.41836 15.7953 9.11061 15.7953C9.09557 15.7953 9.08011 15.7945 9.06468 15.7933C8.73755 15.7683 8.49277 15.4827 8.51772 15.1556C8.65357 13.382 7.50338 11.9119 6.88159 11.2552C6.26015 11.9146 5.108 13.3908 5.24307 15.1556C5.26803 15.4828 5.02324 15.7683 4.69612 15.7933C4.68069 15.7944 4.66522 15.7953 4.65018 15.7953C4.34243 15.7953 4.08221 15.558 4.05847 15.2463C3.97687 14.1773 4.2474 13.2137 4.63515 12.4117L3.25723 13.2073C3.16533 13.26 3.06275 13.2866 2.96018 13.2866C2.85761 13.2866 2.75504 13.26 2.66313 13.2073C2.47936 13.1012 2.36608 12.9048 2.36608 12.6925V11.0234C1.66227 11.0234 0.589724 11.025 0 11.0254V19.8018C0 19.9112 0.0886657 19.9999 0.198033 19.9999H13.5628C13.6722 19.9999 13.7609 19.9112 13.7609 19.8018V11.0235H11.3948V12.6925H11.3947Z" fill="#3C7EF3" />
                                    <path d="M10.9626 0.198206C10.9626 0.0669653 10.8374 -0.0280281 10.7111 0.00751629C9.22591 0.425768 1.69839 2.54535 1.59766 2.57374H10.9626V0.198206Z" fill="#3C7EF3" />
                                </svg>
                            </span>
                            &nbsp;  Notecard

                        </MenuItem>
                        {/* } */}
                        {/* <MenuItem className="menuitem-li-hover-color-with-border" onClick={() => selectActiveMailType(LETTER)}> */}
                        <MenuItem className="menuitem-li-hover-color-with-border" onClick={() => handleClickDirectMailV2('letter')}>
                            <span>
                                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M5.83586 0.0126953H11.6366C12.2191 0.0126953 12.6912 0.484873 12.6912 1.06737V10.8232L8.99988 13.5916L5.30852 10.8233V0.540032C5.30852 0.248801 5.54463 0.0126953 5.83586 0.0126953ZM13.7459 10.0322V4.495L17.4724 7.26376L13.7459 10.0322ZM8.99988 14.7783C9.11111 14.7783 9.22234 14.7433 9.31607 14.6727L17.9998 8.18635V17.4853C17.9998 17.7768 17.7639 18.0126 17.4724 18.0126H0.527337C0.23586 18.0126 0 17.7768 0 17.4853V8.18653L8.68369 14.6727C8.77741 14.7433 8.88865 14.7783 8.99988 14.7783ZM4.25399 10.0322V4.495L0.527337 7.26383L4.25399 10.0322Z" fill="#3C7EF3" />
                                </svg>
                            </span>
                            &nbsp;Letter</MenuItem>
                        </>
                        }
                        {zenDirectActive &&
                        // <MenuItem className="menuitem-li-hover-color-with-border" onClick={() => selectActiveMailType(GIFT)}>
                        <MenuItem className="menuitem-li-hover-color-with-border" onClick={() => handleClickDirectMailV2('zendirect_gift')}>
                            <span>
                                <svg width="20" height="20" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.4404 6.82812C10.1768 7.6091 9.47672 8.08355 8.70668 8.08355C8.58336 8.08355 8.46242 8.05457 8.34125 8.02977L9.2766 9.43281C9.45629 9.7023 9.38359 10.0657 9.1141 10.2454C8.84031 10.4268 8.47832 10.3484 8.30156 10.0829L7.03125 8.17738V15.0312H18.2422C19.2115 15.0312 20 14.2427 20 13.2734V6.82812H10.4404ZM17.0703 12.6875H12.3047C11.9808 12.6875 11.7188 12.4254 11.7188 12.1016C11.7188 11.7777 11.9808 11.5156 12.3047 11.5156H17.0703C17.3942 11.5156 17.6562 11.7777 17.6562 12.1016C17.6562 12.4254 17.3942 12.6875 17.0703 12.6875ZM17.0703 10.3438H12.3047C11.9808 10.3438 11.7188 10.0817 11.7188 9.75781C11.7188 9.43395 11.9808 9.17188 12.3047 9.17188H17.0703C17.3942 9.17188 17.6562 9.43395 17.6562 9.75781C17.6562 10.0817 17.3942 10.3438 17.0703 10.3438Z" fill="#3C7EF3" />
                                    <path d="M8.3368 5.68446L7.50195 6.24122L8.3368 6.79798C8.78125 7.09423 9.37535 6.77594 9.37535 6.2418C9.37535 5.70528 8.77953 5.3893 8.3368 5.68446Z" fill="#3C7EF3" />
                                    <path d="M1.75781 0.967773C0.788516 0.967773 0 1.75629 0 2.72559V5.65527H2.45023C2.84656 4.48133 4.20984 4.04715 5.20418 4.70941L5.85938 5.14629V0.967773H1.75781Z" fill="#3C7EF3" />
                                    <path d="M18.2422 0.967773H7.03125V5.14629L7.68641 4.70941C8.67023 4.05215 10.0416 4.47266 10.4406 5.65527H20V2.72559C20 1.75629 19.2115 0.967773 18.2422 0.967773Z" fill="#3C7EF3" />
                                    <path d="M4.55418 5.68476C4.10906 5.388 3.51562 5.70824 3.51562 6.24093C3.51562 6.77523 4.10758 7.0955 4.55418 6.79828L5.38902 6.24152L4.55418 5.68476Z" fill="#3C7EF3" />
                                    <path d="M4.58906 10.0829C4.40938 10.3512 4.04605 10.4227 3.77652 10.2454C3.50703 10.0657 3.43434 9.70234 3.61402 9.43281L4.54938 8.02977C4.4282 8.05457 4.30727 8.08355 4.18395 8.08355C3.41203 8.08355 2.71281 7.60719 2.44996 6.82812H0V13.2735C0 14.2428 0.788516 15.0313 1.75781 15.0313H5.85938V8.17742L4.58906 10.0829Z" fill="#3C7EF3" />
                                </svg>
                            </span>
                            &nbsp; Gift</MenuItem>
                        }
                    </Menu>
                    {/*Direct Mail Dropdown Structure*/}

                    {/* return (
                    <MenuItem value={value}>{CoreConstants.Timeline.CONTENT_TYPES[value]}</MenuItem>) */}
                    <div className="campaign_dropdown more-setting-dropdown">
                        <a className='dropdown-trigger setting-list-dropdown' data-target='campaign_list_dropdown' onClick={(e) => setAnchorActionMenu(e.currentTarget)}>
                           <span>
                                <svg width="5" height="20" viewBox="0 0 5 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.50007 12.3078C3.77458 12.3078 4.80777 11.2746 4.80777 10.0001C4.80777 8.72557 3.77458 7.69238 2.50007 7.69238C1.22557 7.69238 0.192383 8.72557 0.192383 10.0001C0.192383 11.2746 1.22557 12.3078 2.50007 12.3078Z" fill="#3C7EF3"></path>
                                    <path d="M2.50007 20.0002C3.77458 20.0002 4.80777 18.967 4.80777 17.6925C4.80777 16.418 3.77458 15.3848 2.50007 15.3848C1.22557 15.3848 0.192383 16.418 0.192383 17.6925C0.192383 18.967 1.22557 20.0002 2.50007 20.0002Z" fill="#3C7EF3"></path>
                                    <path d="M2.50007 4.61538C3.77458 4.61538 4.80777 3.5822 4.80777 2.30769C4.80777 1.03319 3.77458 0 2.50007 0C1.22557 0 0.192383 1.03319 0.192383 2.30769C0.192383 3.5822 1.22557 4.61538 2.50007 4.61538Z" fill="#3C7EF3"></path>
                                </svg>
                           </span>
                            More
                        </a> 

                        <Menu
                            id="action-menu"
                            anchorEl={anchorActionMenu}
                            keepMounted
                            open={Boolean(anchorActionMenu)}
                            onClose={() => setAnchorActionMenu(null)}
                        >
                            <MenuItem className="menuitem-li-hover-color-with-border" onClick={handleActionClick} >
                                <ListItemIcon >
                                    <span>
                                        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.7334 0.275391L7.25879 4.06504V11.2876L16.7334 15.0772V0.275391Z" fill="#3C7EF3"/>
                                        <path d="M6.36297 4.20508H5.59961V11.1387H6.36297V4.20508Z" fill="#3C7EF3"/>
                                        <path d="M4.70876 10.9756V4.04199H1.62995C1.19766 4.04199 0.783079 4.21372 0.477402 4.51939C0.171726 4.82507 0 5.23966 0 5.67195V9.35293C0 9.78522 0.171726 10.1998 0.477402 10.5055C0.783079 10.8112 1.19766 10.9829 1.62995 10.9829L4.70876 10.9756Z" fill="#3C7EF3"/>
                                        <path d="M20.0005 7.54223C19.9998 6.93244 19.7573 6.34784 19.3261 5.91665C18.8949 5.48546 18.3103 5.24291 17.7005 5.24219H17.6299V9.84229H17.7005C18.3103 9.84157 18.8949 9.59901 19.3261 9.16783C19.7573 8.73664 19.9998 8.15202 20.0005 7.54223Z" fill="#3C7EF3"/>
                                        <path d="M4.30887 17.7239C4.55132 17.7239 4.79051 17.6679 5.0078 17.5603C5.2251 17.4528 5.41466 17.2966 5.56176 17.1038C5.70885 16.9111 5.8095 16.687 5.8559 16.4491C5.90229 16.2111 5.89318 15.9656 5.82926 15.7318L4.81145 12.0381H1.62669H1.53613L2.78577 16.5721C2.87936 16.9039 3.07883 17.196 3.35379 17.4039C3.62875 17.6119 3.96413 17.7242 4.30887 17.7239Z" fill="#3C7EF3"/>
                                    </svg>
                                    </span>
                                </ListItemIcon>
                                <ListItemText  inset primary={'Action'} />

                            </MenuItem>

                            <MenuItem className="menuitem-li-hover-color-with-border" onClick={handleActivityClick} >
                                <ListItemIcon >
                                    <span>
                                        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.7334 0.275391L7.25879 4.06504V11.2876L16.7334 15.0772V0.275391Z" fill="#3C7EF3"/>
                                        <path d="M6.36297 4.20508H5.59961V11.1387H6.36297V4.20508Z" fill="#3C7EF3"/>
                                        <path d="M4.70876 10.9756V4.04199H1.62995C1.19766 4.04199 0.783079 4.21372 0.477402 4.51939C0.171726 4.82507 0 5.23966 0 5.67195V9.35293C0 9.78522 0.171726 10.1998 0.477402 10.5055C0.783079 10.8112 1.19766 10.9829 1.62995 10.9829L4.70876 10.9756Z" fill="#3C7EF3"/>
                                        <path d="M20.0005 7.54223C19.9998 6.93244 19.7573 6.34784 19.3261 5.91665C18.8949 5.48546 18.3103 5.24291 17.7005 5.24219H17.6299V9.84229H17.7005C18.3103 9.84157 18.8949 9.59901 19.3261 9.16783C19.7573 8.73664 19.9998 8.15202 20.0005 7.54223Z" fill="#3C7EF3"/>
                                        <path d="M4.30887 17.7239C4.55132 17.7239 4.79051 17.6679 5.0078 17.5603C5.2251 17.4528 5.41466 17.2966 5.56176 17.1038C5.70885 16.9111 5.8095 16.687 5.8559 16.4491C5.90229 16.2111 5.89318 15.9656 5.82926 15.7318L4.81145 12.0381H1.62669H1.53613L2.78577 16.5721C2.87936 16.9039 3.07883 17.196 3.35379 17.4039C3.62875 17.6119 3.96413 17.7242 4.30887 17.7239Z" fill="#3C7EF3"/>
                                    </svg>
                                    </span>
                                </ListItemIcon>
                                <ListItemText  inset primary={'Activity'} />

                            </MenuItem>

                            <MenuItem className="menuitem-li-hover-color-with-border" onClick={handleWebhookClick} >
                                <ListItemIcon >
                                    <span>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.8694 10.624H6.12968C6.0197 10.624 5.93084 10.7143 5.93366 10.8239C5.95706 11.728 6.01852 12.5918 6.11201 13.4084C6.12545 13.5259 6.23932 13.6053 6.35432 13.5761C7.5376 13.2757 8.75702 13.124 9.99953 13.124C11.242 13.124 12.4615 13.2757 13.6447 13.5761C13.7597 13.6053 13.8736 13.5259 13.8871 13.4084C13.9805 12.5917 14.042 11.728 14.0654 10.8239C14.0682 10.7143 13.9794 10.624 13.8694 10.624Z" fill="#3C7EF3"/>
                                            <path d="M13.888 6.5888C13.8746 6.4713 13.7607 6.39193 13.6457 6.4211C12.4624 6.72157 11.243 6.87325 10.0005 6.87325C8.758 6.87325 7.53858 6.72157 6.3553 6.42114C6.24033 6.39196 6.12643 6.47134 6.11298 6.58884C6.0195 7.40556 5.95804 8.26927 5.93464 9.17337C5.93181 9.28298 6.02063 9.37326 6.13066 9.37326H13.8703C13.9803 9.37326 14.0692 9.28294 14.0663 9.17337C14.043 8.26923 13.9815 7.40552 13.888 6.5888Z" fill="#3C7EF3"/>
                                            <path d="M13.6452 4.94219C13.294 3.00567 12.7557 1.4175 12.1344 0.295156C12.1068 0.245156 12.0582 0.209883 12.0021 0.198594C11.35 0.0676563 10.6803 0 9.99995 0C9.31964 0 8.64988 0.0676563 7.99784 0.198633C7.94167 0.209922 7.89314 0.245196 7.86547 0.295196C7.24419 1.4175 6.7059 3.00567 6.35469 4.94223C6.3367 5.04137 6.39789 5.13848 6.49549 5.16469C7.61339 5.46477 8.78825 5.625 9.99995 5.625C11.2116 5.625 12.3865 5.46477 13.5044 5.16465C13.602 5.13848 13.6632 5.04133 13.6452 4.94219Z" fill="#3C7EF3"/>
                                            <path d="M14.1712 0.899005C14.0115 0.826232 13.8451 0.985138 13.9083 1.14842C13.9093 1.15131 13.9105 1.1542 13.9116 1.15709C14.2931 2.14553 14.6029 3.26768 14.8348 4.48123C14.858 4.60252 14.9876 4.67162 15.1022 4.62475C15.8737 4.30924 16.6094 3.92428 17.3019 3.47803C17.4044 3.41198 17.4228 3.26951 17.3396 3.18045C17.26 3.09518 17.1787 3.01104 17.0956 2.92823C16.2352 2.07069 15.2458 1.38861 14.1712 0.899005Z" fill="#3C7EF3"/>
                                            <path d="M15.5169 9.37746H19.8043C19.9192 9.37746 20.0093 9.27937 19.9998 9.16523C19.8613 7.48871 19.3076 5.89695 18.3884 4.50909C18.3282 4.41827 18.2061 4.3937 18.115 4.45366C17.3661 4.94675 16.5722 5.37117 15.7375 5.72296C15.5663 5.79515 15.3941 5.86386 15.2209 5.92929C15.1359 5.9614 15.084 6.0466 15.0953 6.13648C15.2187 7.11691 15.2951 8.13964 15.3212 9.18656C15.3239 9.29265 15.4104 9.37746 15.5169 9.37746Z" fill="#3C7EF3"/>
                                            <path d="M6.35469 15.0578C6.7059 16.9943 7.24419 18.5825 7.86547 19.7048C7.89314 19.7548 7.94167 19.7901 7.99784 19.8014C8.64988 19.9323 9.31964 20 9.99995 20C10.6803 20 11.35 19.9323 12.0021 19.8014C12.0582 19.7901 12.1068 19.7548 12.1344 19.7048C12.7557 18.5825 13.294 16.9943 13.6452 15.0578C13.6632 14.9586 13.602 14.8615 13.5044 14.8353C12.3865 14.5352 11.2116 14.375 9.99995 14.375C8.78825 14.375 7.61339 14.5352 6.49549 14.8354C6.39789 14.8615 6.3367 14.9587 6.35469 15.0578Z" fill="#3C7EF3"/>
                                            <path d="M4.26301 14.2785C4.43422 14.2063 4.60646 14.1376 4.77963 14.0722C4.86461 14.04 4.91654 13.9548 4.90522 13.865C4.78182 12.8845 4.70539 11.8618 4.67928 10.8149C4.67662 10.7088 4.59011 10.624 4.48365 10.624H0.196196C0.0812702 10.624 -0.00876554 10.7221 0.00068096 10.8362C0.139243 12.5128 0.692901 14.1045 1.61215 15.4924C1.67228 15.5832 1.79442 15.6078 1.88551 15.5478C2.63445 15.0547 3.42835 14.6303 4.26301 14.2785Z" fill="#3C7EF3"/>
                                            <path d="M17.3019 16.5219C16.6094 16.0756 15.8737 15.6906 15.1022 15.3751C14.9876 15.3283 14.858 15.3974 14.8348 15.5187C14.6029 16.7322 14.2931 17.8544 13.9116 18.8428C13.9105 18.8457 13.9093 18.8486 13.9083 18.8515C13.8451 19.0147 14.0115 19.1737 14.1712 19.1009C15.2458 18.6113 16.2352 17.9292 17.0957 17.0717C17.1788 16.9888 17.26 16.9047 17.3397 16.8194C17.4228 16.7304 17.4044 16.5879 17.3019 16.5219Z" fill="#3C7EF3"/>
                                            <path d="M15.3202 10.8149C15.2941 11.8618 15.2177 12.8846 15.0943 13.865C15.083 13.9549 15.1349 14.0401 15.2199 14.0722C15.3931 14.1376 15.5653 14.2063 15.7365 14.2785C16.5712 14.6304 17.3651 15.0548 18.114 15.5478C18.2051 15.6078 18.3272 15.5832 18.3874 15.4924C19.3066 14.1046 19.8603 12.5128 19.9989 10.8363C20.0083 10.7221 19.9183 10.624 19.8033 10.624H15.5159C15.4094 10.6241 15.3229 10.7089 15.3202 10.8149Z" fill="#3C7EF3"/>
                                            <path d="M2.69852 3.47803C3.39093 3.92428 4.1267 4.30928 4.89818 4.62475C5.01279 4.67162 5.14238 4.60252 5.16554 4.48123C5.39743 3.26768 5.70729 2.14553 6.08879 1.15709C6.08989 1.1542 6.09103 1.15131 6.09212 1.14842C6.15527 0.985138 5.98888 0.826232 5.82919 0.899005C4.75464 1.38865 3.76519 2.07072 2.90469 2.92823C2.8216 3.01104 2.74034 3.09518 2.66069 3.18045C2.57756 3.26951 2.59602 3.41198 2.69852 3.47803Z" fill="#3C7EF3"/>
                                            <path d="M4.67509 9.37745C4.6963 8.26397 4.77438 7.17639 4.90522 6.13647C4.91654 6.04658 4.86461 5.96139 4.77963 5.92928C4.60646 5.86385 4.43422 5.79514 4.26301 5.72295C3.42835 5.37112 2.63445 4.9467 1.88551 4.45365C1.79442 4.39369 1.67232 4.4183 1.61215 4.50908C0.692901 5.89694 0.139243 7.48869 0.00068096 9.16522C-0.00876554 9.27936 0.0812702 9.37745 0.196196 9.37745H4.67509Z" fill="#3C7EF3"/>
                                            <path d="M5.82919 19.1009C5.98888 19.1737 6.15527 19.0147 6.09213 18.8515C6.09103 18.8486 6.08989 18.8457 6.0888 18.8428C5.70733 17.8544 5.39744 16.7322 5.16555 15.5187C5.14238 15.3974 5.0128 15.3283 4.89818 15.3751C4.12671 15.6906 3.39094 16.0756 2.69852 16.5219C2.59598 16.5879 2.57756 16.7304 2.66074 16.8194C2.74038 16.9047 2.82164 16.9888 2.90474 17.0717C3.76515 17.9292 4.75461 18.6113 5.82919 19.1009Z" fill="#3C7EF3"/>
                                        </svg>
                                    </span>
                                </ListItemIcon>
                                <ListItemText  inset primary={'Webhook'} />

                            </MenuItem>
                            <MenuItem className="menuitem-li-hover-color-with-border" onClick={()=>{onOpenWebhookBuilderModal()}} >
                                <ListItemIcon >
                                    <span>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.8694 10.624H6.12968C6.0197 10.624 5.93084 10.7143 5.93366 10.8239C5.95706 11.728 6.01852 12.5918 6.11201 13.4084C6.12545 13.5259 6.23932 13.6053 6.35432 13.5761C7.5376 13.2757 8.75702 13.124 9.99953 13.124C11.242 13.124 12.4615 13.2757 13.6447 13.5761C13.7597 13.6053 13.8736 13.5259 13.8871 13.4084C13.9805 12.5917 14.042 11.728 14.0654 10.8239C14.0682 10.7143 13.9794 10.624 13.8694 10.624Z" fill="#3C7EF3"/>
                                            <path d="M13.888 6.5888C13.8746 6.4713 13.7607 6.39193 13.6457 6.4211C12.4624 6.72157 11.243 6.87325 10.0005 6.87325C8.758 6.87325 7.53858 6.72157 6.3553 6.42114C6.24033 6.39196 6.12643 6.47134 6.11298 6.58884C6.0195 7.40556 5.95804 8.26927 5.93464 9.17337C5.93181 9.28298 6.02063 9.37326 6.13066 9.37326H13.8703C13.9803 9.37326 14.0692 9.28294 14.0663 9.17337C14.043 8.26923 13.9815 7.40552 13.888 6.5888Z" fill="#3C7EF3"/>
                                            <path d="M13.6452 4.94219C13.294 3.00567 12.7557 1.4175 12.1344 0.295156C12.1068 0.245156 12.0582 0.209883 12.0021 0.198594C11.35 0.0676563 10.6803 0 9.99995 0C9.31964 0 8.64988 0.0676563 7.99784 0.198633C7.94167 0.209922 7.89314 0.245196 7.86547 0.295196C7.24419 1.4175 6.7059 3.00567 6.35469 4.94223C6.3367 5.04137 6.39789 5.13848 6.49549 5.16469C7.61339 5.46477 8.78825 5.625 9.99995 5.625C11.2116 5.625 12.3865 5.46477 13.5044 5.16465C13.602 5.13848 13.6632 5.04133 13.6452 4.94219Z" fill="#3C7EF3"/>
                                            <path d="M14.1712 0.899005C14.0115 0.826232 13.8451 0.985138 13.9083 1.14842C13.9093 1.15131 13.9105 1.1542 13.9116 1.15709C14.2931 2.14553 14.6029 3.26768 14.8348 4.48123C14.858 4.60252 14.9876 4.67162 15.1022 4.62475C15.8737 4.30924 16.6094 3.92428 17.3019 3.47803C17.4044 3.41198 17.4228 3.26951 17.3396 3.18045C17.26 3.09518 17.1787 3.01104 17.0956 2.92823C16.2352 2.07069 15.2458 1.38861 14.1712 0.899005Z" fill="#3C7EF3"/>
                                            <path d="M15.5169 9.37746H19.8043C19.9192 9.37746 20.0093 9.27937 19.9998 9.16523C19.8613 7.48871 19.3076 5.89695 18.3884 4.50909C18.3282 4.41827 18.2061 4.3937 18.115 4.45366C17.3661 4.94675 16.5722 5.37117 15.7375 5.72296C15.5663 5.79515 15.3941 5.86386 15.2209 5.92929C15.1359 5.9614 15.084 6.0466 15.0953 6.13648C15.2187 7.11691 15.2951 8.13964 15.3212 9.18656C15.3239 9.29265 15.4104 9.37746 15.5169 9.37746Z" fill="#3C7EF3"/>
                                            <path d="M6.35469 15.0578C6.7059 16.9943 7.24419 18.5825 7.86547 19.7048C7.89314 19.7548 7.94167 19.7901 7.99784 19.8014C8.64988 19.9323 9.31964 20 9.99995 20C10.6803 20 11.35 19.9323 12.0021 19.8014C12.0582 19.7901 12.1068 19.7548 12.1344 19.7048C12.7557 18.5825 13.294 16.9943 13.6452 15.0578C13.6632 14.9586 13.602 14.8615 13.5044 14.8353C12.3865 14.5352 11.2116 14.375 9.99995 14.375C8.78825 14.375 7.61339 14.5352 6.49549 14.8354C6.39789 14.8615 6.3367 14.9587 6.35469 15.0578Z" fill="#3C7EF3"/>
                                            <path d="M4.26301 14.2785C4.43422 14.2063 4.60646 14.1376 4.77963 14.0722C4.86461 14.04 4.91654 13.9548 4.90522 13.865C4.78182 12.8845 4.70539 11.8618 4.67928 10.8149C4.67662 10.7088 4.59011 10.624 4.48365 10.624H0.196196C0.0812702 10.624 -0.00876554 10.7221 0.00068096 10.8362C0.139243 12.5128 0.692901 14.1045 1.61215 15.4924C1.67228 15.5832 1.79442 15.6078 1.88551 15.5478C2.63445 15.0547 3.42835 14.6303 4.26301 14.2785Z" fill="#3C7EF3"/>
                                            <path d="M17.3019 16.5219C16.6094 16.0756 15.8737 15.6906 15.1022 15.3751C14.9876 15.3283 14.858 15.3974 14.8348 15.5187C14.6029 16.7322 14.2931 17.8544 13.9116 18.8428C13.9105 18.8457 13.9093 18.8486 13.9083 18.8515C13.8451 19.0147 14.0115 19.1737 14.1712 19.1009C15.2458 18.6113 16.2352 17.9292 17.0957 17.0717C17.1788 16.9888 17.26 16.9047 17.3397 16.8194C17.4228 16.7304 17.4044 16.5879 17.3019 16.5219Z" fill="#3C7EF3"/>
                                            <path d="M15.3202 10.8149C15.2941 11.8618 15.2177 12.8846 15.0943 13.865C15.083 13.9549 15.1349 14.0401 15.2199 14.0722C15.3931 14.1376 15.5653 14.2063 15.7365 14.2785C16.5712 14.6304 17.3651 15.0548 18.114 15.5478C18.2051 15.6078 18.3272 15.5832 18.3874 15.4924C19.3066 14.1046 19.8603 12.5128 19.9989 10.8363C20.0083 10.7221 19.9183 10.624 19.8033 10.624H15.5159C15.4094 10.6241 15.3229 10.7089 15.3202 10.8149Z" fill="#3C7EF3"/>
                                            <path d="M2.69852 3.47803C3.39093 3.92428 4.1267 4.30928 4.89818 4.62475C5.01279 4.67162 5.14238 4.60252 5.16554 4.48123C5.39743 3.26768 5.70729 2.14553 6.08879 1.15709C6.08989 1.1542 6.09103 1.15131 6.09212 1.14842C6.15527 0.985138 5.98888 0.826232 5.82919 0.899005C4.75464 1.38865 3.76519 2.07072 2.90469 2.92823C2.8216 3.01104 2.74034 3.09518 2.66069 3.18045C2.57756 3.26951 2.59602 3.41198 2.69852 3.47803Z" fill="#3C7EF3"/>
                                            <path d="M4.67509 9.37745C4.6963 8.26397 4.77438 7.17639 4.90522 6.13647C4.91654 6.04658 4.86461 5.96139 4.77963 5.92928C4.60646 5.86385 4.43422 5.79514 4.26301 5.72295C3.42835 5.37112 2.63445 4.9467 1.88551 4.45365C1.79442 4.39369 1.67232 4.4183 1.61215 4.50908C0.692901 5.89694 0.139243 7.48869 0.00068096 9.16522C-0.00876554 9.27936 0.0812702 9.37745 0.196196 9.37745H4.67509Z" fill="#3C7EF3"/>
                                            <path d="M5.82919 19.1009C5.98888 19.1737 6.15527 19.0147 6.09213 18.8515C6.09103 18.8486 6.08989 18.8457 6.0888 18.8428C5.70733 17.8544 5.39744 16.7322 5.16555 15.5187C5.14238 15.3974 5.0128 15.3283 4.89818 15.3751C4.12671 15.6906 3.39094 16.0756 2.69852 16.5219C2.59598 16.5879 2.57756 16.7304 2.66074 16.8194C2.74038 16.9047 2.82164 16.9888 2.90474 17.0717C3.76515 17.9292 4.75461 18.6113 5.82919 19.1009Z" fill="#3C7EF3"/>
                                        </svg>
                                    </span>
                                </ListItemIcon>
                                <ListItemText  inset primary={'Smart Webhook'} />

                            </MenuItem>
                        </Menu>

                    </div>
                    {openActionModal &&
                    <ActionModal currentClip={editableCampaign} openActionModal={openActionModal} setOpenActionModal={ () => closeActionModel() } showAll={true} />
                    }

                    {openActivityModal &&
                    <ActivityModal currentClip={editableCampaign} openActivityModal={openActivityModal} setOpenActivityModal={() => closeActivityModel()} showAll={true} />
                    }

                    {openWebhookModal &&
                        <WebHookModal 
                            openWebhookModal={openWebhookModal} 
                            setOpenWebhookModal={handleCloseWebhookModal} 
                            showAll={true} 
                            currentClip={editableCampaign} 
                            handleRemoveCurrentClip={handleRemoveCurrentClip}
                        />
                    }

                    <SmartWebhookDrawer
                        data={selectedSmartWebhookTemplate}
                        open={openSmartWebhookBuilder}
                        isSubmitting={isSubmittingAddSmartWebhookForm}
                        onClose={onCloseWebhookBuilderModal}
                        onSubmit={handleSubmitWebhookForm}
                    />

                    {/* {renderDirectMailV2option()} */}

                </div>
            </div>
            <CampaignTimeline handleTimelineEdit={handleTimelineEdit} campaignId={props.campaignId} />
        </div>
    );
}

const mapStateToProps = state => {
    return {
        userInfo: state.marketingReducer.userInfo,
        campaignDetails: state.marketingReducer.campaignDetails,


      tabValue: state.marketingReducer.tabValue,
      hour: state.marketingReducer.hour,
      min: state.marketingReducer.min,
      day: state.marketingReducer.day,
      recurringDay: state.marketingReducer.recurringDay,
      laterHour: state.marketingReducer.laterHour,
      laterMin: state.marketingReducer.laterMin,
      time: state.marketingReducer.time,
      startDate: state.marketingReducer.startDate,
      arrSlotTimeHour1: state.marketingReducer.arrSlotTimeHour1,
      arrSlotTimeMin1: state.marketingReducer.arrSlotTimeMin1,
      greetingOption: state.marketingReducer.greetingOption,
      greetingsOptionTimeMin1: state.marketingReducer.greetingsOptionTimeMin1,
      greetingsOptionTimeHour1: state.marketingReducer.greetingsOptionTimeHour1,
      recurringTimeHour1: state.marketingReducer.recurringTimeHour1,
      recurringTimeMin1: state.marketingReducer.recurringTimeMin1,

      campaignTimezone: state.marketingReducer.campaignTimezone
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getUserInfo: (params) => dispatch(getUserInfo(params)),
        fetchCampaignDetails: (params) => dispatch(fetchCampaignDetails(params)),
        fetchCampaignTimelines: (params) => dispatch(fetchCampaignTimelines(params)),

        // for schedule time settings
        // for time settings
        updateTabValue: (params) => dispatch(updateTabValue(params)),
        updateHour: (params) => dispatch(updateHour(params)),
        updateMin: (params) => dispatch(updateMin(params)),
        updateDay: (params) => dispatch(updateDay(params)),
        updateRecurringDay: (params) => dispatch(updateRecurringDay(params)),
        updateLaterHour: (params) => dispatch(updateLaterHour(params)),
        updateLaterMin: (params) => dispatch(updateLaterMin(params)),
        updateTime: (params) => dispatch(updateTime(params)),
        updateStartDate: (params) => dispatch(updateStartDate(params)),
        updateArrSlotTimeHour1: (params) => dispatch(updateArrSlotTimeHour1(params)),
        updateArrSlotTimeMin1: (params) => dispatch(updateArrSlotTimeMin1(params)),
        updateGreetingOption: (params) => dispatch(updateGreetingOption(params)),
        updateGreetingsOptionTimeMin1: (params) => dispatch(updateGreetingsOptionTimeMin1(params)),
        updateGreetingsOptionTimeHour1: (params) => dispatch(updateGreetingsOptionTimeHour1(params)),
        updateRecurringTimeHour1: (params) => dispatch(updateRecurringTimeHour1(params)),
        updateRecurringTimeMin1: (params) => dispatch(updateRecurringTimeMin1(params)),
        reloadCampaignSettingsStatus: (params) => dispatch(reloadCampaignSettingsStatus(params)),
        followupSave: (params, callback) => dispatch(followupSave(params, callback)),
        updateEmailStepSetting: (params, callback) => dispatch(updateEmailStepSetting(params, callback)),
        updateVideoEmailStepSetting: (params, callback) => dispatch(updateVideoEmailStepSetting(params, callback)),
        updateReducerState: (params) => dispatch(updateReducerState(params)),
        deleteCampaignSetting: (params, callback) => dispatch(deleteCampaignSetting(params, callback)),
        updateCampaignSetting: (params, callback) => dispatch(updateCampaignSetting(params, callback))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignSettingSection);