/* eslint-disable array-callback-return */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import { createCampaignByTemplate, getCampaignTemplateByCategory, getCampaignTemplateCategory, _storeCampaignTemplateCategory, _storeCampaignTemplateSearch, _storeSelectedCampaignTemplateCategory } from '../../actions/marketingTemplateAction';
import showNotification from '../../helpers/showNotification';
import icons from './icons';
import './css/marketing1.css'
import './css/marketing_search.css'
import OutSideConstants from '../../constants/ConstantFromOutside';
import { PlatTypeForPackage } from '../../constants/CoreConstants';
import { Link } from 'react-router-dom';
import { createNewCampaign } from '../../actions/marketingAction';
import { MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import image_icons from './images/image_icons';
import Utils from '../../helpers/Utils';
import ShowMoreText from "react-show-more-text";
// import './css/materialize.min.css'


const mapStateToProps = state => ({
    marketingTemplateReducer: state.marketingTemplateReducer
});

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
}));

const mapDispatchToProps = dispatch => ({
    getCampaignTemplateCategory: (params, callback) => dispatch(getCampaignTemplateCategory(params, callback)),
    getCampaignTemplateByCategory: (params, callback) => dispatch(getCampaignTemplateByCategory(params, callback)),
    _storeCampaignTemplateCategory: (params) => dispatch(_storeCampaignTemplateCategory(params)),
    _storeSelectedCampaignTemplateCategory: (params) => dispatch(_storeSelectedCampaignTemplateCategory(params)),
    _storeCampaignTemplateSearch: (params) => dispatch(_storeCampaignTemplateSearch(params)),
    createCampaignByTemplate: (params, callback) => dispatch(createCampaignByTemplate(params, callback)),
    createNewCampaign: (params, callback) => dispatch(createNewCampaign(params, callback)),
})


const MarketingTemplateApp = (props) => {
    const {selectedCategoryId} = props.marketingTemplateReducer
    const [creating, setCreating] = useState(false)

    const handleCreateManually = () => {
        if(creating)return;
        setCreating(true)
        props.createNewCampaign({}, (res) => {
            let current_url = window.location.href
            let domain = (new URL(current_url));
            let root_url = domain.protocol+"//"+domain.hostname
            if(domain.port != ''){
                root_url = root_url + ":" +domain.port
            }
            setCreating(false)
            window.location.href = root_url +'/campaign/details/'+res.id
        })
    }

    useEffect(() => {
        document.title = `Campaign templates | ${Utils.getAccountData('AuthUserAgencyName')}`;
        if (window.setActiveSidebar) {
            window.setActiveSidebar('marketing');
        }
    },[])

    return(
       <>
            {selectedCategoryId == '' ?
            <NonSelectedCategoryContaier handleCreateManually={handleCreateManually}/>
            :
            <SelectedCategoryContaier handleCreateManually={handleCreateManually}/>
            }
       </>
    )

}
const MarketingTemplate = connect(mapStateToProps, mapDispatchToProps)(showNotification(MarketingTemplateApp));
export default MarketingTemplate;

const selectStyles = makeStyles({
    root : {

    }
});


const NonSelectedCategoryContaierApp = (props) => {
    const [categoryList, setCategoryList] = useState([])
    const [selectedCategory, setSelectedCategory] = useState('');
    const classes = selectStyles();

    useEffect(() => {
        props.getCampaignTemplateCategory({}, (res) => {
            props._storeCampaignTemplateCategory(res)
            setCategoryList(res)
        })
    }, [])

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value)
        props._storeSelectedCampaignTemplateCategory({id: categoryList[event.target.value].id, data: categoryList[event.target.value], index: event.target.value})
    }

    return(
        <div className="marketing_section">
            <div className="page_heading mr-0">
                <h4>Marketing Campaign Templates</h4>
                {/*<a onClick={props.handleCreateManually}>*/}
                {/*    {icons.plus}*/}
                {/*    Setup Manually	*/}
                {/*</a>*/}
            </div>
            <div className="page_content">
                <div className="select_content category_select pt-0">
                    <h4 style={{marginTop:"30px",marginBottom:"30px"}}>Use one of our pre-built marketing campaigns!</h4>
                    {/*{OutSideConstants.packageType == PlatTypeForPackage.PLAN_TYPE_STANDARD ?*/}
                    {true == true ?
                        <Select
                            className="category_select input-field"
                            classes={classes}
                            displayEmpty  
                            value={selectedCategory} 
                            onChange={handleCategoryChange}
                            anchorEl={null}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                  },
                                transformOrigin:{
                                vertical: 'top',
                                horizontal: 'center',
                                },
                                getContentAnchorEl: null
                            }}>
                            <MenuItem value="" disabled>Select A Category</MenuItem>
                            {categoryList.map((item, index) => {
                                return(
                                    <MenuItem className="menuitem-li-hover-color-with-border" value={index} key={index}>{item.category}</MenuItem>
                                )
                            })}
                        </Select> 
                    :
                        <div>
                            <h3 className="text-center text-danger" style={{fontSize: 15}}><b>Sorry!</b> To use this feature please upgrade your package to <b>STANDARD PACKAGE</b> type. <b><a>Upgrade Now</a></b></h3>
                        </div>
                    }
                    <div className="campaign_template-working_img">{image_icons.working}</div>
                </div>
            </div>
        </div>
    )
}
export const NonSelectedCategoryContaier = connect(mapStateToProps, mapDispatchToProps)(NonSelectedCategoryContaierApp);


const SelectedCategoryContaierApp = (props) => {

    const classes = useStyles();

    const {categories, selectedCategoryId, selectedCategoryData,templateSearchText, selectedCategoryIndex} = props.marketingTemplateReducer
    const [loading, setLoading] = useState(false)
    const [templateList, setTemplateList] = useState([])
    const [selectedCategory, setSelectedCategory] = useState(selectedCategoryIndex)
    // eslint-disable-next-line no-unused-vars
    const [selectList, setSelectList] = useState(categories)

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value)
        props._storeSelectedCampaignTemplateCategory({id: selectList[event.target.value].id, data: selectList[event.target.value], index: event.target.value})
    }

    useEffect(() => {
        setLoading(true)
        props.getCampaignTemplateByCategory({
            categoryId: selectedCategoryId,
            searchKey: templateSearchText
        }, (res) => {
            setLoading(false)
            setTemplateList(res)
        })
    }, [selectedCategoryId, templateSearchText])

    const handleSearchText = (event) => {
        props._storeCampaignTemplateSearch(event.target.value)
    }

    const handleBackButton = (e) => {
        e.preventDefault();
        props._storeSelectedCampaignTemplateCategory({id: '', data: null, index: ''});       
    }

    return(
        <div className="marketing_section">

            <a href="#!" onClick={handleBackButton} className="back my-2">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0.875 9C0.875 13.4871 4.51289 17.125 9 17.125C13.4871 17.125 17.125 13.4871 17.125 9C17.125 4.51289 13.4871 0.875 9 0.875C4.51289 0.875 0.875 4.51289 0.875 9ZM9.625 5.87148L7.14141 8.375H12.9844V9.625H7.14141L9.625 12.1285L8.73828 13.009L4.76016 9L8.73828 4.99102L9.625 5.87148Z"
                        fill="#3C7EF3"/>
                </svg>
                <span className="ml-2">Back</span>
            </a>

            <div className="page_heading mr-0 row">
                <div className="col s6 pl-0">
                <h4 className="font-18"> System Campaign Template For <span>{selectedCategoryData.category}</span></h4>
                </div>
                <div className="col s6 pr-0">
                <div className="search_field">
                    <Select 
                        className="category_select input-field"
                        displayEmpty  
                        value={selectedCategory} 
                        onChange={handleCategoryChange}
                        anchorEl={null}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'center',
                                },
                            transformOrigin:{
                            vertical: 'top',
                            horizontal: 'center',
                            },
                            getContentAnchorEl: null
                        }}>
                        <MenuItem value="" disabled >Select A Category</MenuItem>
                        {selectList.map((item, index) => {
                            return(
                                <MenuItem className="menuitem-li-hover-color-with-border" value={index} key={index}>{item.category}</MenuItem>
                            )
                        })}
                    </Select>
                    <div className="input-field search mr-0">
                        <input name="search" placeholder="Search" type="text" className="validate" onChange={handleSearchText}/>
                        {icons.search}
                    </div>
                    {/*<a onClick={props.handleCreateManually}>*/}
                    {/*    <svg width="20" height="20" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                    {/*        <circle cx="10" cy="10.4754" r="10" fill="white"/>*/}
                    {/*        <path d="M13.8461 10.4754C13.8461 10.8299 13.8153 11.1165 13.4609 11.1165H10.641V13.9363C10.641 14.2902 10.3544 14.3216 9.99996 14.3216C9.64548 14.3216 9.35894 14.2902 9.35894 13.9363V11.1165H6.53906C6.18522 11.1165 6.15381 10.8299 6.15381 10.4754C6.15381 10.1209 6.18522 9.8344 6.53906 9.8344H9.35894V7.01453C9.35894 6.66004 9.64548 6.62927 9.99996 6.62927C10.3544 6.62927 10.641 6.66004 10.641 7.01453V9.8344H13.4609C13.8153 9.8344 13.8461 10.1209 13.8461 10.4754Z" fill="#3C7EF3"/>*/}
                    {/*    </svg>*/}
                    {/*    Setup Manually*/}
                    {/*</a>*/}
                </div>
                </div>
              
               
            </div>
            <div className="page_content" style={{backgroundImage: 'unset'}}>
                <div className={loading ? "template-page-loader " : "row"}>
                    {loading ?
                        <div className={classes.root}>
                            <CircularProgress />
                        </div>
                    :
                    templateList.length > 0 ?
                        templateList.map((item, index) => {
                            return(
                                <EachTemplate data={item} key={index} index={index} />
                            )
                        }) 
                        : 
                        <div className="d-flex justify-content-center align-items-center" style={{height: '200px'}}>No template created</div>
                    }
                </div>
            </div>
        </div>
    )
}
export const SelectedCategoryContaier = connect(mapStateToProps, mapDispatchToProps)(SelectedCategoryContaierApp);


const EachTemplateApp = props => {
    const {data} = props
    const [creating, setCreating] = useState(false)

    const renderLength = () => {
        let length = 0;
        if(data.settings.length > 0){
            data.settings.map((item) => {
                if (item.day > length) {
                    length = item.day;
                }
            })
        }
        return length;
    }

    const handleCreate = () => {
        if(creating)return
        setCreating(true)
        props.createCampaignByTemplate({campaignId : "system-"+data.id}, (res) => {
            if(res.status == 'error'){
              window.showNotification("ERROR", "Something went wrong !")
                return
            }
            let current_url = window.location.href
            let domain = (new URL(current_url));
            let root_url = domain.protocol+"//"+domain.hostname
            if(domain.port != ''){
                root_url = root_url + ":" +domain.port
            }
            let id = res.html.campaign_id.replace('own-', '')
            setCreating(false)
            window.location.href = root_url +'/campaign/details/'+id
        })
    }

    if(data.settings.length == 0){
        return null
    }

    return(
        <div className="col l6">
            <div className="marketing_card">
                <div className="heading">
                    <h4>{data.title}</h4>
                </div>
                <div className="tags pt-2">
                    <p>Category : <a href="#">{data.admin_campaign_categories.category}</a></p>
                    <p>Campaign Length : <strong>{renderLength()}</strong> days</p>
                </div>
                {data.description != null &&
                <div className="card_article">
                    {/* <p title={data.description}>Description : {data.description.length < 200 ? data.description : data.description.substr(0, 200)+'...'}</p> */}
                    <ShowMoreText
                        lines={3}
                        more="Show more"
                        less="Show less"
                        expanded={false}
                        truncatedEndingComponent={"... "}
                    >
                        { data.description }
                    </ShowMoreText>
                </div>
                }
                <div className="card_action cursor-pointer">
                    <Link to={`/campaign/view/system-${data.id}`} className="card_btn ">
                        <span>
                            {icons.preview}
                            Preview
                        </span>
                    </Link> 
                    <a onClick={handleCreate}>
                        <span>
                            {icons.createCampaign}
                            {creating ? 'Creating ': 'Create '} Campaigns
                        </span>
                    </a>
                </div>
            </div>
        </div>
    )
} 
export const EachTemplate = connect(mapStateToProps, mapDispatchToProps)(EachTemplateApp);