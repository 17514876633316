/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable eqeqeq */
import { useState } from "react";
import { useDispatch, connect, useSelector } from "react-redux";
import ReactAudioPlayer from "react-audio-player";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { FileCopy } from "@material-ui/icons";
import { PERMISSION_LEVELS, PERMISSION_MODULES, PlatTypeForPackage, PRIVACY_LEVEL, QUICK_REPLIES_OPTION_TYPE, QuickReplyType } from "../../../../constants/CoreConstants";
import CustomBootstrapTooltip from "../../../Common/CustomBootstrapTooltip";
import showNotification from "../../../globals/ShowNotification";
import CustomImageViewer from "../../../Common/CustomImageView";
import Utils from "../../../../helpers/Utils";
import NewConfirmAlert from "../../../Common/new-alert/NewConfirmAlert";
import Messages from "../../../../constants/Messages";
import OutSideConstants from "../../../../constants/ConstantFromOutside";
import { getMessageTemplates } from "../../../../actions/marketingAction";
import ActionControl from "../../../Common/ActionControl/ActionControl";
import Constants from "./QuickReplyCard/assets/Constants";
import { handleChangeActionTypes } from "../../../Video/redux/videoEmailAction";
import TemplateBroadcastModal from "../../quickTemplates/TemplateBroadcastModal";
import { cloneQuickReplies, deleteQuickReplies, handlePermissionDeniedModal } from "../../../../actions/quickRepliesAction";
import { updateMessageTemplate } from "../../../../api/marketingApi";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "@material-ui/core";


const TEMPLATE_CLASSES = {
  [QuickReplyType.SMS]: "sms_template",
  [QuickReplyType.MMS]: "mms_template",
  [QuickReplyType.VOICE]: "voice_template",
  [QuickReplyType.EMAIL]: "email_template",
  [QuickReplyType.VIDEO_EMAIL]: "video_email_template",
  [QuickReplyType.POSTCARD]: "postcard_template",
  [QuickReplyType.GREETING_CARD]: "greeting_card_template",
  [QuickReplyType.LETTER]: "letter_template",
  [QuickReplyType.GIFT]: "gift_card_template",
};

const EachTemplate = ({
  template,
  showNotification,
  activeAudioPlayer,
  handleAudioPlayer,
}) => {
  
  return (
    <div
      className={
        "template update_quick_template_module " +
        TEMPLATE_CLASSES[template.type]
      }
    >
      <div>
        <CustomBootstrapTooltip title={template.title} arrow>
          <h6 className="center-align truncate">{template.title}</h6>
        </CustomBootstrapTooltip>
      </div>
      <div
        className={`template_display quick-reply-display-div ${
          template.type == QuickReplyType.VOICE ? "justify-content-center" : ""
        } ${
          template.type == QuickReplyType.POSTCARD ||
          template.type == QuickReplyType.GREETING_CARD ||
          template.type == QuickReplyType.LETTER
            ? ""
            : "awesome__scroll_bar"
        }`}
      >
        <RenderTemplateBody
          template={template}
          activeAudioPlayer={activeAudioPlayer}
          handleAudioPlayer={handleAudioPlayer}
        />
      </div>
      <TemplateActions
        template={template}
        showNotification={showNotification}
      />
    </div>
  );
};

export default showNotification(EachTemplate);

const RenderTemplateBody = ({
  template,
  activeAudioPlayer,
  handleAudioPlayer,
}) => {
  if (template.type == QuickReplyType.SMS) {
    return <div dangerouslySetInnerHTML={{ __html: template.message }} />;
  } else if (template.type == QuickReplyType.MMS) {
    // return <img src={template.video_file_url} alt="Loading"/>
    return (
      <CustomImageViewer url={template.video_file_url} key={template.id} />
    );
  } else if (template.type == QuickReplyType.VIDEO_EMAIL) {
    return <img className="w-100" src={template.gif_file_url} alt="" />;
  } else if (template.type == QuickReplyType.VOICE) {
    return (
      <div className="audio_player">
        <div className="controls d-flex align-items-center">
          <ReactAudioPlayer
            src={template.template_files.file}
            autoPlay={false}
            controls
          />
        </div>
      </div>
    );
  } else if (template.type == QuickReplyType.EMAIL) {
    return (
      <>
        <p className={"email-subject truncate"}>{template.subject}</p>
        <div
          className="regarding_person"
          dangerouslySetInnerHTML={{ __html: template.message }}
        />
      </>
    );
  } else if (
    template.type == QuickReplyType.POSTCARD ||
    template.type == QuickReplyType.GREETING_CARD ||
    template.type == QuickReplyType.LETTER
  ) {
    return <iframe src={template.pdf_url} frameborder="0"></iframe>;
  } else if (template.type == QuickReplyType.GIFT) {
    return (
      <>
        <p>{template.gift_sku_id}</p>
        <iframe src={template.pdf_url} frameborder="0"></iframe>
      </>
    );
  }

  return "";
};

const TemplateAction = ({ template, deleteFromList, index, type }) => {
  const { isClonningQuickReplies } = useSelector((state) => state.quickRepliesReducer);
  const isTempUser = Utils.getAccountData('isTemplateUser');
  const dispatch = useDispatch();
  const [showBroadcastModal, setShowBroadcastModal] = useState(false);
  const [submittingTemplate, setSubmittingTemplate] = useState(false);
  const isTemplatePrivate = template.privacy_level === PRIVACY_LEVEL.PRIVACY_LEVEL_PRIVATE;
  const canUserManageGlobalItem = window.hasPermission(
  PERMISSION_MODULES.MANAGE_GLOBAL_ITEMS,PERMISSION_LEVELS.WRITE);
  const history = useHistory();
  const myUserId = Utils.getAccountData("userId");
  const isUserTemplateOwner = myUserId === template?.user_id;

  const deleteTemplate = () => {
    if((canUserManageGlobalItem  && isUserTemplateOwner ) || 
        (!canUserManageGlobalItem && isTemplatePrivate)) 
        {
      NewConfirmAlert({
        onSubmit: () => { dispatch(deleteQuickReplies({temp_id: template.id}))},
        title: `Are you sure you want to delete this quick reply?`,
        description: '',
        cancelText: 'No',
        submitText: 'Yes'
      });
    } else {
      dispatch(handlePermissionDeniedModal({
        openPermissionDeniedModal: true,
        permissionDeniedTitle:'this quick reply',
        permissionDeniedAction:'delete',
      }));
    }
};


  const openBroadcastModal = (e) => {
    e.preventDefault();
    setShowBroadcastModal(true);
  };

  const updateQuickReplyEmailVideo = (response) => {
    handleSaveTemplate({
      content_type: response.content,
      content: response.content,
      title: response.title,
      templateId: response.id,
      videoId: response.email_video_id,
      email_body: response.message,
      email_subject: response.subject,
    });
  };

  const handleSaveTemplate = (data) => {
    // check validation
    if (submittingTemplate) return;
    setSubmittingTemplate(true);
    if (data.title.trim() == "") {
      window.showNotification(Messages.emptyTitleInAddQuickReply, "error");
      setSubmittingTemplate(false);
      return false;
    }

    let formData = new FormData();
    //$packageType = ViewHelper::getPackTypeForView(auth()->id());
    let package_type = OutSideConstants.packageType;
    formData.append(
      "media_type",
      package_type == PlatTypeForPackage.PLAN_TYPE_STANDARD
        ? QuickReplyType.SMS
        : QuickReplyType.EMAIL
    );
    formData.append("content", data.content);
    formData.append("title", data.title);
    formData.append("id", data.templateId);
    if (data.content_type == QuickReplyType.SMS) {
      // sms save
      for (var key in data) {
        formData.append(key == "body_sms" ? "message" : key, data[key]);
      }
      formData.append("type", QuickReplyType.SMS);
    } else if (data.content_type == QuickReplyType.MMS) {
      // mms save
      for (let key in data) {
        formData.append(key, data[key]);
      }
      formData.append("type", QuickReplyType.MMS);
    } else if (data.content_type == QuickReplyType.VOICE) {
      // voice save
      for (let key in data) {
        formData.append(key, data[key]);
      }
      formData.append("type", QuickReplyType.VOICE);
    } else if (data.content_type == QuickReplyType.VIDEO_EMAIL) {
      // video email save
      for (let key in data) {
        formData.append(key, data[key]);
      }
      formData.append("type", QuickReplyType.VIDEO_EMAIL);
    } else if (data.content_type == QuickReplyType.EMAIL) {
      // email save
      for (let key in data) {
        if (key == "email_subject") {
          formData.append("subject", data[key]);
        } else if (key == "body_sms") {
          formData.append("message", data[key]);
        } else if (key != "content") {
          formData.append(key, data[key]);
        }
      }
      formData.append("type", QuickReplyType.EMAIL);
    } else if (
      data.content_type == QuickReplyType.POSTCARD ||
      data.content_type == QuickReplyType.LETTER ||
      data.content_type == QuickReplyType.GREETING_CARD ||
      data.content_type == QuickReplyType.GIFT
    ) {
      for (let key in data) {
        formData.append([key], data[key]);
      }
      formData.append("type", data.content_type);
    }

    updateMessageTemplate(formData).then((res) => {
      let response = res.data;
      setSubmittingTemplate(false);
      if (response.status == "success") {
        window.showNotification("success", response.html);
        window.sendCommunication({ open: false }, "videoEmail");
        dispatch(getMessageTemplates({ search_key: "", type: type }));
      } else if (response.status == "validation-error") {
        // do validation here
        if (typeof response.html.title != "undefined") {
          window.showNotification(response.html.title, "error");
        }
      } else {
        window.showNotification("Something went wrong. Try again.", "error");
      }
    });
  };

const handleCloneQuickReplies = (id) => {
  if(isClonningQuickReplies) return;
  dispatch(cloneQuickReplies({id}))
};

const handleVideoMailEdit = (e) => {
  e.preventDefault();
  if ((canUserManageGlobalItem  && isUserTemplateOwner ) || 
  (!canUserManageGlobalItem && isTemplatePrivate))  {
    window.sendCommunication(
      {
        open: true,
        from: "quick_reply",
        currentQuickReply: template,
        responseCallback: updateQuickReplyEmailVideo,
      },
      "videoEmail"
    );
  } else {
    dispatch(handlePermissionDeniedModal({
      openPermissionDeniedModal: true,
      permissionDeniedTitle:'this quick reply',
      permissionDeniedAction:'edit',
    }));
  }
};

const handleEditClick = () => {
  if ((canUserManageGlobalItem  && isUserTemplateOwner ) || 
  (!canUserManageGlobalItem && isTemplatePrivate))  {
    history.push(`/quick-replies-global/${template.id}`);
  } else {
    dispatch(handlePermissionDeniedModal({
      openPermissionDeniedModal: true,
      permissionDeniedTitle:'this quick reply',
      permissionDeniedAction:'edit',
    }));
  }
};
  return (
    <>
      <div className="quick-reply-card-alt-content">
        <ActionControl alertMessage="Quick Reply Broadcast feature is not available for template user" status="error"   isDisabled={isTempUser == 1}>
          <div
            onClick={(e) => openBroadcastModal(e)}
            className="quick-reply-card-alt-campaign accent--bg--text--color"
          >
            <svg
              width="25"
              height="21"
              viewBox="0 0 25 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M20.0807 0L8.71094 4.54768V13.2149L20.0807 17.7626V0Z"></path>
              <path d="M7.63481 4.71484H6.71875V13.0354H7.63481V4.71484Z"></path>
              <path d="M5.65064 12.8401V4.51953H1.95599C1.43722 4.51953 0.939714 4.72561 0.572895 5.09243C0.206075 5.45925 0 5.95676 0 6.47552V10.8928C0 11.4115 0.206075 11.9091 0.572895 12.2759C0.939714 12.6427 1.43722 12.8488 1.95599 12.8488L5.65064 12.8401Z"></path>
              <path d="M24.0011 8.72105C24.0002 7.98929 23.7092 7.28775 23.1918 6.77031C22.6743 6.25288 21.9728 5.9618 21.241 5.96094H21.1562V11.4812H21.241C21.9728 11.4803 22.6743 11.1892 23.1918 10.6718C23.7092 10.1544 24.0002 9.45281 24.0011 8.72105Z"></path>
              <path d="M5.1711 20.9403C5.46206 20.9403 5.74908 20.8731 6.00984 20.744C6.27061 20.615 6.49808 20.4275 6.6746 20.1962C6.85111 19.9649 6.9719 19.6961 7.02758 19.4105C7.08325 19.1249 7.07232 18.8303 6.99561 18.5497L5.77421 14.1172H1.95242H1.84375L3.34335 19.5581C3.45566 19.9562 3.69502 20.3068 4.02498 20.5563C4.35494 20.8059 4.75741 20.9407 5.1711 20.9403Z"></path>
            </svg>
            <span>Broadcast</span>
          </div>
        </ActionControl>
        {template.type === Constants.MESSAGE_TEMPLATE.VIDEO_EMAIL ? (
          <a
            href="#"
            className="quick-reply-card-alt-campaign-edit-button accent--bg--text--color"
            onClick={(e) => {handleVideoMailEdit(e)}}
          >
            <EditIcon fontSize="small" />
            <span>Edit</span>
          </a>
        ) : (
          <Button
            onClick={handleEditClick}
            className="quick-reply-card-alt-campaign-edit-button accent--bg--text--color"
          >
            <EditIcon fontSize="small" />
            <span>Edit</span>
          </Button>
        )}
       
        <div
            onClick={() => handleCloneQuickReplies(template?.id)}
            className="quick-reply-card-clone-campaign accent--bg--text--color"
          >
            <FileCopy fontSize="small"/>
            <span>{isClonningQuickReplies ? "Cloning" : "Clone"}</span>
          </div>

       <div
          onClick={(e) => deleteTemplate(e)}
          className="quick-reply-card-alt-campaign-delete-button"
        >
          <DeleteIcon fontSize="small" />
          <span>Delete</span>
        </div>
      </div>

      {showBroadcastModal && (
        <TemplateBroadcastModal
          show={showBroadcastModal}
          template={template}
          onClose={() => setShowBroadcastModal(false)}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    type: state.marketingReducer.type,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeActionTypes: (payload) => dispatch(handleChangeActionTypes(payload)),
  };
};

export const TemplateActions = connect(mapStateToProps,mapDispatchToProps)(TemplateAction);
