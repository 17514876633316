
import "./../CampaignDetails/modify-style.css";
const CampaignActionNoticeBoard = ({
  title="Your campaign will be activated shortly",
  message = "It could take up to 5 minutes."
}) => {
  return (
    <>
      <div className='campaign-noticeboard-parent-div'>
        <div
          style={{ width: "100%",display:'flex',justifyContent:'center',alignItems:'center' }}
        >
            <div style={{ width: "56px", height: "56px" }}>
            <img style={{width:'100%',height:'100%',objectFit:'cover'}} src={`${process.env.REACT_APP_CDN_LINK}/assets/images/releases/1.0.0/marketing-campaign-popup.png`} alt="like"/>
          </div>
        </div>
        <div style={{textAlign:'center',color: "#000",}}>
          <div style={{ marginTop: "20px",fontSize:'18px',fontWeight:700 }}>{title}</div>
          <div style={{ fontSize:'15px'}}>{message}</div>
        </div>
      </div>
    </>
  );
};

export default CampaignActionNoticeBoard;
