import {
    BrushOutlinedIcon,
    CopyIcon,
    DollarIcon,
    FilterIcon,
    FolderFilledIcon,
    LockIcon,
    MailIcon,
    MonitorIcon,
    RadioIcon,
    StarIcon,
    WebsiteIcon,
  } from "./Icons";
export const renderFolderIcon = (icon, color, size = 24) => {
    switch (icon) {
      case "FolderFilledIcon":
        return <FolderFilledIcon color={color} size={size} />;
      case "WebsiteIcon":
        return <WebsiteIcon color={color} size={size} />;
      case "MonitorIcon":
        return <MonitorIcon color={color} size={size} />;
      case "MailIcon":
        return <MailIcon color={color} size={size} />;
      case "FilterIcon":
        return <FilterIcon color={color} size={size} />;
      case "DollarIcon":
        return <DollarIcon color={color} size={size} />;
      case "StarIcon":
        return <StarIcon color={color} size={size} />;
      case "LockIcon":
        return <LockIcon color={color} size={size} />;
      case "RadioIcon":
        return <RadioIcon color={color} size={size} />;
      case "CopyIcon":
        return <CopyIcon color={color} size={size} />;
      case "BrushOutlinedIcon":
        return <BrushOutlinedIcon color={color} size={size} />;
  
      default:
        return <FolderFilledIcon color={color} size={size} />;
    }
  };