/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import { Skeleton } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { TemplateActions } from "./EachTemplate";
import QuickRepliesHead from "./QuickRepliesHead";
import { Link } from "react-router-dom";
import { connect } from 'react-redux'
import { showGlobalNotification } from "../../../actions/globalAction";
import showNotification from "../../globals/ShowNotification";
import { Add } from '@material-ui/icons';
import Utils from '../../../helpers/Utils';
import QucikReplyCard from '../../QuickReplyCard';
import { updateFetchingMoreFlagAction, updateMessageTemplateData } from '../../../actions/marketingAction';
import { useDispatch } from "react-redux";

const mapStateToProps = state => ({
    globalReducer: state.globalReducer
})

const mapDispatchToProps = dispatch => ({
    showGlobalNotification: (params) => dispatch(showGlobalNotification(params)),
})

var quickRepliesScrollTop = 0;

const QuickRepliesApp = (props) => {
    const dispatch = useDispatch();
    const {showNotification, message, type} = props.globalReducer

    const allTemplates = useSelector((state) => state.marketingReducer.messageTemplates);
    // const [allTemplates, setAllTemplates] = useState()
    // const perPage = useSelector((state) => state.marketingReducer.perPage);
    // const currentPage = useSelector((state) => state.marketingReducer.currentPage);
    const needTofetch = useSelector((state) => state.marketingReducer.needTofetch);
    const loading = useSelector((state) => state.marketingReducer.loadingQuickReplies)

    const [activeAudioPlayer, setActiveAudioPlayer] = useState('')

    // useEffect(() => {
    //     if(document.getElementById("main__content_wrapper_parent_module")){
    //         document.getElementById("main__content_wrapper_parent_module").classList.add("disabled_scroll_quick_replies");
    //     }

    //     return () => {
    //         document.getElementById("main__content_wrapper_parent_module").classList.remove("disabled_scroll_quick_replies");
    //     }
    // }, [])

    useEffect(() => {
        document.title = `Quick Replies | ${Utils.getAccountData('AuthUserAgencyName')}`;
        if (window.setActiveSidebar) {
            window.setActiveSidebar('marketing');
        }

        if(showNotification){
            props.showNotification(message, type)
            props.showGlobalNotification({
                showNotification: false,
                message: '',
                type: 'success'
            })
        }
    }, [showNotification])

    const loader = () => {
        let loader = []
        for (let i = 0; i < 9; i++) {
            loader.push(
                <div className={"template "}>
                    <Skeleton  animation="wave" style={{height: '100%'}} />
                </div>
            )
        }
        return loader
    }

    const handleAudioPlayer = (id) => {
        setActiveAudioPlayer(id)
    }

    const templateFooter = (item, index) => {
        return (
            <TemplateActions template={item} index={index} deleteFromList={deleteFromList} />
        )
    }

    const deleteFromList = (index) => {
        let fullData= [...allTemplates]
        fullData.splice(index, 1);
        dispatch(updateMessageTemplateData(fullData))
    }

    const renderTemplates = () => {
        let showTemplates = []
        allTemplates.map((item, index) => {
            showTemplates.push(
                <QucikReplyCard
                    data={item} 
                    key={index}
                    onSelect={() => {}}
                    className={'main-content-from-quick-reply'}
                    footer={() => templateFooter(item, index)}
                />
            )
        })
        return showTemplates;
    }

    const handleOnScroll = () => {
        let container = document.getElementById("quick__reply_list__main__container");
        if(container.scrollTop > quickRepliesScrollTop){
            let innrerHeight = container.clientHeight;
            let dif = container.scrollHeight - (innrerHeight + container.scrollTop);
            if ( dif < 100 && needTofetch) {
                dispatch(updateFetchingMoreFlagAction(true))
            }
        }
        quickRepliesScrollTop = container.scrollTop;
    }

    return (
        <>
        <div className="main_content_container white awesome__scroll_bar quick__reply_list__main__container" id="quick__reply_list__main__container" onScroll={handleOnScroll}>
            <QuickRepliesHead/>
            <div className="main_content_divider"></div>
            <div className={`main_content_description ${loading ? '' : 'templates-container-alt'}`}>
                <div className={`templates_container ${(allTemplates.length == 0 && !loading)? 'noTemplate' : 'show-templates-alt'}`}>
                    {loading ? 
                        loader()
                    :
                        <>
                        {allTemplates.length == 0 ? 
                            <div>
                                <img src='https://s3.us-east-1.amazonaws.com/pypepro/user/1/12rQcHs2rKJxvNVfAxQ36SqIKcEFfOn134LraC42.png' alt='empty'/>
                                <h4 className='mb-5'>No template created</h4>
                                <Link className='accent--bg--text--color' to={'/quick-reply-add-template'}><Add />Create new template</Link>
                            </div>
                        :
                            renderTemplates()
                        }
                        </>
                    }
                </div>
            </div>
        </div>
        </>
    )
}
const QuickReplies = connect(mapStateToProps, mapDispatchToProps)(showNotification(QuickRepliesApp))
export default QuickReplies