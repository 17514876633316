/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";

import CustomSelectBox from './CustomSelectBox';
import LetterPageButtons from './LetterPageButtons';
import Loading from './assets/Loading';
import TextEditorTinyMCE from './assets/TextEditorTinyMCE';
import { DropdownHelper } from './assets/DropdownHelper';
import CoreConstants from './assets/CoreConstants';

const POSTCARD = 6;
const GREETING_CARD = 7;
const GIFT = 8;
const LETTER = 9;

// const STEPNO = 3
// const NEXTSTEPNO = 4

const CARD_TEXT_MAXLENGTH = 500
const DirectmailTypeConvert = {
    [POSTCARD]: CoreConstants.Timeline.CONTENT_TYPE_POST_CARD,
    [GREETING_CARD]: CoreConstants.Timeline.CONTENT_TYPE_GREETINGS_CARD,
    [LETTER]: CoreConstants.Timeline.CONTENT_TYPE_LETTER,
    [GIFT]: CoreConstants.Timeline.CONTENT_TYPE_GIFT
 }

const ThirdStep = (props) => {

    const [showPDF, setShowPDF] = useState(false)
    const [handWrtingStyleList, setHandWrtingStyleList] = useState([]);
    const [textEditorRef,setTextEditorRef] = useState(null);

    useEffect(() => {
        if(props.useThanksIo){
            selectFirstHandwriting();
        }
    }, [])

    const selectFirstHandwriting = () => {

        if(props.messageType === POSTCARD && props.postcardCategoryList != null){
            setHandwritingStyles(props.postcardCategoryList)

        }else if(props.messageType === GREETING_CARD && props.greetingCardCategoryList != null){
            setHandwritingStyles(props.greetingCardCategoryList)

        }else if(props.messageType === LETTER && props.letterCategoryList != null){
            setHandwritingStyles(props.letterCategoryList)
        }else{
            window.showNotification('ERROR','Something went wrong')
            props.moveToSpecificStep( 1)
        }
    }

    const setHandwritingStyles= (fullData) => {

        setHandWrtingStyleList(fullData.hand_writings)
        if(props.editableCampaign !== undefined && props.editableCampaign != null && props.editableCampaign.handwriting_style_id !== undefined &&
            props.editableCampaign.handwriting_style_id != null && props.editableCampaign.handwriting_style_id !== '') {
                props.setSelectedHandWritingId(props.editableCampaign.handwriting_style_id)

        } else {
            props.setSelectedHandWritingId(fullData.hand_writings[0].handwriting_style_id)

        }
    }

    const handleHandwritingDropdownChange = (value) => {
        // event.preventDefault();
        if (value !== '' && value !== props.selectedHandWritingId) {
            props.setSelectedHandWritingId(value)
        }
    }

    const handlePersonalize = (selectedPersonalize) => {
        textEditorRef.insertContent(selectedPersonalize);
    }

    const generatePDF = async (e) => {
        e.preventDefault();
        setShowPDF(true);
        await props.producePDFLink();
        // pdfviewer.current.scrollIntoView({ behavior: "smooth" });
    }



    return (
        <React.Fragment>
            <fieldset>
                <div className="custom-form-card form-card">
                    {/* <!--=== message part 01 row ===--> */}
                    <div className="row">
                        <div className="col s12">
                            {/* <!-- common section message  --> */}
                            <div className="common-section-message">
                                { props.messageType == LETTER && !props.useThanksIo &&
                                    <LetterPageButtons selectedCardSize={props.selectedCardSize} setPage={props.setPage}/>
                                }
                                <div className="first-step-page-main">
								
									<div className="save_postcard_topbar py-0">
		                            	<div className="message-step-inner-item">
		                                    <span>Customize Content</span>
		                                    <p>Customize your {CoreConstants.Timeline.CONTENT_TYPES[DirectmailTypeConvert[props.messageType]]} by modifying it below with our personalize feature. You can drop in variable information for each card using this feature</p>
		                                </div>
										
										{props.useThanksIo &&
		                                    <div className="margin-none-row text-left">
		                                        <span>Handwriting Styles</span>
	                                            {/* <div className="custom-input custom-select-arrow deal-select-label col s12"> */}
	                                            <div className="custom-input deal-select-label">
	                                                {/* <Select className="second__step__area__select second__step__area__select1"
	                                                    labelId="demo-simple-select-placeholder-label-label"
	                                                    id="demo-simple-select-placeholder-label lead_name"
	                                                    value={props.selectedHandWritingId}
	                                                    onChange={handleHandwritingDropdownChange}

	                                                >
	                                                    {props.categoryList != null &&

	                                                        props.categoryList.hand_writings.map((style) => (
	                                                            <MenuItem value={style.handwriting_style_id}>{style.name}</MenuItem>
	                                                        ))
	                                                    }
	                                                </Select> */}
	                                                
	                                                <DropdownHelper
	                                                    datalist={handWrtingStyleList}
	                                                    noneFieldRequired={true}
	                                                    noneFieldTitle="Select Handwriting Style"
	                                                    className=""
	                                                    mapping={{ title: "name" ,id:"handwriting_style_id"}}
	                                                    menuItemClassName=""
	                                                    updateSelectedData={handleHandwritingDropdownChange}
	                                                    selectedValue={props.selectedHandWritingId}
	                                                    // updateDatatable={updateDatatable}
	                                                    dropdownHeader={'campaignChange'}

	                                                />
	                                            </div>		                                        
		                                    </div>
		                                }
									</div>
									
                                    <div className="handwriting-styles">
                                        
                                        {/* <!-- html editor --> */}
                                       <div className="forth__step__editor__pdf row">
                                       <div className="col s6 html-editor-for-design">
                                            <TextEditorTinyMCE
                                                changeTextInEditor={props.changeTextInEditor}
                                                textEditorValue={props.textEditorValue}
                                                textLimit={CARD_TEXT_MAXLENGTH}
                                                setTextEditorRef={setTextEditorRef}
                                            />

                                           <div style={{display:'flex',justifyContent:'end',marginTop:'16px'}}>
                                               <div className="custom__input__select__wr" style={{width:'250px'}}>
	                                                <div className="custom__input__select__input custom-input custom-select-arrow-parsonaliz deal-select-label custom-select-arrow">
	                                                    {/* Personalize Dropdown */}
	                                                    <CustomSelectBox
	                                                        handlePersonalize={handlePersonalize}
                                                            menuPlacement={'top'}
	                                                    />
	                                                </div>
	                                            </div>
	                                        </div>
										
                                        </div>
                                        <div className={"col s6 pdf__generate__wr "+(!showPDF? '': 'pdf__generate__wr__display__inh')}>
                                            {!showPDF &&
                                            <div className="generate__pdf__button">
                                                <h6>Preview PDF not generated, Click on Generate PDF button to generate Postcard PDF preview</h6>
                                                <div><a href="#!" onClick={(e) => generatePDF(e)}>Generate PDF</a>
                                                <span className="span__title__g">Generate PDF is required to set Postcard</span></div>
                                            </div>
                                        }
                                        <div className="pdf-reader-message">
                                            {/* <img src="images/contact/pdf.png" alt="img" /> */}

                                            {!props.loadingPDF && showPDF &&
                                                <div
                                                // className="pdf-inner-msg"
                                                // ref={pdfviewer}
                                                >

                                                    <iframe className="show__pdf__iframe" src={props.pdfLink} frameborder="0"
                                                        title="Postcard" loading={'eager'} name={'Postcard'} menu={false}
                                                     allowFullScreen
                                                    ></iframe>
                                                </div>
                                            }
                                            {!props.loadingPDF && showPDF &&

                                                <ul className="update__pdf__view__btb">
                                                    <li><a href="#" onClick={(e) => generatePDF(e)}>Update PDF</a></li>
                                                    <li><a href={props.pdfLink} target='Blank'>View in New Tab</a></li>
                                                </ul>
                                            }
                                            {props.loadingPDF && showPDF &&

                                                <Loading />
                                                }
                                        </div>
                                        </div>
                                       </div>
                                        {/* <!-- html editor --> */}
                                       
                                        <span>Please scroll down and update preview to ensure text will display properly!</span>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!--=== message part 01 row ===--> */}
                </div>
                {/* <!-- common button multi --> */}
                {/* <button className="custom-next next action-button accent--bg--color" onClick={(e) => props.moveNextStep(e, NEXTSTEPNO)}>
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M26 13C26 5.82063 20.1794 0 13 0C5.82063 0 0 5.82063 0 13C0 20.1794 5.82063 26 13 26C20.1794 26 26 20.1794 26 13ZM12.7094 18.71C12.6161 18.6175 12.542 18.5076 12.4913 18.3864C12.4405 18.2653 12.4141 18.1353 12.4136 18.004C12.4131 17.8726 12.4384 17.7425 12.4882 17.6209C12.538 17.4994 12.6112 17.3889 12.7038 17.2956L15.9738 14H7.625C7.35978 14 7.10543 13.8946 6.91789 13.7071C6.73036 13.5196 6.625 13.2652 6.625 13C6.625 12.7348 6.73036 12.4804 6.91789 12.2929C7.10543 12.1054 7.35978 12 7.625 12H15.9738L12.7038 8.70437C12.6113 8.61105 12.538 8.50043 12.4883 8.37881C12.4385 8.2572 12.4132 8.12698 12.4138 7.99558C12.4144 7.86419 12.4409 7.73419 12.4917 7.61302C12.5425 7.49185 12.6167 7.38188 12.71 7.28938C12.8033 7.19687 12.9139 7.12366 13.0356 7.07392C13.1572 7.02417 13.2874 6.99886 13.4188 6.99944C13.5502 7.00002 13.6802 7.02648 13.8014 7.0773C13.9225 7.12812 14.0325 7.2023 14.125 7.29563L19.0869 12.2956C19.2727 12.4829 19.377 12.7361 19.377 13C19.377 13.2639 19.2727 13.5171 19.0869 13.7044L14.125 18.7044C14.0325 18.7978 13.9225 18.8721 13.8012 18.923C13.6799 18.9738 13.5498 19.0003 13.4184 19.0008C13.2869 19.0013 13.1566 18.9759 13.0349 18.926C12.9132 18.8761 12.8026 18.8027 12.7094 18.71Z" fill="white" />
                    </svg>
                    <span>
                        
                     {(props.from === undefined || props.from == null || props.from !== "addQuickTemplate") ? 'Save & Continue' : 'Save'}
                        
                    </span>
                </button>
                <button className="custom-next custom-close modal-close action-button" onClick={(e) => props.closeModal(e)}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.1016 4.9001C15.2016 1.0001 8.80156 1.0001 4.90156 4.9001C1.00156 8.8001 1.00156 15.2001 4.90156 19.1001C8.80156 23.0001 15.1016 23.0001 19.0016 19.1001C22.9016 15.2001 23.0016 8.8001 19.1016 4.9001ZM14.8016 16.2001L12.0016 13.4001L9.20156 16.2001L7.80156 14.8001L10.6016 12.0001L7.80156 9.2001L9.20156 7.8001L12.0016 10.6001L14.8016 7.8001L16.2016 9.2001L13.4016 12.0001L16.2016 14.8001L14.8016 16.2001Z" fill="white" />
                    </svg>
                    <span>Close</span>
                </button> */}
                {/* <!-- common button multi --> */}
            </fieldset>
        </React.Fragment>

    )


}
const mapStateToProps = state => {
    return {
       postcardCategoryList: state.cardBroadcastReducer.postcardCategoryList,
       greetingCardCategoryList: state.cardBroadcastReducer.greetingCardCategoryList,
       letterCategoryList: state.cardBroadcastReducer.letterCategoryList,
    };
 };

export default connect(mapStateToProps)(ThirdStep);
