import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import AllContacts from '../components/marketing/EachCampaignActions/AllContacts';
import Campaign from "../components/marketing/CampaignList";
import CampaignDetailsPage from "../components/marketing/CampaignDetails";
import AddQuickTemplate from '../components/marketing/quickTemplates/AddTemplates';
import QuickReplies from '../components/marketing/QuickRepliesList/QuickReplies';
import EditTemplate from '../components/marketing/quickTemplates/EditTemplate';
import marketingTemplate from '../components/marketingTemplate';
import CampaignTemplatePreview from '../components/marketingTemplate/preview';
import HealthCheck from '../components/HealthCheck';
import CampaignStats from '../components/marketing/stats';
import CampaignRegistration from '../components/campaignRegistration/Index';
import { MessagingService } from '../components/messagingService/MessagingService';
import MarketingListV2 from '../components/marketing-list-v2';
import MarketingListNew from '../components/marketing-list-v2/MarketingListNew';
import Utils from '../helpers/Utils';
import { AGENCY_BUSINESS_TYPE } from '../constants/CoreConstants';
import QuickRepliesGlobal from '../components/marketing/QuickRepliesGlobal/Index';
import AddQuickTemplateGlobal from '../components/marketing/QuickRepliesGlobal/QuickRepliesContent/QuickReplyTemplate/AddTemplates';
import EditTemplateGlobal from '../components/marketing/QuickRepliesGlobal/QuickRepliesContent/QuickReplyTemplate/EditTemplateGlobal';

const Routes = () => {
    const isAgencyTypeInsurance = Utils.getAccountData("agencyBusinessType") === AGENCY_BUSINESS_TYPE.INSURANCE;
    return (
        <Router>
            <div>
                <Switch>
                    <Route path="/campaign/allContacts/:campaign_id" component={AllContacts}/>
                    <Route exact path="/campaign" component={isAgencyTypeInsurance ? MarketingListNew : MarketingListV2}/>
                    <Route exact path="/campaign-v2" component={Campaign}/>
                    <Route exact path="/campaign/details/:campaignId" component={CampaignDetailsPage}/>
                    <Route exact path="/campaign/state/:campaignId/:tagId" component={CampaignStats}/>
                    <Route exact path="/quick-replies" component={QuickReplies}/>
                    <Route exact path="/quick-reply-add-template" component={AddQuickTemplate}/>
                    <Route exact path="/quick-reply/:templateId" component={EditTemplate}/>
                    <Route exact path="/quick-replies-global" component={QuickRepliesGlobal}/>
                    <Route exact path="/quick-reply-add-template-global" component={AddQuickTemplateGlobal}/>
                    <Route exact path="/quick-replies-global/:templateId" component={EditTemplateGlobal}/>

                    {/* campaign templates */}
                    <Route exact path="/campaign/template" component={marketingTemplate}/>
                    <Route exact path="/campaign/view/:id?" component={CampaignTemplatePreview}/>

                    {/* campaign registration */}
                    <Route exact path="/campaign/registration" component={CampaignRegistration}/>
                    {/* Messaging Services */}
                    <Route exact path="/messaging/services" component={MessagingService}/>

                    <Route path="/campaign/health-check" component={HealthCheck}/>
                </Switch>
            </div>
        </Router>
    );
}
export default Routes;