import { makeStyles } from "@material-ui/core";
import React from "react";
import Header from "./Header";
import QuickRepliesContent from "./QuickRepliesContent/QuickRepliesContent";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 120px)",
    borderRadius: "10px",
  },
}));

const QuickRepliesGlobal = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Header />
      <QuickRepliesContent />
    </div>
  );
};

export default QuickRepliesGlobal;
