export const ADD_QUICK_REPLY_FOLDER = 'ADD_QUICK_REPLY_FOLDER';
export const ADD_QUICK_REPLY_FOLDER_SUCCESS = 'ADD_QUICK_REPLY_FOLDER_SUCCESS';
export const ADD_QUICK_REPLY_FOLDER_FAILURE = 'ADD_QUICK_REPLY_FOLDER_FAILURE';

export const GET_QUICK_REPLY_FOLDER = 'GET_QUICK_REPLY_FOLDER';
export const GET_QUICK_REPLY_FOLDER_SUCCESS = 'GET_QUICK_REPLY_FOLDER_SUCCESS';
export const GET_QUICK_REPLY_FOLDER_FAILURE = 'GET_QUICK_REPLY_FOLDER_FAILURE';

export const UPDATE_QUICK_REPLY_FOLDER = 'UPDATE_QUICK_REPLY_FOLDER';
export const UPDATE_QUICK_REPLY_FOLDER_SUCCESS = 'UPDATE_QUICK_REPLY_FOLDER_SUCCESS';
export const UPDATE_QUICK_REPLY_FOLDER_FAILURE = 'UPDATE_QUICK_REPLY_FOLDER_FAILURE';

export const DELETE_QUICK_REPLY_FOLDER = 'DELETE_QUICK_REPLY_FOLDER';
export const DELETE_QUICK_REPLY_FOLDER_SUCCESS = 'DELETE_QUICK_REPLY_FOLDER_SUCCESS';
export const DELETE_QUICK_REPLY_FOLDER_FAILURE = 'DELETE_QUICK_REPLY_FOLDER_FAILURE';

export const HANDLE_QUICK_REPLIES_TAB = "HANDLE_QUICK_REPLIES_TAB";
export const HANDLE_QUICK_REPLIES_SELECTED_FOLDER = "HANDLE_QUICK_REPLIES_SELECTED_FOLDER";
export const HANDLE_QUICK_REPLIES_USER_ID = "HANDLE_QUICK_REPLIES_USER_ID";
export const HANDLE_QUICK_REPLIES_TEMPLATE_TYPES = "HANDLE_QUICK_REPLIES_TYPES";
export const HANDLE_QUICK_REPLIES_SEARCH = "HANDLE_QUICK_REPLIES_SEARCH";

export const ADD_QUICK_REPLY_SUCCESS = 'ADD_QUICK_REPLY_SUCCESS';

export const GET_QUICK_REPLY = 'GET_QUICK_REPLY';
export const GET_QUICK_REPLY_SUCCESS = 'GET_QUICK_REPLY_SUCCESS';
export const GET_QUICK_REPLY_FAILURE = 'GET_QUICK_REPLY_FAILURE';
export const GET_QUICK_REPLY_MORE_DATA = "GET_QUICK_REPLY_MORE_DATA";

export const DELETE_QUICK_REPLY = 'DELETE_QUICK_REPLY';
export const DELETE_QUICK_REPLY_SUCCESS = 'DELETE_QUICK_REPLY_SUCCESS';
export const DELETE_QUICK_REPLY_FAILURE = 'DELETE_QUICK_REPLY_FAILURE';

export const CLONE_QUICK_REPLY = 'CLONE_QUICK_REPLY';
export const CLONE_QUICK_REPLY_SUCCESS = 'CLONE_QUICK_REPLY_SUCCESS';
export const CLONE_QUICK_REPLY_FAILURE = 'CLONE_QUICK_REPLY_FAILURE';

export const RE_CALL_QUICK_REPLIES_AFTER_ADD_OR_EDIT = "RE_CALL_QUICK_REPLIES_AFTER_ADD_OR_EDIT";
export const HANDLE_PERMISSION_DENIED_MODAL = "HANDLE_PERMISSION_DENIED_MODAL";