import { useContext, useEffect, useState } from "react";
import { HistoryProvider } from "../../../../App";
import Helper from "../../../../helpers/Helper";
import CampaignListSwitch from "../../components/CampaignListSwitch/CampaignListSwitch";
import CustomConfirmAlert from "../../components/custom-alert/CustomConfirmAlert";
import CustomMenuPype from "../../components/custom-menu/CustomMenuPype";
import { IconHandler } from "../../hanlders/IconHandler";
import { Icons } from "../../helpers/Icons";
import Link from "../../Link";
import { handleStates } from "../../reducer/Actions";
import { useMLv2Context } from "../../reducer/MLV2Provider";
import { Link as LinkDom } from "react-router-dom";
import HyperTagShow from "../../components/HyperTagShow";
import CustomLoader from "../../components/CustomLoader";
import { confirmAlert } from 'react-confirm-alert';
import { useIsMounted } from "../../../../hooks/IsMounted";
import Utils from "../../../../helpers/Utils";
import ActionControl from "../../../Common/ActionControl/ActionControl";
import CustomConfirmModal from "../../../Common/CustomConfirmModal";

const each_campaign_list_stats_icon = [
    {index: 19, title: 'Contacts', link: '/contacts?campaignId='},
    {index: 20, title: 'Contacted', link: '/contacts?contacted="contacted"&campaignId='},
    {index: 21, title: 'Responded', link: '/contacts?responded="responded"&campaignId='},
    {index: 22, title: 'Response Rate', link: ''},
    {index: 23, title: 'Lead Flow', link: '/leadFlow?campaignid='}
];

const EachCampaignList = (props) => {
    const isTempUser = Utils.getAccountData('isTemplateUser');
    const isMount = useIsMounted()
    const history = useContext(HistoryProvider);
    const {state, dispatch} = useMLv2Context();
    const [active, setActive] = useState(false);
    const [status, setStatus] = useState(props.item.status === 3 ? true : false);
    const [block, setBlock] = useState(false); //state.isProcessingStatus
    const campaignDescriptionTitle =
      "Are you sure you want to pause this campaign?";
    const campaignPauseDescription =
      "To pause this campaign, click Pause below. If you changed your mind, click Cancel. FYI - it will take a few minutes for your campaign to pause.";
    const confirmModalImg = `${process.env.REACT_APP_CDN_LINK}assets/images/releases/marketing/1.0.0/stopwatch_icon.png`;

    useEffect(() => {
        /* we need this for socket implement */
        if(isMount){
            if(state.resetSwitch && state.selectedCampaignForActionIndex === props.index){
                dispatch(handleStates({
                    resetSwitch: false,
                    selectedCampaignForAction: {},
                    selectedCampaignForActionIndex: null
                }))
                setStatus(previous => !previous)
                setBlock(false)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.resetSwitch])
    useEffect(() => {
        /* we need this for socket implement */
        if(isMount){
            if(state.resetBlock && block){
                dispatch(handleStates({
                    resetBlock: false
                }))
                setBlock(false)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.resetBlock])
    useEffect(() => {
        /* we need this for socket implement */
        if(isMount){
            console.log("--3--")
            if(state.resetSwitchForSocket !== "" && state.resetSwitchForSocket === props.item.id){
                setStatus(true)
                updateListByIndex();
                let old_socket = [...state.campaignIdFromSocket]
                let index = old_socket.indexOf(state.resetSwitchForSocket);
                if(index !== -1){
                    old_socket.splice(index ,1)
                    dispatch(handleStates({
                        resetSwitchForSocket: '',
                        campaignIdFromSocket: old_socket
                    }));
                }else{
                    dispatch(handleStates({
                        resetSwitchForSocket: ''
                    }));
                }
            }
            else{
                let old_socket = [...state.campaignIdFromSocket]
                let index = old_socket.indexOf(props.item.id);
                if(index !== -1){
                    setStatus(true)
                    updateListByIndex();
                    old_socket.splice(index ,1)
                    dispatch(handleStates({
                        campaignIdFromSocket: old_socket
                    }));
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.campaignIdFromSocket, state.resetSwitchForSocket])
    const updateListByIndex = () => {
        let old_list = [...state.campaignList];
        let item = old_list[props.index];
        if(item !== undefined){
            item['status'] = 3;
            dispatch(handleStates({
                campaignList: old_list
            }))
        }
    }
    const handleSwitch = (value) => {
        setStatus(value);
    }
    const pausingCampaign = (value) => {
        setBlock(true)
        Link.API_LIST.campaignPauseApi({campaign_id : props.item.id})
        .then(res => {
            let response = res.data;
            if(response.status === "error"){
                if(response.html === "Drip Campaign Status Is Not Running"){
                    handleSwitch(!value)
                }
                CustomConfirmAlert({
                    handleConfirm : () => {},
                    handleOnClose: () => {},
                    title: 'Warning !',
                    description: response.html.replace(/[0-9]/g,''),
                    icon: 'warning',
                    confirmButtonText: "Close the window",
                    minHeight: '280px',
                    showCloseButton: false
                })
            }
            else if(response.status === "success") {
                handleSwitch(value)
                confirmAlert({
                    childrenElement: ()=>{ return <Link.CampaignActionNoticeBoard 
                        title={"Your campaign will be paused shortly."}
                        message = {"It could take up to 5 minutes."}/>},
                    buttons: [
                        {
                            label: 'Close',
                            onClick: () => {
                                return;
                            },
                            className: "button-modify",
                        }
                    ],
                    overlayClassName: "alert-button-modify",
                })
            }
            setBlock(false)
        })
        .catch(error => {
            setBlock(false)
            handleSwitch(!value)
        })
    }
    const goToLink = (link) => {
        if(link === ''){
            return;
        }
        history.push((link+props.item.id));
    }
    const handleStatusChange = (value) => {
        if(!value){
            /* get confirmation for pausing campaign */
            confirmAlert({
                customUI: ({ onClose }) => (
                  <CustomConfirmModal
                    title={campaignDescriptionTitle}
                    onClose={onClose}
                    handleConfirm = {() => {
                        pausingCampaign(value);                                     
                    }}
                    closeButtonText="Cancel"
                    confirmButtonText="Pause"
                    showConfirmButton={true}
                    showCloseButton={true}
                    description={campaignPauseDescription}
                    imgSrc={confirmModalImg} 
                  />
                ),
                closeOnClickOutside: true,
              });
        }

        else{
            //https://app.pypepro.com/api/v1/marketing/enable-to-start
            // Utils.getAccountData('standardPack')
            //checkIsValidToStartCampaignApi

            handleSwitch(value)
            setBlock(true)
            dispatch(handleStates({isShowActionList: true, selectedCampaignForAction: props.item, selectedCampaignForActionIndex: props.index, gettingActionList: true}))
        }
        /* for runnig campaign : */
        /* check all required setting without filled up : virtual number, email, timezone, */
        /* go to campaign details for filled up settings */
        /* Campaign Start Instruction */
        /* change status */
    }
    const getStatsValue = (type, item) => {
        let value = 0;
        if(type === 'Contacts'){
            if(item.stats !== undefined && item.stats != null){
                if(!Helper.isEmpty(item.stats.total_contact)){
                    value = item.stats.total_contact;
                }
            }
        }
        else if(type === 'Contacted'){
            if(item.stats !== undefined && item.stats != null){
                if(!Helper.isEmpty(item.stats.total_contacted)){
                    value = item.stats.total_contacted;
                }
            }
        }
        else if(type === 'Responded'){
            if(item.stats !== undefined && item.stats != null){
                if(!Helper.isEmpty(item.stats.total_responded)){
                    value = item.stats.total_responded;
                }
            }
        }
        else if(type === 'Response Rate'){
            if(item.stats !== undefined && item.stats != null){
                if(!Helper.isEmpty(item.stats.total_contacted)){
                    if(item.stats.total_contacted > 0){
                        value = `${Number(item.stats.total_responded/item.stats.total_contacted * 100).toFixed(2)}%`
                    }
                    else{
                        value = '0%';
                    }
                }
                else{
                    value = '0%';
                }
            }
            else{
                value = '0%';
            }
        }
        else if(type === 'Lead Flow'){
            if(item.lead_flow_connected_count !== undefined && item.lead_flow_connected_count != null){
                value = item.lead_flow_connected_count;
            }
        }
        return value;
    }
    const handleMenuActionClick = (item) => {
        if(item.value === 'add'){

            if (isTempUser == 1 && window.showNotification){
                window.showNotification('Error', 'Campaign Contact is not available for template user.');
                return;
            }

            dispatch(handleStates({isShowAddContact: true, selectedCampaignForAction: props.item, selectedCampaignForActionIndex: props.index}))
        }
        else if(item.value === 'edit'){
            history.push(('/campaign/details/'+props.item.id));
        }
        else if(item.value === 'add_to_folder'){
            dispatch(handleStates({isShowAddToFolder: true, selectedCampaignForAction: props.item, selectedCampaignForActionIndex: props.index}))
        }
        else if(item.value === 'copy'){
            /* show alert message */
            CustomConfirmAlert({
                handleConfirm : () => {

                    try{
                        let current_url = window.location.href
                        let domain = (new URL(current_url));
                        let root_url = domain.protocol+"//"+domain.hostname
                        if(domain.port !== ''){
                            root_url = root_url + ":" +domain.port
                        }
                        props.copyCampaign({'campaign_id' : props.item.id}, (response) => {
                            if(response.status === 'success'){
                                window.location.href = root_url +'/campaign/details/'+response.new_campaign_id
                            }
                        })
                    }
                    catch(e){
                        console.log(e);
                    }
                    /* change state: processCopyCampaign */
                    /* api call to copy campaign */
                    /* redirect to copied campaign details page */
                },
                handleOnClose: () => {

                },
                title: 'Are you sure?',
                description: 'Do you want to copy this campaign? You can delete copied campaign later. You need to update some setting later.',
                icon: 'question',
                closeButtonText: 'No',
                confirmButtonText: "Yes, Copy this",
                callConfirmLast: false
            })
        }
        else if(item.value === 'delete'){
            /* show alert message */
            CustomConfirmAlert({
                handleConfirm : () => {
                    setBlock(true)
                    Link.API_LIST.deleteCampaignItem({campaign_id: props.item.id, apiVersion: 2})
                    .then(res => {
                        let response = res.data;
                        if(response.status === 'success'){
                            let old_list = [...state.campaignList]
                            old_list.splice(parseInt(props.index), 1)
                            if(old_list.length === 0){
                                dispatch(handleStates({
                                    refreshList: true,
                                    gettingList: true
                                }))
                            }
                            else{
                                dispatch(handleStates({
                                    campaignList: old_list
                                }))
                            }
                        }
                        setBlock(false)
                    })
                    .catch(error => {
                        setBlock(false)
                        console.log(error)
                    })
                },
                handleOnClose: () => {
                },
                title: 'Are you sure?',
                description: 'Do you want to delete this campaign? You can not restore this campaign and it\'s process later.',
                icon: 'question',
                closeButtonText: 'No, Keep it.',
                confirmButtonText: "Yes, Delete it.",
                callConfirmLast: true,
                type_word: 'DELETE CAMPAIGN',
            })
        }
        else if(item.value === 'share'){
            dispatch(handleStates({isShowShare: true, selectedCampaignForAction: props.item, selectedCampaignForActionIndex: props.index}))
        }
        else if(item.value === 'stats'){
            history.push(('/campaign/state/'+props.item.id+"/"+props.item.tag_id));
        }
    }
    const renderStatsIcons = () => {
        let bgColor = '#5463763b', textColor = '#546376'
        if(state.selectedFolder != null){
            bgColor = state.selectedFolder?.color
            textColor = state.selectedFolder?.text_color
        }
        const view = [];
        each_campaign_list_stats_icon.forEach((item, index) => {
            const icons = new IconHandler(textColor, parseInt(item.index), bgColor); 
            let stats_value = getStatsValue(item.title, props.item); //will get this data from props
            view.push(
                <HyperTagShow link={(item.link+props.item.id)} show={item.link === "" ? false : true} >
                    <div className={`mlvc_ecb_sc_l_each_stats ${item.link !== "" ? "mlvc_ecb_sc_l_each_stats_cusor_pointer" : ''}`} key={index} onClick={() => goToLink(item.link)}>
                        <div className="mlvc_ecb_sc_l_each_stats_icon">{icons.getIcon}</div>
                        <div className="mlvc_ecb_sc_l_each_stats_content">
                            <span className="mlvc_ecb_sc_l_each_stats_content_value">{stats_value}</span>
                            <span className="mlvc_ecb_sc_l_each_stats_content_tite">{item.title}</span>
                        </div>
                    </div>
                </HyperTagShow>
            )
        })
        return view;
    }
    const renderDate = () => {
        if(window.globalTimezoneConversionToDifferentTimezone !== undefined){
            // let userTimelineRaw = Link.GetAccountData('userTimezoneRaw');
            return window.globalTimezoneConversionToDifferentTimezone(
                props.item.created_at,
                'UTC',
                props.item.timezone,
                'MM-DD-YYYY hh:mm:ss A'
            )
        }
        return props.item.created_at;
    }
    const renderFolderV2 = () => {
        const view = [];
        if(props.item.folders !== undefined && props.item.folders != null && Array.isArray(props.item.folders)){
            let folderLength = state.folders.length;
            props.item.folders.forEach((item, index) => {
                for(let eachFolderIndex = 0; eachFolderIndex < folderLength; eachFolderIndex++){
                    if(item.folder_id == state.folders[eachFolderIndex].id){
                        view.push(
                            <div className="mlvc_ecb__tb_comon mlvc_ecb__tb_left_folder_title" style={{backgroundColor: state.folders[eachFolderIndex].color, borderRadius: '14px', padding: '1px 7px'}}>
                                <span>{state.folders[eachFolderIndex].title}</span>
                            </div>
                        );
                        break;
                    }
                }
            })
            // const selected = state.folders.filter(item => item.id === props.item.campaign_folder_id)
            // if(selected !== undefined && selected.length > 0){
            //     return (
            //         <div className="mlvc_ecb__tb_comon mlvc_ecb__tb_left_folder_title" style={{backgroundColor: selected[0].color, borderRadius: '14px', padding: '1px 7px'}}>
            //             <span>{selected[0].title}</span>
            //         </div>
            //     );
            // }
        }
        return view;
    }
    return (
        <div className={`mlvc_each_campaign_body_wrapper ${block ? 'mlvc_ecbw_block-old':''}`}>
            <div className={`mlvc_each_campaign_body ${active ? 'mlvc_ecb_focus' : ''}`}>
                {/* top bar */}
                <div className="mlvc_ecb_top_bar">
                    {/* left side */}
                    <div className="mlvc_ecb__tb_left">
                        {/* title with tooltip */}
                        <div className="mlvc_ecb__tb_left_title_wrp">
                            <Link.TOOL_TIP title={props.item.title} arrow placement="top">
                                <LinkDom to={`/campaign/details/${props.item.id}`} className="mlvc_ecb__tb_left_title" data-target="marketing-slide-out">
                                    {/* <span className="mlvc_ecb__tb_left_title">{props.item.title}</span> */}
                                    {props.item.title}
                                </LinkDom>
                            </Link.TOOL_TIP>
                        </div>
                        
                        {/* description */}
                        <div className="mlvc_ecb__tb_left_row">
                            <div className="mlvc_ecb__tb_comon mlvc_ecb__tb_left_feature">{props.item.type === 1 ? "Full Featured" : "Email"}</div>
                            <div className="mlvc_ecb__tb_comon mlvc_ecb__tb_left_date">
                                <span>{Icons.calendar}</span>
                                <span>{renderDate()}</span>
                            </div>
                            {/* {renderFolder()} */}
                            <div className="mlvc_ecb__tb_lw_folders_wrapper">{renderFolderV2()}</div>
                        </div>
                    </div>

                    {/* right side */}
                    <div className="mlvc_ecb__tb_right">
                        {/* some action will be added here */}
                        <div className={`mlvc_ecb_sc_right ${state.showMinimizeView ? '' : 'd-none'}`}>
                            {/* some action will be render here */}
                            <div className="mlvc_ecb_sc_right_switch">
                                {/* TODO: need to change all css class for the switch component */}
                                <ActionControl alertMessage="Campaign feature is not available for template user." status="error"  isDisabled={isTempUser == 1}>
                                    <CampaignListSwitch
                                        onChange={(value) => {
                                            handleStatusChange(value)
                                        }}
                                        value={status}
                                        off={{
                                            text: '',
                                            // color: '#F2994A'
                                        }}
                                        on={{
                                            text: ''
                                        }}
                                        width= '60px'
                                        key={`${props.index}-${props.item.status}`}
                                    />
                                </ActionControl>
                            </div>

                            <div className="mlvc_ecb_sc_right_drom_menu">
                                {/* menu option */}
                                <CustomMenuPype
                                    popup = {{
                                        height:"auto",
                                        list: [
                                            {label: 'Add Contact', icon: Icons.c_add, value: 'add'},
                                            {label: 'Edit Campaign', icon: Icons.c_edit, value: 'edit'},
                                            {label: 'Add to Folder', icon: Icons.c_folder, value: 'add_to_folder'},
                                            {label: 'Copy Campaign', icon: Icons.c_copy, value: 'copy'},
                                            {label: 'Delete Campaign', icon: Icons.c_delete, value: 'delete'},
                                            {label: 'Share Campaign', icon: Icons.c_share, value: 'share'},
                                            {label: 'Campaign Stats', icon: Icons.c_stats, value: 'stats'},
                                        ],
                                        onClick: handleMenuActionClick
                                    }}
                                    onClose={() => setActive(false)}
                                    onClickCallback={() => setActive(true)}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* stats */}
                <div className="mlvc_ecb_stats_container">
                    <div className={`mlvc_ecb_sc_left ${state.showMinimizeView ? 'd-none' : ''}`}>
                        {renderStatsIcons()}
                    </div>

                    <div className={`mlvc_ecb_sc_right ${state.showMinimizeView ? 'd-none' : ''}`}>
                        {/* some action will be render here */}
                        <div className="mlvc_ecb_sc_right_switch">
                            {/* TODO: need to change all css class for the switch component */}
                            <ActionControl alertMessage="Campaign feature is not available for template user." status="error"  isDisabled={isTempUser == 1}>
                                <CampaignListSwitch
                                    onChange={(value) => {
                                        handleStatusChange(value)
                                    }}
                                    value={status}
                                    off={{
                                        text: 'PAUSED'
                                    }}
                                    on={{
                                        text: 'RUNNING'
                                    }}
                                    key={`${props.index}-${props.item.status}`}
                                />
                            </ActionControl>
                        </div>

                        <div className="mlvc_ecb_sc_right_drom_menu">
                            {/* menu option */}
                            <CustomMenuPype
                                popup = {{
                                    height:"auto",
                                    list: [
                                        {label: 'Add Contact', icon: Icons.c_add, value: 'add'},
                                        {label: 'Edit Campaign', icon: Icons.c_edit, value: 'edit'},
                                        {label: 'Add to Folder', icon: Icons.c_folder, value: 'add_to_folder'},
                                        {label: 'Copy Campaign', icon: Icons.c_copy, value: 'copy'},
                                        {label: 'Delete Campaign', icon: Icons.c_delete, value: 'delete'},
                                        {label: 'Share Campaign', icon: Icons.c_share, value: 'share'},
                                        {label: 'Campaign Stats', icon: Icons.c_stats, value: 'stats'},
                                    ],
                                    onClick: handleMenuActionClick
                                }}
                                onClose={() => setActive(false)}
                                onClickCallback={() => setActive(true)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* {block &&
            <CustomLoader className={'mlvc_ecbw_block_loader'} />
            } */}
        </div>
    );
}
export default EachCampaignList;