/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useEffect, useRef, useState} from 'react';
import { Skeleton } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { connect } from 'react-redux'
import { Add } from '@material-ui/icons';
import { useDispatch } from "react-redux";
import Utils from '../../../../../helpers/Utils';
import { TemplateActions } from '../EachTemplate';
import QucikReplyCard from '../QuickReplyCard';
import { showGlobalNotification } from "../../../../../actions/globalAction";
import showNotification from '../../../../globals/ShowNotification';
import { getQuickReplies,  reCallQuickRepliesAfterAddOrEdit} from '../../../../../actions/quickRepliesAction';
import { PRIVACY_LEVEL, QUICK_REPLIES_OPTION_TYPE, TEMPLATE_ACTION } from '../../../../../constants/CoreConstants';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { ContentStyles } from '../QuickRepliesStyle';



const mapStateToProps = state => ({
    globalReducer: state.globalReducer
})

const mapDispatchToProps = dispatch => ({
    showGlobalNotification: (params) => dispatch(showGlobalNotification(params)),
})


const QuickRepliesApp = (props) => {
    const dispatch = useDispatch();
    const {showNotification, message, type} = props.globalReducer
    const { quickRepliesParams, gettingQuickReplies, quickRepliesList, totalQuickReplies  } = useSelector(
        (state) => state.quickRepliesReducer
      );
      const location = useLocation();
      const history = useHistory();
      const classes = ContentStyles();


useEffect(()=>{
    if (location?.state?.from){
        const { from, template_folder_id, privacy_level } = location?.state || {};
        if (from === TEMPLATE_ACTION.EDIT || from === TEMPLATE_ACTION.CREATE) {
            dispatch(reCallQuickRepliesAfterAddOrEdit({
                privacy_level: privacy_level  ?? quickRepliesParams.privacy_level,
                template_folder_id,
            }));
            history.replace(location.pathname, { state: {} });
        }
    }
    else{
        dispatch(getQuickReplies({
            ...quickRepliesParams,
            privacy_level: quickRepliesParams.privacy_level === QUICK_REPLIES_OPTION_TYPE.PRIVATE ? PRIVACY_LEVEL.PRIVACY_LEVEL_PRIVATE : PRIVACY_LEVEL.PRIVACY_LEVEL_GLOBAL
        }));
    }
   
},[quickRepliesParams]);

    useEffect(() => {
        document.title = `Quick Replies | ${Utils.getAccountData('AuthUserAgencyName')}`;
        if (window.setActiveSidebar) {
            window.setActiveSidebar('marketing');
        }

        if(showNotification){
            props.showNotification(message, type)
            props.showGlobalNotification({
                showNotification: false,
                message: '',
                type: 'success'
            })
        }
    }, [showNotification])

    const loader = () => {
        let loader = []
        for (let i = 0; i < 9; i++) {
            loader.push(
                <div className={"template "}>
                    <Skeleton  animation="wave" style={{height: '100%'}} />
                </div>
            )
        }
        return loader
    }

    const templateFooter = (item, index) => {
        return (
            <TemplateActions template={item} index={index} deleteFromList={deleteFromList} />
        )
    }

    const deleteFromList = (index) => {
        let fullData= [...quickRepliesList]
        fullData.splice(index, 1);
        // dispatch(updateMessageTemplateData(fullData))
    }

    const renderTemplates = () => {
        if (!Array.isArray(quickRepliesList)) return null;
        let showTemplates = []
        quickRepliesList.map((item, index) => {
            showTemplates.push(
                <QucikReplyCard
                    data={item} 
                    key={index}
                    onSelect={() => {}}
                    className={'main-content-from-quick-reply'}
                    footer={() => templateFooter(item, index)}
                />
            )
        })
        return showTemplates;
    };



    return (
        <>
       {( !gettingQuickReplies && !quickRepliesList[0]) ?(
        <div className={classes.emptyPageWrapper}>
           <div className={classes.emptyImgWrapper}>
           <img
              src={`${process.env.REACT_APP_CDN_LINK}assets/bulk-emails/images/releases/1.0.0/Empty%20Campaign.png`}
              alt="empty"
              className={classes.emptyImg}
            />
           </div>
            <h4 className='mb-5'>No template created yet!</h4>
            <Link className={classes.emptyPageButton} to={'/quick-reply-add-template-global'}>
                <Add style={{color:'#fff', marginRight:"10px"}} />
                Create new template
            </Link>
         </div>
        ) : ( 
         <div className='main_content_container'>
            <div className='main_content_description'>
                <div className='templates_container'>
                        {
                        gettingQuickReplies ? 
                        loader() : renderTemplates()
                        }
                    
                </div>
            </div>
        </div>
        )}
        </>
    )
}
const QuickReplies = connect(mapStateToProps, mapDispatchToProps)(showNotification(QuickRepliesApp))
export default QuickReplies