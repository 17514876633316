/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import { fetchContactForDataTable } from "../../../actions/marketingAction";
import { IconList } from "../../../constants/IconList";
import Utils from "../../../helpers/Utils";
import { GlobalTable } from "../../globals/GlobalTable";
//moved to CDN
// import '../marketing.css';
import Helper from "../../../helpers/Helper";
import useDelayCallback from "../../../hooks/useDelayCallback";
import DynamicSkeleton from "../../Common/DynamicSkeleton";

const App = props => {

    const [contacts, setContacts] = useState([])
    const [totalContacts, setTotalContacts] = useState(0)
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(0)
    const [itemPerPage, setItemPerPage] = useState(10)
    const [operation, setOperation] = useState([])
    const [queryTerm, setQueryTerm] = useState([])
    const [firstLoad, setFirstLoad] = useState(true)
    const [isfetchingData, setIsfetchingData] = useState(true)

    const [noHeader, setNoHeader] = useState(true)
    const [selectedContactIds, setSelectedContactIds] = useState([])

    const [queryText, setQueryText] = useState('')

    useDelayCallback(() => {
        setIsfetchingData(true)
        /* check type */
        let type = 'text',
            text = queryText.trim()
        if(text !== ""){
            if(Utils.hasWhiteSpace(text)){
                type = 'name'
            }else if(Utils.checkOnlyNumber(text) && text.length > 5){
                type = 'number'
            }
            else if(Utils.validateEmail(text)){
                type = 'email'
            }else{
                type = 'all'
            }
        }
        let form_data = {
            from: 'campaign_to_add',
            fields : ['id', 'first_name', 'last_name', 'email', 'number'],
            searchText: text,
            searchTextType: type, //name, email, number, text
            currentPage: page > 0 ? (page - 1) : 0,
            perPage: 20
        }
        props.fetchContactForDataTable(form_data, fetchContactResponse)
    }, [firstLoad, page, itemPerPage, queryText])

    const fetchContactResponse = (response) => {
        if(response.status === "success"){
            if(page === 1){
                setTotalContacts(response.total);
                setTotalPage(Math.ceil(response.total / 20));
            }
            if(response.contacts !== undefined){
                setContacts(response.contacts)
            }
        }
        setIsfetchingData(false)
    }

    const onChangePage = (event, page) => {
        event.preventDefault()
        if(page != null){
            setPage(page)
        }
    }

    const handleAddExistingContact = () => {
        props.handleAddNewContact({contact_ids : selectedContactIds})
    }

    const renderPagination = () => {
    
        let previous_page = null, next_page = null; 
        if(page > 1){
            previous_page = page - 1
        }
    
        if(page != totalPage){
            next_page = page + 1
        }
    
        if(totalContacts == 0){
            return false;
        }
    
        return(
            <div className="pagination_bar">
                <ul>
                    <li>
                        <a 
                            href="#" onClick={(event) => onChangePage(event, previous_page)}
                            style={previous_page == null ? {cursor : 'not-allowed', backgroundColor: '#9daac1', borderColor: '#9daac1'} : {}}
                        >
                            {IconList.previous}
                        </a>
                    </li>
                    <li>
                        <a>
                            <span>{page}</span>
                        </a>
                    </li>
                    <li>
                        <a className="d-flex align-items-center"> 
                            <span>
                                {IconList.backwardSlash}
                            </span>	
                            {totalPage}
                        </a>
                    </li>
                    <li>
                        <a 
                            href="#" onClick={(event) => onChangePage(event, next_page)}
                            style={next_page == null ? {cursor : 'not-allowed', backgroundColor: '#9daac1', borderColor: '#9daac1'} : {}}
                        >
                            {IconList.next}	
                        </a>
                    </li>
                    <li style={{color: '#9daac1', marginLeft: 20}}>Total leads : {totalContacts}</li>
                </ul>
            </div>
        )
    }

    const columns = [
    {
        name: <span style={{fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px'}}>Full Name</span>,
        selector: row => row['first_name'],
        sortable: false,
        cell: row => {
            let name = ''
            if(Helper.isvalidText(row.first_name) && Helper.isvalidText(row.last_name)){
                name = row.first_name + ' ' + row.last_name
            }
            else if(Helper.isvalidText(row.first_name)){
                name = row.last_name
            }
            else if(Helper.isvalidText(row.last_name)){
                name = row.last_name
            }
            return name;
        },
        minWidth:'250px'
        /*
        please do not remove those code
        cell: row => <button row={row} onClick={(event) => {
            console.log(event)
        }} >hello</button>,
        allowOverflow: true,
        button: true,
        width: '56px',
        grow: 2, //do not know this props
        ignoreRowClick: true
        right: true,
        center: true
        */
    },
    {
        name: <span style={{fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px'}}>Email</span>,
        selector: row => row['email'],
        sortable: false,
        minWidth:'250px',
        // hide: 'lg',
        // omit: true
    },
    {
        name: <span style={{fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px'}}>Phone Number</span>,
        selector: row => Utils.formatPhoneNumber(row['number']),
        sortable: false,
        minWidth:'250px'

    },
    ];

    window.campaignAddExistingContact = () => {
        handleAddExistingContact()
    }

    return (
      <>
        {isfetchingData ? (
          <DynamicSkeleton />
        ) : (
          <div className="existing_contact" id="existing-contact">
            <div className="contact_table">
              <div
                className={"existing_contact custom_data_table_container"}
                style={{ padding: "0px 20px 20px 20px" }}
              >
                <GlobalTable
                  table_unique_id={"contact_search"}
                  columns={columns}
                  data={contacts}
                  keyField={"id"}
                  noDataComponent={
                    <span style={{ padding: 10, color: "red" }}>
                      No contact saved yet!
                    </span>
                  }
                  defaultSortField="title"
                  selectableRows={true}
                  selectableRowsData={{
                    selectableRowsNoSelectAll: isfetchingData
                      ? true
                      : contacts.length == 0
                      ? true
                      : false,
                    selectableRowsHighlight: false,
                    onSelectedRowsChange: (state) => {
                      if (state.selectedRows.length == []) {
                        setNoHeader(true);
                      } else {
                        setNoHeader(false);
                      }
                      let contactIds = [];
                      state.selectedRows.forEach((element) => {
                        contactIds.push(element.id);
                      });
                      setSelectedContactIds(contactIds);
                    },
                  }}
                  pagination={false}
                  // pagination={contacts.length == 0 ? false : true}
                  // paginationData ={{
                  //     paginationTotalRows: totalContacts,
                  //     paginationPerPage: itemPerPage,
                  //     onChangePage: onChangePage, //for pagination change
                  //     onChangeRowsPerPage: onChangeRowsPerPage, //for per page change
                  //     paginationComponentOptions: {
                  //         rowsPerPageText : 'Pages of '
                  //     }
                  // }}

                  subHeader={true}
                  subHeaderData={{
                    subHeaderComponent: (
                      <div
                        className="top_search_field d-flex align-items-center"
                        style={{ width: "100%" }}
                      >
                        <input
                          value={queryText}
                          onChange={(event) => {
                            setPage(1);
                            setQueryText(event.target.value);
                          }}
                          className=""
                          type="text"
                          placeholder="Search Existing Contacts Name"
                        />
                        <span>{IconList.search}</span>
                      </div>
                    ),
                  }}
                  noHeader={noHeader}
                  noHeaderData={{
                    noContextMenu: false,
                    contextActions: [],
                    actions: [],
                  }}
                />

                {renderPagination()}
              </div>
            </div>
          </div>
        )}
      </>
    );
}

const mapDispatchToProps = dispatch => ({
    fetchContactForDataTable: (params, callback) => dispatch(fetchContactForDataTable(params, callback))
})

const ExistingContactListToAddTab = connect(null, mapDispatchToProps)(App);
export default ExistingContactListToAddTab;