import { setCookieCampaign } from "../..";
import CustomMenuPype from "../../components/custom-menu/CustomMenuPype";
import { Icons } from "../../helpers/Icons";
import { handleStates, handleStoreSearchText } from "../../reducer/Actions";
import { useMLv2Context } from "../../reducer/MLV2Provider";

const fold = (<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="m8.9 20-1.4-1.4 4.5-4.5 4.5 4.5-1.4 1.4-3.1-3.1ZM12 9.9 7.5 5.4 8.9 4 12 7.1 15.1 4l1.4 1.4Z" fill="#546376" /></svg>)
const unfold = (<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="m12 21-4.5-4.5 1.45-1.45L12 18.1l3.05-3.05 1.45 1.45ZM8.95 9.05 7.5 7.6 12 3.1l4.5 4.5-1.45 1.45L12 6Z" fill="#546376" /></svg>)

const CampaignListTopBar = ({ className }) => {
    const { state, dispatch } = useMLv2Context();
    const handleMinimizeClick = () => {
        dispatch(handleStates({ showMinimizeView: !state.showMinimizeView }))
    }

    const handleStatusFilterChange = (item) => {

        if (item.value === state.selectedStatus.value) {
            return;
        }
        let selectedStatus = {
            "value": item.value,
            "label": item.label,
        };

        dispatch(handleStates({
            selectedStatus: { ...selectedStatus },
            gettingList: true,
            currentPage: 1
        }));

        setCookieCampaign(
            'campaign_filter',
            JSON.stringify({
                search: state.searchText,
                perPage: state.perPage,
                selectedStatus: selectedStatus,
            })
        );
    };

    return (
        <div className={`mlvc_body_container_top_serch ${className}`}>

            <div className="mlvc_ecb_sc_pagination_left">
                {window.screen.width > 1240 &&
                    <span className={`mlvc_top_folder_collapese_icon ${state.collapseFolderMenu ? 'mlvc_tfci_rotate' : ''}`} onClick={() => { dispatch(handleStates({ collapseFolderMenu: !state.collapseFolderMenu })) }}>{state.collapseFolderMenu ? Icons.collapseLarge : Icons.collapseLarge}</span>
                }
                <div className="mlvc_ecb_sc_top_expand_btn" onClick={handleMinimizeClick}>{state.showMinimizeView ? fold : unfold}</div>
                <div>
                    <CustomMenuPype
                        wrapperClass="mlvc_row_per_page_dropdown"
                        popup={{
                            placement: 'center',
                            list: [
                                { label: '10', value: 10 },
                                { label: '20', value: 20 },
                                { label: '30', value: 30 },
                                { label: '50', value: 50 },
                                { label: '100', value: 100 },
                            ],
                            onClick: (item) => {
                                if (item.value === state.perPage) {
                                    return;
                                }
                                dispatch(handleStates({ perPage: item.value, gettingList: true, currentPage: 1 }))
                                setCookieCampaign(
                                    'campaign_filter',
                                    JSON.stringify({
                                        search: state.searchText,
                                        perPage: item.value
                                    })
                                )
                            },
                            height: 'auto',
                            width: '80px',
                            listStyle: {
                                justifyContent: 'center'
                            },
                           
                        }}
                        label={{
                            renderCustom: (
                                <div className="campaign_list_perpage_body">
                                    <span className="clpb_label">{state.perPage}</span>
                                    <span className="clpb_icon">{Icons.arrow_down}</span>
                                </div>
                            )
                        }}
                    />
                </div>
            </div>

            <div className="mlvc_body_container_top_serch_left">
                <div>
                    <CustomMenuPype
                        wrapperClass="mlvc_row_per_page_dropdown"
                        popup={{
                            placement: 'left',
                            list: [
                                { value: "ALL", label: 'All',},
                                { label: 'Running', value: 'RUNNING' },
                                { label: 'Paused', value: 'PAUSED' }
                            ],
                            onClick: (item) => {
                                handleStatusFilterChange(item);
                            },
                            height: 'auto',
                            width: "268px",
                            listStyle: {
                                justifyContent: 'flex-start',
                                padding:"5px"
                            },
                            optionClass:'campaign_list_dropdown'
                        }}
                        label={{
                            renderCustom: (
                                <div className="campaign_status_filter_new">
                                    <span className="clpb_label">{state.selectedStatus.label}</span>
                                    <span className="clpb_icon">{Icons.arrow_down}</span>
                                </div>
                            ),
                        }}
                    />
                </div>

                <div className="mlvc_body_container_top_serch_left_input_wr"> <input value={state.searchText} className="input_focus_box_border_rem mlvc_body_container_tsl_input" placeholder="Search" onChange={(e) => dispatch(handleStoreSearchText(e.target.value))} />
                    <span className="mlvc_body_container_tsl_icon">{Icons.search_icon}</span>
                </div>
                {/* <div className="mlvc_body_container_top_ref_resh_btn">Refresh</div> */}
            </div>
            {/* <div className="mlvc_body_container_top_serch_right">
                <div className="mlvc_body_container_tsr_left">
                    <span>{Icons.date_icon}</span>
                    <span>All time</span>
                </div>
                <div className="mlvc_body_container_tsr_right">
                    <span>{Icons.date_icon}</span>
                    <span>All time</span>
                </div>
            </div> */}
        </div>
    );
}
export default CampaignListTopBar;

