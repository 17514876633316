import React, { useEffect, useState } from "react";
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import FolderIcon from "@material-ui/icons/Folder";
import { Skeleton } from "@material-ui/lab";

import EachFolderItem from "./EachFolderItem";
import { useDispatch, useSelector } from "react-redux";
import { SidebarLeftIcon, SidebarRightIcon } from "../../../../helpers/Icons";
import AddOrEditquickRepliesFolder from "./AddOrEditQuickRepliesFolder";
import SidebarWrapper from "./QuickRepliesStyle";
import { getQuickRepliesFolder, handlePermissionDeniedModal, handleSelectedQuickRepliesFolder } from "../../../../actions/quickRepliesAction";
import { PERMISSION_LEVELS, PERMISSION_MODULES } from "../../../../constants/CoreConstants";

const SideBar = () => {
  const { quickRepliesFolderList, gettingQuickRepliesFolder, quickRepliesParams } = useSelector(
    (state) => state.quickRepliesReducer
  );
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [openNewFolderCreateModal, setOpenNewFolderCreateModal] =
    useState(false);
  const dispatch = useDispatch();
  const canUserManageGlobalItem = window.hasPermission(
    PERMISSION_MODULES.MANAGE_GLOBAL_ITEMS,PERMISSION_LEVELS.WRITE);

  const handleCollapsed = () => {
    setIsSidebarCollapsed((prevState) => !prevState);
  };

  useEffect(() => {
    dispatch(getQuickRepliesFolder());
  }, []);

  const handleSelectAll = () => {
    dispatch(handleSelectedQuickRepliesFolder(null));
  };

  const handleCreateFolder = () => {
    if(canUserManageGlobalItem){
      setOpenNewFolderCreateModal(true);
    } else {
      dispatch(handlePermissionDeniedModal({
        openPermissionDeniedModal: true,
        permissionDeniedTitle:'Quick Replies Folder',
        permissionDeniedAction:'Create',
      }));
    }
  }

  const showContent = isSidebarCollapsed ? "none" : "block";

  const renderFolderList = () => {
    if (gettingQuickRepliesFolder) {
      return (
        <>
          <Skeleton height={45} />
          <Skeleton height={45} />
          <Skeleton height={45} />
          <Skeleton height={45} />
        </>
      );
    } else if (
      Array.isArray(quickRepliesFolderList) &&
      !gettingQuickRepliesFolder
    ) {
      return quickRepliesFolderList.map((folder, index) => (
        <EachFolderItem key={index} folder={folder} />
      ));
    }
  };

  return (
    <SidebarWrapper isSidebarCollapsed={isSidebarCollapsed}>
      <div className="sidebarHeader">
        <Typography className="sidebarTitle" variant="body2">
          Folders
        </Typography>
        <IconButton color="primary" onClick={handleCollapsed} size="large">
          {isSidebarCollapsed ? <SidebarRightIcon /> : <SidebarLeftIcon />}
        </IconButton>
      </div>

      <List
        className="sidebarList"
        component="div"
        aria-label="main mailbox folders"
        dense
      >
        <ListItem
          button
          onClick={handleSelectAll}
          style={{ marginBottom: 4 }}
          selected={quickRepliesParams.template_folder_id === null}
        >
          <ListItemIcon>
            <FolderIcon
              color="primary"
              fontSize="small"
              style={{ color: "#346fef" }}
            />
          </ListItemIcon>
          <ListItemText>
            <div className="allFolder">All Quick Replies</div>
          </ListItemText>
        </ListItem>

        <div className="folderWrapper">
          <div className="folderList">{renderFolderList()}</div>
        </div>

        <Divider light style={{ marginTop: 16, marginBottom: 8 }} />
        <ListItem button 
        onClick={handleCreateFolder}
        >
          <ListItemIcon>
            <Tooltip title={showContent === "none" ? " Create Category" : ""}>
              <AddIcon style={{ color: "#346fef" }} fontSize="small" />
            </Tooltip>
          </ListItemIcon>
          <ListItemText>
            <Typography
              variant="body2"
              fontWeight={600}
              minWidth={170}
              style={{ color: "#346fef" }}
            >
              New Folder
            </Typography>
          </ListItemText>
        </ListItem>
      </List>

      {openNewFolderCreateModal && (
        <AddOrEditquickRepliesFolder
          open={openNewFolderCreateModal}
          onClose={() => setOpenNewFolderCreateModal(false)}
        />
      )}
    </SidebarWrapper>
  );
};

export default SideBar;
