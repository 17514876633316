/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { connect } from 'react-redux'
import { addSingleContactToCampaign, fetchCampaign, moveContactToCampaign } from "../../../actions/marketingAction";
import GlobalModal from "../../globals/Modal/GlobalModal";
//moved to CDN
// import '../marketing.css';
import AddSingleContactTab from "./AddSingleContact";
import ExistingContactListToAddTab from "./ExistingContactListToAdd";
import AddIcon from '@material-ui/icons/Add';
import NewLoader from "../../Common/NewLoader";
import DynamicSkeleton from "../../Common/DynamicSkeleton";

const App = props => {

    const [tabType, setTabType] = useState('1') //1 for signle contact, 2 for existing contaact
    const [addingContact, setAddingContact] = useState(false)

    const handleAddNewContact = async ( data, customFields) => {
        if (addingContact) return
        setAddingContact(true)
        
        let postData = data;
        postData['campaign_id'] = props.campaign.id
        postData['customFields'] = customFields

        await props.addSingleContactToCampaign(postData, (response) => {
            if(response.status == 'success'){
              window.showNotification("SUCCESS", response.html)
                props.handleAddNewContactModal(false)
                props.fetchCampaign({page: 0})
            }
            else if(response.status == 'validation-error'){
                let message = 'Something went wrong'
                if(response.html.email != undefined){
                    if(typeof response.html.email === 'string'){
                        message = response.html.email
                    }
                    else{
                        message = response.html.email[0]
                    }
                }
                else if(response.html.number != undefined){
                    if(typeof response.html.number === 'string'){
                        message = response.html.number
                    }
                    else{
                        message = response.html.number[0]
                    }
                }
                window.showNotification("ERROR", message)
            }
            else{
              window.showNotification("ERROR", 'Something went wrong')
            }
            setAddingContact(false)
        })
    }

    const handleAddExistingContact = async (data) => {
        if(addingContact)return
        setAddingContact(true)
        let postData = data;
        postData['campaignId'] = props.campaign.id
        let total_contacts = data['contact_ids'].length
        await props.moveContactToCampaign(postData, (response) => {
            setAddingContact(false)
            if(response.status == 'success'){
              window.showNotification("SUCCESS", (total_contacts + " Contact(s) queued to campaign."))
                props.handleAddNewContactModal(false)
                props.fetchCampaign({page: 0})
            }
            else{
              window.showNotification("ERROR", "Something went wrong")
            }
        })
    }

    const headerTitle = () => {
        return(
            <div className="campaign____add_modal">
                <span className="campaign____add_modal___title">Create New Contact/Lead</span>
                <span className="campaign____add_modal___button accent--bg--color" onClick={tabType == 1 ? () => setTabType(2) : () => setTabType(1)}>
                    {tabType == 2 ? 'Add Single Contact' : 'Select Existing Contact'}
                </span>
            </div>
        )
    }

    const handleSubmit = () => {
        if(tabType == 1){
            window.campaignAddSingleContact()
        }
        else{
            window.campaignAddExistingContact()
        }
    }
    
    return (
      <>
        <GlobalModal
          open={props.open}
          onClose={props.onClose}
          // hideFooter={true}
          className="global-large-modal campaign-contact-add-modal main-add-contact-modal"
          title={headerTitle()}
          onSubmit={handleSubmit}
          buttonText={"Add New Contact"}
          buttonIcon={<AddIcon fontSize="small" />}
        >
          {addingContact ? (
            <DynamicSkeleton />
          ) : (
            <div className="tabs_all_content marketing-add-contact-modal">
              {/* <div className="tabs_titles">
                    <ul className="tabs tabs-fixed-width">
                        <li className="tab"><a className={`${tabType == 1 ? 'active' : ''}`} onClick={() => setTabType(1)}>Add Single Contact</a></li>
                        <li className="tab"><a className={`${tabType == 2 ? 'active' : ''}`} onClick={() => setTabType(2)} >Select Existing Contact</a></li>
                    </ul>
                </div> */}
              <div className="tabs_contents">
                {tabType == 1 && (
                  <AddSingleContactTab
                    addingContact={addingContact}
                    handleAddNewContact={handleAddNewContact}
                    handleAddNewContactModal={props.handleAddNewContactModal}
                  />
                )}

                {tabType == 2 && (
                  <ExistingContactListToAddTab
                    addingContact={addingContact}
                    handleAddNewContactModal={props.handleAddNewContactModal}
                    handleAddNewContact={handleAddExistingContact}
                  />
                )}
              </div>
            </div>
          )}
        </GlobalModal>
      </>
    );
}


const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
    addSingleContactToCampaign: (params, callback) => dispatch(addSingleContactToCampaign(params, callback)),
    fetchCampaign: (params, callback) => dispatch(fetchCampaign(params, callback)),
    moveContactToCampaign: (params, callback) => dispatch(moveContactToCampaign(params, callback))
})

const AddContactToCampaign = connect(mapStateToProps, mapDispatchToProps)(App);
export default AddContactToCampaign;
