import { makeStyles, styled } from "@material-ui/core";

const SidebarWrapper = styled("div")(({ theme, isSidebarCollapsed }) => ({
  minWidth: isSidebarCollapsed ? '56px' : "250px",
  height: "calc(100vh - 205px)",
  transition: " 350ms ease",
  background: "white",
  overflow: "hidden",
  padding: "16px 10px",
  position: "relative",
  borderRadius: theme.spacing(1),

  "& .MuiIconButton-root": {
    "&:focus": {
      background: "transparent",
    },
  },

  "& .sidebarHeader": {
    display: "flex",
    justifyContent: isSidebarCollapsed ? "center" : "space-between",
    alignItems: "center",
    padding: isSidebarCollapsed ? "0 !important" : "0 8px !important",
  },

  "& .sidebarTitle": {
    display: isSidebarCollapsed ? "none" : "block",
    fontWeight: 600,
    minWidth: 170,
    color: theme.palette.text.secondary,
  },

  "& .sidebarList": {
    "& .allFolder": {
      fontSize: "13px !important",
    },
    "& .Mui-selected": {
      background: "rgba(52, 111, 239, 0.08)",
      borderRadius: 8,
      "& .MuiTypography-root": {
        fontWeight: 600,
      },
    },
    "& .MuiListItem-button:hover": {
      borderRadius: theme.spacing(1),
    },
    "& .MuiListItem-gutters": {
      padding: isSidebarCollapsed ? "8px 0" : "4px 8px",
    },
    "& .MuiListItemIcon-root": {
      minWidth: isSidebarCollapsed ? 35 : 30,
      justifyContent: isSidebarCollapsed ? "center" : "flex-start",
    },
    "& .MuiListItemText-root": {
      minWidth: 170,
      display: isSidebarCollapsed ? "none" : "block",
    },
    "& .MuiListItemSecondaryAction-root": {
      display: isSidebarCollapsed ? "none" : "block",
      right: 8,
    },
    "& .folderWrapper": {
      height: "calc(100vh - 380px)",
      overflowY: "auto",
      overflowX: "hidden",
      "&::-webkit-scrollbar": {
        width: "6px",
      },
      "&::-webkit-scrollbar-track": {
        borderRadius: "10px",
        backgroundColor: "#2c3e50",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#346fef",
        borderRadius: "10px",
      },
    },

    "& .folderList": {
      display: "flex",
      flexDirection: "column",
      gap: 4,

      "& svg": {
        fontSize: 20,
      },
    },
  },

  "& .sidebarDivider": {
    margin: "12px",
  },
}));

export const ContentStyles = makeStyles((theme) => ({
  root: {
    background: "white",
    borderRadius: "8px",
    padding: "16px",
    flex: 1,
    height: "calc(100vh - 205px)",
    width:'100%',

    "& .headWrapper": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingBottom: 16,
      marginBottom: 16,
      borderBottom: "1px solid #cdcdcd",
    },

    "& .newFieldButton": {
      "&:hover, &:focus": {
        backgroundColor: "#3f51b5",
      },
    },

    "& .record_search": {
      display: "flex",
      justifyContent: "flex-end",
      position: "relative",
      "& .search": {
        border: "1px solid rgb(167 167 167 / 65%) !important",
        margin: 0,
        "&:focus": {
          boxShadow: "none !important",
        },
      },

      "& .search_icon": {
        right: 12,
        top: 12,
        position: "absolute",
      },
    },
  },
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "var(--light_blue)",
    },
  },
  tab: {
    fontSize: "15px !important",
    color: "var(--dark_blue)",
    fontWeight: 500,
    textTransform: "capitalize",
    "&:focus": {
      color: "var(--dark_blue)",
      backgroundColor: "transparent",
    },
    "&:hover": {
      color: "var(--light_blue)",
      backgroundColor: "transparent",
    },
    "&.Mui-selected": {
      color: "var(--light_blue)",
      backgroundColor: "transparent",
    },
  },
  cardListWrapper: {
    display: "flex", 
    gap: "20px", 
    width: "100%",  
    height: "calc(100vh - 290px)", 
    overflow:'auto',
    "&::-webkit-scrollbar": {
        width: "6px",
      },
      "&::-webkit-scrollbar-track": {
        borderRadius: "10px",
        backgroundColor: "#2c3e50",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#346fef",
        borderRadius: "10px",
      },
  },
  emptyPageWrapper: {
    width:'100%', 
    display:'flex', 
    flexDirection:'column', 
    justifyContent:'center', 
    alignItems:'center'
  },
  emptyImgWrapper: {
    height:'320px', 
    width:'500px',
    marginTop:'80px',
  },
  emptyImg: {
    height:'100%',
    width:'100%',
    objectFit:'cover'
  },
  emptyPageButton: {
    display: "flex",
    background: "var(--light_blue)",
    color: "#fff",
    height: 38,
    width: 250,
    borderRadius: 6,
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 12px !important",
  }
}));

export const HeaderStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
    backgroundColor: "white",
    borderBottom: "1px solid #3c7ef31a",
    gap: "20px",

    "& .headerLeft": {
      display: "flex",
      gap: 20,
      width: "65%",

      "& .headerText": {
        "& .title": {
          fontSize: 20,
          fontWeight: "bold",
          margin: "0 0 8px 0",
          color: "var(--dark_blue)",
        },

        "& .description": {
          fontSize: 14,
          color: "var(--dark_blue)",
          margin: 0,
        },
      },
    },

    "& .headerRight": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      gap: 12,
      width: "45%",
      "& .record_search": {
        height: "38px",
        display: "flex",
        justifyContent: "flex-end",
        position: "relative",
        minWidth:'245px',
        "& .search": {
          border: "1px solid rgb(167 167 167 / 65%) !important",
          margin: 0,
          "&:focus": {
            boxShadow: "none !important",
          },
        },

        "& .search_icon": {
          right: 12,
          top: 12,
          position: "absolute",
        },
      },
      "& .newFieldButton": {
        backgroundColor: "var(--accent-color)",
        color: "#ffffff !important",
        minWidth: "180px",
        height: "38px !important",
        "& .MuiSvgIcon-root": {
          color: "#ffffff !important",
        },
        "&:focus, &:hover": { backgroundColor: "var(--accent-color)" },
      },
    },
  },
}));
export const AddorEditQuickRepliesStyles = makeStyles({
  titleHeader: {
    fontSize: "20px",
  },
  iconsButton: {
    borderRadius: "12px",
    padding: "8px",
    "&:hover,&:focus": {
      backgroundColor: "white",
    },
  },
  selectedFolderIcon: {
    backgroundColor: "white",
  },
  checkIconColor: {
    color: "white",
    height: "30px",
    width: "30px",
  },
  outerIconColor: {
    height: "50px",
    width: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    cursor: "pointer",
  },
  innerIconColor: {
    height: "40px",
    width: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    border: "2px solid white",
  },
  errorStyle: {
    border: "1px solid red !important",
    borderRadius: "4px",
  },
  errorText: {
    color: "red",
  },
  saveButton: {
    backgroundColor: "#3C7EF3",
    fontWeight: 600,
    color: "white",
    minWidth: "fit-content",
    "&:hover, &:focus": {
      backgroundColor: "#3C7EF3",
      color: "white",
    },
  },
});

export default SidebarWrapper;
