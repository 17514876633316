import React, { createContext } from "react";
import Routes from "./routes/Routes";
import { createBrowserHistory } from "history";
import { createGenerateClassName, StylesProvider } from "@material-ui/styles";
import ErrorBoundary from "./components/Common/ErrorBoundary";
import Utils from "./helpers/Utils";

const defaultHistory = createBrowserHistory();
export const HistoryProvider = createContext({});

const generateClassName = createGenerateClassName({
    productionPrefix: 'mrktng' + Utils.generateRandomString(5),
});

function App({ history = defaultHistory }) {
    return (
        <HistoryProvider.Provider value={history}>
            <ErrorBoundary>
                <StylesProvider generateClassName={generateClassName}>
                    <Routes/>
                </StylesProvider>
            </ErrorBoundary>    
        </HistoryProvider.Provider>
        
    );
}

export default App;
