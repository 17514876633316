import React from 'react';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const CampaignDetailsSkeleton = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={5} lg={6}>
          <Skeleton variant="rect" height={600} />
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={6}>
          <Skeleton variant="rect" height={400} style={{ marginBottom: '16px' }} />
          <Skeleton variant="rect" height={150} />
        </Grid>
      </Grid>
      <Skeleton variant="rect" height={100} style={{ marginTop: '20px' }} />
    </>
  );
};

export default CampaignDetailsSkeleton;
