import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import BasicInput from "../../Common/BasicInput/BasicInput";
import AddIcon from "@material-ui/icons/Add";
import { HeaderStyles } from "./QuickRepliesContent/QuickRepliesStyle";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import useDebounce from "../../../hooks/useDebounce";
import { useDispatch } from "react-redux";
import { handleSelectedQuickRepliesSearch } from "../../../actions/quickRepliesAction";

const Header = () => {
  const classes = HeaderStyles();
  const [search, setSearch] = useState(null);
  const debouncedSearch = useDebounce(search, 1000);
  const dispatch = useDispatch();

  useEffect(()=>{
    if(debouncedSearch !== null){
      dispatch(
        handleSelectedQuickRepliesSearch(debouncedSearch)
      );
    }
  },[debouncedSearch]);  // eslint-disable-line react-hooks/exhaustive-deps
  
  const handleSearchInput = (e) => {
    setSearch(e.target.value);
  }

  return (
    <div className={classes.header}>
      <div className="headerLeft">
        <img
          src={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/1.0.0/quick_replies_icon.png`}
          alt=""
          height={70}
        />

        <div className="headerText">
          <h5 className="title">Quick Replies</h5>
          <p className="description">
          Save time by creating message templates for things like FAQ, holiday messages, and commonly used phrases.
          </p>
        </div>
      </div>

      <div className="headerRight">
        <div class="record_search">
          <BasicInput
            placeholder="Search by quick reply title"
            onChange={(e) => handleSearchInput(e)}
            fullWidth
          />
          <div class="search_icon">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.4351 10.0629H10.7124L10.4563 9.81589C11.3528 8.77301 11.8925 7.4191 11.8925 5.94625C11.8925 2.66209 9.23042 0 5.94625 0C2.66209 0 0 2.66209 0 5.94625C0 9.23042 2.66209 11.8925 5.94625 11.8925C7.4191 11.8925 8.77301 11.3528 9.81589 10.4563L10.0629 10.7124V11.4351L14.6369 16L16 14.6369L11.4351 10.0629ZM5.94625 10.0629C3.66838 10.0629 1.82962 8.22413 1.82962 5.94625C1.82962 3.66838 3.66838 1.82962 5.94625 1.82962C8.22413 1.82962 10.0629 3.66838 10.0629 5.94625C10.0629 8.22413 8.22413 10.0629 5.94625 10.0629Z"
                fill="#133159"
                fill-opacity="0.65"
              ></path>
            </svg>
          </div>
        </div>
      <Link to={'/quick-reply-add-template-global'}>
      <Button
          variant="contained"
          color="primary"
          className="newFieldButton"
          startIcon={<AddIcon />}
          disableElevation
        >
          New Quick Reply
        </Button>
      
      </Link>
      </div>
    </div>
  );
};

export default Header;
