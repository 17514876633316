import {getCookie} from "../../../../helpers/Cookie";

export const insertPersonalizedTag = (personalizedTag, inputRef, onChange) => {
    const input = inputRef.current;
    if (input.editor) {
        inputRef.current.editor.execCommand("mceInsertContent", false, personalizedTag);
    } else {
        const value = input.value;
        const selectionStart = input.selectionStart;
        onChange(value.substring(0, selectionStart) + personalizedTag + value.substring(selectionStart));
        setTimeout(() => {
            input.setSelectionRange(selectionStart + personalizedTag.length, selectionStart + personalizedTag.length);
        }, 100);
    }
};

export const imageUploadHandler = async (blobInfo) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/calendar/upload-email-file`;

    return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("file", blobInfo.blob(), blobInfo.filename());
        formData.append("api-key", getCookie(process.env.REACT_APP_ACCESS_TOKEN))

        const xhr = new XMLHttpRequest();
        xhr.open("POST", url);

        xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
                const progress = (event.loaded / event.total) * 100;
                console.log(`Upload progress: ${progress.toFixed(2)}%`);
            }
        };

        xhr.onload = () => {
            if (xhr.status === 201) {
                const response = JSON.parse(xhr.responseText);
                resolve(response.location);
            } else {
                reject(new Error(`Upload failed with status ${xhr.status}`));
            }
        };

        xhr.onerror = () => {
            reject(new Error("Upload failed due to a network error."));
        };

        xhr.send(formData);
    });
};

export const chatCompletions = async (data) => {
    const url = `${process.env.REACT_APP_PYPEPRO_TENANT_BACKEND_SERVER}/api/v1/openai/chat-completions`;

    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", url);
        xhr.setRequestHeader("Authorization", `Bearer ${getCookie(process.env.REACT_APP_ACCESS_TOKEN)}`);

        xhr.onload = () => {
            resolve(JSON.parse(xhr.responseText))
        };

        xhr.onerror = () => {
            reject(new Error("Upload failed due to a network error."));
        };

        const formData = new FormData();
        formData.append("content", data.content);

        xhr.send(formData);
    });
};

export const saveOpenApiKey = async (data) => {
    const url = `${process.env.REACT_APP_PYPEPRO_TENANT_BACKEND_SERVER}/api/v1/openai/save-api-key`;

    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", url);
        xhr.setRequestHeader("Authorization", `Bearer ${getCookie(process.env.REACT_APP_ACCESS_TOKEN)}`);

        xhr.onload = () => {
            resolve(JSON.parse(xhr.responseText))
        };

        xhr.onerror = () => {
            reject(new Error("Upload failed due to a network error."));
        };

        const formData = new FormData();
        formData.append("apiKey", data.apiKey);

        xhr.send(formData);
    });
};