/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import { MenuItem } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import { CheckCircle } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { connect, useSelector } from "react-redux";
import {
  campaignCheckToStart,
  campaignPause,
  campaignStart,
  deleteCampaign,
  fetchCampaignDetails,
  updateCampaignDetailsAction
} from "../../../actions/marketingAction";
import {
  updateCampaignDetails,
  updateStatusContinueAfterFailedApi,
} from "../../../api/marketingApi";
import { HistoryProvider } from "../../../App";
import OutSideConstants from "../../../constants/ConstantFromOutside";
import { DropdownMenuProps } from "../../../helpers/DropdownSettings";
import Utils from "../../../helpers/Utils";
import useQueryParam from "../../../hooks/useQueryParam";
import ActionControl from "../../Common/ActionControl/ActionControl";
import CustomConfirmAlert from "../../Common/custom-alert/CustomConfirmAlert";
import CustomBootstrapTooltip from "../../Common/CustomBootstrapTooltip";
import CustomConfirmModal from "../../Common/CustomConfirmModal";
import SwitchC from "../../Common/Switch/Switch";
import { ImgCampaign } from "../../Common/TinyMCETextEditor/utils/icons";
import CustomModalPype from "../../marketing-list-v2/components/modal/CustomModalPype";
import CampaignActionNoticeBoard from "../CampaignList/CampaignActionNoticeBoard";
import LookupValidation from "../EachCampaignActions/LookupValidation";
import ActionListDetails from "./action-list/ActionList";
import Styles from "./action-list/style.module.css";
import { CampaignEmailIcon, DeleteIcon, LookupValidationIcon, PauseIcon, PlayIcon, TotalCampaignIcon, WarningIcon } from "./CampaignSettingStatContact.js/CampaignIcons";
import CampaignStatusModal from "./CampaignStatusModal";
import "./marketing_new_design.css";
const standardPack = OutSideConstants.standardPack;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  topContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
    padding: "20px 20px 13px 20px",
  },
  continueButton: {
    width: "200px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#00FF9D",
    borderRadius: "4px",
    padding: "10px",
    border: "none ",
    gap: "5px",
    minHeight: "45px",
    "&:focus": {
      backgroundColor: "#5aff9e",
    },
  },
  continueText: {
    color: "#000",
    fontWeight: 500,
  },
  pauseButton: {
    width: "200px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#C3CBD5",
    borderRadius: "4px",
    padding: "10px",
    border: "none ",
    gap: "5px",
    minHeight: "45px",
    "&:focus": {
      backgroundColor: "#C3CBD5",
    },
  },
  pauseText: {
    color: "#000",
    fontWeight: 500,
  },
  removeButton: {
    width: "200px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ff264a",
    borderRadius: "4px",
    padding: "10px",
    border: "none ",
    gap: "5px",
    minHeight: "45px",
    "&:focus": {
      backgroundColor: "#ff264a",
    },
  },
  removeText: {
    color: "#fff",
    fontWeight: 500,
  },
  pauseButtonTop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#163159",
    borderRadius: "4px",
    padding: "8px",
    border: "none ",
    color: "#ffffff",
    cursor: "pointer",
    minHeight: "40px",
    "&:focus, &:hover": {
      backgroundColor: "#163159",
    },
  },
  deleteButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ef244a",
    borderRadius: "4px",
    padding: "8px",
    border: "none ",
    color: "#ffffff",
    cursor: "pointer",
    minHeight: "40px",
    "&:focus, &:hover": {
      backgroundColor: "#ef244a",
    },
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 5,
  },
  startButtonTop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#163159",
    borderRadius: "4px",
    padding: "10px",
    border: "none ",
    color: "#ffffff",
    cursor: "pointer",
    minHeight: "40px",
    "&:focus, &:hover": {
      backgroundColor: "#163159",
    },
  },
  divider: {
    borderTop: "1.5px solid #e5e5e5",
    margin: "0",
  },
}));


const CampaignDetails = (props) => {

  const classes = useStyles();
  const isTempUser = Utils.getAccountData("isTemplateUser");
  const optionUnsub = 0;
  const optionPause = 2;
  const optionContinue = 1;

  const campaignStatusStart = 3;
  const campaignStatusProcessing = 0;

  const campaignDetails = useSelector(
    (state) => state.marketingReducer.campaignDetails
  );
  const campaignLeadSourceDetails = useSelector(
    (state) => state.marketingReducer.campaignLeadSourceDetails
  );
  const campaignContactStat = useSelector(
    (state) => state.marketingReducer.campaignContactStat
  );
  const [accessDenied, setAccessDenied] = useState(false);
  const [isStartingCampaign, setIsStartingCampaign] = useState(false);
  const [campaignTitle, setCampaignTitle] = useState("New Campaign");
  const [responseOption, setResponseOption] = useState(optionUnsub);
  const [campaignNumber, setCampaignNumber] = useState(null);
  const [campaignTimezone, setCampaignTimezone] = useState(null);
  const [campaignStatus, setCampaignStatus] = useState(
    campaignStatusProcessing
  );
  const [isContinueAfterFailed, setIsContinueAfterFailed] = useState(2);

  const [openLookupValidation, setOpenLookupValidation] = useState(false);
  const [openCampaignStatusModal, setOpenCampaignStatusModal] = useState(false);
  const [redirectFrom] = useQueryParam("redirect-from");

  let connectedNylasEmail = "";
  const history = useContext(HistoryProvider);

  if (
    campaignDetails &&
    campaignDetails.nylas != "" &&
    campaignDetails.nylas != null
  ) {
    connectedNylasEmail = JSON.parse(campaignDetails.nylas).email;
  }

  const [showActionList, setShowActionList] = useState(false);
  const campaignDescriptionTitle = "Are you sure you want to pause this campaign?";
  const campaignPauseDescription = "To pause this campaign, click Pause below. If you changed your mind, click Cancel. FYI - it will take a few minutes for your campaign to pause.";
  const confirmModalImg = `${process.env.REACT_APP_CDN_LINK}assets/images/releases/marketing/1.0.0/stopwatch_icon.png`;

  useEffect(() => {
    if (redirectFrom === "import-contacts"){
      setOpenCampaignStatusModal(true);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  useEffect(() => {

    if (campaignDetails && campaignDetails.basic !== undefined && campaignDetails.basic !== null) {
      setCampaignTitle(campaignDetails.basic.title);
      setResponseOption(campaignDetails.basic.carry_on_campaign);
      setCampaignTimezone(campaignDetails.basic.timezone);
      setCampaignStatus(campaignDetails.basic.status);

      if (
        campaignDetails.basic.campaign_number != null &&
        campaignDetails.basic.campaign_number.virtual_number_id != undefined
      )
        setCampaignNumber(
          campaignDetails.basic.campaign_number.virtual_number_id
        );

      setIsContinueAfterFailed(campaignDetails.basic?.is_continue_after_fail);
    }else{
      setAccessDenied(true);
    }
  }, [props.userInfo, campaignDetails]);

  // const loadSocket = () => {
  //   const socket = socketIOClient(process.env.REACT_APP_NODE_SERVER);
  //   const userRoom = "background-task-notification:room:" + props.userInfo.id;

  //   socket.on("connect", function (data) {
  //     socket.emit("join-background-task-notification-room", userRoom);
  //   });

  //   const campaignId = campaignDetails.basic.id;
  //   socket.on("background-task-notification", function (data) {
  //     if (
  //       data.params.related_to == 2 &&
  //       data.params.action === "delete" &&
  //       data.params.related_id == campaignId
  //     ) {
  //       props.fetchCampaignDetails({
  //         campaignId: campaignId,
  //       });
  //       setIsStartingCampaign(false);
  //     }
  //   });
  // };

  const handleNumberChange = (e) => {
    e.preventDefault();
    setCampaignNumber(e.target.value);
    // campaign_number.virtual_number_id
    let data = {
      ...campaignDetails.basic.campaign_number,
      virtual_number_id: e.target.value,
    };
    props.updateCampaignDetailsAction({
      basic: { ...campaignDetails.basic, campaign_number: data },
    });

    updateCampaignDetails({
      campaignId: campaignDetails.basic.id,
      key: "virtual_number_id",
      value: e.target.value,
    });
  };

  const updateTitle = (e) => {
    e.preventDefault();
    updateCampaignDetails({
      campaignId: campaignDetails.basic.id,
      key: "title",
      value: e.target.value,
    });
  };

  const changeResponseOption = (event, option) => {
    event.preventDefault();
    setResponseOption(option);

    props.updateCampaignDetailsAction({
      basic: { ...campaignDetails.basic, carry_on_campaign: option },
    });
    updateCampaignDetails({
      campaignId: campaignDetails.basic.id,
      key: "carry_on_campaign",
      value: option,
    });
  };

  const updateCampaignTitle = (value) => {
    setCampaignTitle(value);
    props.updateCampaignDetailsAction({
      basic: { ...campaignDetails.basic, title: value },
    });
  };

  const handleTimezoneChange = (event, option) => {
    event.preventDefault();
    setCampaignTimezone(event.target.value);

    props.updateCampaignDetailsAction({
      basic: { ...campaignDetails.basic, timezone: option },
    });
    updateCampaignDetails({
      campaignId: campaignDetails.basic.id,
      key: "timezone",
      value: event.target.value,
    });
  };

  const deleteCampaign = () => {
      CustomConfirmAlert({
          handleConfirm: () => {
              props.deleteCampaign(
                  { campaign_id: campaignDetails.basic.id },
                  (response) => {
                      if (response.status == "success") {
                          window.showNotification("SUCCESS", "Campaign deleted successfully");
                          history.push("/campaign");
                      } else {
                          showAlert("Something Wrong!", "Please try again");
                      }
                  }
              );
          },
          callConfirmLast: true,
          title: 'Are you sure?',
          description: 'If you delete this campaign, you will not be able to restore it again.',
          type_word: 'DELETE CAMPAIGN',
          icon: 'question',
          closeButtonText: 'No',
          confirmButtonText: 'Yes'
      });
  };

  const showAlert = (title, message) => {
    let alertTitle = title ? title : "Sorry";
    let alertMessage = message ? message : "Something went wrong";
    confirmAlert({
      title: alertTitle,
      message: alertMessage,
      buttons: [
        {
          label: "Ok",
          onClick: () => {
            return;
          },
        },
      ],
    });
  };

  const startCampaignStatus = (fields = {}) => {
    let data = {
      campaignId: campaignDetails.basic.id,
      campaign_id: campaignDetails.basic.id,
      prevent_previous_followup: 0,
      sent_immediate_after_resume: 1,
    };
    data = { ...data, ...fields };
    props.campaignStart(data, (response) => {
      console.log(response);
      if (response.data.status == "error") {
        if (response.data & response.data.html) {
          if (response.data.html) {
            // showAlert('Error', response.data.html);
            // }else if (response.html){
            let regularRemove = /[0-9]/g;
            console.log(response.data.html.replace(regularRemove, ""));
            showAlert("Error", response.data.html.replace(regularRemove, ""));
          }
        } else if (response.html) {
          let regularRemove = /[0-9]/g;
          showAlert("Error", response.html.replace(regularRemove, ""));
        }
      } else if (response.data.status == "success") {
        setOpenCampaignStatusModal(false);
        confirmAlert({
          childrenElement: () => {
            return <CampaignActionNoticeBoard />;
          },
          buttons: [
            {
              label: "Close",
              onClick: () => {
                return;
              },
              className: "button-modify",
            },
          ],
          overlayClassName: "alert-button-modify",
        });
        setIsStartingCampaign(true);
      }
    });
  };

  const pauseCampaign = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <CustomConfirmModal
          title={campaignDescriptionTitle}
          onClose={onClose}
          handleConfirm={async () => {
            props.campaignPause(
                        { campaign_id: campaignDetails.basic.id },
                        (response) => {
                          if (response.status == "error") {
                            if (response.data && response.data.html) {
                              let regularRemove = /[0-9]/g;
                              showAlert(
                                  "Error",
                                  response.data.html.replace(regularRemove, "")
                              );
                            } else if (response.html) {
                              let regularRemove = /[0-9]/g;
            
                              showAlert(
                                  "Error",
                                  response.html.replace(regularRemove, "")
                              );
                            }
                          } else if (response.status == "success") {
                            confirmAlert({
                              childrenElement: () => {
                                return (
                                    <CampaignActionNoticeBoard
                                    title={"Your campaign will be paused shortly."}
                                    message = {"It could take up to 5 minutes."}
                                    />
                                );
                              },
                              buttons: [
                                {
                                  label: "Close",
                                  onClick: () => {
                                    return;
                                  },
                                  className: "button-modify",
                                },
                              ],
                              overlayClassName: "alert-button-modify",
                            });
                          }
                        }
                    );
          }}
          closeButtonText="Cancel"
          confirmButtonText="Pause"
          showConfirmButton={true}
          showCloseButton={true}
          description={campaignPauseDescription}
          imgSrc={confirmModalImg} 
        />
      ),
      closeOnClickOutside: true,
    });
  };
  const startCampaign = () => {
    setShowActionList(true);
  };

  const startCampaignAfterCheckList = (event) => {
    if (
      event !== undefined &&
      event != null &&
      event.preventDefault() !== undefined
    ) {
      event.preventDefault();
    }
    if (campaignDetails.basic.type == 1) {
      if (campaignNumber == null && standardPack) {
        showAlert(
          "Error",
          "Please select campaign number to start the campaign"
        );
        return false;
      }
    }
    props.campaignCheckToStart(
      {
        campaign_id: campaignDetails.basic.id,
      },
      (response) => {
        if (response.status) {
          confirmAlert({
            title: "Confirm to start?",
            message: "Are you sure to want to start this campaign?",
            buttons: [
              {
                label: "Yes",
                onClick: () => {
                  startCampaignStatus();
                },
              },
              {
                label: "No",
                onClick: () => {
                  return;
                },
              },
            ],
          });
        } else {
          window.showNotification("ERROR", response.message);
        }
      }
    );
  };

  let numberOption = [];
  if (campaignDetails && campaignDetails.basic) {
    numberOption = campaignDetails.basic.virtual_numbers.map(function (
      data,
      index
    ) {
      return (
        <MenuItem
          className='menuitem-li-hover-color-with-border'
          key={index}
          value={data.id}
        >
          {data.virtual_number}
        </MenuItem>
      );
    });
  }

  let timezoneOption = [];
  if (campaignLeadSourceDetails) {
    timezoneOption = campaignLeadSourceDetails.timezones.map(function (
      data,
      index
    ) {
      return (
        <MenuItem
          className='menuitem-li-hover-color-with-border'
          key={index}
          value={data.timezone_long_form}
        >
          {data.timezone_short_form}
        </MenuItem>
      );
    });
  }

  const goToTotalContacts = (e) => {
    e.preventDefault();
    history.push("/contacts?campaignId=" + campaignDetails.basic.id);
  };

  const renderCampaignEmail = (nylas_email) => {
    let renderEmail = "";
    if (nylas_email === "") {
      if (campaignDetails?.basic?.campaign_email) {
        renderEmail = campaignDetails.basic.campaign_email;
      }
    } else {
      renderEmail = nylas_email;
    }
    return renderEmail;
  };

  const handleStatusContinueAfterFailed = (value) => {
    if (
      campaignContactStat &&
      campaignContactStat[0].total_contact > 0 &&
      campaignNumber !== null
    ) {
      CustomConfirmAlert({
        handleConfirm: () => {},
        handleOnClose: () => {},
        title: "WRONG ATTEMPT !",
        // description: 'YOU CAN ONLY CHANGE THIS STATUS BEFORE CAMPAIGN START. ONCE CAMPAIGN STARTED, YOU CAN NOT CHANGE THIS STATUS ANYMORE THOUGH CAMAPIGN IS IN RUNNING OR PAUSE MODE.',
        description:
          "You can only change this status before campaign start. Once campaign started, you can not change this status anymore.",
        icon: "warning",
        confirmButtonText: "Ok. Got it !",
        callConfirmLast: false,
        showCloseButton: false,
        minHeight: "280px",
      });
      return;
    }
    CustomConfirmAlert({
      handleConfirm: () => {
        // let status_value = value == 1 ? 1 : 2
        let status_value = value != 1 ? 1 : 2;
        updateStatusContinueAfterFailedApi({
          campaign_id: campaignDetails.basic.id,
          value: status_value,
        })
          .then((res) => {
            let response = res.data;
            if (response.status === "success") {
              setIsContinueAfterFailed(status_value);
              props.updateCampaignDetailsAction({
                basic: {
                  ...campaignDetails.basic,
                  is_continue_after_fail: status_value,
                },
              });
            } else {
              window.showNotification(
                "Something went wrong! Please try again later"
              );
            }
          })
          .catch((error) => {});
      },
      handleOnClose: () => {},
      title: "CONFIRMATION ALERT",
      description:
        "You can only change this status before campaign start. Once campaign started, you can not change this status anymore.",
      icon: "warning",
      confirmButtonText: "Ok. Go ahead",
      closeButtonText: "Close",
      callConfirmLast: false,
      minHeight: "280px",
    });
  };

  const showLookupValidationModal = (e) => {
    e.preventDefault();
    setOpenLookupValidation(true)

  }

  const handleGoBack = () => {
    history.goBack(); // This function will go back to the previous page in the history
  };

  return (
      <div className='campaign_details'>
      <div>
        {accessDenied && (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={true}
                closeAfterTransition
            >
              <div className={Styles.mainWrp}>
                <div className={Styles.headerWrp}>
                  <div className={Styles.headerLeft}>Access Denied</div>
                </div>
                <div className={`${Styles.mainContentAccessDenied} awesome__scroll_bar`}>
                  <div className=''>
                    <h3 className={Styles.accessDeniedBodyTitle}>
                      This campaign was created by another user on your team.
                      To view the campaign details you need to be the campaign owner.
                    </h3>
                    <span
                        className={Styles.startButton}
                        onClick={handleGoBack}
                    >
               Go Back
              </span>
                  </div>
                  <div className=''>
                    <span>
                      <ImgCampaign/>
                    </span>
                  </div>
                </div>
              </div>
            </Modal>
        )}
      </div>
      <div className={classes.topContainer}>
        <span className='d-flex align-items-center'>
          <span style={{textAlign:"center",fontSize:"20px",fontWeight:700,color:"#133159"}}>Campaign Details</span>
          <ActionControl
            alertMessage='Campaign Contact feature is not available for template user'
            status='error'
            isDisabled={isTempUser == 1}
          >
            <a
              onClick={goToTotalContacts}
              href={"/contacts?campaignId=" + campaignDetails?.basic?.id}
              className='number_count_btn d-flex align-items-center'
            >
              <svg
                width='20'
                height='20'
                viewBox='0 0 20 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M18.999 13.79c-.603.685-1.42.941-2.268 1.138-.138.032-.1-.057-.101-.113-.02-.521-.033-1.042-.09-1.56-.09-.794-.246-1.567-.671-2.263-.342-.562-.827-.962-1.397-1.27-.08-.044-.161-.083-.275-.141.3-.036.562-.044.811-.136a1.7 1.7 0 0 0 .57-.333c.04-.037.072-.072.135-.06.606.126 1.21.255 1.778.518.75.346 1.168.94 1.331 1.734.095.46.149.92.157 1.388.012.2.012.196.02.43v.668Z'
                  fill='#3C7EF3'
                />
                <path
                  d='M10.006 17.27c-1.16.01-2.315-.066-3.444-.36-.704-.182-1.38-.435-1.957-.899l-.107-.09a.786.786 0 0 1-.31-.715c.053-.846.032-1.698.198-2.534.118-.598.318-1.16.749-1.614.417-.441.947-.693 1.51-.885a11.584 11.584 0 0 1 1.546-.385c.075-.015.137-.017.206.047.473.442 1.057.577 1.681.567.519-.008 1.015-.126 1.42-.467a.499.499 0 0 1 .483-.119c.691.15 1.376.312 2.02.616.888.419 1.384 1.13 1.593 2.07.147.667.177 1.343.205 2.021.01.246 0 .494.026.737.028.254-.069.436-.245.602-.475.448-1.05.712-1.66.911-1.019.334-2.074.455-3.14.495-.258.01-.516.001-.774.001ZM13.078 5.566c.014 1.212-.31 2.3-1.221 3.15C10.713 9.784 9.074 9.714 8 8.57a3.846 3.846 0 0 1-1.037-2.324c-.078-.825-.042-1.65.2-2.451.307-1.014 1.015-1.568 2.04-1.73a4.841 4.841 0 0 1 1.848.052c1.046.24 1.626.921 1.87 1.933.12.498.152 1.006.157 1.517ZM3.239 5.562c.02-.462.05-.922.188-1.37.244-.796.79-1.248 1.594-1.394a3.98 3.98 0 0 1 1.534.025c.09.02.105.04.068.121-.565 1.231-.611 2.52-.41 3.83.086.553.288 1.071.58 1.549.08.13.052.171-.072.237-.944.499-2.095.216-2.795-.696-.522-.68-.715-1.458-.687-2.302ZM16.775 5.58c.017 1.04-.26 1.966-1.065 2.676-.703.62-1.68.722-2.463.277-.062-.035-.12-.051-.06-.147.639-1.053.787-2.206.694-3.41-.054-.705-.17-1.392-.49-2.03-.028-.058-.05-.1.049-.119.623-.123 1.246-.134 1.857.048.757.226 1.17.774 1.347 1.521.091.388.119.785.131 1.185Z'
                  fill='#3C7EF3'
                />
                <path
                  d='M5.785 9.584c-.186.107-.363.204-.536.31-.875.533-1.362 1.337-1.6 2.313-.196.807-.238 1.63-.26 2.455-.007.286-.01.282-.284.22-.55-.123-1.077-.307-1.553-.617l-.059-.038c-.333-.215-.533-.476-.486-.92.072-.697.043-1.402.203-2.092.188-.813.662-1.376 1.425-1.7.53-.225 1.088-.35 1.65-.46.04-.007.078-.013.114.022.37.364.836.467 1.332.492.014 0 .028.007.054.015Z'
                  fill='#3C7EF3'
                />
              </svg>
              (
              {campaignContactStat
                ? campaignContactStat[0].total_contact
                : "00"}
              )
            </a>
          </ActionControl>
        </span>

        {openLookupValidation &&
            <LookupValidation
                open={openLookupValidation}
                onClose={() => setOpenLookupValidation(false)}
                campaignId={campaignDetails.basic.id}
            />
        }

        <div className='campaign_bottom_buttons'>
          <div className='campaign_bottom_buttons_row  d-flex'>
            { Utils.getAccountData("carrierLookup") &&
            <>
              {(Utils.getAccountData('userId') === 1 || Utils.getAccountData('userId') === 6541) &&
              <div className=' campaign_button'>
                <CustomBootstrapTooltip
                        title={'Lookup Validation'}
                        arrow
                      >
                  <a href="#!" onClick={(e) => showLookupValidationModal(e)}>
                    <LookupValidationIcon/>
                  </a>
                </CustomBootstrapTooltip>

              </div>
              }
            </>
            }
            <div style={{display:"flex",alignItems:"center",gap:10}}>
                <button onClick={deleteCampaign} className={classes.deleteButton}>
                  <span className={classes.iconContainer}>
                    <DeleteIcon/>
                    Delete Campaign
                  </span>
                </button>
              {campaignStatus == campaignStatusStart && (
                  <button  className={classes.pauseButtonTop} onClick={pauseCampaign}>
                    <span className={classes.iconContainer}>
                      Pause Campaign
                      <PauseIcon/>
                    </span>
                  </button>
              )}

              {campaignStatus != campaignStatusStart && (
                  <button onClick={startCampaign} className={classes.startButtonTop}>
                    {isStartingCampaign ? (
                      <>
                        Starting Campaign..
                      </>
                    ) : (
                      <>
                        <span className={classes.iconContainer}>
                          Start Campaign
                          <PlayIcon/>
                        </span>
                      </>
                    )}
                  </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <hr className={classes.divider} />
      <div
        className='campaign_details_content-old'
        style={{ paddingBottom: 1 }}
      >
        <div className='campaign_details_content'>
          <div className='flexParent'>
            <div className='input-field flexChild'>
              <label
                htmlFor='campaign_name'
                className='campaign_sub_title active campaign-sub-title-alt'
              >
                Campaign Name
              </label>
              <input
                onBlur={(event) => updateTitle(event)}
                onChange={(event) => updateCampaignTitle(event.target.value)}
                placeholder='Write Campaign Name'
                type='text'
                value={campaignTitle}
              />
            </div>

            <div className='campaign_email_address flexChild'>
              <div className='input-field'>
                <label
                  htmlFor='campaign_email'
                  className='campaign_sub_title d-flex align-items-center active campaign-sub-title-alt'
                >
                  Campaign Email Address
                  {connectedNylasEmail !== "" && (
                    <CustomBootstrapTooltip
                      title={
                        <React.Fragment>
                          <p>
                            {campaignDetails
                              ? connectedNylasEmail != ""
                                ? campaignDetails.basic
                                  ? campaignDetails.basic.campaign_email
                                  : ""
                                : ""
                              : ""}
                          </p>
                        </React.Fragment>
                      }
                      arrow
                    >
                      <span className='ml-2'>
                       <CampaignEmailIcon/>
                      </span>
                    </CustomBootstrapTooltip>
                  )}
                </label>
                {/* <input readOnly={true}  placeholder="Write Email Address" value={campaignDetails ? connectedNylasEmail == '' ? campaignDetails.basic ? campaignDetails.basic.campaign_email : 'Email Address' : connectedNylasEmail : 'Email Address2'} type="text"/> */}
                <input
                  readOnly={true}
                  placeholder='Write Email Address'
                  value={renderCampaignEmail(connectedNylasEmail)}
                  type='text'
                />
                <p
                  className='truncate campaign__settings_mail'
                  style={{ width: "250px" }}
                  title={
                    campaignDetails
                      ? connectedNylasEmail != ""
                        ? campaignDetails.basic
                          ? campaignDetails.basic.campaign_email
                          : ""
                        : ""
                      : ""
                  }
                >
                  {campaignDetails
                    ? connectedNylasEmail != ""
                      ? campaignDetails.basic
                        ? campaignDetails.basic.campaign_email
                        : ""
                      : ""
                    : ""}
                </p>
              </div>
            </div>
          </div>
          <div className='total_number_count d-none'>
            <div className='number_count_title d-flex justify-content-between align-items-center'>
              <h6>Total Number of Contacts</h6>
              <a
                href={"/contacts?campaignId=" + campaignDetails?.basic?.id}
                onClick={goToTotalContacts}
              >
                See all
              </a>
            </div>
            <a
              onClick={goToTotalContacts}
              href={"/contacts?campaignId=" + campaignDetails?.basic?.id}
              className='number_count_btn'
            >
              <span>
                 <TotalCampaignIcon/>
              </span>
              Number of Contacts (
              {campaignContactStat
                ? campaignContactStat[0].total_contact
                : "00"}
              )
            </a>
          </div>

          <div className='campaign_phn_no_time_zone'>
            <div className='row'>
              {campaignDetails && campaignDetails?.basic?.type == 1 ? (
                <div className='col s6 m6 l12 xl6'>
                  <div className='campaign_phn_no'>
                    <h6>Campaign Phone Number</h6>
                    <Select
                      disabled={
                        campaignContactStat &&
                        campaignContactStat[0].total_contact > 0 &&
                        campaignNumber !== null
                          ? true
                          : false
                      }
                      value={campaignNumber}
                      onChange={handleNumberChange}
                      MenuProps={DropdownMenuProps}
                      displayEmpty
                      id={"campaign_details_virtual_number"}
                    >
                      <MenuItem
                        className='menuitem-li-hover-color-with-border'
                        disabled
                        value={null}
                      >
                        {"Select a phone number"}
                      </MenuItem>
                      {numberOption}
                    </Select>
                  </div>
                  <div className='bottom_content d-flex'>
                    <span className='mt-3'>
                      <WarningIcon/>
                    </span>
                    <p>
                      You won’t be able to change the number after a contact has
                      been added
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className='col s6 m6 l12 xl6'>
                <div className='campaign_time_zone'>
                  <h6>Campaign Timezone</h6>
                  <Select
                    disabled={
                      campaignContactStat &&
                      campaignContactStat[0].total_contact > 0
                        ? true
                        : false
                    }
                    value={campaignTimezone}
                    onChange={handleTimezoneChange}
                    MenuProps={DropdownMenuProps}
                    displayEmpty
                    id={"campaign_details_timezone"}
                  >
                    {timezoneOption}
                  </Select>
                  <div className='bottom_content d-flex'>
                    <span className='mt-3'>
                      <WarningIcon/>
                    </span>
                    <p>
                      You won’t be able to change the timezone after a contact
                      has been added
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='campaign_continue_after_failed_container'>
            <div className='campaign_continue_afc_row'>
              <span>If a message fails, pause campaign for that contact:</span>
              <CustomBootstrapTooltip
                title={
                  <div>
                    <p>
                      - When enabled, this feature will prevent future messages
                      from going out to a contact after a failed attempt.
                    </p>
                    <p style={{ margin: "5px 0px 0px 10px" }}>
                      Ex. If a text comes back as undelivered we will pause the
                      campaign for that specific contact.
                    </p>
                    <p style={{ marginTop: "5px" }}>
                      - You won’t be able to change this setting once campaign
                      is started
                    </p>
                  </div>
                }
                arrow
              >
                <span className='ml-2'>
                  <WarningIcon/>
                </span>
              </CustomBootstrapTooltip>
            </div>
            <div>
              <SwitchC
                status={isContinueAfterFailed != 1 ? true : false}
                // status={isContinueAfterFailed == 1 ? false : true}
                brandColor={"#27AE60"}
                onChange={(value) => handleStatusContinueAfterFailed(value)}
                onText={"On"}
                offText={"Off"}
              />
            </div>
          </div>
        </div>
        <div className='campaign_response_options m-1'>
          <div className='sub_title'>
            <h2 className='ml-0'>What If A Contact Responds?</h2>
            {
              openCampaignStatusModal && (
                <CampaignStatusModal
                open={openCampaignStatusModal}
                onClose={() => setOpenCampaignStatusModal(false)}
                campaignStatus={campaignStatus}
                campaignStatusStart={campaignStatusStart}
                startCampaign={startCampaign}
                />
              )
            }
          </div>
          <p>
          If a contact responds you can keep the campaign going, pause it, or remove the contact from the campaign.
          Select an option below.
          </p>
          <div style={{display:"flex",justifyContent:"center",alignItems:"center",gap:"10px",paddingBottom:"20px"}}> 
            <button
              onClick={(event, option) =>
                changeResponseOption(event, optionContinue)
              }
              className={classes.continueButton}
            >
             {responseOption === optionContinue && <CheckCircle/>}
              <span className={classes.continueText}>Continue</span>
            </button>
            <button
              onClick={(event, option) =>
                changeResponseOption(event, optionPause)
              }
              className={classes.pauseButton}
            >
            {responseOption === optionPause && <CheckCircle/>}
              
              <span className={classes.pauseText}>Pause</span>
            </button>
            <button
              onClick={(event, option) =>
                changeResponseOption(event, optionUnsub)
              }
              className={classes.removeButton}
              
            >
            {responseOption === optionUnsub && <CheckCircle style={{color:'#fff'}}/>}
             
             <span className={classes.removeText}>Remove Contact</span>
            </button>
          </div>
        </div>
      </div>

      {showActionList && (
        <CustomModalPype
          isShow={showActionList}
          hanldeModal={(status) => {
            setShowActionList(status);
          }}
          component={
            <ActionListDetails
              campaignId={campaignDetails?.basic?.id}
              campaignType={props.campaignDetails?.basic?.type}
              handleClose={(
                status,
                redirect = false,
                startCampaign = false
              ) => {
                if (redirect) {
                  if (startCampaign) {
                    startCampaignAfterCheckList();
                    setShowActionList(status);
                  } else {
                    setShowActionList(status);
                  }
                } else {
                  if (startCampaign) {
                    startCampaignAfterCheckList();
                    setShowActionList(status);
                  } else {
                    setShowActionList(status);
                  }
                }
              }}
              closeOnActionClick={(e, reason) => {
                setShowActionList(false);
              }}
              handleStartCampaign={(wait = true) => {
                if (wait) {
                  try {
                    setTimeout(() => {
                      setShowActionList(false);
                      window.showNotification(
                        "success",
                        "Please wait. Campaign is starting..."
                      );
                      startCampaignStatus();
                    }, 2000);
                  } catch (error) {}
                } else {
                  startCampaignStatus();
                  startCampaignAfterCheckList();
                }
              }}
              autoStart={true}
            />
          }
          blockBackdrop={true}
        />
      )}
    </div>
    );
};

const mapStateToProps = (state) => {
  return {
    campaignDetails: state.marketingReducer.campaignDetails,
    campaignLeadSourceDetails: state.marketingReducer.campaignLeadSourceDetails,
    campaignContactStat: state.marketingReducer.campaignContactStat,
    canStart: state.marketingReducer.canStart,
    canStartCode: state.marketingReducer.canStartCode,
    userInfo: state.marketingReducer.userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteCampaign: (params, callback) =>
      dispatch(deleteCampaign(params, callback)),
    campaignCheckToStart: (params, callback) =>
      dispatch(campaignCheckToStart(params, callback)),
    campaignStart: (params, callback) =>
      dispatch(campaignStart(params, callback)),
    campaignPause: (params, callback) =>
      dispatch(campaignPause(params, callback)),
    fetchCampaignDetails: (params, callback) =>
      dispatch(fetchCampaignDetails(params, callback)),
    updateCampaignDetailsAction: (params, callback) =>
      dispatch(updateCampaignDetailsAction(params)),
  };
};

const CampaignTopDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignDetails);

export default CampaignTopDetails;
