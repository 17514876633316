import {createOrUpdateCampaignFolderApi} from "../api/marketingApi";

const rootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/marketing`
const inboxUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/inbox`
const profileUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/profile`
const leadFlowUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/leadFlow`
const contactUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/contacts`
const containerUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/container`
const backEndBaseUrl = `${process.env.REACT_APP_JAVAEND_URL}/${process.env.REACT_APP_API_VERSION}`
const dealUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/deal`
const smartWebhookBackendV1 = `${process.env.REACT_APP_SMART_WEBHOOK_BACKEND_SERVER}/v1`

const Api = {
    campaigns: `${rootUrl}/campaigns`,
    campaignsLoadMore: `${rootUrl}/campaigns-loadmore`,
    addSingleContactToCampaign: `${rootUrl}/campaigns/add/single-contact`,
    campaignDetails: `${rootUrl}/details`,
    campaignTimeline: `${rootUrl}/campaign/timelines`,
    customFields: `${inboxUrl}/get-custom-fields`,
    updateCampaignDetails: `${rootUrl}/update-details`,
    campaignLeadSourceDetails: `${rootUrl}/get-campaign-leadsource-details`,
    getUserData: `${profileUrl}/get-user-data`,
    fetchLeadGroups: `${rootUrl}/get-lead-groups`,
    updateLeadGroups: `${rootUrl}/update-lead-groups`,
    fetchLeadFlows: `${rootUrl}/get-lead-flows`,
    updateLeadFlows: `${rootUrl}/update-lead-flows`,
    shareCampaign: `${rootUrl}/campaigns/share-link-with-friend`,
    shareCampaignLink: `${rootUrl}/campaigns/generate-share-link`,
    deleteCampaign: `${rootUrl}/campaign/delete`,

    getPreRecording: `${rootUrl}/voice-template`,
    storeCampaignStepSetting: `${rootUrl}/add/campaign-setting-step`,
    storeCampaignStepSettingPreTemp: `${rootUrl}/add/campaign-setting-step/pre-temp`,
    updateCampaignActivitySetting: `${rootUrl}/update-campaign-activity`,
    updateCampaignSetting: `${rootUrl}/update-campaign-settings`,
    updateCampaignStepSettingWithMultimedia: `${rootUrl}/upload-multimedia-file-edit`,
    updateCampaignStepSettingWithPreviousFile: `${rootUrl}/multimedia-update-previous-file`,
    updateCampaignStepSettingWithPreTemp: `${rootUrl}/edit/campaign-setting-step/pre-temp`,
    fetchStages: `${rootUrl}/fetch-stages`,
    fetchStage: `${rootUrl}/fetch-stage`,
    fetchPipelines: `${rootUrl}/fetch-pipelines`,
    getAllPipeLine: `${dealUrl}/select-all-pipeline-except`,
    fetchTags: `${rootUrl}/get-all-tags`,
    fetchTeamMembers: `${rootUrl}/get-all-team-members`,
    fetchCampaignsExceptCurrent: `${rootUrl}/get-all-campaign-except-current`,
    saveFollowup: `${rootUrl}/campaign/followup/save`,
    saveActionFollowup: `${rootUrl}/campaign/action-followup/save`,
    testEmailSend: `${rootUrl}/campaign/send-test-email`,
    testSMSSend: `${rootUrl}/campaign/send-test-sms`,
    saveActivityFollowup: `${rootUrl}/campaign/activity-followup/save`,
    fetchCampaignContactStat: `${rootUrl}/get-campaign-contact-stat`,
    fetchVideoFolders: `${rootUrl}/video/folders`,
    fetchVideos: `${rootUrl}/videos`,
    deleteVideo: `${rootUrl}/video/delete`,
    uploadVideo: `${rootUrl}/video/upload`,
    getMessageTemplate: `${rootUrl}/message-templates`,
    messageTemplateDelete: `${rootUrl}/message-template-delete`,
    getSingleMessageTemplate: `${rootUrl}/get-single-message-template`,
    updateMessageTemplate: `${rootUrl}/update-message-template`,
    addToBroadcast: `${rootUrl}/add-to-broadcast`,

    updateCampaignSmsStepSetting: `${rootUrl}/update-campaign-settings/sms/step`,
    updateCampaignEmailStepSetting: `${rootUrl}/update-campaign-settings/email/step`,
    updateCampaignVideoEmailStepSetting: `${rootUrl}/update-campaign-settings/video-email/step`,
    updateCampaignCallBridgeStepSetting: `${rootUrl}/update-campaign-settings/call-bridge/step`,

    deleteCampaignetting: `${rootUrl}/timeline-settings/delete`,

    getMailCategory: `${rootUrl}/get-card-basic-setting`,
    getMailTemplate: `${rootUrl}/get-card-templates`,
    createMailTemplate: `${rootUrl}/card-template-create`,
    producePDF: `${rootUrl}/generate-pdf`,
    addCardBroadcastToContact: `${rootUrl}/add-card-broadcast-to-contact`,
    getThirdPartyInfo: `${rootUrl}/get-third-party-info`,
    getCustomFields: `${rootUrl}/get-custom-fields`,

    // for lead source
    fetchLeadSource: `${rootUrl}/lead-sources`,

    fetchContactListForDataTable: `${rootUrl}/contacts/datatable`,
    contactMoveToCampaign: `${rootUrl}/contact/move/campaign`,

    // copy campaign
    copyCampaign: `${rootUrl}/campaign/copy`,
    campaignEnableToStart: `${rootUrl}/enable-to-start`,
    getCampaignBasicInfo: `${rootUrl}/get-campaign-basic-info`,
    campaignCheckToStart: `${rootUrl}/check-able-to-start`,
    campaignStart: `${rootUrl}/campaign/start`,
    campaignPause: `${rootUrl}/campaign/pause`,

    addQuickReplyTemplate: `${rootUrl}/msg-temp-add-submit`,
    createNewCampaign: `${rootUrl}/campaign/create`,

    userEmailList: `${rootUrl}/user-email-list`,
    userNumberList : `${rootUrl}/virtual-number-list`,

    // lead group
    updateOrCreateLeadGroup: `${profileUrl}/update-or-create-lead-group`,
    getTeamUsers: `${profileUrl}/get-team-users`,

    /* campagin templates */
    getCampaginTemplateCategory: `${rootUrl}/campaign/wizard/setup`,
    getCampaignTemplateByCategory: `${rootUrl}/agency/campaign/templates/list`,
    getCampaignTemplateDetails: `${rootUrl}/system/campaign/campaign-info`,
    createCampaignFromTemplate: `${rootUrl}/system/campaign-from-scratch`,

    getSmsTemplate: `${rootUrl}/get/sms/template`,

    createLeadFlow: `${leadFlowUrl}/create`,

    /* delete lead flow from campagin */
    deleteLeadFlowFromCampaign: `${rootUrl}/remove-lead-flow-from-campaign`,
    getCampaignStatsContact: `${rootUrl}/get-campaign-stats-contact-list`,
    deleteContactFromCampaign: `${rootUrl}/remove-single-contact-from-campaign`,
    getSingleContactTimeline: `${rootUrl}/single-contact-timeline`,

    getZipCodeByPlace: `${contactUrl}/get-google-map-resource`,

    /* campaign setting contact list api */
    getCampaignSettingContactList: `${backEndBaseUrl}/reports/campaign/contacts`,
    getCampaignSettingQueueContactList: `${backEndBaseUrl}/reports/campaign/contacts/queue`,

    /* campaign stats contact delete */
    deleteContactFromCampaignV2: `${contactUrl}/campaign/single-contact/unsubscribe`,


    /* campaign registration */
    submitCampaignRegistration: `${rootUrl}/campaign-use-case-save`,
    getMessagingService: `${containerUrl}/user/get-messaging-services`,
    createMessagingService: `${containerUrl}/user/create-messaging-services`,
    updateMessagingService: `${containerUrl}/user/update-user-messaging-service`,
    removeMessagingService: `${containerUrl}/user/remove-user-messaging-service`,
    addNumberInMessagingService: `${containerUrl}/user/add-number-in-messaging-services`,
    removeNumberFromMessagingService: `${containerUrl}/user/reomve-number-from-messaging-service`,
    createCampaignUseCase: `${containerUrl}/user/create-campaign-use-case`,
    getA2pBrandList: `${containerUrl}/user/get-a2p-brand-list`,

    /* campaign folders */
    getCampaignFolders: `${rootUrl}/get-campaign-folders`,
    createOrUpdateCampaignFolder: `${rootUrl}/createOrUpdate-campaign-folder`,
    moveCampaignToFolder: `${rootUrl}/move-campaign-to-folder`,
    deleteCampaignToFolder: `${rootUrl}/delete-campaign-folder`,
    getCampaignCheckList: `${rootUrl}/get-campaign-check-list`,

    updateStatusContinueAfterFailed: `${rootUrl}/update-status-continue-after-failed`,

    /* resent failed contact api */
    resentFailedContact: `${backEndBaseUrl}/fail-resends/settings/`,
    fetchFormList: `${rootUrl}/get-form-list`,

    /* todo check start validation */
    checkIsValidToStartCampaign: `${rootUrl}/is-valid-to-start-campaign`,

    getCollaboratorListDropdown: `${profileUrl}/get-team-users-without-template`,

    campaignFolderReorder: `${rootUrl}/campaign-folder-reorder`,
    getCampaignSettingInfos: `${rootUrl}/get-campaign-setting-infos`,
    updateCampaignSettingInfos:  `${rootUrl}/update-campaign-setting-infos`,

    //smart webhook
    editCollectionSmartWebhooks: `${smartWebhookBackendV1}/smartwebhooks/update`,
    collectionSmartWebhooks: `${smartWebhookBackendV1}/smartwebhooks/list`,
    collectionSmartWebhooksDetails: `${smartWebhookBackendV1}/smartwebhooks/details`,
    deleteCollectionSmartWebhooks: `${smartWebhookBackendV1}/smartwebhooks/delete`,
    cloneSmartWebhook: `${smartWebhookBackendV1}/smartwebhooks/clone`,
    testSmartWebhook: `${smartWebhookBackendV1}/smartwebhooks/test`
}

export default Api;