import ReactSelect from 'react-select';
import styled from 'styled-components';

const StyledSelect = styled(ReactSelect)(
    ({ theme, OptionsMaxHeight = 300, classNamePrefix, width, minWidth, maxWidth }) => ({
      [`& .${classNamePrefix}__control`]: {
        height:'38px',
        minWidth: minWidth || '200px',
        width: width || '100%',
        maxWidth: maxWidth || 'none',
        borderColor: '#e7e6e6',
        borderRadius: 8,
        boxShadow: 'none',
        cursor: 'pointer',
        backgroundColor: '#ffffff',
        '&:hover': {
          borderColor: '#e7e6e6',
          backgroundColor: '#0e0c0c0a',
        },
        [`& .${classNamePrefix}__input-container`]: {
          height: '38px',
          '& input': {
            height: '100%',
          },
        },
        [`& .${classNamePrefix}__value-container`]: {
            height: '38px', 
          },
        [`& .${classNamePrefix}__indicator-separator`]: {
          display: 'none',
        },
        [`& .${classNamePrefix}__indicator`]: {
          '& svg': {
            fill: '#000000',
          },
        },
        [`&.${classNamePrefix}__control--is-focused`]: {
          borderColor: '#e7e6e6',
        },
        [`&.${classNamePrefix}__control--menu-is-open`]: {
          [`& .${classNamePrefix}__indicator`]: {
            transform: 'rotate(180deg)',
          },
        },
        [`& .${classNamePrefix}__multi-value`]: {
          fontSize: '14px',
          [`& .${classNamePrefix}__multi-value__remove`]: {
            cursor: 'pointer',
          },
        },
        [`& .${classNamePrefix}__placeholder`]: {
          fontSize: '14px',
        },
        [`& .${classNamePrefix}__single-value`]: {
          '& span': {
            fontWeight: 'bold',
            fontSize: '15px',
          },
        },
      },
      [`& .${classNamePrefix}__menu`]: {
        zIndex: 9,
        minWidth: minWidth || '100px',
        width: width || '100%',
        maxWidth: maxWidth || 'none',
        marginTop: 0,
        [`& .${classNamePrefix}__menu-list`]: {
          maxHeight: OptionsMaxHeight,
          fontSize: '14px',
          [`& .${classNamePrefix}__option`]: {
            cursor: 'pointer',
            [`&.${classNamePrefix}__option--is-focused`]: {
              backgroundColor: '#6258ff1c',
            },
            [`&.${classNamePrefix}__option--is-selected`]: {
              color: 'initial',
              backgroundColor: '#6258ff1c',
            },
            '& span': {
              fontWeight: 'bold',
              fontSize: '15px',
            },
          },
        },
      },
      '&.rounded': {
        [`& .${classNamePrefix}__control`]: {
          [`& .${classNamePrefix}__multi-value`]: {
            borderRadius: '15px',
            overflow: 'hidden',
            lineHeight: 'unset',
          },
        },
      },
    })
  );
  export default StyledSelect;