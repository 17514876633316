import React from "react";
import SideBar from "./SideBar";
import Content from "./Content";
import { makeStyles } from "@material-ui/core";
import { handlePermissionDeniedModal } from "../../../../actions/quickRepliesAction";
import { useDispatch, useSelector } from "react-redux";
import PermissionDeniedModal from "../../../globals/Modal/PermissionDeniedModal";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(2),
  },
}));

const QuickRepliesContent = () => {
  const { permissionDeniedModal } = useSelector((state) => state.quickRepliesReducer);
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleClose = () => {
    dispatch(
      handlePermissionDeniedModal({
        openPermissionDeniedModal: false,
        permissionDeniedTitle: "",
        permissionDeniedAction: "",
      })
    );
  };
  return (
    <div className={classes.root}>
      <SideBar />
      <Content />
      {permissionDeniedModal.openPermissionDeniedModal && (
        <PermissionDeniedModal
          open={permissionDeniedModal.openPermissionDeniedModal}
          onClose={handleClose}
          permissionDeniedTitle={permissionDeniedModal.permissionDeniedTitle ||"quick reply"}
          permissionDeniedAction={permissionDeniedModal.permissionDeniedAction || "modify" }
        />
      )}
    </div>
  );
};

export default QuickRepliesContent;
