/* eslint-disable eqeqeq */
import { useState } from "react";
import BasicInput from "../../Common/BasicInput/BasicInput";
import { InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import styled from 'styled-components';

const ScrollableContainer = styled.div`
  max-height: 435px;
  overflow-y: auto;
  padding: 10px;  
  border: 1px solid #ccc;
  border-radius: 4px; 

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--dark_blue);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
`;
const BroadcastTag = (props) => {
  const [tagIds, setTagIds] = useState([]);
  const [search, setSearch] = useState("");

  const onChangeList = (isChecked, id) => {
    let idsArray = [...tagIds];
    if (isChecked) {
      idsArray.push(id);
    } else {
      idsArray = idsArray.filter((e) => e !== id);
    }
    setTagIds(idsArray);
    props.changeIds(idsArray);
  };

  const renderList = () => {
    let filterArr;
    if (search !== "") {
      filterArr = props.tags.filter(function (obj) {
        return obj.title.toUpperCase().indexOf(search.toUpperCase()) !== -1;
      });
    } else {
      filterArr = props.tags;
    }

    if (filterArr.length == 0) {
      return (
        <div>
          <p
            style={{
              color: "red",
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              padding: "50px",
            }}
          >
            {" "}
            No tag found !
          </p>
        </div>
      );
    }

    return filterArr.map((tag, index) => {
      return (
        <label key={index}>
          <input
            type="checkbox"
            onChange={(e) => onChangeList(e.target.checked, tag.value)}
            checked={tagIds.includes(tag.value)}
            className="filled-in"
          />
          <span className="campaingName">{tag.title}</span>
        </label>
      );
    });
  };

  return (
    <div>
      <div className="tabBody">
        <div
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            marginRight: "12px",
          }}
        >
          <BasicInput
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search Existing Tag"
            fullWidth
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon style={{ color: "#656565" }} />
              </InputAdornment>
            }
          />
        </div>
        <ScrollableContainer className="campaingData">
          {renderList()}
        </ScrollableContainer>
        {/* <TimeSetting user={userInfo} timeSettingValue={props.timeSettingValue}/>
                <div className="d-flex justify-content-start senderEmailWrapper"> 
                <span>Sender {props.fromText !== undefined ? props.fromText : 'Phone Number'}: </span>
                <Select className="globalFormControlItem" value={props.selectedFrom} onChange={(e) => props.onChangeFrom(e.target.value)}>
                    {
                        props.froms.map((elem,index) => {
                            return <MenuItem className="menuitem-li-hover-color-with-border" key={index} value={elem.id}>{elem.value}</MenuItem>
                        })
                    }
                </Select>
                </div> */}
        {/* <Select value={props.selectedFrom} onChange={(e) => props.onChangeFrom(e.target.value)}>
                    {
                        props.froms.map((elem,index) => {
                            return <MenuItem key={index} value={elem.id}>{elem.value}</MenuItem>
                        })
                    }
                </Select> */}
      </div>
    </div>
  );
};

export default BroadcastTag;
