import { all, takeEvery, call, put } from "redux-saga/effects";
import * as QUICK_REPLIES_API from "../api/quickRepliesApi";
import * as ACTION from "../constants/quickRepliesActionTypes";
import Utils from "../helpers/Utils";
import {
  addQuickRepliesFolderFailure,
  addQuickRepliesFolderSuccess,
  getQuickRepliesFolderSuccess,
  getQuickRepliesFolderFailure,
  updateQuickRepliesFolderSuccess,
  updateQuickRepliesFolderFailure,
  deleteQuickRepliesFolderSuccess,
  deleteQuickRepliesFolderFailure,
  getQuickRepliesSuccess,
  getQuickRepliesFailure,
  cloneQuickRepliesSuccess,
  cloneQuickRepliesFailure,
  deleteQuickRepliesSuccess,
  deleteQuickRepliesFailure,
} from "../actions/quickRepliesAction";

function* quickRepliesMiddlewareWatcher() {
  yield takeEvery(
    ACTION.ADD_QUICK_REPLY_FOLDER,
    addQuickRepliesFolderMiddleware
  );
  yield takeEvery(
    ACTION.GET_QUICK_REPLY_FOLDER,
    getQuickRepliesFolderMiddleware
  );
  yield takeEvery(
    ACTION.UPDATE_QUICK_REPLY_FOLDER,
    updateQuickRepliesFolderMiddleware
  );
  yield takeEvery(
    ACTION.DELETE_QUICK_REPLY_FOLDER,
    deleteQuickRepliesFolderMiddleware
  );
  yield takeEvery(
    ACTION.GET_QUICK_REPLY,
    getQuickRepliesMiddleware
  );
  yield takeEvery(
    ACTION.CLONE_QUICK_REPLY,
    cloneQuickRepliesMiddleware
  );
  yield takeEvery(
    ACTION.DELETE_QUICK_REPLY,
    deleteQuickRepliesMiddleware
  );
};

function* addQuickRepliesFolderMiddleware(action) {
  try {
    const response = yield call(
      QUICK_REPLIES_API.addQuickRepliesFolderApi,
      action.payload
    );
    if (response.success) {
      yield put(addQuickRepliesFolderSuccess(response.data));
      window.showNotification("SUCCESS", response.message);

      if (action.payload && action.payload.callback) {
        action.payload.callback();
      }
    } else {
      yield put(addQuickRepliesFolderFailure(response.message));
      window.showNotification("ERROR", response.message);
    }
  } catch (error) {
    yield put(addQuickRepliesFolderFailure(error.message));
    window.showNotification("ERROR", error.message);
    Utils.handleException(error);
  }
};

function* getQuickRepliesFolderMiddleware(action) {
  try {
    const response = yield call(
      QUICK_REPLIES_API.getQuickRepliesFolderApi,
      action.payload
    );
    if (response.success) {
      yield put(getQuickRepliesFolderSuccess(response.data));
    } else {
      yield put(getQuickRepliesFolderFailure(response.message));
      window.showNotification("ERROR", response.message);
    }
  } catch (error) {
    yield put(getQuickRepliesFolderFailure(error.message));
    window.showNotification("ERROR", error.message);
    Utils.handleException(error);
  }
};

function* updateQuickRepliesFolderMiddleware(action) {
  try {
    const response = yield call(
      QUICK_REPLIES_API.updateQuickRepliesFolderApi,
      action.payload
    );
    if (response.success) {
      yield put(updateQuickRepliesFolderSuccess(action.payload));
      window.showNotification("SUCCESS", response.message);

      if (action.payload && action.payload.callback) {
        action.payload.callback();
      }
    } else {
      yield put(updateQuickRepliesFolderFailure(response.message));
      window.showNotification("ERROR", response.message);
    }
  } catch (error) {
    yield put(updateQuickRepliesFolderFailure(error.message));
    window.showNotification("ERROR", error.message);
    Utils.handleException(error);
  }
};
function* deleteQuickRepliesFolderMiddleware(action) {
  try {
    const response = yield call(
      QUICK_REPLIES_API.deleteQuickRepliesFolderApi,
      action.payload
    );
    if (response.success) {
      yield put(deleteQuickRepliesFolderSuccess(action.payload));
      window.showNotification("SUCCESS", response.message);

      if (action.payload && action.payload.callback) {
        action.payload.callback();
      }
    } else {
      yield put(deleteQuickRepliesFolderFailure(response.message));
      window.showNotification("ERROR", response.message);
    }
  } catch (error) {
    yield put(deleteQuickRepliesFolderFailure(error.message));
    window.showNotification("ERROR", error.message);
    Utils.handleException(error);
  }
};

function* getQuickRepliesMiddleware(action) {
  try {
    const response = yield call(
      QUICK_REPLIES_API.getQuickRepliesApi,
      action.payload
    );
    if (response.success) {
      yield put(getQuickRepliesSuccess(response.data));
    } else {
      yield put(getQuickRepliesFailure(response.message));
      window.showNotification("ERROR", response.message);
    }
  } catch (error) {
    yield put(getQuickRepliesFailure(error.message));
    window.showNotification("ERROR", error.message);
    Utils.handleException(error);
  }
};

function* cloneQuickRepliesMiddleware(action) {
  try {
    const response = yield call(
      QUICK_REPLIES_API.cloneQuickRepliesApi,
      action.payload
    );
    if (response.success) {
      yield put(cloneQuickRepliesSuccess(response.data));
      window.showNotification("SUCCESS", response.message);
    } else {
      yield put(cloneQuickRepliesFailure(response.message));
      window.showNotification("ERROR", response.message);
    }
  } catch (error) {
    yield put(cloneQuickRepliesFailure(error.message));
    window.showNotification("ERROR", error.message);
    Utils.handleException(error);
  }
};

function* deleteQuickRepliesMiddleware(action) {
  try {
    const response = yield call(
      QUICK_REPLIES_API.deleteQuickRepliesApi,
      action.payload
    );
    if (response.success) {
      yield put(deleteQuickRepliesSuccess(action.payload));
      window.showNotification("SUCCESS", response.message);

      // if (action.payload && action.payload.callback) {
      //   action.payload.callback();
      // }
    } else {
      yield put(deleteQuickRepliesFailure(response.message));
      window.showNotification("ERROR", response.message);
    }
  } catch (error) {
    yield put(deleteQuickRepliesFailure(error.message));
    window.showNotification("ERROR", error.message);
    Utils.handleException(error);
  }
};


export default function* quickRepliesMiddleware() {
  yield all([quickRepliesMiddlewareWatcher()]);
}
