import { Box, Button, Dialog, IconButton, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import CheckIcon from "@material-ui/icons/Check";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { renderFolderIcon } from "../../../../helpers/RenderFolderIcon";
import {
  addQuickRepliesFolder,
  updateQuickRepliesFolder,
} from "../../../../actions/quickRepliesAction";
import BasicInput from "../../../Common/BasicInput/BasicInput";
import { AddorEditQuickRepliesStyles } from "./QuickRepliesStyle";

const folderIconList = [
  "FolderFilledIcon",
  "WebsiteIcon",
  "MonitorIcon",
  "MailIcon",
  "FilterIcon",
  "DollarIcon",
  "StarIcon",
  "LockIcon",
  "RadioIcon",
  "CopyIcon",
  "BrushOutlinedIcon",
];

const AddOrEditquickRepliesFolder = ({ open, onClose, edit = null }) => {
  const { isAddingQuickReplyFolder, isUpdatingQuickReplyFolder } = useSelector(
    (state) => state.quickRepliesReducer
  );
  const {
    titleHeader,
    iconsButton,
    checkIconColor,
    outerIconColor,
    innerIconColor,
    errorText,
    selectedFolderIcon,
    errorStyle,
    saveButton,
  } = AddorEditQuickRepliesStyles();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    title: edit?.title || null,
    folder_color: edit?.folder_color || "#346fef",
    folder_icon: edit?.folder_icon || folderIconList[0],
  });
  const [error, setError] = useState({
    title: "",
    folder_color: "",
    folder_icon: "",
  });
  const isFormDataChanged =
    formData.title !== edit?.title ||
    formData.folder_color !== edit?.folder_color ||
    formData.folder_icon !== edit?.folder_icon;

  const validationForm = () => {
    const errors = {};

    if (!formData.title?.trim()) {
      errors.title = "Folder title is required.";
    } else if (formData.title.length > 100) {
      errors.title = "Folder title must not exceed 100 characters.";
    }

    setError(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = () => {
    if (
      !validationForm() ||
      isAddingQuickReplyFolder ||
      isUpdatingQuickReplyFolder
    ) {
      return;
    }

    if (edit) {
      formData.id = edit.id;
      dispatch(
        updateQuickRepliesFolder({
          ...formData,
          callback: () => {
            onClose();
          },
        })
      );
    } else {
      dispatch(
        addQuickRepliesFolder({
          ...formData,
          callback: () => {
            onClose();
          },
        })
      );
    }
  };
  const handleFolderName = (e) => {
    setError({ ...error, title: "" });
    setFormData({ ...formData, title: e.target.value });
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="newfolder-dialog-title"
      aria-describedby="newfolder-dialog-description"
    >
      <Box minWidth={600} minHeight={300}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          padding={2}
          borderBottom={"1px solid #d3d3d3"}
        >
          <Typography className={titleHeader}>
            {edit ? "Edit Quick Replies Folder" : "Add Quick Replies Folder"}
          </Typography>
          <Box
            onClick={onClose}
            border={"1px solid #d3d3d3"}
            display={"flex"}
            padding={"3px"}
            borderRadius={"25%"}
            sx={{ cursor: "pointer" }}
          >
            <Close />
          </Box>
        </Box>
        <Box padding={2}>
          <Box>
            <Typography className={`${error.title ? errorText : ""}`}>
              Category Name<span style={{ color: "var(--red)" }}>*</span>
            </Typography>
            <Box mt={1}>
              <BasicInput
                fullWidth
                className={`${error.title ? errorStyle : ""}`}
                value={formData.title}
                onChange={handleFolderName}
              />
              {error.title && (
                <Typography variant="body2" className={errorText}>
                  {error.title}
                </Typography>
              )}
            </Box>
          </Box>
          <Box paddingTop={2}>
            <Typography>Category Icon</Typography>
            <Box
              display={"flex"}
              mt={1}
              justifyContent={"space-around"}
              sx={{ background: "rgba(148, 157, 178, 0.12)" }}
              borderRadius={"8px"}
              padding={"8px"}
            >
              {folderIconList.map((icon, i) => (
                <IconButton
                  key={i}
                  className={`${iconsButton} ${
                    formData.folder_icon === icon ? selectedFolderIcon : ""
                  }`}
                  onClick={() =>
                    setFormData({ ...formData, folder_icon: icon })
                  }
                >
                  {renderFolderIcon(icon, "gray")}
                </IconButton>
              ))}
            </Box>
          </Box>
          <Box pt={2}>
            <Typography>Icon Color</Typography>
            <Box mt={1} display={"flex"} gridGap={8}>
              <Box
                className={outerIconColor}
                sx={{ background: " #346fef" }}
                onClick={() =>
                  setFormData({ ...formData, folder_color: "#346fef" })
                }
              >
                {formData.folder_color === "#346fef" && (
                  <Box
                    sx={{ background: " #346fef" }}
                    className={innerIconColor}
                  >
                    <CheckIcon className={checkIconColor} fontWeight={600} />
                  </Box>
                )}
              </Box>
              <Box
                className={outerIconColor}
                sx={{ background: "#924cff" }}
                onClick={() =>
                  setFormData({ ...formData, folder_color: "#924cff" })
                }
              >
                {formData.folder_color === "#924cff" && (
                  <Box
                    className={innerIconColor}
                    sx={{ background: " #924cff" }}
                  >
                    <CheckIcon className={checkIconColor} fontWeight={600} />
                  </Box>
                )}
              </Box>
              <Box
                className={outerIconColor}
                sx={{ background: " #008951" }}
                onClick={() =>
                  setFormData({ ...formData, folder_color: "#008951" })
                }
              >
                {formData.folder_color === "#008951" && (
                  <Box
                    className={innerIconColor}
                    sx={{ background: " #008951" }}
                  >
                    <CheckIcon className={checkIconColor} fontWeight={600} />
                  </Box>
                )}
              </Box>
              <Box
                className={outerIconColor}
                sx={{ background: " #f79009" }}
                onClick={() =>
                  setFormData({ ...formData, folder_color: "#f79009" })
                }
              >
                {formData.folder_color === "#f79009" && (
                  <Box
                    className={innerIconColor}
                    sx={{ background: " #f79009" }}
                  >
                    <CheckIcon className={checkIconColor} fontWeight={600} />
                  </Box>
                )}
              </Box>
              <Box
                className={outerIconColor}
                sx={{ background: "#00bec5" }}
                onClick={() =>
                  setFormData({ ...formData, folder_color: "#00bec5" })
                }
              >
                {formData.folder_color === "#00bec5" && (
                  <Box
                    className={innerIconColor}
                    sx={{ background: "#00bec5" }}
                  >
                    <CheckIcon className={checkIconColor} fontWeight={600} />
                  </Box>
                )}
              </Box>
              <Box
                className={outerIconColor}
                sx={{ background: "#1d2939" }}
                onClick={() =>
                  setFormData({ ...formData, folder_color: "#1d2939" })
                }
              >
                {formData.folder_color === "#1d2939" && (
                  <Box
                    className={innerIconColor}
                    sx={{ background: "#1d2939" }}
                  >
                    <CheckIcon className={checkIconColor} fontWeight={600} />
                  </Box>
                )}
              </Box>
              <Box
                className={outerIconColor}
                sx={{ background: "#475467" }}
                onClick={() =>
                  setFormData({ ...formData, folder_color: "#475467" })
                }
              >
                {formData.folder_color === "#475467" && (
                  <Box
                    className={innerIconColor}
                    sx={{ background: "#475467" }}
                  >
                    <CheckIcon className={checkIconColor} fontWeight={600} />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          padding={2}
          borderTop={"1px solid #d3d3d3"}
          display={"flex"}
          justifyContent={"end"}
          fullWidth
        >
          <Box display={"flex"} gridGap={8}>
            <Button onClick={onClose} variant="contained" disableElevation>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              className={saveButton}
              variant="contained"
              disableElevation
              disabled={
                (edit && !isFormDataChanged) ||
                isAddingQuickReplyFolder ||
                isUpdatingQuickReplyFolder
              }
            >
              {edit
                ? isUpdatingQuickReplyFolder
                  ? "Updating Folder"
                  : "Update Folder"
                : isAddingQuickReplyFolder
                ? "Saving Folder"
                : "Save Folder"}
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AddOrEditquickRepliesFolder;
