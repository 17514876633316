/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import {connect} from "react-redux";
import '../single-contact.css'
import { addCampaignStepSetting, addCampaignStepSettingPreTemp, fetchCampaignTimelines, fetchPreRecording, updateArrSlotTimeHour1, updateArrSlotTimeMin1, updateCampaignSettingWithMultimedia, updateCampaignSettingWithPreTemp, updateCampaignSettingWithPreviousFile, updateDay, updateGreetingOption, updateGreetingsOptionTimeHour1, updateGreetingsOptionTimeMin1, updateHour, updateLaterHour, updateLaterMin, updateMin, updateRecurringDay, updateRecurringTimeHour1, updateRecurringTimeMin1, updateStartDate, updateTabValue, updateTime } from "../../../actions/marketingAction";
// import PreRecordingList from "./PreRecordingList";
import RecordRTC, { StereoAudioRecorder } from 'recordrtc';
import ScheduleSetting from "./ScheduleSetting";
import CoreConstants from "../../../constants/CoreConstants";
import moment from "moment";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CustomAudioPlayer from "../../globals/CustomAudioPlayer";
import GlobalModal from "../../globals/Modal/GlobalModal";
import QucikReplyCard from "../../QuickReplyCard";
import { getMessageTemplate } from "../../../api/marketingApi";
import CustomCircleLoader from "../../marketing-list-v2/components/CustomCircleLoader";

let recordAudio;
let videoStream;
var totalSeconds = 0;
var audio_record_interval = ''

const App = props => {
 
    const fileInputRef = useRef(null)
    const bottomRef = useRef(null)
    const [bottomScroll, setBottomScroll] = useState(false)

    const [campaignDetails, setCampaignDetails] = useState(props.campaignDetails);
    const [title, setTitle] = useState('')
    // const [preRecording, setPreRecording] = useState([{}, {}, {}, {}, {}, {}, {}])
    const [videoUrl, setVideoUrl] = useState('')
    const [audioFile, setAudioFile] = useState(null)

    const [audioTimer, setAudioTimer] = useState(0)
    const [audioInterval, setAudioInterval] = useState('')

    // eslint-disable-next-line no-unused-vars
    const [showAllTimeSetting, setShowAllTimeSetting] = useState(props.showAllTimeSettings);

    const [audioFileUpload, setAudioFileUpload] = useState(false)
    const [selectPreRecording, setSelectPreRecording] = useState(false)
    const [selectPreRecordingFile, setSelectPreRecordingFile] = useState('')
    const [tabValue, setTabValue] = useState(props.tabValue);
    const [hour, setHour] = useState(props.hour);
    const [min, setMin] = useState(props.min);
    const [day, setDay] = useState(props.day);
    const [time, setTime] = useState(props.time);
    const [startDate, setStartDate] = useState(props.startDate);
    const [greetingOption, setGreetingOption] = useState(props.greetingOption);

    // for update
    const [updateSetting, setUpdateSetting] = useState(false)
    const [currentAudioFile, setCurrentAudioFile] = useState('')
    const [editableCampaignSettingId, setEditableCampaignSettingId] = useState(null)

    const [activePreviousFile, setActivePreviousFile] = useState(false)

    const [savingToServer, setSavingToServer] = useState(false)

    //! For Quick Reply
    const [isShowQuickReplies, setIsShowQuickReplies] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [loadingTemplate, setLoadingTemplate] = useState(false);
    const [needToloadMoreTemplate, setNeedToloadMoreTemplate] = useState(false);
    const [loadingMoreTemplate, setLoadingMoreTemplate] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);


    /* use effect start */
    useEffect(() => {
        if(props.currentClip != null){
            setUpdateSetting(true)
            setCurrentAudioFile(props.currentClip.file_path)
            setEditableCampaignSettingId(props.currentClip.id)
            setActivePreviousFile(true)
            setTitle(props.currentClip.voice_title)
        }
        else{
            setActivePreviousFile(false)
        }
    }, [updateSetting]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        props.fetchPreRecording()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // useEffect(() => {
    //     setPreRecording(props.preRecording)
    // }, [props.preRecording]);

    useEffect(() => {
        setCampaignDetails(props.campaignDetails);
    }, [props.campaignDetails]);

    /* use effect end */


    /* recording function */
    const openMic = (e) => {
        e.preventDefault();
        if(audio_record_interval != ''){
            return
        }
        let hasGetUserMedia = '';
        try {
            // eslint-disable-next-line no-unused-vars
            hasGetUserMedia = !!(navigator.mediaDevices || navigator.mediaDevices.getUserMedia({audio: true}) || navigator.webkitGetUserMedia ||
                navigator.mozGetUserMedia || navigator.msGetUserMedia);
        } catch (e) {
            return 0;
        }
        requestUserMedia();
    }

    const requestUserMedia = () => {
        if(audioInterval != '' && audio_record_interval != ''){
          window.showNotification("WARNING", "A record is in progress !")
            return false
        }
        videoStream=null;
        navigator.mediaDevices.getUserMedia({audio: true})
            .then(function (mediaStream) {

                let audio = document.getElementById('record-audio');
                    audio.srcObject=mediaStream;
                    videoStream = mediaStream;
                    audio.onloadedmetadata = function(e) {
                        audio.play();
                    };
                    
                audio_record_interval = setInterval(() => {
                    if(totalSeconds == 179){
                        stopRecord()
                    }
                    else{
                        setAudioTimer(prevTime => prevTime + 1)
                        totalSeconds = totalSeconds + 1
                    }
                }, 1000);
                setAudioInterval(audio_record_interval)                
                setAudioFileUpload(false)
                setVideoUrl('')
                setActivePreviousFile(false)
                startRecord()
            })
            .catch(function (err) {
                return 0;
            });
    }

    const startRecord = () => {
        totalSeconds = 0;
        recordAudio = RecordRTC(videoStream, {type: 'audio/webm', mimeType: 'audio/webm', recorderType: StereoAudioRecorder, audioBitsPerSecond: 128000});
        recordAudio.startRecording();
    }

    const stopRecord = () => {
        if(audio_record_interval == ''){
            return
        }
        if (totalSeconds < 5) {
          window.showNotification("WARNING", "Audio duration must be between 5 seconds to 3 minutes")
            return false;
        }
        recordAudio.stopRecording(() => {
            let urlCreator = window.URL || window.webkitURL;
            let videoUrl = urlCreator.createObjectURL(recordAudio.blob);
            videoStream.getTracks().forEach(track => track.stop());

            let mimeType = 'audio/webm';
            let blob = recordAudio instanceof Blob ? recordAudio : recordAudio.getBlob();
            blob = new File([blob], 'RecordRTC-audio.webm', {
                type: mimeType
            });

            setAudioFile(blob)

            setVideoUrl(videoUrl)

            return false;
        });

        clearInterval(audioInterval)
        clearInterval(audio_record_interval)
        audio_record_interval=''
        setAudioInterval('')
        setAudioTimer(0)
        totalSeconds=0
    }

    const calculateTime = () => {
        let seconds = "00",
            minutes = "00";

        seconds = pad(audioTimer % 60)
        minutes = pad(parseInt(audioTimer / 60))

        return minutes + ":" + seconds;
    }

    const pad = (val) => {
        var valString = val.toString();
        if (valString.length < 2) {
            return "0" + valString;
        } else {
            return valString;
        }
    }

    /* input fields */
    const handleTitleInputField = (element) => {
        setTitle(element.target.value)
    }

    /* upload file in server */
    const hanldeSubmitButton = () => {
        if(savingToServer){
            return false
        }
        // check validation
        if(!updateSetting && videoUrl == ''){
          window.showNotification("WARNING", "No audio selected!")
            return false
        }
        setSavingToServer(true)
        let type;
        switch (tabValue) {
            case 1:
            case 2:
                type = CoreConstants.SENDING_TYPE_SCHEDULE;
                break;
            case 0:
                type = CoreConstants.SENDING_TYPE_IMMEDIATE;
                break;
            default:
                type = tabValue;
            break;
        }

        let formData = new FormData();
        formData.append('message_type', CoreConstants.MESSAGE_TYPE_VOICE);
        formData.append('nScheduleHours', hour);
        formData.append('nScheduleMinutes', min);
        formData.append('nScheduleDays', day);
        formData.append('followupScheduleTime', time);
        formData.append('campaign_id', campaignDetails.basic.id);
        formData.append('type', type);
        formData.append('day_type', (tabValue != 0 ? tabValue : 1));
        formData.append('date', moment(startDate).format('YYYY-MM-DD'));
        formData.append('greeting_option', greetingOption);

        formData.append('voice_title', title);
        formData.append('title', title);

        if(!activePreviousFile){
            if(updateSetting){
                if(selectPreRecording){
                    formData.append('qqfile', selectPreRecordingFile);
                    formData.append('file', selectPreRecordingFile);
                }
                else{
                    if(audioFileUpload){
                        formData.append('qqfile', document.getElementById('audio-file-upload').files[0]);
                    }
                    else{
                        formData.append('qqfile', audioFile);
                        formData.append('file_recording_for_update', true);
                    }
                }
            }
            else{
                if(selectPreRecording){
                    formData.append('file', selectPreRecordingFile);
                }
                else{
                    if(audioFileUpload){
                        formData.append('file', document.getElementById('audio-file-upload').files[0]);
                    }
                    else{
                        formData.append('file', audioFile);
                    }
                }
            }            
        }

        if(updateSetting){
            formData.append('campaign_settings_id', editableCampaignSettingId);
            if(!activePreviousFile){
                if(selectPreRecording){
                    props.updateCampaignSettingWithPreTemp(formData, () => {
                        props.fetchCampaignTimelines({
                            campaignId: campaignDetails.basic.id
                        });
                        props.setOpenVoiceMailModal(false)
                        props.handleRemoveCurrentClip()
                        setSavingToServer(false)
                    })
                }
                else{
                    props.updateCampaignSettingWithMultimedia(formData, (response) => {
                        if(response.status == "error"){
                            window.showNotification("ERROR", response.html)
                            setSavingToServer(false)
                        }
                        else{
                            props.fetchCampaignTimelines({
                                campaignId: campaignDetails.basic.id
                            });
                            props.setOpenVoiceMailModal(false)
                            props.handleRemoveCurrentClip()
                            setSavingToServer(false)
                        }
                    })
                }
            }
            else{
                props.updateCampaignSettingWithPreviousFile(formData, () => {
                    props.fetchCampaignTimelines({
                        campaignId: campaignDetails.basic.id
                    });
                    props.setOpenVoiceMailModal(false)
                    props.handleRemoveCurrentClip()
                    setSavingToServer(false)
                })
            }
        }
        else{
            if(selectPreRecording){
                props.addCampaignStepSettingPreTemp(formData, (response) => {
                    props.fetchCampaignTimelines({
                        campaignId: campaignDetails.basic.id
                    });
                    props.setOpenVoiceMailModal(false)
                    setSavingToServer(false)
                })
            }
            else{    
                props.addCampaignStepSetting(formData, (response) => {
                    if(response.status == "error"){
                        window.showNotification("ERROR", response.html)
                        setSavingToServer(false)
                    }
                    else{
                        props.fetchCampaignTimelines({
                            campaignId: campaignDetails.basic.id
                        });
                        props.setOpenVoiceMailModal(false)
                        setSavingToServer(false)
                    }
                })
            }
        }
        
    }

    const uploadFile = async (event) => {
        if(event.target.files.length > 0){
            let typeSplit = event.target.files[0].type.split('/')
            let type = typeSplit[0]
            if(type != 'audio' && type != 'video'){
              window.showNotification("ERROR", "File type not match. Upload only audio type")
                return;
            }else{
                setAudioFileUpload(true)
                if (!document.getElementById('audio-file-upload').files.length) return;
                let urlCreator = window.URL || window.webkitURL;
                const urlObj = urlCreator.createObjectURL(document.getElementById('audio-file-upload').files[0]);
                setVideoUrl(urlObj)
                setActivePreviousFile(false)
            }
        }
        else{
            setVideoUrl('')
            setAudioFileUpload(false)
            setActivePreviousFile(true)
        }
    }

    // const handlePreRecordSelect = (data) => {
    //     setSelectPreRecording(true)
    //     setSelectPreRecordingFile(data.file)
    //     setVideoUrl(data.file)
    //     setActivePreviousFile(false)
    // }

    // const closeModal = () => {
    //     props.setOpenVoiceMailModal(false)
    //     props.handleRemoveCurrentClip()
    // }

    useEffect(() => {
        setTabValue(props.tabValue);
        setHour(props.hour);
        setMin(props.min);
        setDay(props.day);
        setTime(props.time);
        setStartDate(props.startDate);
        setGreetingOption(props.greetingOption);
    }, [
        props.tabValue, props.hour, props.min, props.day, props.recurringDay, props.laterHour, props.laterMin, props.time, props.startDate, props.arrSlotTimeHour1, props.arrSlotTimeMin1, props.greetingOption, props.greetingsOptionTimeMin1, props.greetingsOptionTimeHour1, props.recurringTimeHour1, props.recurringTimeMin1,
    ]);

    const scrollToBottom = () => {
        if(!bottomScroll){
            bottomRef.current.scrollIntoView({behavior: "smooth"})
            setBottomScroll(true)
        }
    }

    const getAllTemplates = (params) => {
        /* 
        page: 0
        perPage: 20
        search_key: ""
        type: ["1", "2", "3", "4", "5", "6", "7", "8", "9"]
        
        */
        getMessageTemplate(params)
        .then(res => {
            let response = res.data;
            if(response.status === 'success'){
                if(response.html.length >= 20){
                    setNeedToloadMoreTemplate(true)
                    setCurrentPage(currentPage + 1)
                }else{
                    setNeedToloadMoreTemplate(false)
                }
                setTemplates([...templates, ...response.html])
                setLoadingTemplate(false)
                setLoadingMoreTemplate(false)
            }
            else{
                setLoadingTemplate(false)
                setLoadingMoreTemplate(false)
                setNeedToloadMoreTemplate(false)
            }
        }).catch(err => {
            setLoadingTemplate(false)
            setLoadingMoreTemplate(false)
            setNeedToloadMoreTemplate(false)
        })
    }

    const handleOnScroll = (e) => {
        if(
            needToloadMoreTemplate && 
            !loadingMoreTemplate && 
            Math.round(e.target.scrollTop + e.target.clientHeight, 10) >= Math.round(e.target.scrollHeight, 10)
        ) {
            setLoadingMoreTemplate(true)
            getAllTemplates({ 
                page: currentPage,
                perPage: 20,
                search_key: "",
                type: [CoreConstants.MESSAGE_TEMPLATE_VOICE]
            })
        }
    }

    /* for quick replies */
    const handleQuickReplyView = () => {
        setIsShowQuickReplies(true);
        if(templates.length <= 0){
            if(loadingTemplate){
                return false;
            }
            setLoadingTemplate(true)
            getAllTemplates({ 
                page: 0,
                perPage: 20,
                search_key: "",
                type: [CoreConstants.MESSAGE_TEMPLATE_VOICE]
            })
        }
        
    }
    
    const renderMain = () => {
        return(
            <>
                <div className="p-4">
                    <div className="row">
                        <div className="col s6 d-flex form_input">
                            <h5 className="text-dark-blue mr-3">Title:</h5>
                            <input className="modal_bordered_text_field radius-5 ml-3" type="text" placeholder="Enter Voice Title" onChange={handleTitleInputField} value={title}/>
                        </div>
                    </div>
                    <div className="bg-light-blue-10 mt-3 p-3 radius-10">
                        <h6 className="text-dark-blue m-0 text-left">Record/Upload a new audio file</h6>
                        <div className="row mt-3">
                            <div className="col s12 m6 l6 xl6">
                                <div
                                    className="d-flex justify-content-center align-items-center">
                                    <div className="voice_mail_icon" onClick={openMic} style={audio_record_interval != '' ? {opacity: 0.5} : {}}>
                                        <span
                                            className="bg-light-blue-10 circle d-flex justify-content-center align-items-center">
                                            <i className="material-icons">
                                                mic_none
                                            </i>
                                        </span>
                                        <p className="text-gray">Record</p>
                                    </div>
                                    <div className="voice_mail_icon ml-5" onClick={stopRecord} style={audio_record_interval == '' ? {opacity: 0.5} : {}}>
                                        <span
                                            className="bg-light-blue-10 circle d-flex justify-content-center align-items-center">
                                            <svg width="22" height="22" viewBox="0 0 22 22"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M13.7547 7.99414H8.25913C8.12756 7.99414 8.01953 8.09732 8.01953 8.23097V13.7861C8.01953 13.917 8.12687 14.0243 8.25913 14.0243H13.7547C13.8863 14.0243 13.9929 13.9177 13.9929 13.7861V8.23097C13.9922 8.09801 13.8863 7.99414 13.7547 7.99414Z"
                                                    fill="#FF264A" />
                                                <path
                                                    d="M11.0061 0.592163C5.2606 0.592163 0.601562 5.24981 0.601562 10.9967C0.601562 16.7437 5.2606 21.4013 11.0061 21.4013C16.7524 21.4013 21.4093 16.7437 21.4093 10.9967C21.4093 5.24981 16.7524 0.592163 11.0061 0.592163ZM11.0061 19.6632C6.21969 19.6632 2.33901 15.7825 2.33901 10.9967C2.33901 6.2089 6.21969 2.32891 11.0061 2.32891C15.7933 2.32891 19.6719 6.2089 19.6719 10.9967C19.6719 15.7825 15.7933 19.6632 11.0061 19.6632Z"
                                                    fill="#FF264A" />
                                            </svg>
                                        </span>
                                        <p className="text-gray">Stop</p>
                                    </div>
                                    <div className="voice_mail_icon ml-5 cursor-pointer" style={audio_record_interval != '' ? {opacity: 0.5} : {}}>
                                        <label
                                            className="bg-light-blue-10 circle d-flex justify-content-center align-items-center">
                                            <input disabled={audio_record_interval == '' ? false : true} ref={fileInputRef} type="file"
                                                   className="d-none"  id="audio-file-upload" accept="audio/mp3, video/webm" onChange={uploadFile} />
                                            <svg width="27" height="22" viewBox="0 0 27 22"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4.77493 7.41407C5.98568 2.59081 10.8771 -0.337687 15.7004 0.873064C19.4697 1.81927 22.2066 5.07698 22.4886 8.95291C25.1649 9.39426 26.9767 11.9216 26.5354 14.5979C26.143 16.9772 24.0814 18.7197 21.67 18.7101H17.5772V17.073H21.67C23.4783 17.073 24.9442 15.6071 24.9442 13.7988C24.9442 11.9905 23.4783 10.5245 21.67 10.5245C21.2179 10.5245 20.8514 10.1581 20.8514 9.70598C20.8555 5.63728 17.5605 2.33562 13.4918 2.33158C9.96971 2.32805 6.93782 4.81801 6.25653 8.2735C6.18925 8.61853 5.90925 8.8821 5.56075 8.92835C3.32291 9.24703 1.76719 11.3195 2.08581 13.5573C2.37195 15.5667 4.08765 17.0629 6.11737 17.073H9.39161V18.7101H6.11737C2.95282 18.7006 0.395231 16.1274 0.40485 12.9629C0.41283 10.3286 2.21591 8.03919 4.77493 7.41407Z"
                                                    fill="#3C7EF3" />
                                                <path
                                                    d="M14.0656 10.7619L17.3398 14.0361L16.1857 15.1903L14.303 13.3158V21.1657H12.6659V13.3158L10.7914 15.1903L9.6372 14.0361L12.9114 10.7619C13.2307 10.4445 13.7463 10.4445 14.0656 10.7619Z"
                                                    fill="#3C7EF3" />
                                            </svg>
                                        </label>
                                        <p className="text-gray">Upload</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-start mt-2">
                                    <i className="material-icons text-red mr-2">
                                        error_outline
                                    </i>
                                    <p className="text-red text-left m-0">Audio duration must be between 5 seconds to 3 minutes</p>
                                </div>
                            </div>
                            <div className="col s12 m6 l6 xl6">
                                <div className="d-flex align-items-center record-audio-files-container" style={(audioTimer.toString() != '0' || currentAudioFile != '' || videoUrl != '') ? {} : {backgroundColor: 'transparent'}}>
                                    <div className="audio-setup">
                                        <audio controls='controls' id='record-audio' muted className="hide_audio_file"/>
                                        {audioTimer.toString() != '0' &&
                                            <div className="recorder_timer">
                                                <span className="voice-record-countdown">{calculateTime(audioTimer)}</span>
                                            </div>
                                            
                                        }
                                        {currentAudioFile != '' &&
                                        <div className="current-audio-file-clip">
                                            <span>Current Clip</span>
                                            <audio controls="controls" src={currentAudioFile} style={{display: 'none'}} />
                                            <CustomAudioPlayer 
                                                hanldePlayer={(activePlayerId) => {}}
                                                activePlayerId={'currentAudioFile_campaign_setting'}
                                                unique_key={'currentAudioFile_campaign_setting'}
                                                src={currentAudioFile}
                                            />
                                        </div>
                                        }
                                        {videoUrl != '' &&
                                        <div className={`recorded-audio-file-clip ${videoUrl == '' ? 'd-none' : ''}`}>
                                            <span>Recording</span>
                                            {/* <audio controls="controls" src={videoUrl} className={`${videoUrl == '' ? 'd-none' : '' }`} /> */}
                                            <CustomAudioPlayer 
                                                hanldePlayer={(activePlayerId) => {}}
                                                activePlayerId={'videoUrl_campaign_setting'}
                                                unique_key={'videoUrl_campaign_setting'}
                                                src={videoUrl}
                                            />
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col m5 s12 ">

                            <div className="pl-1">
                                    <div className="quick-reply-old quick_reply_hanlde_button" onClick={handleQuickReplyView}>
                                        <svg width="10" height="15" viewBox="0 0 10 15" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0H10V15L5 11.9118L0 15V0Z" fill="#133159"></path>
                                        </svg>
                                        Use Quick Reply
                                    </div>
                                </div>
                            {/* <div className="input-field pre_rec selects_fields pre_rec_input_field sms-quick-replies-template">
                                <PreRecordingList from="voice-add-modal" title="Pre Recorded" preRecording={preRecording} handlePreRecordSelect={handlePreRecordSelect}/>
                            </div> */}
                        </div>
                    </div>
                    <div className="voice_mail_sending_tab">
                        <ScheduleSetting showAllTimeSetting={showAllTimeSetting} tabChangeCallback={scrollToBottom} />
                    </div>
                    <div ref={bottomRef}></div>
                </div>
            </>
        )
    }

    const showTemplates = () => {
        let showTemplates = []
        templates.forEach((item, index) => {
            showTemplates.push(
                <QucikReplyCard
                    data={item} 
                    key={index}
                    index={index}
                    onSelect={(item) => {
                        setIsShowQuickReplies(false);
                        setSelectPreRecording(true)
                        let file_url = ''
                        if(item.template_files === null){
                            file_url = item.video_file_url;
                        }
                        else{
                            file_url = item.template_files?.file
                        }
                        setSelectPreRecordingFile(file_url)
                        setVideoUrl(file_url)
                        setActivePreviousFile(false)
                    }}
                    className={'main-content-from-quick-reply'}
                />
            )
        })
        return showTemplates;
    }

    const renderTemplate = () => {
        return(
            <div className="awesome__scroll_bar campaign_setting_template_container" onScroll={handleOnScroll} id="quick__reply_list__main__container_settings">
                {loadingTemplate ? 
                    <div className="loading_content">Please wait...</div>
                    :
                    <div className="campaign_setting_template_body">
                        {templates.length > 0 ? 
                            <div className="templates___body">
                                <div className="templates___body__back_button" onClick={() => setIsShowQuickReplies(false)}>{'<< Back'}</div>
                                {showTemplates()}
                            </div>
                            :
                            <div className="empty_template_body">No Voice templated added</div>
                        }
                    </div>
                }
            </div>
        )
    }
    
    const renderView = () => {
        if(isShowQuickReplies){
            return renderTemplate()
        }
        return renderMain()
    }

    return (
        <GlobalModal 
            open={props.open} 
            onClose={props.setOpenVoiceMailModal}
            title="Voice Mail Followup" 
            onSubmit={hanldeSubmitButton}
            buttonText={savingToServer ? "Processing followup" : (updateSetting ? 'Save' : 'Save')}
            buttonIcon={
                // savingToServer ? <MoreHorizIcon /> : 
                savingToServer ? <CustomCircleLoader fill="#fff" size="extra-small"/> : 
                <i className="material-icons p-1 mr-2">
                    mail
                </i>
            }
            className="global-large-modal campaign__setting__email alt_sent_icon_desing"
        >

            {renderView()}
            
        </GlobalModal>
    );
}

const mapStateToProps = state => {
    return {
        campaignDetails: state.marketingReducer.campaignDetails,
        preRecording: state.marketingReducer.preRecording,

        tabValue: state.marketingReducer.tabValue,
        hour: state.marketingReducer.hour,
        min: state.marketingReducer.min,
        day: state.marketingReducer.day,
        recurringDay: state.marketingReducer.recurringDay,
        laterHour: state.marketingReducer.laterHour,
        laterMin: state.marketingReducer.laterMin,
        time: state.marketingReducer.time,
        startDate: state.marketingReducer.startDate,
        arrSlotTimeHour1: state.marketingReducer.arrSlotTimeHour1,
        arrSlotTimeMin1: state.marketingReducer.arrSlotTimeMin1,
        greetingOption: state.marketingReducer.greetingOption,
        greetingsOptionTimeMin1: state.marketingReducer.greetingsOptionTimeMin1,
        greetingsOptionTimeHour1: state.marketingReducer.greetingsOptionTimeHour1,
        recurringTimeHour1: state.marketingReducer.recurringTimeHour1,
        recurringTimeMin1: state.marketingReducer.recurringTimeMin1,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchPreRecording: () => dispatch(fetchPreRecording()),
        addCampaignStepSetting: (params, callback) => dispatch(addCampaignStepSetting(params, callback)),
        addCampaignStepSettingPreTemp: (params, callback) => dispatch(addCampaignStepSettingPreTemp(params, callback)),
        updateCampaignSettingWithMultimedia: (params, callback) => dispatch(updateCampaignSettingWithMultimedia(params, callback)),
        updateCampaignSettingWithPreviousFile: (params, callback) => dispatch(updateCampaignSettingWithPreviousFile(params, callback)),
        updateCampaignSettingWithPreTemp: (params, callback) => dispatch(updateCampaignSettingWithPreTemp(params, callback)),
        fetchCampaignTimelines: (params) => dispatch(fetchCampaignTimelines(params)),

        updateTabValue: (params) => dispatch(updateTabValue(params)),
        updateHour: (params) => dispatch(updateHour(params)),
        updateMin: (params) => dispatch(updateMin(params)),
        updateDay: (params) => dispatch(updateDay(params)),
        updateRecurringDay: (params) => dispatch(updateRecurringDay(params)),
        updateLaterHour: (params) => dispatch(updateLaterHour(params)),
        updateLaterMin: (params) => dispatch(updateLaterMin(params)),
        updateTime: (params) => dispatch(updateTime(params)),
        updateStartDate: (params) => dispatch(updateStartDate(params)),
        updateArrSlotTimeHour1: (params) => dispatch(updateArrSlotTimeHour1(params)),
        updateArrSlotTimeMin1: (params) => dispatch(updateArrSlotTimeMin1(params)),
        updateGreetingOption: (params) => dispatch(updateGreetingOption(params)),
        updateGreetingsOptionTimeMin1: (params) => dispatch(updateGreetingsOptionTimeMin1(params)),
        updateGreetingsOptionTimeHour1: (params) => dispatch(updateGreetingsOptionTimeHour1(params)),
        updateRecurringTimeHour1: (params) => dispatch(updateRecurringTimeHour1(params)),
        updateRecurringTimeMin1: (params) => dispatch(updateRecurringTimeMin1(params)),
    };
}

const VoiceMailModal = connect(mapStateToProps, mapDispatchToProps)(App);

export default VoiceMailModal;