import { useEffect } from "react";
import { connect } from "react-redux";
import { copyCampaign } from "../../../../actions/marketingAction";
import useDelayCallback from "../../hanlders/hooks/useDelayCallback";
import { handleStates, handleStoreCampaignList } from "../../reducer/Actions";
import { useMLv2Context } from "../../reducer/MLV2Provider";
import CampaignListSkeleton from "./CampaignListSkeleton";
import EachCampaignList from "./EachCampaignList";
import { CAMPAIGN_OPTION_TYPE, PRIVACY_LEVEL } from "../../../../constants/CoreConstants";
import { getCampaignListApi } from "../../../../api/marketingApi";

const CampaignList = (props) => {
    const { state, dispatch } = useMLv2Context();

    useEffect(() => {
        if (state.isCampaignListFiltered) {
            getCampaignList()
        }
    }, [state.isCampaignListFiltered]); // eslint-disable-line react-hooks/exhaustive-deps


    useDelayCallback(() => {

        if (state.searchText !== null) {
            try {
                getCampaignList()
            } catch (error) {
                console.log('error', [error])
            }
        }
    }, [state.searchText])

    const getCampaignList = async () => {
        try {
            dispatch(handleStates({
                gettingList: true
            }));

            const payload = {
                privacyLevel: state.selectedTabOption === CAMPAIGN_OPTION_TYPE.GLOBAL ? PRIVACY_LEVEL.PRIVACY_LEVEL_GLOBAL : PRIVACY_LEVEL.PRIVACY_LEVEL_PRIVATE,
                status: state.selectedStatus.value,
                folderId: state.selectedFolder.id,
                createdBy: state.selectedUser.value,
                search: state.searchText,
                page: state.currentPage,
                perPage: state.perPage,
            };

            const resp = await getCampaignListApi(payload);

            if (resp.success && resp.data && resp.data[0]) {
                dispatch(handleStoreCampaignList({
                    campaignList: resp.data,
                    collapseId: null,
                    gettingList: false,
                    totalRecords: state.currentPage === 1 ? resp.total_count : state.totalRecords,
                    isCampaignListFiltered: false,
                }));
            } else {
                dispatch(handleStoreCampaignList({
                    campaignList: [],
                    collapseId: null,
                    gettingList: false,
                    totalRecords: 0,
                    isCampaignListFiltered: false,
                }));
            }
        } catch (error) {
            window.showNotification("ERROR", "Something went wrong! Please try again.");
            dispatch(handleStoreCampaignList({
                campaignList: [],
                collapseId: null,
                gettingList: false,
                totalRecords: 0,
                isCampaignListFiltered: false,
            }));
        }
    };


    const renderList = () => {
        const view = [];
        state.campaignList.forEach((item, index) => {
            view.push(
                <EachCampaignList
                    key={index}
                    index={index}
                    item={item}
                    copyCampaign={props.copyCampaign}
                />
            );
        })
        return view;
    }

    return (
        <div className={`mlvc_body_container_right ${state.showMinimizeView ? 'mlvc_each_campaign_body_active' : ''}`}>
            {!state.gettingList && renderList()}
            {state.gettingList && <CampaignListSkeleton />}
        </div>
    );
}
const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
    copyCampaign: (params, callback) => dispatch(copyCampaign(params, callback)),

})

export default connect(mapStateToProps, mapDispatchToProps)(CampaignList);