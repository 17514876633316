export const all_campaign_item = {
    'id': null,
    'title': 'All Campaigns',
    'icon': 0,
    'color': '#5463763b',
    'text_color': '#546376',
    'campaign_count': 0,
    'description': ''
}
export const mlv2States = {
    creatingDOM: false,

    /* top header */
    isShowFolderCreate: false,
    isShowCampaignCreate: false,

    /* left side */
    folders: [],
    // selectedFolder: null,
    selectedFolder: all_campaign_item,
    gettingFolder: false,
    movingToFolder: false,
    showDeleteFolder: false,
    deletingFolder: false,
    refresh: false,

    /* right side */
    searchText: '',
    selectedIds: '', //id_{campaign id};
    selectedStatus: { value: 'ALL', label: 'Filter by Campaign status' },

    /* each list */
    collapseId: null,
    selectedForRunning: null,
    isShowRunningConfirmation: false,
    isShowActionList: false,
    isShowAddToFolder: false,
    submittingAddToFolder: false,

    /* list */
    campaignList: [],
    gettingList: true,
    currentPage: 1,
    totalRecords: 0,
    perPage: 10,
    refreshList: false,

    /* list menu */
    isShowAddContact: false,
    deletingCampaign: false,
    isShowShare: false,
    selectedCampaignForAction: {},
    selectedCampaignForActionIndex: null,
    processCopyCampaign: false,
    isShowCampaignStart: false,
    isProcessingStatus: false,
    resetSwitch: false,
    resetBlock: false,

    /* required action list */
    gettingActionList: true,
    actionList: [],

    /* folder add or edit */
    editFolderData: null,
    editFolderIndex: null,
    submittingFolder: false,

    /* show minimize view list */
    showMinimizeView: false,
    isShowFolderDrawer: false,

    campaignIdFromSocket: [],
    resetSwitchForSocket: '',

    /* collapseHandler */
    collapseFolderMenu: false
}
export const colors = [
    {
        bg: '#D2CCFF',
        text: '#15008A'
    },
    {
        bg: '#D9E2FF',
        text: '#002E75'
    },
    {
        bg: '#FFDAD9',
        text: '#940015'
    },
    {
        bg: '#FFDCC3',
        text: '#944200'
    },
    {
        bg: '#F9D8FF',
        text: '#5F0085'
    },
    {
        bg: '#7EFBA4',
        text: '#006625'
    },
    {
        bg: '#FFFFFF',
        text: '#546376'
    },
    {
        bg: '#1c1b1f1f',
        text: '#1c1b1f61'
    }
]
export const borderColor = {
    '#D2CCFF': '#6258FF',
    '#D9E2FF': '#006DF5',
    '#FFDAD9': '#ED0039',
    '#FFDCC3': '#F2994A',
    '#F9D8FF': '#A352C2',
    '#7EFBA4': '#008945',
    '#FFFFFF': '#546376',
    '#1c1b1f1f': '#546376'
}