import {useContext, useRef, useState} from 'react';
import { getCampaignSettingContactListApi, resentFailedContactApi } from '../../api/marketingApi';
import { HistoryProvider } from '../../App';
import Helper from '../../helpers/Helper';
import Utils from '../../helpers/Utils';
import { useIsMounted } from '../../hooks/IsMounted';
import useDelayCallback from '../../hooks/useDelayCallback';
import CustomConfirmAlert from '../Common/custom-alert/CustomConfirmAlert';
import Actions from './Actions';
import MEISCustomTable from './custom-components/custom-table/MEISCustomTable';
import './style.css';
import SubmitButton from './SubmitButton';

const dummy_data = [
    {
        contactId: 13387877,
        email: "sarah@gets3.com",
        firstName: "Sarah",
        gatewayResponse: "{\"body\": \"hi Sarah\", \"num_segments\": \"1\", \"direction\": \"outbound-api\", \"from\": \"+15863716301\", \"date_updated\": \"Thu, 11 Aug 2022 15:41:14 +0000\", \"price\": null, \"error_message\": null, \"uri\": \"/2010-04-01/Accounts/ACf57320dfd35b62a2727bc30f8bd137b0/Messages/SMa968d60cf7e2cb293aa7aabb817b1005.json\", \"account_sid\": \"ACf57320dfd35b62a2727bc30f8bd137b0\", \"num_media\": \"0\", \"to\": \"+18102781026\", \"date_created\": \"Thu, 11 Aug 2022 15:41:14 +0000\", \"status\": \"queued\", \"sid\": \"SMa968d60cf7e2cb293aa7aabb817b1005\", \"date_sent\": null, \"messaging_service_sid\": null, \"error_code\": null, \"price_unit\": \"USD\", \"api_version\": \"2010-04-01\", \"subresource_uris\": {\"media\": \"/2010-04-01/Accounts/ACf57320dfd35b62a2727bc30f8bd137b0/Messages/SMa968d60cf7e2cb293aa7aabb817b1005/Media.json\"}}",
        lastName: "SalesPype",
        message: null,
        number: "18102781026",
        scheduleTime: "2022-08-11 15:41:04.0",
        status: 3
    },
    {
        contactId: 13387878,
        email: "sarah@gets3.com",
        firstName: "Sarah",
        gatewayResponse: "{\"body\": \"hi Sarah\", \"num_segments\": \"1\", \"direction\": \"outbound-api\", \"from\": \"+15863716301\", \"date_updated\": \"Thu, 11 Aug 2022 15:41:14 +0000\", \"price\": null, \"error_message\": null, \"uri\": \"/2010-04-01/Accounts/ACf57320dfd35b62a2727bc30f8bd137b0/Messages/SMa968d60cf7e2cb293aa7aabb817b1005.json\", \"account_sid\": \"ACf57320dfd35b62a2727bc30f8bd137b0\", \"num_media\": \"0\", \"to\": \"+18102781026\", \"date_created\": \"Thu, 11 Aug 2022 15:41:14 +0000\", \"status\": \"queued\", \"sid\": \"SMa968d60cf7e2cb293aa7aabb817b1005\", \"date_sent\": null, \"messaging_service_sid\": null, \"error_code\": null, \"price_unit\": \"USD\", \"api_version\": \"2010-04-01\", \"subresource_uris\": {\"media\": \"/2010-04-01/Accounts/ACf57320dfd35b62a2727bc30f8bd137b0/Messages/SMa968d60cf7e2cb293aa7aabb817b1005/Media.json\"}}",
        lastName: "SalesPype",
        message: null,
        number: "18102781026",
        scheduleTime: "2022-08-11 15:41:04.0",
        status: 3
    },
    {
        contactId: 23387877,
        email: "sarah@gets3.com",
        firstName: "Sarah",
        gatewayResponse: "{\"body\": \"hi Sarah\", \"num_segments\": \"1\", \"direction\": \"outbound-api\", \"from\": \"+15863716301\", \"date_updated\": \"Thu, 11 Aug 2022 15:41:14 +0000\", \"price\": null, \"error_message\": null, \"uri\": \"/2010-04-01/Accounts/ACf57320dfd35b62a2727bc30f8bd137b0/Messages/SMa968d60cf7e2cb293aa7aabb817b1005.json\", \"account_sid\": \"ACf57320dfd35b62a2727bc30f8bd137b0\", \"num_media\": \"0\", \"to\": \"+18102781026\", \"date_created\": \"Thu, 11 Aug 2022 15:41:14 +0000\", \"status\": \"queued\", \"sid\": \"SMa968d60cf7e2cb293aa7aabb817b1005\", \"date_sent\": null, \"messaging_service_sid\": null, \"error_code\": null, \"price_unit\": \"USD\", \"api_version\": \"2010-04-01\", \"subresource_uris\": {\"media\": \"/2010-04-01/Accounts/ACf57320dfd35b62a2727bc30f8bd137b0/Messages/SMa968d60cf7e2cb293aa7aabb817b1005/Media.json\"}}",
        lastName: "SalesPype",
        message: null,
        number: "18102781026",
        scheduleTime: "2022-08-11 15:41:04.0",
        status: 3
    },
    {
        contactId: 11387877,
        email: "sarah@gets3.com",
        firstName: "Sarah",
        gatewayResponse: "{\"body\": \"hi Sarah\", \"num_segments\": \"1\", \"direction\": \"outbound-api\", \"from\": \"+15863716301\", \"date_updated\": \"Thu, 11 Aug 2022 15:41:14 +0000\", \"price\": null, \"error_message\": null, \"uri\": \"/2010-04-01/Accounts/ACf57320dfd35b62a2727bc30f8bd137b0/Messages/SMa968d60cf7e2cb293aa7aabb817b1005.json\", \"account_sid\": \"ACf57320dfd35b62a2727bc30f8bd137b0\", \"num_media\": \"0\", \"to\": \"+18102781026\", \"date_created\": \"Thu, 11 Aug 2022 15:41:14 +0000\", \"status\": \"queued\", \"sid\": \"SMa968d60cf7e2cb293aa7aabb817b1005\", \"date_sent\": null, \"messaging_service_sid\": null, \"error_code\": null, \"price_unit\": \"USD\", \"api_version\": \"2010-04-01\", \"subresource_uris\": {\"media\": \"/2010-04-01/Accounts/ACf57320dfd35b62a2727bc30f8bd137b0/Messages/SMa968d60cf7e2cb293aa7aabb817b1005/Media.json\"}}",
        lastName: "SalesPype",
        message: null,
        number: "18102781026",
        scheduleTime: "2022-08-11 15:41:04.0",
        status: 3
    },
    {
        contactId: 13317877,
        email: "sarah@gets3.com",
        firstName: "Sarah",
        gatewayResponse: "{\"body\": \"hi Sarah\", \"num_segments\": \"1\", \"direction\": \"outbound-api\", \"from\": \"+15863716301\", \"date_updated\": \"Thu, 11 Aug 2022 15:41:14 +0000\", \"price\": null, \"error_message\": null, \"uri\": \"/2010-04-01/Accounts/ACf57320dfd35b62a2727bc30f8bd137b0/Messages/SMa968d60cf7e2cb293aa7aabb817b1005.json\", \"account_sid\": \"ACf57320dfd35b62a2727bc30f8bd137b0\", \"num_media\": \"0\", \"to\": \"+18102781026\", \"date_created\": \"Thu, 11 Aug 2022 15:41:14 +0000\", \"status\": \"queued\", \"sid\": \"SMa968d60cf7e2cb293aa7aabb817b1005\", \"date_sent\": null, \"messaging_service_sid\": null, \"error_code\": null, \"price_unit\": \"USD\", \"api_version\": \"2010-04-01\", \"subresource_uris\": {\"media\": \"/2010-04-01/Accounts/ACf57320dfd35b62a2727bc30f8bd137b0/Messages/SMa968d60cf7e2cb293aa7aabb817b1005/Media.json\"}}",
        lastName: "SalesPype",
        message: null,
        number: "18102781026",
        scheduleTime: "2022-08-11 15:41:04.0",
        status: 3
    },
    {
        contactId: 13387477,
        email: "sarah@gets3.com",
        firstName: "Sarah",
        gatewayResponse: "{\"body\": \"hi Sarah\", \"num_segments\": \"1\", \"direction\": \"outbound-api\", \"from\": \"+15863716301\", \"date_updated\": \"Thu, 11 Aug 2022 15:41:14 +0000\", \"price\": null, \"error_message\": null, \"uri\": \"/2010-04-01/Accounts/ACf57320dfd35b62a2727bc30f8bd137b0/Messages/SMa968d60cf7e2cb293aa7aabb817b1005.json\", \"account_sid\": \"ACf57320dfd35b62a2727bc30f8bd137b0\", \"num_media\": \"0\", \"to\": \"+18102781026\", \"date_created\": \"Thu, 11 Aug 2022 15:41:14 +0000\", \"status\": \"queued\", \"sid\": \"SMa968d60cf7e2cb293aa7aabb817b1005\", \"date_sent\": null, \"messaging_service_sid\": null, \"error_code\": null, \"price_unit\": \"USD\", \"api_version\": \"2010-04-01\", \"subresource_uris\": {\"media\": \"/2010-04-01/Accounts/ACf57320dfd35b62a2727bc30f8bd137b0/Messages/SMa968d60cf7e2cb293aa7aabb817b1005/Media.json\"}}",
        lastName: "SalesPype",
        message: null,
        number: "18102781026",
        scheduleTime: "2022-08-11 15:41:04.0",
        status: 3
    },
    {
        contactId: 13337877,
        email: "sarah@gets3.com",
        firstName: "Sarah",
        gatewayResponse: "{\"body\": \"hi Sarah\", \"num_segments\": \"1\", \"direction\": \"outbound-api\", \"from\": \"+15863716301\", \"date_updated\": \"Thu, 11 Aug 2022 15:41:14 +0000\", \"price\": null, \"error_message\": null, \"uri\": \"/2010-04-01/Accounts/ACf57320dfd35b62a2727bc30f8bd137b0/Messages/SMa968d60cf7e2cb293aa7aabb817b1005.json\", \"account_sid\": \"ACf57320dfd35b62a2727bc30f8bd137b0\", \"num_media\": \"0\", \"to\": \"+18102781026\", \"date_created\": \"Thu, 11 Aug 2022 15:41:14 +0000\", \"status\": \"queued\", \"sid\": \"SMa968d60cf7e2cb293aa7aabb817b1005\", \"date_sent\": null, \"messaging_service_sid\": null, \"error_code\": null, \"price_unit\": \"USD\", \"api_version\": \"2010-04-01\", \"subresource_uris\": {\"media\": \"/2010-04-01/Accounts/ACf57320dfd35b62a2727bc30f8bd137b0/Messages/SMa968d60cf7e2cb293aa7aabb817b1005/Media.json\"}}",
        lastName: "SalesPype",
        message: null,
        number: "18102781026",
        scheduleTime: "2022-08-11 15:41:04.0",
        status: 3
    },
    {
        contactId: 13345877,
        email: "sarah@gets3.com",
        firstName: "Sarah",
        gatewayResponse: "{\"body\": \"hi Sarah\", \"num_segments\": \"1\", \"direction\": \"outbound-api\", \"from\": \"+15863716301\", \"date_updated\": \"Thu, 11 Aug 2022 15:41:14 +0000\", \"price\": null, \"error_message\": null, \"uri\": \"/2010-04-01/Accounts/ACf57320dfd35b62a2727bc30f8bd137b0/Messages/SMa968d60cf7e2cb293aa7aabb817b1005.json\", \"account_sid\": \"ACf57320dfd35b62a2727bc30f8bd137b0\", \"num_media\": \"0\", \"to\": \"+18102781026\", \"date_created\": \"Thu, 11 Aug 2022 15:41:14 +0000\", \"status\": \"queued\", \"sid\": \"SMa968d60cf7e2cb293aa7aabb817b1005\", \"date_sent\": null, \"messaging_service_sid\": null, \"error_code\": null, \"price_unit\": \"USD\", \"api_version\": \"2010-04-01\", \"subresource_uris\": {\"media\": \"/2010-04-01/Accounts/ACf57320dfd35b62a2727bc30f8bd137b0/Messages/SMa968d60cf7e2cb293aa7aabb817b1005/Media.json\"}}",
        lastName: "SalesPype",
        message: null,
        number: "18102781026",
        scheduleTime: "2022-08-11 15:41:04.0",
        status: 3
    },
    {
        contactId: 13387237,
        email: "sarah@gets3.com",
        firstName: "Sarah",
        gatewayResponse: "{\"body\": \"hi Sarah\", \"num_segments\": \"1\", \"direction\": \"outbound-api\", \"from\": \"+15863716301\", \"date_updated\": \"Thu, 11 Aug 2022 15:41:14 +0000\", \"price\": null, \"error_message\": null, \"uri\": \"/2010-04-01/Accounts/ACf57320dfd35b62a2727bc30f8bd137b0/Messages/SMa968d60cf7e2cb293aa7aabb817b1005.json\", \"account_sid\": \"ACf57320dfd35b62a2727bc30f8bd137b0\", \"num_media\": \"0\", \"to\": \"+18102781026\", \"date_created\": \"Thu, 11 Aug 2022 15:41:14 +0000\", \"status\": \"queued\", \"sid\": \"SMa968d60cf7e2cb293aa7aabb817b1005\", \"date_sent\": null, \"messaging_service_sid\": null, \"error_code\": null, \"price_unit\": \"USD\", \"api_version\": \"2010-04-01\", \"subresource_uris\": {\"media\": \"/2010-04-01/Accounts/ACf57320dfd35b62a2727bc30f8bd137b0/Messages/SMa968d60cf7e2cb293aa7aabb817b1005/Media.json\"}}",
        lastName: "SalesPype",
        message: null,
        number: "18102781026",
        scheduleTime: "2022-08-11 15:41:04.0",
        status: 3
    },
    {
        contactId: 13117877,
        email: "sarah@gets3.com",
        firstName: "Sarah",
        gatewayResponse: "{\"body\": \"hi Sarah\", \"num_segments\": \"1\", \"direction\": \"outbound-api\", \"from\": \"+15863716301\", \"date_updated\": \"Thu, 11 Aug 2022 15:41:14 +0000\", \"price\": null, \"error_message\": null, \"uri\": \"/2010-04-01/Accounts/ACf57320dfd35b62a2727bc30f8bd137b0/Messages/SMa968d60cf7e2cb293aa7aabb817b1005.json\", \"account_sid\": \"ACf57320dfd35b62a2727bc30f8bd137b0\", \"num_media\": \"0\", \"to\": \"+18102781026\", \"date_created\": \"Thu, 11 Aug 2022 15:41:14 +0000\", \"status\": \"queued\", \"sid\": \"SMa968d60cf7e2cb293aa7aabb817b1005\", \"date_sent\": null, \"messaging_service_sid\": null, \"error_code\": null, \"price_unit\": \"USD\", \"api_version\": \"2010-04-01\", \"subresource_uris\": {\"media\": \"/2010-04-01/Accounts/ACf57320dfd35b62a2727bc30f8bd137b0/Messages/SMa968d60cf7e2cb293aa7aabb817b1005/Media.json\"}}",
        lastName: "SalesPype",
        message: null,
        number: "18102781026",
        scheduleTime: "2022-08-11 15:41:04.0",
        status: 3
    },
    {
        contactId: 12287877,
        email: "sarah@gets3.com",
        firstName: "Sarah",
        gatewayResponse: "{\"body\": \"hi Sarah\", \"num_segments\": \"1\", \"direction\": \"outbound-api\", \"from\": \"+15863716301\", \"date_updated\": \"Thu, 11 Aug 2022 15:41:14 +0000\", \"price\": null, \"error_message\": null, \"uri\": \"/2010-04-01/Accounts/ACf57320dfd35b62a2727bc30f8bd137b0/Messages/SMa968d60cf7e2cb293aa7aabb817b1005.json\", \"account_sid\": \"ACf57320dfd35b62a2727bc30f8bd137b0\", \"num_media\": \"0\", \"to\": \"+18102781026\", \"date_created\": \"Thu, 11 Aug 2022 15:41:14 +0000\", \"status\": \"queued\", \"sid\": \"SMa968d60cf7e2cb293aa7aabb817b1005\", \"date_sent\": null, \"messaging_service_sid\": null, \"error_code\": null, \"price_unit\": \"USD\", \"api_version\": \"2010-04-01\", \"subresource_uris\": {\"media\": \"/2010-04-01/Accounts/ACf57320dfd35b62a2727bc30f8bd137b0/Messages/SMa968d60cf7e2cb293aa7aabb817b1005/Media.json\"}}",
        lastName: "SalesPype",
        message: "Failed",
        number: "18102781026",
        scheduleTime: "2022-08-11 15:41:04.0",
        status: 3
    },
];

const back_icon = (<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.1258 6.7925L12.8333 5.5L7.33331 11L12.8333 16.5L14.1258 15.2075L9.92748 11L14.1258 6.7925Z" fill="#1C1B1F"/>
</svg>)
const next_icon = (<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.16671 5.5L7.87421 6.7925L12.0725 11L7.87421 15.2075L9.16671 16.5L14.6667 11L9.16671 5.5Z" fill="#1C1B1F"/>
</svg>)

export const SEND_ONLY = 1;
export const SEND_AND_RESUME = 2;
export const RESUME_ONLY = 3;

const ResentFailedContact = ({campaignSettingId, onClose, is_continue_after_fail}) => {
    const history = useContext(HistoryProvider)
    const isMounted = useIsMounted();
    const perPage = 20;
    const [contactList, setContactList] = useState([]);
    // const [contactList, setContactList] = useState(dummy_data);
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalContacts, setTotalContacts] = useState(0);
    const [search, setSearch] = useState('');
    const [action, setAction] = useState(SEND_ONLY);
    const [submitting, setSubmitting] = useState(false);
    const inputRef = useRef();

    const userTimeZone = Utils.getAccountData('userTimezoneRaw');

    /* selected contact */
    const [selectedContacts, setSelectedContacts] = useState([]);
    
    useDelayCallback(() => {
        getContactList();
    }, [currentPage]);

    useDelayCallback(() => {
        if(isMounted){
            /* functionalites for other dependencies */
            if(currentPage === 1){
                getContactList();
            }
            else{
                setCurrentPage(1)
            }
        }
    }, [search/* other dependencies */]);

    const getContactList = (page = currentPage) => {
        setIsLoading(true)
        /* getting data via api */
        let formData = {
            campaignSettingsId: campaignSettingId.toString(),
            searchKey: search,
            page: parseInt(currentPage),
            limit: perPage,
            status: [2],
        }
        getCampaignSettingContactListApi(formData).then(response => {
            if(response.message != null) {
                window.showNotification("ERROR", response.message);
            } else {
                if(response.campaignContacts !== undefined){
                    setTotalContacts(response.totalContacts);
                    setContactList(response.campaignContacts);
                }                
            }
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
        })
        
    }

    const handleSubmit = () => {
        if(contactList.length === 0 && currentPage === 1){
            return;
        }
        if(submitting){
            return
        }
        /* for all contact select need to show alert and then hit api */
        /* TODO: check validation */
        if(action == null){
            window.showNotification("WARNING", "Please select an action type !");
            return
        }
        let selectedContactIds = selectedContacts;
        if(Array.isArray(selectedContactIds)){
            if(selectedContactIds.length === 0){
                window.showNotification("WARNING", "No contact selected !");
                return
            }
        }
        else{
            if(selectedContacts === "__ALL__" || selectedContacts === "__ALL_RECOREDS__"){
                selectedContactIds = [];
            }
        }
        setSubmitting(true);
        sendApiCall(selectedContactIds)      
        return
        if(selectedContactIds.length === 0){
            let message = 'You select all failed contacts not only selected this page under this campaign setting. Followup will be sent for all failed contacts'
            if(selectedContacts.length === 0){
                message = 'If you don\'t select any contact, then all failed contacts under this campaign setting will be selected'
            }
            CustomConfirmAlert({
                handleConfirm : () => {
                    // sendApiCall(selectedContactIds)
                    setSubmitting(false);
                },
                handleOnClose: () => {
                    setSubmitting(false);
                },
                title: 'PLEASE WAIT',
                description: message,
                icon: 'warning',
                confirmButtonText: "Submit Send",
                closeButtonText: 'Cancel',
                callConfirmLast: false,
                minHeight: '280px'
            })
        }
        else{
            sendApiCall(selectedContactIds)
        }
    }

    const sendApiCall = (selectedContactIds) => {
        resentFailedContactApi({
            campaignSettingId: campaignSettingId,
            // contactId: selectedContacts,
            contactId: selectedContactIds,
            action: parseInt(action),
            searchKey: search
        }).then(response => {
            if(response.success){
                window.showNotification("SUCCESS", response.message);
                onClose()
            }
            else{
                window.showNotification("ERROR", response.message);
            }
            setSubmitting(false)
        }).catch(err => {
            setSubmitting(false)
        })
    }

    const onChangePage = (page) => {
        if(submitting){
            return
        }
        if(page === ""){
            // setCurrentPage(1)
        }
        else{
            let max_value = Math.ceil(totalContacts / perPage)
            if(parseInt(page) > max_value){
                // setCurrentPage(max_value)
            }
            else{
                setCurrentPage(parseInt(page))
            }
        }
    }

    const onChangeSearch = (value) => {
        if(submitting){
            return
        }
        setSearch(value);
        inputRef.current.value = 1;
    }

    const onChangeAction = (value) => {
        setAction(value)
    }

    const onClickEach = (contact_id) => {
        if(contact_id !== undefined){
            onClose();
            history.push('/contacts/'+contact_id);
        }
    }

    const handleStepChange = (type = 'next') => {
        if(type === 'next'){
            if(currentPage >= Math.ceil((totalContacts / perPage))){
                return
            }
            else{
                onChangePage(currentPage + 1)
                inputRef.current.value = currentPage + 1
            }
        }
        else if(type === 'back'){
            if(currentPage <= 1){
                return
            }
            else{
                onChangePage(currentPage - 1)
                inputRef.current.value = currentPage - 1
            }
        }
    }

    const renderPagination = () => {
        return (
            <div className='resent_failed_table_pagination'>
                {totalContacts !== 0 &&
                <div className="resent_failed_table_pagi_row">
                    <span className={`rftp_back_button  ${currentPage === 1 ? 'rftp_bb_disabled':''}`} onClick={() => handleStepChange('back')}>{back_icon}</span>
                    <input className="rftp_input_field" ref={inputRef} defaultValue={currentPage} /*value={currentPage}*/ onChange={(e) => onChangePage(e.target.value)}/>
                    <span className="rftp_divider"></span>
                    <input className="rftp_input_field_total" readOnly value={Math.ceil((totalContacts / perPage))}/>
                    <span className={`rftp_next_button ${currentPage === Math.ceil((totalContacts / perPage)) ? 'rftp_bb_disabled':''}`} onClick={() => handleStepChange('next')}>{next_icon}</span>
                </div>
                }
            </div>
        );
    }

    return (
        <div className="marketing_resent_failed_contact_module">
            {/* action type */}
            {contactList.length > 0 &&
            <div className='mrfcm_resent_action_container'>
                <span className='mrfcm_rac_title'>Choose an option below to add failed contacts back to your campaign:</span>
                <Actions 
                    onChange={onChangeAction} 
                    value={action}
                    actions={[
                        {value: SEND_ONLY, title: 'Resend the failed message only', des: 'This option will resend the failed message only. It will not send out any future messages that may exist inside your campaign.'},
                        {value: SEND_AND_RESUME, title: 'Send & Resume Campaign (most popular option)', des: 'This option will resend the failed message and resume the campaign where it left off.'},
                        {value: RESUME_ONLY, title: 'Skip & Resume', des: 'This option will skip the failed message, but add the contact back to the appropriate stage of the campaign.'},
                    ]}
                    is_continue_after_fail={is_continue_after_fail}
                />
            </div>
            }

            {/* contact table will be here */}
            <MEISCustomTable 
                tableWrapperclass="campaign_report"
                isLoading={isLoading}
                data={contactList}
                select={{
                    isShowHeaderCheckBox: false,
                    onChangeCheckBox: (e) => setSelectedContacts(e),
                    converToInt:true,
                    isShowView:true,
                    viewText: " contact (s) selected !",
                    actions: [
                        {title: 'Select All', action: (e) => setSelectedContacts('__ALL_RECOREDS__')},
                    ],
                    search: {
                        placeholder: 'Search',
                        onChange: onChangeSearch,
                        defaultValue: search
                    },
                    checkBoxField: 'contactId',
                    allRecordSelectText: totalContacts + ' contact(s) selected !'
                }}
                renderPagination={renderPagination()}
                rowDecoration={[
                    {
                        title: 'Full Name', 
                        value: (row) => {
                            let name = ''
                            if(Helper.isvalidText(row.firstName) && Helper.isvalidText(row.lastName)){
                                name = row.firstName + ' ' + row.lastName
                            }
                            else if(Helper.isvalidText(row.firstName)){
                                name = row.firstName
                            }
                            else if(Helper.isvalidText(row.lastName)){
                                name = row.lastName
                            }
                            return <div style={{cursor: 'pointer', color: '#3c7ef3'}} onClick={() => onClickEach(row.contactId)}>{name}</div>;
                        }, 
                        minWidth: '150px'
                    },
                    {
                        title: 'Email', 
                        value: (row) => {
                            return <div style={{cursor: 'pointer', color: '#3c7ef3'}} onClick={() => onClickEach(row.contactId)}>{row['email']}</div>
                        },
                        minWidth: '150px'
                    },
                    {
                        title: 'Phone Number', 
                        value: (row) => {
                            return <div style={{cursor: 'pointer', color: '#3c7ef3'}} onClick={() => onClickEach(row.contactId)}>{Utils.formatPhoneNumber(row['number'])}</div>
                        },
                        minWidth: '150px'
                    },
                    {
                        title: 'Scheduled At',
                        value: (row) => {
                            return <div style={{color: '#3c7ef3'}} >{window.globalTimezoneConversionToDifferentTimezone(row['scheduleTime'],'UTC',userTimeZone, 'MM/DD/yyyy hh:mm:ss A')}</div>
                        },
                        minWidth: '150px'
                    },
                    {
                        title: 'Reason', 
                        value: (row) => {
                            let message = "";
                            let link = "";
                            if(row['message'] !== undefined && row['message'] != null && row['message'].trim() !== ""){
                                if(row['message'].includes("Resource has been exhausted")){
                                    message = "Gmail rate limit over. For more details, please visit this link. "
                                    link=<a target={"_blank"} rel="noreferrer" href="https://support.google.com/a/answer/166852?hl=en"> Gmail rate limit</a>;
                                }
                                else if(row['message'].includes("Campaign Is Not Running")){
                                    message = "Campaign Is Not Running."
                                }
                                else if(row['message'].includes("Contact Unsubscribe Or Blocked")){
                                    message = "Contact Unsubscribe Or Blocked !"
                                }
                                else if(row['message'].includes("Too many concurrent connections opened")){
                                    message = "Too many concurrent connections opened. Gmail rate limit over. For more details, please visit this link. "
                                    link=<a target={"_blank"} rel="noreferrer" href="https://support.google.com/a/answer/166852?hl=en"> Gmail rate limit</a>;
                                }else{
                                    message = row['message'];
                                }
                            }
                            return <div className="campaign_setting_stats__failed__message">{message} {link}</div>;
                        },
                        minWidth: '200px'
                    }
                ]}
                emptyContainer={{
                    text: "No contact available !",
                    // overlay: (<div>Add more</div>)
                }}
                onClose={onClose}
                selectedContacts={selectedContacts}
            />

            {/* hints */}
            <span className='mrfcm_hints'>
                {/* All contact will be selected by default if you don't select any specific contact */}
            </span>
            {/* submit button */}
            {contactList.length > 0 &&
            <div className='mrfcm_button_container'>
                <SubmitButton submitting={submitting} handleSubmit={handleSubmit} text="Send" loadingText="Sending" />
            </div>
            }
        </div>
    );
}
export default ResentFailedContact;