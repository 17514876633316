import Api from "../constants/Api";
import { HttpRequest } from "./rootApi";

export const addQuickRepliesFolderApi = async (payload) => {
  return HttpRequest.post(Api.quickRepliesFolder, payload);
};

export const getQuickRepliesFolderApi = async (payload) => {
  return HttpRequest.get(Api.quickRepliesFolder, payload);
};
export const updateQuickRepliesFolderApi = async (payload) => {
  return HttpRequest.put(Api.quickRepliesFolder, payload);
};
export const deleteQuickRepliesFolderApi = async (payload) => {
  return HttpRequest.delete(Api.quickRepliesFolder, payload);
};

export const getTeamUsersApi = async (payload) => {
  return HttpRequest.post(Api.getTeamUsers, payload);
};

export const getQuickRepliesApi = async (payload) => {
  return HttpRequest.get(Api.quickReplies, payload);
};

export const cloneQuickRepliesApi = async (payload) => {
  return HttpRequest.post(Api.cloneQuickReplies, payload);
};

export const deleteQuickRepliesApi = async (payload) => {
  return HttpRequest.post(Api.messageTemplateDelete, payload);
};