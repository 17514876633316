import * as ACTION from "../constants/quickRepliesActionTypes";

export const addQuickRepliesFolder = (payload) => ({
  type: ACTION.ADD_QUICK_REPLY_FOLDER,
  payload: payload,
});

export const addQuickRepliesFolderSuccess = (payload) => ({
  type: ACTION.ADD_QUICK_REPLY_FOLDER_SUCCESS,
  payload: payload,
});
export const addQuickRepliesFolderFailure = (payload) => ({
  type: ACTION.ADD_QUICK_REPLY_FOLDER_FAILURE,
  payload: payload,
});

export const getQuickRepliesFolder = (payload) => ({
  type: ACTION.GET_QUICK_REPLY_FOLDER,
  payload: payload,
});

export const getQuickRepliesFolderSuccess = (payload) => ({
  type: ACTION.GET_QUICK_REPLY_FOLDER_SUCCESS,
  payload: payload,
});
export const getQuickRepliesFolderFailure = (payload) => ({
  type: ACTION.GET_QUICK_REPLY_FOLDER_FAILURE,
  payload: payload,
});

export const updateQuickRepliesFolder = (payload) => ({
  type: ACTION.UPDATE_QUICK_REPLY_FOLDER,
  payload: payload,
});

export const updateQuickRepliesFolderSuccess = (payload) => ({
  type: ACTION.UPDATE_QUICK_REPLY_FOLDER_SUCCESS,
  payload: payload,
});
export const updateQuickRepliesFolderFailure = (payload) => ({
  type: ACTION.UPDATE_QUICK_REPLY_FOLDER_FAILURE,
  payload: payload,
});

export const deleteQuickRepliesFolder = (payload) => ({
  type: ACTION.DELETE_QUICK_REPLY_FOLDER,
  payload: payload,
});

export const deleteQuickRepliesFolderSuccess = (payload) => ({
  type: ACTION.DELETE_QUICK_REPLY_FOLDER_SUCCESS,
  payload: payload,
});
export const deleteQuickRepliesFolderFailure = (payload) => ({
  type: ACTION.DELETE_QUICK_REPLY_FOLDER_FAILURE,
  payload: payload,
});

export const handleQuickRepliesTab = (payload) => ({
  type: ACTION.HANDLE_QUICK_REPLIES_TAB,
  payload: payload,
});

export const handleSelectedQuickRepliesFolder = (payload) => ({
  type: ACTION.HANDLE_QUICK_REPLIES_SELECTED_FOLDER,
  payload: payload,
});

export const handleSelectedQuickRepliesUserId = (payload) => ({
  type: ACTION.HANDLE_QUICK_REPLIES_USER_ID,
  payload: payload,
});

export const handleSelectedQuickRepliesTypes = (payload) => ({
  type: ACTION.HANDLE_QUICK_REPLIES_TEMPLATE_TYPES,
  payload: payload,
});

export const handleSelectedQuickRepliesSearch = (payload) => ({
  type: ACTION.HANDLE_QUICK_REPLIES_SEARCH,
  payload: payload,
});


export const getQuickReplies = (payload) => ({
  type: ACTION.GET_QUICK_REPLY,
  payload: payload,
});

export const getQuickRepliesSuccess = (payload) => ({
  type: ACTION.GET_QUICK_REPLY_SUCCESS,
  payload: payload,
});
export const getQuickRepliesFailure = (payload) => ({
  type: ACTION.GET_QUICK_REPLY_FAILURE,
  payload: payload,
});

export const getQuickRepliesMoreData = (payload) => ({
  type: ACTION.GET_QUICK_REPLY_MORE_DATA,
  payload: payload,
});

export const handleAddQuickReplies = (payload) => ({
  type: ACTION.ADD_QUICK_REPLY_SUCCESS,
  payload: payload,
});

export const cloneQuickReplies = (payload) => ({
  type: ACTION.CLONE_QUICK_REPLY,
  payload: payload,
});

export const cloneQuickRepliesSuccess = (payload) => ({
  type: ACTION.CLONE_QUICK_REPLY_SUCCESS,
  payload: payload,
});
export const cloneQuickRepliesFailure = (payload) => ({
  type: ACTION.CLONE_QUICK_REPLY_FAILURE,
  payload: payload,
});

export const deleteQuickReplies = (payload) => ({
  type: ACTION.DELETE_QUICK_REPLY,
  payload: payload,
});
export const deleteQuickRepliesSuccess = (payload) => ({
  type: ACTION.DELETE_QUICK_REPLY_SUCCESS,
  payload: payload,
});
export const deleteQuickRepliesFailure = (payload) => ({
  type: ACTION.DELETE_QUICK_REPLY_FAILURE,
  payload: payload,
});

export const reCallQuickRepliesAfterAddOrEdit = (payload) => ({
  type: ACTION.RE_CALL_QUICK_REPLIES_AFTER_ADD_OR_EDIT,
  payload: payload,
});

export const handlePermissionDeniedModal = (payload) => ({
  type: ACTION.HANDLE_PERMISSION_DENIED_MODAL,
  payload: payload,
});


