import { useEffect, useState } from "react";
import DynamicSkeleton from "../../Common/DynamicSkeleton";
import { InputAdornment } from "@material-ui/core";
import BasicInput from "../../Common/BasicInput/BasicInput";
import SearchIcon from "@material-ui/icons/Search";
import styled from "styled-components";

const ScrollableContainer = styled.div`
  max-height: 435px;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--dark_blue);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
`;

const BroadcastCampaign = (props) => {
  const [campaignIds, setCampaignIds] = useState([]);
  const [search, setSearch] = useState("");
  const [campaigns, setCampaigns] = useState([]);
  const [count, setCount] = useState(0);

  const onChangeList = (isChecked, id) => {
    let idsArray = [...campaignIds];
    if (isChecked) {
      idsArray.push(id);
    } else {
      idsArray = idsArray.filter((e) => e !== id);
    }
    setCampaignIds(idsArray);
    props.changeIds(idsArray);
  };

  const handleLoadMore = () => {
    props.loadMore(count, search);
  };

  const handleSearch = (e) => {
    setCount(0);
    setSearch(e.target.value);
    props.loadMore(0, e.target.value);
  };

  useEffect(() => {
    setCampaigns(props.campaigns);
    setCount(props.campaigns.length);
  }, [props.campaigns]);

  const renderList = () => {
    let filterArr = [];
    filterArr = campaigns;
    if (props.loading) {
      return <DynamicSkeleton />;
    }
    return (
      <div>
        {filterArr && filterArr.length > 0 ? (
          filterArr.map((campaign, index) => (
            <label key={index}>
              <input
                type="checkbox"
                onChange={(e) => onChangeList(e.target.checked, campaign.id)}
                checked={campaignIds.includes(campaign.id)}
                className="filled-in"
              />
              <span className="campaingName">{campaign.title}</span>
            </label>
          ))
        ) : (
          <p
            style={{
              color: "red",
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              padding: "50px",
            }}
          >
            No campaign found!
          </p>
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="tabBody">
        <div
          style={{
            marginTop: "10px",
            marginRight: "12px",
            marginBottom: "10px",
          }}
        >
          <BasicInput
            onChange={handleSearch}
            value={search}
            placeholder="Search Existing Campaign"
            fullWidth
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon style={{ color: "#656565" }} />
              </InputAdornment>
            }
          />
        </div>

        <ScrollableContainer className="campaingData">
          {renderList()}
        {count < props.countCampaign && (
          <div style={{ marginTop: "10px",marginBottom:"10px"}}>
            <button className="btn btn-sm" onClick={handleLoadMore}>
              Load More Data
            </button>
          </div>
        )}
        </ScrollableContainer>

        {/* <TimeSetting user={userInfo} timeSettingValue={props.timeSettingValue}/>
                <div className="d-flex justify-content-start senderEmailWrapper"> 
                <span>Sender {props.fromText !== undefined ? props.fromText : 'Phone Number'}: </span>
                <Select className="globalFormControlItem" value={props.selectedFrom} onChange={(e) => props.onChangeFrom(e.target.value)}>
                    {
                        props.froms.map((elem,index) => {
                            return <MenuItem className="menuitem-li-hover-color-with-border" key={index} value={elem.id}>{elem.value}</MenuItem>
                        })
                    }
                </Select>
                </div> */}
      </div>
    </div>
  );
};

export default BroadcastCampaign;
