/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-multi-str */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { Editor } from '@tinymce/tinymce-react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CoreConstants, { QuickReplyType } from '../../../../../constants/CoreConstants';
import Messages from '../../../../../constants/Messages';
import VideoEmail from '../../../../Video/VideoEmail';
import { image_upload_handler } from '../../../../../api/rootApi';
import CustomSelectBox from '../../../../Common/CustomSelectBox';
import { IconList } from '../../../../../constants/IconList';
const AddVideoEmailTemplate = props => {
    // eslint-disable-next-line no-unused-vars
    const [initialState, setInitialState] = useState({
        videoStatus: CoreConstants.VIDEO_STATUS_WELCOME,
        loader: false,
        loaderMessage: 'Open Camera',
        videoUrl: '',
        gifUrl: '',
        isVideoSelected: false,
        videoObj: null,
        activeVideoId: props.emailVideoId,
        fileUpload: false,
        videoPreviewLoader: false,
        startRecording: false
    });

    const [state, setState] = useState(initialState);
    const [videoId, setVideoId] = useState('');
    const [subject, setSubject] = useState('')
    const [body, setBody] = useState('')

    const editorRef = useRef(null);

    useEffect(() => {
        props.handleContentType(QuickReplyType.VIDEO_EMAIL);
    });

    useEffect(() => {
        if (props.template !== undefined && props.template !== null) {
            setState({
                ...state,
                videoUrl: props.template.video_file_url,
                // videoUrl: "https://s3.us-east-1.amazonaws.com/pypepro/videoFiles/1/1-1635587244.mp4",
                videoStatus: CoreConstants.VIDEO_STATUS_PREVIEW,
                activeVideoId: props.template.campaign_video_file_for_email_id,
                isVideoSelected: true
            });
            setVideoId(props.template.campaign_video_file_for_email_id);
            setSubject(props.template.subject);
            setBody(props.template.message);
        }
    }, [props.template]);

    const handleChangeEditor = (event) => {
        setBody(event)
    }

    const handleBodyPersonalize = (event) => {
        setBody(body + event.target.value);
    }

    const handleSaveTemplate = () => {
        if (state.startRecording) {
            if (window.showNotification !== undefined) {
                window.showNotification("WARNING", "Stop video recording first");
            }
            return
        }
        let formData = []
        // check validation
        if (props.contentType == QuickReplyType.VIDEO_EMAIL && videoId == '') {
            if (window.showNotification !== undefined) {
                window.showNotification("WARNING", Messages.videoNotFound);
            }
            return false
        }

        formData['videoId'] = videoId;
        formData['email_body'] = body;
        formData['email_subject'] = subject;
        props.handleSaveTemplate(formData)
    }
    const emailUploadFileApi = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/calendar/upload-email-file`;

    const apiKeys = () => {

        return 'f2zuqw07g06pnduwafiv7wehs2nc44j426n4nq0rjdhs8w1x';
    }
    return (
        <div id="video_email" className="video___email___template___content">
            <VideoEmail from="saved_reply" videoUrl={state.videoUrl} emailVideoId={videoId} setEmailVideoId={setVideoId} />
            <div className="under_tab_buttons">
                <h6>Subject</h6>
                <div className="input-field">
                    <input placeholder="Enter subject" value={subject} type="text" className="email-subject-video-template" onChange={(event) => setSubject(event.target.value)} />
                </div>

                <Editor
                    apiKey={apiKeys()}
                    onInit={(evt, editor) => editorRef.current = editor}
                    value={body}
                    cloudChannel='5-dev'
                    init={{
                        height: 300,
                        browser_spellcheck: true,
                        // menubar: false,
                        menu: {
                            favs: { title: 'My Favorites', items: 'code visualaid | searchreplace | emoticons' }
                        },
                        menubar: 'favs file edit view insert format tools table help',
                        plugins: [
                            'advlist autolink lists link image imagetools hr charmap print preview anchor pagebreak',
                            'searchreplace visualblocks visualchars code fullscreen media nonbreaking',
                            'insertdatetime media table contextmenu paste code help wordcount template'
                        ],
                        convert_urls: false,
                        toolbar:
                            'insertfile undo redo | styleselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | link image | \
                            bullist numlist outdent indent | removeformat | print preview media fullpage | help',
                        imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
                        imagetools_proxy: emailUploadFileApi,
                        images_upload_handler: image_upload_handler,
                        automatic_uploads: true,
                        file_picker_types: 'file image media',
                    }}
                    onEditorChange={handleChangeEditor}
                    id="add-sms-template-text-area"
                />
                <div className="d-flex">
                    <div className="input-field video-email-template-personalized-field">
                        <CustomSelectBox fetch={false} outlined={true} handlePersonalize={handleBodyPersonalize} />
                    </div>
                </div>

                <div className="d-flex justify-content-end">

                    <div className="save_template_button">
                        <a className="accent--bg--color cursor-pointer" onClick={(e) => { e.preventDefault(); handleSaveTemplate(e) }}>
                            {props.submitingTemplate ? <MoreHorizIcon /> : IconList.saveTemplate}
                            <span>{props.submitingTemplate ? "Saving" : "Save"} Template</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(AddVideoEmailTemplate);