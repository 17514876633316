/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react"
import { InputAdornment, MenuItem } from '@material-ui/core';
import SimpleReactValidator from 'simple-react-validator';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from "@material-ui/core/Select";
import Radio from '@material-ui/core/Radio';
import { getTeamUsers, updateOrCreateLeadGroup } from "../../../api/marketingApi";
import './LeadGroupModal.css';
import GlobalModal from "../../globals/Modal/GlobalModal";
import AddCircleIcon from '@material-ui/icons/AddCircle'
import DynamicSkeleton from "../../Common/DynamicSkeleton";
import BasicInput from "../../Common/BasicInput/BasicInput";
import SearchIcon from '@material-ui/icons/Search';

const useIsMounted = () => {
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;
    }, []);

    return isMounted.current;
}

const LeadGroupModal = (props) => {
    const isMounted = useIsMounted();

    const [loading, setLoading] = useState(false);
    const [componentLoading, setComponentLoading] = useState(true);
    const [teamUsers, setTeamUsers] = useState([]);

    const [groupName, setGroupName] = useState('');
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const [groupType, setGroupType] = useState(1);
    const [notClaimWithIn, setNotClaimWithIn] = useState(30);
    const [assignTo, setAssignTo] = useState(null);

    const [debouncedQuery, setDebouncedQuery] = useState('');
    const [query, setQuery] = useState('');

    // debouncing
    useEffect(() => {
        if (isMounted) {
            const timer = setTimeout(() => {
                setDebouncedQuery(query);
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [query]);

    //validator
    const [, forceUpdate] = useState() //this is a dummy state, when form submitted, change the state so that message is rendered
    const simpleValidator = useRef(new SimpleReactValidator({
            autoForceUpdate: {forceUpdate: forceUpdate},
            className: 'small text-danger mdi mdi-alert pt-1 pl-1'
    }));

    useEffect(() => {
        loadData();
    }, [])

    useEffect(() => {
        if (props.data) {
            setGroupName(props.data.name);
            setGroupType(parseInt(props.data.method));
            setNotClaimWithIn(props.data.time_to_auto_assign);
            setAssignTo(props.data.assigned_to);
            let userArray = [];
            props.data.group_users.forEach(element => {
                userArray.push(element.id)
            });
            setSelectedUserIds(userArray);
        }
    }, [props.data])

    const toggle = () => {
        props.hideModal();
    }

    const toggleAfterCreate = () => {
        props.hideModalAndFetch();
    }

    const loadData = () => {
        getTeamUsers({})
        .then(response => {
            setTeamUsers(response.data.data);
            setComponentLoading(false);
        });
    }

    const handelCheckBox = (_userId) => {
        let usersArray = [...selectedUserIds];

        if (usersArray.includes(_userId)) {
            usersArray = usersArray.filter(function(item) {
                return item !== _userId
            });
        } else {
            usersArray.push(_userId);
        }

        setSelectedUserIds(usersArray);
    }

    const handleGroupTypeChange = (event) => {
        setGroupType(parseInt(event.target.value));
    };

    const onSubmitHandle = (e) =>{
        e.preventDefault();
        
        if (simpleValidator.current.allValid()) {
            let formData;

            if (props.data) {
                formData = {
                    groupName: groupName,
                    assigned_to: assignTo,
                    methodType: groupType,
                    time_to_auto_assign: notClaimWithIn,
                    selectedUsers: selectedUserIds,
                    group_id: props.data.id 
                }
            } else {
                formData = {
                    groupName: groupName,
                    assigned_to: assignTo,
                    methodType: groupType,
                    time_to_auto_assign: notClaimWithIn,
                    selectedUsers: selectedUserIds,
                }
            }

            setLoading(true);

            updateOrCreateLeadGroup(formData).then(response => {
                if (response.data.status === 'success' || response.data.success === true) {
                    toggleAfterCreate();
                } else if (response.data.status === 'validation-error') {
                    for (var key of Object.keys(response.data.message)) {
                        let errors = response.data.message[key];
                        errors.forEach(error => {
                          window.showNotification("ERROR", error)
                        });
                    }
                } else {
                  window.showNotification("ERROR", response.data.message)
                }
            }).finally(() => {
                setLoading(false);
            });
        } else {
            simpleValidator.current.showMessages();
            forceUpdate(1);
        }
    }
    const filteredUsers = teamUsers.filter((item) => {
      const keyword = debouncedQuery.toUpperCase().trim();
      return keyword === "" || item.full_name.toUpperCase().includes(keyword);
    });

    return (
      <GlobalModal
        title={props.title}
        onClose={toggle}
        open={true}
        buttonText={
          !loading
            ? props.data
              ? "Update Group"
              : "Create New Group"
            : "Please Wait..."
        }
        buttonIcon={<AddCircleIcon />}
        onSubmit={onSubmitHandle}
        className={`${props.className} global-large-modal`}
      >
        {componentLoading ? (
          <DynamicSkeleton />
        ) : (
          <div className="modal__content__bottom">
            <div className="" style={{ marginBottom: "10px" }}>
              <p
                for=""
                className="active"
                style={{
                  marginBottom: "10px",
                  fontSize: "16px",
                  color: "#082852",
                }}
              >
                Group Name
              </p>
              <BasicInput
                type="text"
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
                placeholder="Enter Group Name"
                fullWidth
              />
              {simpleValidator.current.message(
                "group name",
                groupName,
                "required"
              )}
            </div>
            <div style={{ marginBottom: "10px" }}>
              <BasicInput
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Type and search"
                fullWidth
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon
                      style={{
                        color: "#656565",
                        marginRight: "5px",
                        marginTop: "5px",
                      }}
                    />
                  </InputAdornment>
                }
              />
              <div className="modal__search__preview">
                {Array.isArray(teamUsers) && teamUsers.length > 0 ? (
                  filteredUsers.length > 0 ? (
                    filteredUsers.map((item, index) => (
                      <label key={index}>
                        <input
                          type="checkbox"
                          className="filled-in"
                          checked={selectedUserIds.includes(parseInt(item.id))}
                          onChange={() => handelCheckBox(item.id)}
                        />
                        <span className="checkbox__text_1">
                          {item.full_name}
                        </span>
                      </label>
                    ))
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      No matching results found
                    </div>
                  )
                ) : (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "20px",
                    }}
                  >
                    No team users found
                  </div>
                )}

                {simpleValidator.current.message(
                  "user",
                  selectedUserIds,
                  "required"
                )}
              </div>
            </div>
            <div className="round_robin_checkbox modal__input__box d-flex justify-content-between">
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={groupType}
                onChange={handleGroupTypeChange}
              >
                <div className="modal__checkbox">
                  <label>
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="Round Robin"
                      checked={groupType === 1 ? true : null}
                    />
                    <span className="checkbox__text__holder">
                      <span className="checkbox__text_2">
                        Evenly distributes leads to agents in sequential order
                      </span>
                    </span>
                  </label>
                </div>
                <div className="modal__checkbox">
                  <label>
                    <FormControlLabel
                      value={2}
                      control={<Radio />}
                      label="First To Claim"
                      checked={groupType === 2 ? true : null}
                    />
                    <span className="checkbox__text__holder">
                      <span className="checkbox__text_2">
                        Multiple agents contacted, first to claim lead wins
                      </span>
                    </span>
                  </label>
                </div>
              </RadioGroup>
            </div>
            {groupType == 2 && (
              <div className="modal__input__box d-flex">
                <div className="modal__select d-flex justify-content-between align-items-center">
                  <p className="modal__select__text">If not claimed after</p>
                  <div className="input-field col s12 add_grp_select">
                    <input
                      type="number"
                      value={notClaimWithIn}
                      onChange={(e) => setNotClaimWithIn(e.target.value)}
                    />
                  </div>
                </div>
                <div className="modal__select d-flex justify-content-between align-items-center">
                  <p className="modal__select__text">Assign to</p>
                  <div className="input-field col s12 add_grp_select">
                    <Select
                      value={assignTo}
                      onChange={(e) => setAssignTo(e.target.value)}
                    >
                      {teamUsers.map((item, index) => (
                        <MenuItem
                          className="menuitem-li-hover-color-with-border"
                          key={index}
                          value={item.id}
                        >
                          {item.full_name}
                        </MenuItem>
                      ))}
                    </Select>
                    {simpleValidator.current.message("", assignTo, "required")}
                    <span className="select_icon">
                      <svg
                        width="14"
                        height="7"
                        viewBox="0 0 14 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 1L7 6L13 1"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </GlobalModal>
    );
}

export default LeadGroupModal;