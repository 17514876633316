import { Button, Dialog, makeStyles } from "@material-ui/core";
import React from "react";
import Utils from "../../../helpers/Utils";

const useStyles = makeStyles({
  container: {
    minWidth: '650px',
    backgroundColor: 'var(--white) !important',

  },
  bodyContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "32px",
    textAlign: "center",
    backgroundColor: 'var(--white) !important',
  },
  title: {
    height: "unset !important",
    color: "var(--dark_blue)",
    fontWeight: 600,
    fontSize: "18px",
    marginBottom: "20px",
    marginTop: "20px",
  },
  description: {
    height: "unset !important",
    color: "var(--dark_blue)",
    fontSize: "15px",
  },
  closeButton: {
    color: (props) => props.isCampaignStart ? "var(--white)" : "var(--dark_blue)",
    width: "100px !important",
    border: "1px solid #ccc !important",
    fontWeight: 600,
    marginRight: "8px",
    textTransform: "capitalize !important",
    backgroundColor: (props) => props.isCampaignStart ? "var(--light_blue)" : "var(--white)",
    "&:focus,&:hover": {
      backgroundColor: (props) => props.isCampaignStart ? "var(--light_blue)" : "var(--white)",
    },
  },
  saveButton: {
    color: "#FFF",
    width: "100px !important",
    fontWeight: 600,
    backgroundColor: "var(--light_blue)",
    textTransform: "capitalize",
    "&:focus,&:hover": {
      background: "var(--light_blue)",
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '32px',
    backgroundColor: 'var(--white) !important'
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  }

});

const CampaignStatusModal = ({
  open,
  onClose,
  campaignStatus,
  campaignStatusStart,
  startCampaign,
}) => {
  const isCampaignStart = (campaignStatus === campaignStatusStart);
  const classes = useStyles({ isCampaignStart });

  const handleClose = () => {
    Utils.removeQueryParam(["redirect-from"]);
    onClose();
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          minWidth: "650px",
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <div
        className={classes.container}
      >
        <div className={classes.bodyContainer}>
          <div style={{ width: "70px", height: "70px" }}>
            <img
              src={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/1.0.0/campaign%20rocket%20icon.png`}
              alt="logo"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </div>
          <div>
            <h5 className={classes.title}>
              Your Contacts Have Been Added To The Campaign!
            </h5>
            {
              campaignStatus !== campaignStatusStart && (
                <p className={classes.description}>
                  If you're ready to start your campaign, click the start button. If
                  you need to make additional edits first, click exit
                </p>
              )}
          </div>
        </div>

        <div className={classes.buttonContainer}>
          <div className={classes.button}>
            <Button
              variant="outlined"
              onClick={handleClose}
              className={classes.closeButton}
            >
              Exit
            </Button>
            {campaignStatus !== campaignStatusStart && (
              <Button
                onClick={startCampaign}
                className={classes.saveButton}
                variant="contained"
                disableElevation
              >
                Start
              </Button>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default CampaignStatusModal;
