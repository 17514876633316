/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from 'react'
import { connect } from 'react-redux'
import { Button, TextField } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CloseIcon from '@material-ui/icons/Close';
import HelpIcon from '@material-ui/icons/Help';
import { QuickReplyType } from '../../../DirectCardSend/assets/CoreConstants';
import Utils from '../../../../../helpers/Utils';
import Messages from '../../../../../constants/Messages';
import SwitchC from '../../../../Common/Switch/Switch';
import BootstrapTooltip from '../../../../Common/BootstrapTooltip';
import GlobalCustomFieldGrouped from '../../../../globals/GlobalCustomFieldGrouped/Index';
import { IconList } from '../../../../../constants/IconList';
import ModalComponent from '../../../../Common/modal/ModalComponent';
import UnicodeAlert from '../../../CampaignDetails/alert-popup/UnicodeAlert';

const App = props => {
    const textareaRef = useRef(null);
    const uploderRef = useRef(null);
    const previewRef = useRef(null);

    const [message, setMessage] = useState('')
    const [contentType, setContentType] = useState(QuickReplyType.SMS)
    const [smsCount,setSmsCount] = useState({
        smslength:0,
        sms:0
    });
    /* for adding unsubscribed message */
    const [addUnsubscriedText, setAddUnsubscriedText] = useState(false); 

    const [isShowUnicodeAlert, setIsShowUnicodeAlert] = useState(false)

    useEffect(() => {
        if(props.template !== undefined && props.template !== null) {
            setMessage(props.template.message);
            setContentType(props.template.type);
            previewRef.current.src = props.template.video_file_url
            let countResult = Utils.countSms(props.template.message)
            setSmsCount({
                ...smsCount,
                smslength:countResult.length,
                sms:countResult.sms
            })
        }
    },[props.template]);

    const handleBodyPersonalize = (event) => {
        let start = textareaRef.current.selectionStart;
        let end = textareaRef.current.selectionEnd;
        let text = textareaRef.current.value;
        let before = text.substring(0, start);
        let after = text.substring(end, text.length);
        let newText = (before + event + after);
    
        setMessage(newText);
        if(addUnsubscriedText){
            props.handlePreview((newText+"\r\nReply STOP to unsubscribe."));
        }
        else{
            props.handlePreview(newText);
        }

        setTimeout(() => {
            //Lines below not works without timeout
            textareaRef.current.selectionStart = textareaRef.current.selectionEnd = start + event.length;
            textareaRef.current.focus();
        }, 500);
    }
    
    const handleSaveTemplate = () => {
        setIsShowUnicodeAlert(false);
        let formData = []
        // check validation
        if(message.trim() == ''){
          window.showNotification("ERROR", Messages.emptyMessageBody)
            textareaRef.current.focus()
            return false
        }

        formData['body_sms'] = message
        if(addUnsubscriedText){
            formData['body_sms'] = (message+"\r\nReply STOP to unsubscribe.")
        }
        else{
            formData['body_sms'] = message
        }

        if(contentType == QuickReplyType.MMS){
            formData['content'] = QuickReplyType.MMS
            formData['file_media'] = uploderRef.current.files[0]
        }
        props.handleSaveTemplate(formData)
    }

    const handleChangeEditor = (event) => {
        let countResult = Utils.countSms(event)
        setSmsCount({
            ...smsCount,
            smslength:countResult.length,
            sms:countResult.sms
        })
        setMessage(event)
        if(addUnsubscriedText){
            props.handlePreview((event+"<br/>Reply STOP to unsubscribe."));
        }
        else{
            props.handlePreview(event);
        }
        // props.handlePreview(event)
    }
    
    const hanleFileChange = () => {
        props.handleContentType(QuickReplyType.MMS)
        setContentType(QuickReplyType.MMS)
        let input_field = uploderRef.current;
        let preview_file = previewRef.current
        preview_file.src = URL.createObjectURL(input_field.files[0])
        props.handlePreviewFile(URL.createObjectURL(input_field.files[0]))
    }

    const handleRemoveImage = () => {
        props.handleContentType(QuickReplyType.SMS)
        setContentType(QuickReplyType.SMS)
        let preview_file = previewRef.current
        preview_file.src = ""
        props.handlePreviewFile(null)
    }

    const renderUnsubTooltip = () => {
        let whole_message = "";
        if(message.length > 0){
            whole_message = message + "\r\nReply STOP to unsubscribe.";
        }
        else{
            whole_message = "Reply STOP to unsubscribe.";
        }
        if(!addUnsubscriedText){
            whole_message = message;
        }
        let countResult = Utils.countSms(whole_message);
        return(
            <div>
                {addUnsubscriedText &&
                <div>
                    <p style={{padding: 0, margin:0, underLine: '1px solid', fontSize:'12px'}}><b>Receiver Text: </b></p>
                    {message.length > 0 ?
                        <i>{message}<br/>Reply STOP to unsubscribe.</i>
                    :
                        <i>Reply STOP to unsubscribe.</i>
                    }
                </div>
                }
                {!addUnsubscriedText &&
                <div>
                    <p style={{padding: 0, margin:0, underLine: '1px solid', fontSize:'12px'}}><b>Receiver Text: </b></p>
                    <i>{message}</i>
                </div>
                }
                <div><span>Total SMS: {countResult.length}/{countResult.sms}</span></div>
                <span style={{color: 'red'}}><i>'Reply STOP to unsubscribe.'</i> text will be appended automatically if you ON this.</span>
            </div>
        );
    }

    const handleUnSubTextSwitch = (value) => {
        setAddUnsubscriedText(value)
        if(value){
            props.handlePreview((message+"<br/>Reply STOP to unsubscribe."));
        }
        else{
            props.handlePreview(message);
        }
    }

    /* for unicode alert */
    const handleEdit = () => {
        setIsShowUnicodeAlert(false)
    }
    const handleAddTemplate = (e) => {
        /* check unicode */
        const hasUniCode = checkHasUniCode(message.trim())
        if(hasUniCode){
            setIsShowUnicodeAlert(true)
        }
        else{
            handleSaveTemplate()
        }
    }
    const checkHasUniCode = (text) => {
        // eslint-disable-next-line no-control-regex
        if (!text.length) return false;
        if (text.charCodeAt(0) > 255) return true;
        for (var i = 0, n = text.length; i < n; i++) {
            if (text.charCodeAt( i ) > 255) { 
                return true;
            }
            // eslint-disable-next-line no-control-regex
            let regex = /[^\u0000-\u00FF]/;
            const codePoint =  text.codePointAt(i);
            const hexCodePoint = codePoint.toString(16);
            let testing = toUTF16(hexCodePoint)
            if(regex.test(testing)){
                return true
            }
        }
        return false;
    }
    function toUTF16(codePoint) {
        var TEN_BITS = parseInt('1111111111', 2);
        function u(codeUnit) {
            return '\\u'+codeUnit.toString(16).toUpperCase();
        }
    
        if (codePoint <= 0xFFFF) {
            return u(codePoint);
        }
        codePoint -= 0x10000;
    
        // Shift right to get to most significant 10 bits
        var leadingSurrogate = 0xD800 | (codePoint >> 10);
    
        // Mask to get least significant 10 bits
        var trailingSurrogate = 0xDC00 | (codePoint & TEN_BITS);
    
        return u(leadingSurrogate) + u(trailingSurrogate);
        // return u(leadingSurrogate);
    }

    return(
        <div id="text" className="">
            <TextField
                multiline
                rows={4}
                placeholder={"Type your Message"}
                variant="outlined"
                value={message}
                style={{width:"100%"}}
                inputRef={textareaRef}
                onChange={(e) => handleChangeEditor(e.target.value)}
            />

            <p style={{marginTop:"5px"}}>Total SMS: {smsCount.smslength}/{smsCount.sms}</p>

            <div className="add__unsubscried__text">
                <span>Append <small><i>Reply STOP to unsubscribe</i></small></span>
                <SwitchC 
                    status={addUnsubscriedText} 
                    brandColor={"#27AE60"} 
                    onChange={(value) => handleUnSubTextSwitch(value)}
                    onText={''}
                    offText={''}
                    minWidth40={true}
                />
                <BootstrapTooltip arrow placement={'top'} title={renderUnsubTooltip()}>
                    <HelpIcon fontSize="small" />
                </BootstrapTooltip>
            </div>

            <div className="under_tab_buttons">
                <div className="d-flex justify-content-between">

                    <div className="d-flex">
                        <GlobalCustomFieldGrouped className={"marketing__module__personalized_tag"} labelText={"Personalize"} handleSelect={handleBodyPersonalize}/>
                        <div className="file-field input-field upload_bitton alt">
                            <div className="btn d-flex align-items-center">
                                {IconList.uploadIcon}											
                                <span>Upload Attachment</span>
                                <input ref={uploderRef} type="file" id="add-quick-reply-mms-input" accept="image/png, image/gif, image/jpeg" onChange={hanleFileChange}/>
                            </div>
                        </div>

                    </div>


                    {/* <div className="save_template_button cursor-pointer" onClick={handleSaveTemplate}> */}
                    <div className="save_template_button cursor-pointer" onClick={handleAddTemplate}>
                        <a onClick={(e) => e.preventDefault()} className="accent--bg--color">
                            {props.submitingTemplate ? <MoreHorizIcon /> : IconList.saveTemplate}
                            <span>{props.submitingTemplate ? 'Saving' : 'Save'} Template</span>
                        </a>
                    </div>

                </div>
            </div>

            <div style={{height: 200, width: 300, position: "relative"}} className={` ${contentType == QuickReplyType.MMS ? '' : 'd-none'}`}>
                <img alt="" ref={previewRef} className={`global__message_img ${contentType == QuickReplyType.MMS ? '' : 'd-none'}`} style={{height:'100%', width:'100%'}} id="add-quick-reply-image-preview" src=""/>
                <Button className="remove__icon" type="button" onClick={handleRemoveImage}><CloseIcon /></Button>
            </div>

            <ModalComponent 
                isShow={isShowUnicodeAlert}
                component={<UnicodeAlert handleEdit={handleEdit} handleSend={handleSaveTemplate} />}
                blockBackdrop={true}
                style={{
                    width: '590px',
                    height: '390px'
                }}
            /> 
        </div>
    )
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({

})

const AddSmsTemplate = connect(mapStateToProps, mapDispatchToProps)(App)
export default AddSmsTemplate