/* eslint-disable eqeqeq */
import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import moment from 'moment-timezone';
import "react-datepicker/dist/react-datepicker.css";
import './time-setting.css';
import CustomDatePicker from './CustomDatePicker';
import GlobalCustomSelect from '../globals/FormComponents/GlobalCustomSelect';

class TimeSetting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabIndex: 0,
            startDate: new Date(moment.tz(props.user !== null ? props.user.timezone : 'UTC').format('YYYY/MM/DD')),
            // startDate: moment(new Date()).format("YYYY-MM-DD"),
            arrSlotTimeHour1 : '08',
            arrSlotTimeMin1 : '00',
        };
        this.timeIntervalOptions=this.timeIntervalOptions.bind(this);
    }

    onSelect = (index)=> {
        let self = this;
        this.setState({
            tabIndex: index,
            startDate: new Date(moment.tz(this.props.user !== null ? this.props.user.timezone : 'UTC').format('YYYY/MM/DD')),
            // startDate: moment(new Date()).format("YYYY-MM-DD"),
            arrSlotTimeHour1 : '08',
            arrSlotTimeMin1 : '00'
        },()=> this.props.timeSettingValue(
            {
                tabIndex: self.state.tabIndex,
                time: self.state.arrSlotTimeHour1+':'+self.state.arrSlotTimeMin1,
                date: self.state.startDate,
            }
        ));
    };


    handleChange =(date)=> {
        this.setState({
            startDate: date
        }, ()=> this.props.timeSettingValue(
            {
                tabIndex: this.state.tabIndex,
                time: this.state.arrSlotTimeHour1+':'+this.state.arrSlotTimeMin1,
                date: this.state.startDate,
            }
        ));
    }

    onTimeChange(options){

        let selectedTime =options.target.value.split(':')
        var arrslotTimeHour1 = ('0' + selectedTime[0]).slice(-2);
        var arrSlotTimeMin1 = ('0' + selectedTime[1]).slice(-2);

        this.setState({
            arrSlotTimeMin1 : arrSlotTimeMin1,
            arrSlotTimeHour1 : arrslotTimeHour1
        }, ()=> this.props.timeSettingValue(
            {
                tabIndex: this.state.tabIndex,
                time: this.state.arrSlotTimeHour1+':'+this.state.arrSlotTimeMin1,
                date: this.state.startDate,
            }
        ));
    }
    getTimezone = (timezone) => {
        var zone = 'Eastern Time';

        switch(timezone) {
            case "EST":
                zone = 'Eastern Time';
                break;
            case "America/Chicago":
                zone = 'Central Time';
                break;
            case "America/Denver":
                zone = 'Mountain Time';
                break;
            case "America/Phoenix":
                zone = 'Arizona Time';
                break;
            case "America/Los_Angeles":
                zone = 'Pacific Time';
                break;
            case "America/Anchorage":
                zone = 'Alaska Time';
                break;
            default:
                zone = 'Hawaii Time'
        }

        return zone;

    };

    timeIntervalOptions(interval){
        let option=[];
        for (let ap=1;ap<=2;ap++){
            for (let i=0;i<12;i++){
                for(let j=0;j<60/interval;j++){
                    let hv=i;let h=i;
                    if(i==0) h=12
                    if(ap!=1) hv= hv+12;
                    // option.push(<option value={('0' + hv).slice(-2)+':'+('0' + interval*j).slice(-2)} data-content="<i className='fa fa-address-book-o' aria-hidden='true'></i>Option1">{('0' + h).slice(-2)+':'+('0' + interval*j).slice(-2)+' '+(ap==1?'AM':'PM')}</option>)
                    option.push({
                        label : (('0' + h).slice(-2)+':'+('0' + interval*j).slice(-2)+' '+(ap==1?'AM':'PM')),
                        value: (('0' + hv).slice(-2)+':'+('0' + interval*j).slice(-2))
                    })
                }
            }
        }
        return option;
    }

    render() {

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12 text-center mt-3">
                        <label><h5 className={'h5'}>Select when to send this followup</h5></label>
                    </div>
                </div>
                <Tabs selectedIndex={this.state.tabIndex} onSelect={this.onSelect}>
                    <div className="timeSetting m-portlet m-portlet--tabs popup-tab m-portlet--unair campaign-followup-tabs custom-scheduling-tab mb-0">
                        <div className="m-portlet__head">
                            <div className="m-portlet__head-tools">
                                <div className="react-tabs-header d-flex">
                                    <TabList>
                                        <Tab>
                                            Immediately
                                        </Tab>

                                        <Tab>
                                           On a custom date
                                        </Tab>

                                    </TabList>
                                </div>
                            </div>
                        </div>
                        <div className="m-portlet__body">
                            <div className="react-tabs-body">
                                <div className="tab-content text-center">
                                    <TabPanel>
                                       {/* Sending immediately */}
                                    </TabPanel>
                                    <TabPanel>

                                        <div className="form-group d-flex justify-content-center w-100 py-2">
                                            <div className='pr-2 d-flex align-items-center date-picker-at-campaign-quick-broadcast'>
                                                Date
                                                <CustomDatePicker
                                                    activityDate={this.state.startDate}
                                                    onChange={this.handleChange}
                                                />
                                                {/* <DatePicker
                                                    minDate={new Date(moment.tz(this.props.user !== null ? this.props.user.timezone : 'UTC').format('YYYY/MM/DD HH:mm:ss'))}
                                                    selected={this.state.startDate}
                                                    onChange={this.handleChange}
                                                    className={"form-control globalFormControlItem alt"}
                                                /> */} at
                                            </div>
                                            <div className="pl-2 d-flex align-items-center global__custom_select">
                                                <div className={"pr-2 timepicker-wrapper"}>
                                                    <GlobalCustomSelect
                                                        placeholder="Time"
                                                        data={this.timeIntervalOptions(15)}
                                                        // value={min}
                                                        value={this.state.arrSlotTimeHour1 + ':' + this.state.arrSlotTimeMin1}
                                                        handleOnChange={this.onTimeChange.bind(this)}
                                                        value_field={'value'}
                                                        title_field={'label'}
                                                    />
                                                    {/* <select name="" className={'form-control globalFormControlItem'}
                                                            onChange={this.onTimeChange.bind(this)}
                                                            value={this.state.arrSlotTimeHour1 + ':' + this.state.arrSlotTimeMin1}
                                                            style={{'height': '40px', 'font-size': '18px'}}>
                                                        {this.timeIntervalOptions(15)}
                                                    </select> */}
                                                </div>
                                                <span> {this.props.user?.timezone}</span>
                                            </div>
                                        </div>

                                    </TabPanel>

                                </div>
                            </div>
                        </div>
                    </div>
                </Tabs>
            </React.Fragment>

        );
    }
}


export default TimeSetting;
