/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from 'react'
import { connect } from 'react-redux'
import { IconList } from '../../../constants/IconList'
import CustomAudioPlayer from '../../globals/CustomAudioPlayer'
import RecordRTC, { StereoAudioRecorder } from 'recordrtc';
import { QuickReplyType } from '../../../constants/CoreConstants';
import Messages from '../../../constants/Messages';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import checkFileMimeType from '../../../helpers/CheckMimeType';

let recordAudio;
let videoStream;
var totalSeconds = 0;
var interval = '';

const App = props => {

    const uploadFileRef = useRef(null)

    const [fileUrl, setFileUrl] = useState('');
    const [blobFile, setBlobFile] = useState(null);
    const [audioTimer, setAudioTimer] = useState(totalSeconds);
    const [recording, setRecording] = useState(false);
    const [audioFileUpload, setAudioFileUpload] = useState(false);
    const [fileId,setFileId] = useState(null);
    const [source,setSource] = useState('');

    useEffect(() => {
        if(props.template !== undefined && props.template !== null) {
            if (props.template.template_files != null) {
                setFileUrl(props.template.template_files.file);
                setFileId(props.template.template_file_id);
                props.handlePreviewFile(props.template.template_files.file);
            } else {
                setFileUrl(props.template.video_file_url);
                setFileId(props.template.template_file_id);
                props.handlePreviewFile(props.template.video_file_url)
            }    
        }
    },[props.template]);

    /* recording function */
    const openMic = (e) => {
        e.preventDefault();
        let hasGetUserMedia = '';
        try {
            hasGetUserMedia = !!(navigator.mediaDevices || navigator.mediaDevices.getUserMedia({audio: true}) || navigator.webkitGetUserMedia ||
                navigator.mozGetUserMedia || navigator.msGetUserMedia);
        } catch (e) {
            return 0;
        }
        requestUserMedia();
    }

    const requestUserMedia = () => {
        if(interval !== ''){
          window.showNotification("ERROR", "A record is in progress !")
            return false
        }
        videoStream=null;
        navigator.mediaDevices.getUserMedia({audio: true})
            .then(function (mediaStream) {

                let audio = document.getElementById('record-audio');
                    audio.srcObject=mediaStream;
                    videoStream = mediaStream;
                    audio.onloadedmetadata = function(e) {
                        audio.play();
                    };
                    
                interval = setInterval(() => {
                    if(totalSeconds == 179){
                        stopRecord(true)
                    }
                    else{
                        setAudioTimer(prevTime => prevTime + 1)
                        totalSeconds = totalSeconds + 1
                    }
                }, 1000);
                setFileUrl('')
                startRecord()
                setRecording(true)
                setAudioFileUpload(false)
                props.handlePreviewFile(null)
            })
            .catch(function (err) {
                return 0;
            });
    }

    const startRecord = () => {
        totalSeconds = 0;
        recordAudio = RecordRTC(videoStream, {type: 'audio/webm', mimeType: 'audio/webm', recorderType: StereoAudioRecorder, audioBitsPerSecond: 128000});
        recordAudio.startRecording();
    }

    const stopRecord = (stop = false) => {
        if(!stop && !recording){
            return false
        }
        
        if (totalSeconds < 5) {
          window.showNotification("WARNING", "Audio duration must be between 5 seconds to 3 minutes")
            return false;
        }
        recordAudio.stopRecording(() => {
            let urlCreator = window.URL || window.webkitURL;
            let audioFileUrl = urlCreator.createObjectURL(recordAudio.blob);
            videoStream.getTracks().forEach(track => track.stop());

            let mimeType = 'audio/webm';
            let blob = recordAudio instanceof Blob ? recordAudio : recordAudio.getBlob();
            blob = new File([blob], 'RecordRTC-audio.webm', {
                type: mimeType
            });
            setSource('record');
            setBlobFile(blob)
            setFileUrl(audioFileUrl)
            setRecording(false)
            clearInterval(interval)
            interval = ''
            setAudioTimer(0)
            totalSeconds=0
            props.handlePreview(audioFileUrl)
            props.handlePreviewFile(audioFileUrl)
            return false;
        });
    }

    const calculateTime = () => {
        let seconds = "00",
            minutes = "00";

        seconds = pad(audioTimer % 60)
        minutes = pad(parseInt(audioTimer / 60))

        return minutes + ":" + seconds;
    }

    const pad = (val) => {
        var valString = val.toString();
        if (valString.length < 2) {
            return "0" + valString;
        } else {
            return valString;
        }
    }
    /* record function end */

    const uploadFile = () => {
        if(recording){
            return false
        }
        setFileUrl('')
        props.handlePreviewFile(null)
        setAudioFileUpload(true)
        if (!document.getElementById('audio-file-upload-add-template').files.length) return;
        let urlCreator = window.URL || window.webkitURL;
        const urlObj = urlCreator.createObjectURL(document.getElementById('audio-file-upload-add-template').files[0]);
        
        
        let file = document.getElementById('audio-file-upload-add-template').files[0];
        if(file.size > 5242880){
          window.showNotification("WARNING", "File is too big")
            return false;
        };
        setTimeout(() => {
            setFileUrl(urlObj)
            setSource('upload');
        }, 100);
        props.handlePreview(urlObj)
        setTimeout(() => {
            props.handlePreviewFile(urlObj)
        }, 100);

        // checkFileMimeType(document.getElementById('audio-file-upload-add-template').files[0], (mimeType) => {
        //     console.log(mimeType)
        //     if(mimeType != 'audio'){
                
        //     }
        //     else{
        //         setTimeout(() => {
        //             setFileUrl(urlObj)
        //             setSource('upload');
        //         }, 100);
        //         props.handlePreview(urlObj)
        //         setTimeout(() => {
        //             props.handlePreviewFile(urlObj)
        //         }, 100);
        //     }
        // })
    }

    const handleSaveTemplate = () => {
        if(recording){
          window.showNotification("WARNING", "Please stop recording first")
            return false
        }
        let formData = []
        // check validation
        if(fileUrl.trim() == ''){
          window.showNotification("ERROR", Messages.emptyVoiceFile)
            return false
        }

        formData['content'] = QuickReplyType.VOICE
        if(audioFileUpload){
            if (!document.getElementById('audio-file-upload-add-template').files.length) {
              window.showNotification("ERROR", 'Somethings went wrong !')
                return
            };
            formData['file_media_2'] = document.getElementById('audio-file-upload-add-template').files[0]
        }
        else{
            formData['file_media_2'] = blobFile
        }

        if(fileId !== null) {
            formData['fileId'] = fileId
            formData['source'] = source
        }

        props.handleSaveTemplate(formData)
    }

    const clickUpload = (e) => {
        e.preventDefault();
        if(uploadFileRef != null){
            uploadFileRef.current.click();
        }
    }
    
    return(
        <div id="voice" className="">
            <div className="voice_email_container">
                <p>Record/Upload a new audio file</p>
                <div className="d-flex record_stop_upload_container">
                    <div className="record_stop_upload_box">
                        
                        <div className="record_stop_upload_buttons d-flex">
                            <div className="record_button" onClick={openMic}>
                                <a href="#!" onClick={e => e.preventDefault()}>
                                    {IconList.recordVoiceInAddTemplate}																	
                                    <p>Record</p>																
                                </a>
                            </div>

                            <div className="stop_button" onClick={stopRecord} style={recording ? {opacity : 1} : {opacity : 0.3}}>
                                <a href="#!" onClick={e => e.preventDefault()}>
                                    {IconList.stopRecordInAddTemplate}																	
                                    <p>Stop</p>																
                                </a>
                            </div>

                            <div className="upload_button" style={recording ? {opacity : 0.3} : {opacity : 1}}>
                                <a href="#!" onClick={clickUpload}>
                                    <label
                                        className="bg-light-blue-10 circle d-flex justify-content-center align-items-center">
                                        {IconList.uploadIconBlue}
                                    </label>
                                    <p>Upload</p>																
                                </a>
                                <input type="file" disabled={recording ? true : false} className="d-none"  id="audio-file-upload-add-template" accept="audio/mp3, video/webm" ref={uploadFileRef} onChange={uploadFile} />
                            </div>
                        </div>
                    </div>																	

                    <div className="audio_player">
                        {audioTimer.toString() != '0' &&
                            <span>{calculateTime(audioTimer)}</span>
                        }
                        <audio controls='controls' id='record-audio' muted className="d-none"/>
                        {fileUrl != '' &&
                        <CustomAudioPlayer 
                            hanldePlayer={() => {}}
                            activePlayerId={fileUrl}
                            unique_key={fileUrl}
                            src={fileUrl}
                        />
                        }
                        {/* <div className="controls d-flex align-items-center">
                        </div> */}
                    </div>

                    
                </div>
                <div className="warning_box d-flex">
                    {IconList.infoIconRed}
                    <p>Audio duration must be between 5 seconds to 3 minutes</p>
                </div>

            </div>

            <div className="under_tab_buttons">
                <div className="d-flex justify-content-end">												
                    <div className="save_template_button cursor-pointer" onClick={handleSaveTemplate}>
                        <a className="accent--bg--color" onClick={(e) => e.preventDefault()}>
                            {props.submitingTemplate ? <MoreHorizIcon /> : IconList.saveTemplate}
                            <span>{props.submitingTemplate ? "Saving" : "Save" } Template</span>
                        </a>
                    </div>
                </div>
            </div>

        </div>
    )

}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({

})

const AddVoiceTemplate = connect(mapStateToProps, mapDispatchToProps)(App)
export default AddVoiceTemplate