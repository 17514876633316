import React from "react";
import "./style.css";

const ActionControl = ({isDisabled = false, alertMessage= "Action Restricted", status="warning", children}) => {
  const handleAlert = () => {
      if (isDisabled && window.showNotification){
          window.showNotification(status, alertMessage);
      }
  };

  return(
    <div onClick={handleAlert}>
      <div aria-disabled={isDisabled} className={isDisabled ? "action-disabled-action-control" : ""}>
        {children}
      </div>
    </div>
  );
};

export default ActionControl;
