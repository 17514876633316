/* eslint-disable eqeqeq */

import { useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";
import { getMessageTemplates, storeCurrentPageAction, storePerPageAction } from "../../../actions/marketingAction";
import {QuickReplyType} from "../../../constants/CoreConstants";
import Utils from "../../../helpers/Utils";
import useDelayCallback from "../../../hooks/useDelayCallback";
import GlobalSearchBox from "../../globals/GlobalSearchBox";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { handleChangeActionTypes } from '../../Video/redux/videoEmailAction';
import { useIsMounted } from "../../../hooks/IsMounted";
const ALL_TYPES = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];

const QuickRepliesHeadApp = (props) => {

    const dispatch = useDispatch();
    const isMounted = useIsMounted();
    const [searchText,setSearchText] = useState("");
    const perPage = useSelector((state) => state.marketingReducer.perPage);
    const currentPage = useSelector((state) => state.marketingReducer.currentPage);
    const fetchingMore = useSelector((state) => state.marketingReducer.fetchingMore);
    let firstLanding = true;

    useEffect(() => {
        loadData();
    },[]); // eslint-disable-line react-hooks/exhaustive-deps
    

    const loadData = () => {
        let per_page = perPage;
        if(firstLanding){
            per_page = 20;
            // 80 + 10 + 88 + 10 + 34 + 40
            let calculate_per_page = ((window.innerHeight - 252) / 370);
            if(Math.floor(calculate_per_page) > 20){
                per_page = Math.floor(calculate_per_page);
            }
            dispatch(storePerPageAction(per_page));
            firstLanding = false;
        }

        dispatch(storeCurrentPageAction(0));
        dispatch(getMessageTemplates({
            'search_key' : searchText,
            'type' : props.type,
            'perPage': per_page,
            'page': 0
        }));
    }

    useDelayCallback(() => {
        if(isMounted) {
            loadData();
        }
    },[searchText]);

    useEffect(() => {
        if(fetchingMore){
            dispatch(getMessageTemplates({
                'search_key' : searchText,
                'type' : props.type,
                'perPage': perPage,
                'page': currentPage
            }))
        }
    }, [fetchingMore]) // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeType = (value) => {
        dispatch(storeCurrentPageAction(0));
        if(value == ''){
            dispatch(getMessageTemplates({
                'search_key' : searchText,
                'type' : ALL_TYPES,
                'perPage': perPage,
                'page': 0
            }));
            props.changeActionTypes(ALL_TYPES);
        }
        else{
            dispatch(getMessageTemplates({
                'search_key' : searchText,
                'type' : value,
                'perPage': perPage,
                'page': 0
            }));
            props.changeActionTypes(value);
        }
    }

    return (
        
        <div className="main_content_topbar d-flex justify-content-between align-items-center message__template">

            <h6 className="mr20pxSpaceAlt width-unset">Manage Quick Replies</h6>

            <div className="d-flex align-items-center">

                <div className="input-field">
                    <select value={props.type} onChange={e => onChangeType(e.target.value)}>
                        <option value="" selected>Show all templates</option>
                        <option value={QuickReplyType.SMS}>Text</option>
                        <option value={QuickReplyType.MMS}>MMS</option>
                        <option value={QuickReplyType.EMAIL}>Email</option>
                        <option value={QuickReplyType.VOICE}>Voicemail</option>
                        <option value={QuickReplyType.VIDEO_EMAIL}>Video Email</option>
                        {
                            Utils.getAccountData('agencyId') === 1 && 
                            <>
                                <option value={QuickReplyType.POSTCARD}>Postcard</option>
                                <option value={QuickReplyType.GREETING_CARD}>Greeting Card</option>
                                <option value={QuickReplyType.LETTER}>Letter</option>
                                <option value={QuickReplyType.GIFT}>Gift</option>
                            </>
                        }
                    </select>
                </div>

                {/* <div className="search_bar d-flex align-items-center">
                    <input />
                    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.2549 11.255H12.4649L12.1849 10.985C13.1649 9.845 13.7549 8.365 13.7549 6.755C13.7549 3.165 10.8449 0.255005 7.25488 0.255005C3.66488 0.255005 0.754883 3.165 0.754883 6.755C0.754883 10.345 3.66488 13.255 7.25488 13.255C8.86488 13.255 10.3449 12.665 11.4849 11.685L11.7549 11.965V12.755L16.7549 17.745L18.2449 16.255L13.2549 11.255ZM7.25488 11.255C4.76488 11.255 2.75488 9.245 2.75488 6.755C2.75488 4.26501 4.76488 2.255 7.25488 2.255C9.74488 2.255 11.7549 4.26501 11.7549 6.755C11.7549 9.245 9.74488 11.255 7.25488 11.255Z" fill="#133159" fillOpacity="0.5"/>
                    </svg>                                
                </div> */}

                <GlobalSearchBox 
                    wrapperClass="search_bar_quicktemplate_global"
                    type="search" 
                    placeholder="Search templates" 
                    autoComplete="off" 
                    onChange={(e) => setSearchText(e.target.value)}/>

                <div className="new_template_button">
                    <Link to={'/quick-reply-add-template'} className="accent--bg--color">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="10" cy="10" r="10" fill="white"/>
                            <path d="M13.8461 10C13.8461 10.3545 13.8153 10.641 13.4609 10.641H10.641V13.4609C10.641 13.8148 10.3544 13.8462 9.99996 13.8462C9.64548 13.8462 9.35894 13.8148 9.35894 13.4609V10.641H6.53906C6.18522 10.641 6.15381 10.3545 6.15381 10C6.15381 9.64554 6.18522 9.359 6.53906 9.359H9.35894V6.53913C9.35894 6.18464 9.64548 6.15387 9.99996 6.15387C10.3544 6.15387 10.641 6.18464 10.641 6.53913V9.359H13.4609C13.8153 9.359 13.8461 9.64554 13.8461 10Z" fill="#3C7EF3"/>
                        </svg>                
                        <span>New Template</span>                       
                    </Link>                                
                </div>
            </div>
        </div>
     );
}

const mapStateToProps = (state) => {
  return {
    type: state.marketingReducer.type,
  };
};

const mapDispatchToProps = dispatch => {
    return {
        changeActionTypes: (payload) => dispatch(handleChangeActionTypes(payload)),
    }
}

const QuickRepliesHead = connect(mapStateToProps, mapDispatchToProps)(QuickRepliesHeadApp)

export default QuickRepliesHead;