/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { useState, useRef, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import "./quickTemplate.css";
import moment from "moment";
import {
  PERMISSION_LEVELS,
  PERMISSION_MODULES,
  PlatTypeForPackage,
  PRIVACY_LEVEL,
  QUICK_REPLIES_OPTION_TYPE,
  QuickReplyType,
  TEMPLATE_ACTION,
} from "../../../../../constants/CoreConstants";
import { IconList } from "../../../../../constants/IconList";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Utils from "../../../../../helpers/Utils";
import AddSmsTemplate from "./AddSmsTemplate";
import AddEmailTemplate from "./AddEmailTemplate";
import AddVoiceTemplate from "./AddVoiceTemplate";
import AddDirectEmailTemplate from "./AddDirectEmailTemplate";
import CustomAudioPlayer from "../../../../globals/CustomAudioPlayer";
import RecordedVideo from "../../../../Video/RecordedVideo";
import Messages from "../../../../../constants/Messages";
import OutSideConstants from "../../../../../constants/ConstantFromOutside";
import Label from "../../../../../constants/Label.js";
import { addQuickReplyTemplate } from "../../../../../actions/marketingAction";
import { handleChangeActionTypes } from "../../../../Video/redux/videoEmailAction";
import BasicInput from "../../../../Common/BasicInput/BasicInput.js";
import StyledSelect from "../../../../Common/BasicSelect/StyledSelect.js";
import { getQuickRepliesFolder } from "../../../../../actions/quickRepliesAction.js";

const PrivacyLevelOptions = [
  { value: PRIVACY_LEVEL.PRIVACY_LEVEL_GLOBAL, title: "Global Quick Reply" },
  { value: PRIVACY_LEVEL.PRIVACY_LEVEL_PRIVATE, title: "My Quick Reply" },
];
const PrivacyLevelMyOption = [
  { value: PRIVACY_LEVEL.PRIVACY_LEVEL_PRIVATE, title: "My Quick Reply" },
];

const tabOptionsList = [
  {
    id: QuickReplyType.SMS,
    title: "Text",
    iconActive: IconList.smsIconBlue,
    iconInactive: IconList.smsIconDeepBlue,
  },
  {
    id: QuickReplyType.EMAIL,
    title: "Email",
    iconActive: IconList.emailIconBlue,
    iconInactive: IconList.emailIconDeepBlue,
  },
  {
    id: QuickReplyType.VIDEO_EMAIL,
    title: "Video Email",
    iconActive: IconList.videoIconBlue,
    iconInactive: IconList.videoIconDeepBlue,
  },
  {
    id: QuickReplyType.VOICE,
    title: "Voice",
    iconActive: IconList.voiceIconBlue,
    iconInactive: IconList.voiceIconDeepBlue,
  },
];

const cardOptions = {
  id: QuickReplyType.POSTCARD,
  title: "Direct Mail",
  iconActive: IconList.sendIconBlue,
  iconInactive: IconList.sendIconDeepBlue,
};

const ALL_TYPES = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];

const AddQuickTemplateGlobal = (props) => {
  const { quickRepliesFolderList, quickRepliesParams } = useSelector(
    (state) => state.quickRepliesReducer
  );
  const history = useHistory();

  // refs
  const titleRef = useRef(null);
  /* states variable start */
  const canUserManageGlobalItem = window.hasPermission(
    PERMISSION_MODULES.MANAGE_GLOBAL_ITEMS,PERMISSION_LEVELS.WRITE);
  const [tabOptions, setTabOptions] = useState([]);
  const [title, setTitle] = useState("");
  const [tabIndex, setTabIndex] = useState(QuickReplyType.SMS);
  const [contentType, setContentType] = useState(QuickReplyType.SMS);
  const [textForView, setTextForView] = useState("");
  const [fileShow, setFileShow] = useState(null);
  const [showSubject, setShowSubject] = useState("");
  const [userTemplateFolder, setUserTemplateFolder] = useState(null);
  const [privacyLevel, setPrivacyLevel] = useState(canUserManageGlobalItem ?  PrivacyLevelOptions[0] : PrivacyLevelMyOption[0]  || null);

  const [submitingTemplate, setSubmitingTemplate] = useState(false);
    const dispatch= useDispatch();
  /* states variable end */

  useEffect(() => {
    document.title = `Add Template | ${Utils.getAccountData(
      "AuthUserAgencyName"
    )}`;
    if (window.setActiveSidebar) {
      window.setActiveSidebar("marketing");
    }

    // const tabOptionsArr = [...tabOptionsList];
    if (
      Utils.getAccountData("agencyId") === 1 &&
      tabOptionsList.length < 5
      // && process.env.REACT_APP_PRODUCTION_MODE !== true
    ) {
      tabOptionsList.push(cardOptions);
    }
    setTabOptions(tabOptionsList);
  }, []);

  /* render functinos start */
  const getGroupNameById = (id, folderObject) => {
    if(id){
        return folderObject[id]
    } else {
        return '';
    }
};
  useEffect(()=>{
    if(Array.isArray(quickRepliesFolderList) && quickRepliesFolderList.length === 0){
        dispatch(getQuickRepliesFolder());
    };
    setPrivacyLevel(
      quickRepliesParams.privacy_level === QUICK_REPLIES_OPTION_TYPE.PRIVATE
    ? PrivacyLevelMyOption[0]
    : quickRepliesParams.privacy_level === QUICK_REPLIES_OPTION_TYPE.GLOBAL
    ? PrivacyLevelOptions[0]
    : canUserManageGlobalItem
    ? PrivacyLevelOptions[0]
    : PrivacyLevelMyOption[0] || null
    );
},[]);

useEffect(() => {
  if (!quickRepliesFolderList) return;
  const folderObj = quickRepliesFolderList.reduce((acc, folder) => {
    acc[folder.id] = folder.title;
    return acc;
  }, {});

  const formatUserTemplateFolder = quickRepliesParams.template_folder_id
    ? {
        id: quickRepliesParams.template_folder_id,
        title: getGroupNameById(quickRepliesParams.template_folder_id, folderObj),
      }
    : null;

  setUserTemplateFolder( formatUserTemplateFolder || quickRepliesFolderList[0] || null);
}, [quickRepliesFolderList]);

  const handleSelectTemplate = (selectedType) => {
    if (parseInt(props.type) === selectedType) {
      props.changeActionTypes(selectedType);
    } else {
      props.changeActionTypes(ALL_TYPES);
    }
  };
  const handleFolderChange = (selectedFolder) => {
    setUserTemplateFolder(selectedFolder);
  };
  const handlePrivacyChange = (privacy) => {
    setPrivacyLevel(privacy);
  };
  const leftInputPortion = () => {
    return (
      <div className="add_template_content">
        <h6>Title</h6>
        <div className="input-field">
          <BasicInput
            placeholder="Title Here"
            type="text"
            className="validate"
            onChange={(event) => setTitle(event.target.value)}
            ref={titleRef}
            fullWidth
          />
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "20px", 
          }}
        >
          <div style={{ marginTop: "12px" }}>
            <h6>Select a Folder</h6>
            <div className="input-field">
              <StyledSelect
                classNamePrefix="quick-replies"
                value={userTemplateFolder}
                onChange={handleFolderChange}
                getOptionValue={(folder) => folder.id}
                getOptionLabel={(folder) => folder.title}
                options={quickRepliesFolderList}
                isSearchable={false}
                placeholder="Select a Folder"
              />
            </div>
          </div>
          <div style={{ marginTop: "12px" }}>
            <h6>Select Privacy Type</h6>
            <div className="input-field">
              <StyledSelect
                classNamePrefix="quick-replies"
                value={privacyLevel || null}
                onChange={handlePrivacyChange}
                getOptionValue={(privacy) => privacy.value}
                getOptionLabel={(privacy) => privacy.title}
                options={canUserManageGlobalItem ? PrivacyLevelOptions : PrivacyLevelMyOption}
                isSearchable={false}
                placeholder="Select Privacy Type"
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="">
            <ul className="tabs">
              {tabOptions.map((item, index) => {
                return (
                  <li
                    className="tab"
                    onClick={() => onChangeTab(item.id)}
                    key={"add-template-tab" + index}
                  >
                    <a
                      className={item.id == tabIndex ? "active" : ""}
                      style={
                        index == tabOptions.length - 1
                          ? { borderRadius: "0px 10px 10px 0px" }
                          : {}
                      }
                      href="#!"
                      onClick={(e) => e.preventDefault()}
                    >
                      {item.id == tabIndex
                        ? item.iconActive
                        : item.iconInactive}
                      <span>{item.title}</span>
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="tab_content">
            {tabIndex === QuickReplyType.SMS ? (
              <AddSmsTemplate
                handleSaveTemplate={handleSaveTemplate}
                handlePreview={handlePreview}
                handlePreviewFile={handlePreviewFile}
                handleContentType={handleContentType}
                submitingTemplate={submitingTemplate}
              />
            ) : tabIndex === QuickReplyType.EMAIL ? (
              <AddEmailTemplate
                handleSaveTemplate={handleSaveTemplate}
                handlePreview={handlePreview}
                submitingTemplate={submitingTemplate}
                handleSubject={(value) => setShowSubject(value)}
              />
            ) : tabIndex === QuickReplyType.VIDEO_EMAIL ? (
              <p>Add video template</p>
            ) : // <AddVideoEmailTemplate
            //     handleSaveTemplate={handleSaveTemplate}
            //     contentType={contentType}
            //     handleContentType={handleContentType}
            //     submitingTemplate={submitingTemplate}
            //     handlePreviewFile={handlePreviewFile}
            // />
            tabIndex === QuickReplyType.VOICE ? (
              <AddVoiceTemplate
                handleSaveTemplate={handleSaveTemplate}
                handlePreview={handlePreview}
                handlePreviewFile={handlePreviewFile}
                submitingTemplate={submitingTemplate}
              />
            ) : (
              <AddDirectEmailTemplate
                handleSaveTemplate={handleSaveTemplate}
                submitingTemplate={submitingTemplate}
                editTemplate={false}
                updateTabOptionsForDM={setContentType}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  const rightViewPortion = () => {
    return (
      <div className="add_template_display">
        <div className="smartphone_image">
          <img
            src={
              process.env.REACT_APP_PUBLIC_URL_CUSTOM +
              "assets/images/quick-reply-template/smartphone.png"
            }
            alt=""
          />
          <div className="smartphone_display">
            <div className="display_number">
              {tabIndex != QuickReplyType.SMS ? (
                <h6 className="truncate" style={{ textTransform: "lowercase" }}>
                  {"example_email@example.mail"}
                </h6>
              ) : (
                <h6 className="truncate">
                  {Utils.formatPhoneNumber("+10175806261")}
                </h6>
              )}
            </div>

            <div className="message_box">
              <div className="display_message">
                <div className="message_time ">
                  <p className="right-align">
                    {moment(new Date()).format("YYYY-MM-DD hh:mm:ss A")}
                  </p>
                </div>
                <div className="display_message_body alt awesome__scroll_bar">
                  {showSubject != "" && (
                    <span className="messageSubject truncate">
                      {showSubject}
                    </span>
                  )}
                  {tabIndex != QuickReplyType.VOICE && (
                    <p
                      className="left-align"
                      dangerouslySetInnerHTML={{ __html: textForView }}
                    />
                  )}
                  {fileShow != null && tabIndex == QuickReplyType.SMS && (
                    <img
                      style={{ width: "100%" }}
                      className="display_message_body_img"
                      alt=""
                      src={fileShow}
                    />
                  )}
                  {fileShow != null && tabIndex == QuickReplyType.VOICE && (
                    <CustomAudioPlayer
                      hanldePlayer={() => {}}
                      activePlayerId={fileShow}
                      unique_key={fileShow}
                      src={fileShow}
                    />
                  )}

                  {fileShow != null &&
                    tabIndex == QuickReplyType.VIDEO_EMAIL && (
                      <RecordedVideo urlLink={fileShow} />
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  /* render functinos end */

  /* others */
  const handlePreview = (text) => {
    setTextForView(text);
  };

  const handlePreviewFile = (file) => {
    setFileShow(file);
  };

  const handleContentType = (type) => {
    setContentType(type);
  };

  const handleBackButton = () => {
    history.push('/quick-replies-global', { from: TEMPLATE_ACTION.CREATE, template_folder_id: userTemplateFolder?.id, privacy_level: privacyLevel?.value === PRIVACY_LEVEL.PRIVACY_LEVEL_PRIVATE ? QUICK_REPLIES_OPTION_TYPE.PRIVATE : QUICK_REPLIES_OPTION_TYPE.GLOBAL });
    if (typeof props.handleBack != "undefined") {
      props.handleBack();
    }
  };

  const onChangeTab = (tab) => {
    if (tab === QuickReplyType.VIDEO_EMAIL) {
      window.sendCommunication(
        {
          open: true,
          from: "quick_reply",
          responseCallback: handleSaveVideoTemplate,
        },
        "videoEmail"
      );
    } else {
      setShowSubject("");
      setFileShow(null);
      setTextForView("");
      setTabIndex(tab);
      setContentType(tab);
    }
  };

  const handleSaveVideoTemplate = (response) => {
    handleSaveTemplate(
      {
        videoId: response.email_video_id,
        email_body: response.message,
        email_subject: response.subject,
        title: response.title,
      },
      QuickReplyType.VIDEO_EMAIL
    );
  };

  const handleSaveTemplate = (data, tempTabIndex = '') => {

    if(submitingTemplate)return;
    // check validation
    setSubmitingTemplate(true)
    let formData = new FormData();
    if (tempTabIndex!= QuickReplyType.VIDEO_EMAIL) {
        if (title.trim() === '') {
            window.showNotification('ERROR', Messages.emptyTitleInAddQuickReply);
            titleRef.current.focus()
            setSubmitingTemplate(false)
            return false
        }
        formData.append('title', title)
    }

    //$packageType = ViewHelper::getPackTypeForView(auth()->id());
    let package_type = OutSideConstants.packageType
    formData.append('media_type', package_type == PlatTypeForPackage.PLAN_TYPE_STANDARD ? QuickReplyType.SMS : QuickReplyType.EMAIL)
    formData.append('content', tempTabIndex !== '' ? tempTabIndex :contentType)
    if(contentType == QuickReplyType.SMS){
        // sms save
        for (var key in data) {
            formData.append(key, data[key])
        }
        handleSelectTemplate (contentType);

    } else if(contentType == QuickReplyType.MMS){
        // mms save
        for (let key in data) {
            formData.append(key, data[key])
        }
        handleSelectTemplate (contentType);
    } else if(contentType == QuickReplyType.VOICE){
        // voice save
        for (let key in data) {
            formData.append(key, data[key])
        }
        handleSelectTemplate (contentType);
    } else if(tempTabIndex == QuickReplyType.VIDEO_EMAIL){
        // video email save
        for (let key in data) {
            formData.append(key, data[key])
        }
    } else if(contentType == QuickReplyType.EMAIL){
        // email save
        for (let key in data) {
            formData.append(key, data[key])
        }
        handleSelectTemplate (contentType);
    } else if(contentType == QuickReplyType.POSTCARD || contentType == QuickReplyType.GREETING_CARD || contentType == QuickReplyType.GIFT || contentType == QuickReplyType.LETTER){
        // direct mail save
        for (let key in data) {
            formData.append(key, data[key])
        }
        handleSelectTemplate (contentType);    
    }
    formData.append("privacy_level", privacyLevel?.value);
    formData.append("template_folder_id", userTemplateFolder?.id || quickRepliesFolderList[0]?.id );
    props.addQuickReplyTemplate(formData, (response) => {
        setSubmitingTemplate(false);
        if(response.status == 'success'){
            window.showNotification('SUCCESS',response.html);
            if (tempTabIndex == QuickReplyType.VIDEO_EMAIL) {
                window.sendCommunication({open: false}, 'videoEmail');
                handleSelectTemplate (tempTabIndex);
            };
            // todo here
            handleBackButton()
        }
        else if(response.status === 'validation-error'){
            // do validation here
            if(typeof response.html.title != 'undefined'){
                window.showNotification('ERROR', response.html.title)
            }
            else if(typeof response.html.voice_title != 'undefined'){
                window.showNotification('ERROR', response.html.voice_title)
            }
        }
        else{
            window.showNotification('ERROR','Something went wrong. Try again.')
        }
    }) 
}

  return (
    <div className="main_content_container add_template_page white">
      <div className="">
        <div className="main_content_topbar d-flex justify-content-between align-items-center">
          <h6>{Label.addQucikReplyTemplateHeadTitle}</h6>
          <div className="back_button">
            <Link className="accent--bg--color" to={"/quick-replies-global"}>
              {IconList.backCircleWhite}
              <span>Back</span>
            </Link>
          </div>
        </div>
        <div className="main_content_divider"></div>
        <div
          className={`main_content_description  ${
            tabIndex == QuickReplyType.VIDEO_EMAIL ? "activeVideoEmail" : ""
          }`}
        >
          {leftInputPortion()}
          {rightViewPortion()}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    type: state.marketingReducer.type,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addQuickReplyTemplate: (params, callback) =>
      dispatch(addQuickReplyTemplate(params, callback)),
    changeActionTypes: (payload) => dispatch(handleChangeActionTypes(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddQuickTemplateGlobal);
