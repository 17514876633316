import React, { useEffect, useRef, useState } from "react";
import { Tab, Tabs } from "@material-ui/core";
import { ContentStyles } from "./QuickRepliesStyle";
import StyledSelect from "../../../Common/BasicSelect/StyledSelect"
import { getTeamUsersApi } from "../../../../api/quickRepliesApi";
import QuickReplies from "./QuickReplyTemplate/QuickReplies";
import {  getQuickRepliesMoreData, handleQuickRepliesTab, handleSelectedQuickRepliesTypes, handleSelectedQuickRepliesUserId } from "../../../../actions/quickRepliesAction";
import { useDispatch, useSelector } from "react-redux";
import {  QUICK_REPLIES_OPTION_TYPE, QUICK_REPLIES_OPTIONS } from "../../../../constants/CoreConstants";
const Content = () => {
  const {  quickRepliesParams, isQuickrepliesHasMoreData } = useSelector(
    (state) => state.quickRepliesReducer
  );
  const classes = ContentStyles();
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedTeamUser, setSelectedTeamUser] = useState(null);
  const [teamUserList, setTeamUserList] = useState([]);
  const dispatch = useDispatch();
  const quickRepliesListRef = useRef();
  useEffect(()=>{
    getTeamUserData();
  },[])
  const getTeamUserData = async () => {
    try {
      const response = await  getTeamUsersApi({});
      if((response.status==="success") && Array.isArray(response.data)){
        setTeamUserList(response.data);
      }
      else {
        setTeamUserList([]);
      }
    } catch (error) {
      console.log(error, "error");
      
    }
  }
  const handleTabChange = (event, newValue) => {
    if(quickRepliesParams.privacy_level === newValue) return;
    dispatch(handleQuickRepliesTab(newValue))
  };

 const  handleChangeUser = selectedUser => {
    setSelectedTeamUser(selectedUser);
    dispatch(handleSelectedQuickRepliesUserId(selectedUser.id))
  };

  const  handleChangeTemplate = selectedTemp => {
    setSelectedOption(selectedTemp);
    dispatch(handleSelectedQuickRepliesTypes(selectedTemp.value));
  };

  const handleOnScroll = () => {
    const container = quickRepliesListRef.current;
    if (container) {
      const { scrollTop, scrollHeight, clientHeight } = container;
      if (scrollTop + clientHeight >= scrollHeight) {
        if(isQuickrepliesHasMoreData){
          dispatch(getQuickRepliesMoreData());
        }
      }
    }
  };

  return (
    <div className={classes.root}>
      <div
        style={{
          marginBottom: "12px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Tabs
            className={classes.tabs}
            value={quickRepliesParams.privacy_level}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              className={classes.tab}
              disableRipple
              label="Global Quick Replies"
              style={{ textTransform: "capitalize" }}
            />
            <Tab
              className={classes.tab}
              disableRipple
              label="My Quick Replies"
              style={{ textTransform: "capitalize" }}
            />
          </Tabs>
        </div>
        <div style={{display:'flex', justifyContent:'flex-end', alignItems:'center', gap:'10px'}}>
          {/* filter */}

          
          <div style={{width:'220px', height:'38px'}}>
            <StyledSelect
              classNamePrefix='quick-replies'
              value={selectedTeamUser || null}
              onChange={handleChangeUser}
              getOptionValue={(teamUserList)=> teamUserList.id}
              getOptionLabel={(teamUserList) => teamUserList.full_name }
              options={teamUserList}
              isSearchable={false}
              placeholder="Filter by user"
              isDisabled={quickRepliesParams.privacy_level===QUICK_REPLIES_OPTION_TYPE.PRIVATE} 
            />
          </div>
          <div style={{width:'220px', height:'38px'}}>
            <StyledSelect
              classNamePrefix='quick-replies'
              value={selectedOption || null}
              onChange={handleChangeTemplate}
              getOptionValue={(QUICK_REPLIES_OPTIONS)=> QUICK_REPLIES_OPTIONS.value}
              getOptionLabel={(QUICK_REPLIES_OPTIONS) => QUICK_REPLIES_OPTIONS.title }
              options={QUICK_REPLIES_OPTIONS}
              isSearchable={false}
              placeholder="Filter by type"
            />
          </div>

        </div>
      </div>

      <div className={classes.cardListWrapper} ref={quickRepliesListRef} onScroll={handleOnScroll}>
        <div style={{ width: "100%", height: "100%", }}>
          <QuickReplies/>

        </div>
      </div>
    </div>
  );
};

export default Content;
