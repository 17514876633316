import React from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Warning } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { permissionDeniedModalStyle } from "./PermissionDeniedModalStyle";

const PermissionDeniedModal = ({ open, onClose, permissionDeniedTitle='', permissionDeniedAction='modify' }) => {
  const classes = permissionDeniedModalStyle();

  return (
    <div>
      <Modal
        className={classes.modal}
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={classes.bodyWrapper}>
              <Warning style={{ color: "var(--red)", fontSize: 60 }} />
              <span style={{ fontSize: "14px", marginBottom: "10px", fontWeight: 600 }}>Permission Denied</span>
              <span style={{ fontSize: "14px", marginBottom: "20px" }}>
                You do not have the necessary permission to {permissionDeniedAction} {permissionDeniedTitle}.
              </span>
              <Button variant='contained' color='primary' onClick={onClose} className={classes.closeButton}>
                Close
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
export default PermissionDeniedModal;
