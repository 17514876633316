/* eslint-disable no-unused-vars */
import { PRIVACY_LEVEL, QUICK_REPLIES_OPTION_TYPE } from "../constants/CoreConstants";
import * as ACTION from "../constants/quickRepliesActionTypes";

const initialState = {
  isAddingQuickReplyFolder: false,
  isUpdatingQuickReplyFolder: false,
  isDeletingQuickReplyFolder: false,
  gettingQuickRepliesFolder: false,
  quickRepliesFolderList: [],
  quickRepliesParams: {
    page: 1,
    perPage: 20,
    privacy_level: QUICK_REPLIES_OPTION_TYPE.GLOBAL,
    template_folder_id: null,  
    type: null,              
    user_id: null,          
    search_key: null     
 },
 gettingQuickReplies:false,
 quickRepliesList: [],
 isClonningQuickReplies: false,
 isDeletingQuickReplies: false,
 isQuickrepliesHasMoreData: false,
 permissionDeniedModal: {
  openPermissionDeniedModal: false,
  permissionDeniedTitle: "",
  permissionDeniedAction: "",
 },
};

const quickRepliesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.ADD_QUICK_REPLY_FOLDER:
      return {
        ...state,
        isAddingQuickReplyFolder: true,
      };
    case ACTION.ADD_QUICK_REPLY_FOLDER_SUCCESS:
      return {
        ...state,
        isAddingQuickReplyFolder: false,
        quickRepliesFolderList: [
          action.payload,
          ...state.quickRepliesFolderList,
        ],
      };
    case ACTION.ADD_QUICK_REPLY_FOLDER_FAILURE:
      return {
        ...state,
        isAddingQuickReplyFolder: false,
        quickRepliesFolderList: [],
      };
    case ACTION.GET_QUICK_REPLY_FOLDER:
      return {
        ...state,
        gettingQuickRepliesFolder: true,
      };
    case ACTION.GET_QUICK_REPLY_FOLDER_SUCCESS:
      return {
        ...state,
        gettingQuickRepliesFolder: false,
        quickRepliesFolderList: action.payload,
      };
    case ACTION.UPDATE_QUICK_REPLY_FOLDER:
      return {
        ...state,
        isUpdatingQuickReplyFolder: true,
      };
    case ACTION.UPDATE_QUICK_REPLY_FOLDER_SUCCESS:
      return {
        ...state,
        isUpdatingQuickReplyFolder: false,
        quickRepliesFolderList: state.quickRepliesFolderList.map((folder) =>
          folder.id === action.payload.id
            ? {
                ...folder,
                title: action.payload.title,
                folder_color: action.payload.folder_color,
                folder_icon: action.payload.folder_icon,
              }
            : folder
        ),
      };
    case ACTION.UPDATE_QUICK_REPLY_FOLDER_FAILURE:
      return {
        ...state,
        isUpdatingQuickReplyFolder: false,
        quickRepliesFolderList: [...state.quickRepliesFolderList],
      };
    case ACTION.DELETE_QUICK_REPLY_FOLDER:
      return {
        ...state,
        isDeletingQuickReplyFolder: true,
      };
    case ACTION.DELETE_QUICK_REPLY_FOLDER_SUCCESS:
      return {
        ...state,
        isDeletingQuickReplyFolder: false,
        quickRepliesFolderList: state.quickRepliesFolderList.filter(
          (folder) => folder.id !== action.payload.id
        ),
      };
    case ACTION.DELETE_QUICK_REPLY_FOLDER_FAILURE:
      return {
        ...state,
        isDeletingQuickReplyFolder: false,
      };
    case ACTION.HANDLE_QUICK_REPLIES_TAB: 
      return {
        ...state,
        quickRepliesParams: {
        ...state.quickRepliesParams,
        privacy_level: action.payload,
        page:1
        },
      };
    case ACTION.HANDLE_QUICK_REPLIES_SELECTED_FOLDER:
      return {
        ...state,
        quickRepliesParams: {
          ...state.quickRepliesParams,
          template_folder_id: action.payload,
          page:1
        },
      };
    case ACTION.HANDLE_QUICK_REPLIES_USER_ID:
      return {
        ...state,
        quickRepliesParams: {
          ...state.quickRepliesParams,
          user_id: action.payload,
          page:1,
        },
      };
      case ACTION.HANDLE_QUICK_REPLIES_TEMPLATE_TYPES:
      return {
        ...state,
        quickRepliesParams: {
          ...state.quickRepliesParams,
          type: action.payload,
          page:1
        },
      };
    case ACTION.HANDLE_QUICK_REPLIES_SEARCH: 
      return {
        ...state,
        quickRepliesParams: {
          ...state.quickRepliesParams,
          search_key: action.payload,
          page: 1
        },
      };
    case ACTION.GET_QUICK_REPLY:
      return {
        ...state,
        gettingQuickReplies: true,
      };
    case ACTION.GET_QUICK_REPLY_SUCCESS:
     
      return {

        ...state,
        gettingQuickReplies: false,
        quickRepliesList: state.quickRepliesParams.page === 1 ? action.payload : 
        [...state.quickRepliesList, ...action.payload],
        isQuickrepliesHasMoreData: action.payload.length === state.quickRepliesParams.perPage
      };
    case ACTION.GET_QUICK_REPLY_FAILURE:
      return {
        ...state,
        gettingQuickReplies: false,
      };
    case ACTION.ADD_QUICK_REPLY_SUCCESS:
      return {
        ...state,
        quickRepliesParams:{
          ...state.quickRepliesParams,
          privacy_level:action.payload.privacy_level === PRIVACY_LEVEL.PRIVACY_LEVEL_PRIVATE ? QUICK_REPLIES_OPTION_TYPE.PRIVATE : QUICK_REPLIES_OPTION_TYPE.GLOBAL,
          template_folder_id:action.payload.template_folder_id,
          page:1
        }
      };
    case ACTION.CLONE_QUICK_REPLY:
      return {
        ...state,
        isClonningQuickReplies: true,
      };
    case ACTION.CLONE_QUICK_REPLY_SUCCESS:
      return {
        ...state,
        isClonningQuickReplies: false,
        quickRepliesParams:{
          ...state.quickRepliesParams,
          privacy_level: QUICK_REPLIES_OPTION_TYPE.PRIVATE
        }
      };
    case ACTION.CLONE_QUICK_REPLY_FAILURE:
      return {
        ...state,
        isClonningQuickReplies: false,
      };
    case ACTION.DELETE_QUICK_REPLY:
      return {
        ...state,
        isDeletingQuickReplies: true,
      };
    case ACTION.DELETE_QUICK_REPLY_SUCCESS:
      return {
        ...state,
        isDeletingQuickReplies: false,
        quickRepliesList: state.quickRepliesList.filter(item => item.id !== action.payload.temp_id)
      };
    case ACTION.DELETE_QUICK_REPLY_FAILURE:
      return {
        ...state,
        isDeletingQuickReplies: false,
      };
    case ACTION.GET_QUICK_REPLY_MORE_DATA:
      return {
        ...state,
        quickRepliesParams:{
          ...state.quickRepliesParams,
          page:state.quickRepliesParams.page + 1
          },
          isQuickrepliesHasMoreData: false
      };
    case ACTION.RE_CALL_QUICK_REPLIES_AFTER_ADD_OR_EDIT: 
      return {
        ...state,
        quickRepliesParams: {
          ...state.quickRepliesParams,
          privacy_level:action.payload.privacy_level,
          template_folder_id: action.payload.template_folder_id,
          page: 1
        }
      };
    case ACTION.HANDLE_PERMISSION_DENIED_MODAL: 
      return {
        ...state,
        permissionDeniedModal: {
          ...state.permissionDeniedModal,
          openPermissionDeniedModal: action.payload.openPermissionDeniedModal,
          permissionDeniedTitle: action.payload.permissionDeniedTitle,
          permissionDeniedAction: action.payload.permissionDeniedAction,
        }
      }

    default:
      return state;
  }
};

export default quickRepliesReducer;
