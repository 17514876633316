
import React, { useContext, useEffect, useState } from "react";
import Menu from '@material-ui/core/Menu';
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { connect } from 'react-redux'
// import '../marketing.css';
import Utils from "../../../helpers/Utils";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Link } from "react-router-dom";
import AddContactToCampaign from "../EachCampaignActions/AddContact";
import ShareCampaign from "../EachCampaignActions/ShareCampaign";
import Helper from "../../../helpers/Helper";
import { campaignCheckToStart, campaignPause, campaignStart, copyCampaign, deleteCampaign, getCampaignBasicInfo } from "../../../actions/marketingAction";
import Loading from "../../Common/Loading";
import OutSideConstants from "../../../constants/ConstantFromOutside";
import CampaignStartModal from "./CampaignStartModal";
import CustomBootstrapTooltip from "../../Common/CustomBootstrapTooltip";
import { HistoryProvider } from "../../../App";
import CampaignActionNoticeBoard from "./CampaignActionNoticeBoard";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
// import socketIOClient from "socket.io-client";

const standardPack = OutSideConstants.standardPack

const campaignActionMenuIcon = [
    <svg width="18" height="18" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.00024 13V7M7.00024 7V1M7.00024 7H13.0002M7.00024 7H1.00024" stroke="#133159" strokeOpacity="0.35" strokeWidth="2" strokeLinecap="round"/>
    </svg>,
    <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.87559 10.3113L1.03114 13.9557C1.00201 14.089 1.00301 14.227 1.03406 14.3598C1.06512 14.4926 1.12545 14.6168 1.21064 14.7232C1.29583 14.8297 1.40373 14.9158 1.52646 14.9753C1.6492 15.0347 1.78366 15.066 1.92003 15.0668C1.98357 15.0733 2.0476 15.0733 2.11114 15.0668L5.77781 14.2224L12.8178 7.20906L8.88892 3.28906L1.87559 10.3113Z" fill="#133159" fillOpacity="0.35"/>
        <path d="M15.0309 3.69766L12.4086 1.07543C12.2362 0.903907 12.0029 0.807617 11.7597 0.807617C11.5166 0.807617 11.2833 0.903907 11.1109 1.07543L9.65308 2.53321L13.5775 6.45766L15.0353 4.99988C15.1206 4.91414 15.1882 4.81241 15.2341 4.70053C15.2801 4.58865 15.3035 4.4688 15.3031 4.34785C15.3027 4.22689 15.2784 4.10721 15.2317 3.99564C15.185 3.88407 15.1167 3.78281 15.0309 3.69766Z" fill="#133159" fillOpacity="0.35"/>
    </svg>,
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#9fafc5"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/></svg>,
    <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 5L2.92937 13.8284C2.94876 14.0128 3.03571 14.1834 3.17345 14.3074C3.31119 14.4314 3.48997 14.5 3.67531 14.5H12.3247C12.51 14.5 12.6888 14.4314 12.8266 14.3074C12.9643 14.1834 13.0512 14.0128 13.0706 13.8284L14 5H2ZM9.75 11.7956L8 10.0456L6.25 11.7956L5.45437 11L7.20437 9.25L5.45437 7.5L6.25 6.70437L8 8.45438L9.75 6.70437L10.5456 7.5L8.79562 9.25L10.5456 11L9.75 11.7956Z" fill="#133159" fillOpacity="0.35"/>
        <path d="M14.625 1.5H1.375C1.16789 1.5 1 1.66789 1 1.875V3.625C1 3.83211 1.16789 4 1.375 4H14.625C14.8321 4 15 3.83211 15 3.625V1.875C15 1.66789 14.8321 1.5 14.625 1.5Z" fill="#133159" fillOpacity="0.35"/>
    </svg>,
    <svg width="18" height="18" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.4484 10.6112C10.783 10.611 10.144 10.8861 9.66841 11.3775L5.03282 8.6254C5.12808 8.21031 5.12808 7.7772 5.03282 7.3621L9.66695 4.60996C10.0999 5.05464 10.6686 5.32209 11.2712 5.36442C11.8738 5.40676 12.471 5.22122 12.9559 4.84102C13.4409 4.46082 13.7818 3.91078 13.9177 3.28938C14.0536 2.66798 13.9756 2.01579 13.6977 1.44957C13.4197 0.883346 12.96 0.440063 12.4009 0.199077C11.8417 -0.0419082 11.2195 -0.0648604 10.6458 0.134329C10.072 0.333519 9.58416 0.741845 9.2695 1.28621C8.95484 1.83057 8.83393 2.47542 8.92844 3.10532L4.18495 5.9229C3.81236 5.59511 3.35903 5.38622 2.87806 5.32071C2.39708 5.2552 1.90839 5.33578 1.46923 5.55301C1.03006 5.77024 0.658621 6.11513 0.398411 6.54726C0.1382 6.9794 0 7.48089 0 7.99298C0 8.50508 0.1382 9.00657 0.398411 9.4387C0.658621 9.87084 1.03006 10.2157 1.46923 10.433C1.90839 10.6502 2.39708 10.7308 2.87806 10.6653C3.35903 10.5997 3.81236 10.3909 4.18495 10.0631L8.92844 12.8822C8.84643 13.4264 8.92441 13.984 9.15184 14.4799C9.37928 14.9759 9.74524 15.3862 10.2004 15.6557C10.6555 15.9252 11.178 16.0409 11.6973 15.9871C12.2167 15.9334 12.7079 15.7128 13.1048 15.3551C13.5017 14.9973 13.7853 14.5197 13.9172 13.9866C14.049 13.4535 14.023 12.8905 13.8424 12.3735C13.6619 11.8565 13.3356 11.4103 12.9075 11.0952C12.4795 10.7801 11.9702 10.6112 11.4484 10.6112Z" fill="#133159" fillOpacity="0.35"/>
    </svg>,
    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#9fafc5"><g><rect fill="none" height="24" width="24"/></g><path d="M18,11c0,0.67,0,1.33,0,2c1.2,0,2.76,0,4,0c0-0.67,0-1.33,0-2C20.76,11,19.2,11,18,11z"/><path d="M16,17.61c0.96,0.71,2.21,1.65,3.2,2.39c0.4-0.53,0.8-1.07,1.2-1.6c-0.99-0.74-2.24-1.68-3.2-2.4 C16.8,16.54,16.4,17.08,16,17.61z"/><path d="M20.4,5.6C20,5.07,19.6,4.53,19.2,4c-0.99,0.74-2.24,1.68-3.2,2.4c0.4,0.53,0.8,1.07,1.2,1.6 C18.16,7.28,19.41,6.35,20.4,5.6z"/><path d="M4,9c-1.1,0-2,0.9-2,2v2c0,1.1,0.9,2,2,2h1v4h2v-4h1l5,3V6L8,9H4z M9.03,10.71L11,9.53v4.94l-1.97-1.18L8.55,13H8H4v-2h4 h0.55L9.03,10.71z"/><path d="M15.5,12c0-1.33-0.58-2.53-1.5-3.35v6.69C14.92,14.53,15.5,13.33,15.5,12z"/></svg>
]

const CampaignActionMenu = [
    {id: 0, title: 'Add Contact', value:'add_contact', icon: campaignActionMenuIcon[0], link: false}, 
    {id: 1, title: 'Edit Campaign', value:'edit_campaign', icon: campaignActionMenuIcon[1], link: true, hrefLink: '/campaign/details/'},
    {id: 2, title: 'Copy Campaign', value:'copy_campaign', icon: campaignActionMenuIcon[2], link: false}, 
    {id: 3, title: 'Delete Campaign', value:'delete_campaign', icon: campaignActionMenuIcon[3], link: false}, 
    {id: 4, title: 'Share Campaign', value:'share_campaign', icon: campaignActionMenuIcon[4], link: false},
    {id: 5, title: 'Campaign Stats', value:'campaign_stats', icon: campaignActionMenuIcon[5], link: true, hrefLink: '/campaign/state/'}
]

const App = props => {
    const history = useContext(HistoryProvider);

    const [campaign, setCampaign] = useState({})
    const [listener, setListener] = useState({switchListner: false})
    const [anchorActionMenu, setAnchorActionMenu] = React.useState(null);
    const [openAddNewContactModal, setOpenAddNewContactModal] = React.useState(false);
    const [openShareModal, setOpenShareModal] = React.useState(false);

    const [showFullItemLoader, setShowFullItemLoader] = useState(false)

    const [canStart, setCanStart] = useState(false)
    const [canStartCode, setCanStartCode] = useState(0)

    const [isLoading, setIsLoading] = useState(false)
    const [loadingText, setLoadingText] = useState('')
    const [isOpenCampaignStartModal, setIsOpenCampaignStartModal] = useState(false)
    const [isProcessingStatus, setIsProcessingStatus] = useState(false)

    useEffect(() => {
        setCampaign(props.eachItem)
    }, []);

    useEffect(() => {
        setCanStart(props.marketing.canStart)
        setCanStartCode(props.marketing.canStartCode)
    }, [props.marketing.canStart, props.marketing.canStartCode]);

    const executeListeners = () => {
        if(!listener.switchListner){
            let element = document.querySelector('.Switch-'+props.index)
            if (campaign.status == '3') {
                element.className=`Switch Switch-${props.index} Off d-flex align-items-center`
            } else {
                element.className=`Switch Switch-${props.index} On d-flex align-items-center`
            }

            // if you want to add listener
            // let element = document.querySelector('.Switch-'+props.index)
            // if(element != null){
            //     element.addEventListener('click', function() { 
            //         // will call a function
            //         handleSwitch()
            //     }, false);
            // }

            // setListener({...listener, switchListner: true})
        }

    }

    const handleSwitch = () => {
        let element = document.querySelector('.Switch-'+props.index)
        const classes = element.className;
        if (classes.includes('On')) {
            element.className=`Switch Switch-${props.index} Off d-flex align-items-center`
        } else {
            element.className=`Switch Switch-${props.index} On d-flex align-items-center`
        }
    }

    const renderActionMenu = () => {
        let menuOptions = [];
        CampaignActionMenu.map((row, index) => {
            menuOptions.push(
                <MenuItem className="menuitem-li-hover-color-with-border" value={row.id} onClick={() => handleListMenuAction(row.value)} key={("action-menu-"+index)}>
                    <ListItemIcon >
                        <span>
                            {row.icon}
                        </span>
                    </ListItemIcon>
                    <ListItemText /*classes={{ primary: classes.primary }}*/ inset>
                        {row.link &&
                            // <Link to={'/campaign/details/'+campaign.id}>{row.title}</Link>
                            <>
                            {row.value === 'edit_campaign' ?
                            <Link to={row.hrefLink+campaign.id}>{row.title}</Link>:
                            <Link to={row.hrefLink+campaign.id+'/'+campaign.tag_id}>{row.title}</Link>
                            }
                            </>
                        }

                        {!row.link &&
                            <span>{row.title}</span>
                        }                        
                    </ListItemText>
                </MenuItem>
            );
        });

        return menuOptions;
    }

    const handleListMenuAction = (action) => {
        setAnchorActionMenu(null)
        switch(action){
            case 'add_contact':
                handleAddNewContactModal(true)
                break;
            case 'edit_campaign':
                break;
            case 'copy_campaign':
                confirmCopyCampaign()
                break;
            case 'delete_campaign':
                deleteCampaignAction();
                break;
            case 'share_campaign':
                handleSharePopUpModal(true)
                break;
            default:
                break;
        }
    }

    const confirmCopyCampaign = () => {
        confirmAlert({
            title: 'Are you sure?',
            message: 'Do you want to copy this campaign?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: copyCampaign
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    }
                }
            ]
        })
    }

    const copyCampaign = () => {
        let current_url = window.location.href
        let domain = (new URL(current_url));
        let root_url = domain.protocol+"//"+domain.hostname
        if(domain.port != ''){
            root_url = root_url + ":" +domain.port
        }
        props.copyCampaign({'campaign_id' : campaign.id}, (response) => {
            if(response.status == 'success'){
                window.location.href = root_url +'/campaign/details/'+response.new_campaign_id
            }
        })
    }

    const deleteCampaignAction = () => {
        setIsLoading(true)
        confirmAlert({
            title: 'Are you sure?',
            message: 'Do you want to remove this campaign?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        props.deleteCampaign({campaign_id: campaign.id}, (response) => {
                            if(response.status == 'success'){
                                props.deleteCampaignFromList(props.index, props.eachItem)
                            }
                            setIsLoading(false)
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        setIsLoading(false)
                        return;
                    }
                }
            ],
            onClickOutside:()=>{setIsLoading(false)}
        })
    }

    const goToTotalContacts = (campaign_id) => {
        history.push('/contacts?campaignId='+campaign_id);
    }

    const goToTotalContacted = (campaign_id) => {
        history.push('/contacts?campaignId='+campaign_id+'&contacted="contacted"');
    }

    const goToTotalResponsed = (campaign_id) => {
        history.push('/contacts?campaignId='+campaign_id+'&responded="responded"');
    }

    const goToTotalLeadFlow = (campaign_id) => {
        // history.push('/contacts?campaignId='+campaign_id+'&leadFlow="leadFlow"');
        history.push('/leadFlow?campaignid='+campaign_id);
    }

    const handleAddNewContactModal = (status) => {
        setOpenAddNewContactModal(status);
    };

    const handleSharePopUpModal = (status) => {
        setOpenShareModal(status);
    };

    const pauseCampaign = () => {
        confirmAlert({
            title: 'Confirm to pause?',
            message: 'Are you sure to want to pause this campaign?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        pauseCampaignStatus(campaign.id)
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    }
                }
            ]
        })
    }

    const pauseCampaignStatus = (campaignId) =>{
        props.campaignPause({campaign_id : campaignId}, (response) => {
            if(response.status == "error"){
                if(response.html === "Drip Campaign Status Is Not Running"){
                    handleSwitch()
                }
                window.showNotification("ERROR", response.html.replace(/[0-9]/g,''))
            }
            else if(response.status === "success") {
                handleSwitch()
                confirmAlert({
                    childrenElement: ()=>{ return <CampaignActionNoticeBoard 
                        title={"Your campaign will be paused shortly."}
                        message = {"It could take up to 5 minutes."}
                        />},

                    buttons: [
                        {
                            label: 'Close',
                            onClick: () => {
                                return;
                            }
                        }
                    ]
                })
            }
        })
    }

    const startCampaign = () => {
        if(campaign.status == '3'){
            pauseCampaign()
            return
        }
        // ---------------will uncomment later-------------

        if(!canStart){
            let title = '';
            let msg = '';

            if(canStartCode == 1 && standardPack){
                //need credit
                title = 'No credit';
                msg = 'You dont have enough credit to start campaign';
            }else if(canStartCode == 2){
                //need can spam
                title = 'Can Spam';
                msg = 'Please fill up the can-spam act form to start campaign.';
            }

            if(msg != ''){
              window.showNotification("ERROR", msg)
                return false;
            }
        }

        setIsLoading(true)
        setLoadingText('Please wait...')
        props.getCampaignBasicInfo({campaign_id: campaign.id}, (response) => {
            if(response.status){
                if(response.data.type == 1){
                    let canProeced = true // false later

                    // --------------will uncomment later------------------

                    if(response.data.campaign_number == null && standardPack){
                      window.showNotification("ERROR", 'Please select campaign number to start the campaign')
                        canProeced = false
                    }
                    
                    if(canProeced){
                        props.campaignCheckToStart({campaign_id: campaign.id}, (checkResponse) => {
                            if(checkResponse.status){
                                if(response.data.start_time != '0000-00-00 00:00:00') {
                                    setIsOpenCampaignStartModal(true)
                                    handleSwitch()
                                }
                                else{
                                    confirmAlert({
                                        title: 'Confirm to start?',
                                        message: 'Are you sure to want to start this campaign?',
                                        buttons: [
                                            {
                                                label: 'Yes',
                                                onClick: () => {
                                                    startCampaignStatus()
                                                }
                                            },
                                            {
                                                label: 'No',
                                                onClick: () => {
                                                    return;
                                                }
                                            }
                                        ]
                                    })
                                }
                            }
                            else{
                              window.showNotification("ERROR", checkResponse.message)
                            }
                        });
                    }
                } else {
                    props.campaignCheckToStart({campaign_id: campaign.id}, (checkResponse) => {
                        if(checkResponse.status){
                            if(response.data.start_time != '0000-00-00 00:00:00') {
                                setIsOpenCampaignStartModal(true)
                                handleSwitch()
                            }
                            else{
                                confirmAlert({
                                    title: 'Confirm to start?',
                                    message: 'Are you sure to want to start this campaign?',
                                    buttons: [
                                        {
                                            label: 'Yes',
                                            onClick: () => {
                                                startCampaignStatus()
                                            }
                                        },
                                        {
                                            label: 'No',
                                            onClick: () => {
                                                return;
                                            }
                                        }
                                    ]
                                })
                            }
                        }
                        else{
                          window.showNotification("ERROR", checkResponse.message)
                        }
                    });
                }
            }
            else{
              window.showNotification("ERROR", response.message)
            }
            setIsLoading(false)
            setLoadingText('')
        })
    }

    const startCampaignStatus = (fields = {}) => {
        setIsProcessingStatus(true)
        let data = {
            campaignId : campaign.id,
            campaign_id : campaign.id,
            prevent_previous_followup: 0,
            sent_immediate_after_resume: 1
        }
        data = {...data, ...fields}
        props.campaignStart(data, (response) => {
            if(response.data.status == "error"){

                if(response.data.html && response.data.html === "Drip Campaign Status Is Not Pause"){
                    handleSwitch()
                    window.showNotification("ERROR", response.data.html)
                }
            }
            else if(response.data.status === "success") {
                handleSwitch()
                confirmAlert({
                    childrenElement: ()=>{ return <CampaignActionNoticeBoard />},

                    buttons: [
                        {
                            label: 'Close',
                            onClick: () => {
                                setIsOpenCampaignStartModal(false)
                                return;
                            }
                        }
                    ]
                })
            }
            setIsProcessingStatus(false)
        })
    }

    const handleCampaignStartModal = (status) => {
        setIsOpenCampaignStartModal(status)
        handleSwitch()
    }

    const renderStats= (type) => {
        if(props.eachItem.stats !== undefined && props.eachItem.states !== null){
            if(type === 'total_responded'){
                if(props.eachItem.stats?.total_responded !== undefined){
                    return campaign.stats.total_responded;
                }
            }
        }
        return 0;
    }

    /* for drag and drop */
    const onDragStart = (event) => {
        event.dataTransfer.effectAllowed = 'move';
        event.currentTarget.classList.add('meis_active_drag');
        let parent_div = event.target.parentElement;
        parent_div.querySelector('.meis_each__item__title__v2').classList.add('meis_active_drag')
        event.dataTransfer.setData('text/plain', parent_div.getAttribute('campaign-id'));
        event.currentTarget.querySelector('.meis_drag__icon__v2').removeAttribute('aria-describedby')
    }
    const onDragEnd = (event) => {
        event.currentTarget.classList.remove('meis_active_drag');
        let parent_div = event.target.parentElement;
        parent_div.querySelector('.meis_each__item__title__v2').classList.remove('meis_active_drag')
    }

    return (
        <>
            {Utils.checkEmptyJson(campaign) &&
            <div className="campaigns_list_item d-flex align-items-center justify-content-between content_loader" key={props.index} >
                
                {/* <div className='meis_pype__drag__each__item__v3' key={campaign.id} campaign-id={campaign.id} id={`dragable_item_${campaign.id}`}>
                    <div className='meis_drag__container' draggable="true" onDragStart={onDragStart} onDragEnd={onDragEnd}>
                        <div className='meis_drag__icon__v2'><DragIndicatorIcon fontSize='small' /></div>
                        <span className='meis_drag__container_title'>{campaign.title}</span>
                    </div>
                    <CustomBootstrapTooltip title={campaign.title} arrow placement="top">
                        <Link to={`/campaign/details/${campaign.id}`} className="meis_each__item__title__v2 waves-effect has-sidenav" data-target="marketing-slide-out">
                            <h5>{Utils.limitText(campaign.title, 30)}</h5>
                        </Link>
                    </CustomBootstrapTooltip>
                </div> */}
                <CustomBootstrapTooltip title={campaign.title} arrow placement="top">
                    <Link to={`/campaign/details/${campaign.id}`} className="meis_each__item__title__v2 waves-effect has-sidenav" data-target="marketing-slide-out">
                        <h5>{Utils.limitText(campaign.title, 30)}</h5>
                    </Link>
                </CustomBootstrapTooltip>

                {/* status switch */}
                <div className="running_switch" onClick={startCampaign}>
                    <div id="MainContainer">
                        <div className="demo">
                            <div className={`Switch Switch-${props.index} ${campaign.status == '3' ? 'Off': 'On'} d-flex align-items-center`}>
                                <div className="Toggle"></div>
                                <span className="On">RUNNING</span> <span className="Off">PAUSE</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="campaign_list_value contacts d-flex align-items-center cursor-pointer" onClick={() => goToTotalContacts(campaign.id)}>
                    <span>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.99984 0.666016C4.48234 0.666016 0.666504 4.48185 0.666504 8.99935C0.666504 13.5168 4.48234 17.3327 8.99984 17.3327C13.5173 17.3327 17.3332 13.5168 17.3332 8.99935C17.3332 4.48185 13.5173 0.666016 8.99984 0.666016ZM8.99984 4.83268C10.439 4.83268 11.4998 5.89268 11.4998 7.33268C11.4998 8.77268 10.439 9.83268 8.99984 9.83268C7.5615 9.83268 6.49984 8.77268 6.49984 7.33268C6.49984 5.89268 7.5615 4.83268 8.99984 4.83268ZM4.74484 12.976C5.49234 11.876 6.739 11.1427 8.1665 11.1427H9.83317C11.2615 11.1427 12.5073 11.876 13.2548 12.976C12.1898 14.116 10.679 14.8327 8.99984 14.8327C7.32067 14.8327 5.80984 14.116 4.74484 12.976Z" fill="white"/>
                        </svg>
                    </span>
                    <div>
                        <h3>{(campaign.stats != undefined && campaign.stats != null) ? Helper.isEmpty( campaign.stats.total_contact)? 0 : campaign.stats.total_contact: 0}</h3>
                        <h6>contacts</h6>
                    </div>
                </div>

                <div className="campaign_list_value contacted d-flex align-items-center cursor-pointer" onClick={() => goToTotalContacted(campaign.id)}>
                    <span>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.8181 15.9999C12.1209 15.9999 11.1416 15.7477 9.67518 14.9284C7.89194 13.9284 6.51264 13.0052 4.73905 11.2362C3.02903 9.52728 2.19688 8.42084 1.03222 6.30153C-0.283511 3.90864 -0.0592222 2.65434 0.191495 2.11827C0.49007 1.47754 0.93079 1.09432 1.50044 0.713963C1.824 0.501973 2.1664 0.32025 2.52331 0.171099C2.55902 0.155742 2.59224 0.141099 2.62188 0.127884C2.79867 0.0482406 3.06653 -0.0721179 3.40582 0.056455C3.63225 0.141456 3.8344 0.315387 4.15083 0.627891C4.79977 1.2679 5.68656 2.69327 6.01371 3.39328C6.23335 3.86507 6.37871 4.1765 6.37907 4.52579C6.37907 4.93473 6.17335 5.25009 5.92371 5.59045C5.87692 5.65438 5.83049 5.71545 5.78549 5.77474C5.5137 6.13189 5.45406 6.2351 5.49334 6.41939C5.57299 6.78975 6.16692 7.89226 7.14301 8.8662C8.11909 9.84015 9.18982 10.3966 9.56161 10.4759C9.75375 10.5169 9.85911 10.4548 10.2277 10.1734C10.2805 10.133 10.3348 10.0912 10.3916 10.0494C10.7723 9.76622 11.0731 9.56586 11.4723 9.56586H11.4745C11.822 9.56586 12.1195 9.71657 12.6124 9.96515C13.2552 10.2894 14.7235 11.1648 15.3674 11.8145C15.6806 12.1302 15.8553 12.3316 15.9406 12.5577C16.0692 12.898 15.9481 13.1648 15.8692 13.3434C15.856 13.373 15.8413 13.4055 15.826 13.4416C15.6756 13.7979 15.4928 14.1396 15.2799 14.4623C14.9002 15.0302 14.5156 15.4699 13.8734 15.7688C13.5437 15.9248 13.1828 16.0038 12.8181 15.9999Z" fill="white"/>
                        </svg>
                    </span>
                    <div>
                        <h3>{(campaign.stats != undefined && campaign.stats != null) ? Helper.isEmpty( campaign.stats.total_contacted)? 0 : campaign.stats.total_contacted: 0}</h3>
                        <h6>Contacted</h6>
                    </div>
                </div>

                <div className="campaign_list_value responded d-flex align-items-center cursor-pointer" onClick={() => goToTotalResponsed(campaign.id)}>
                    <span>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.5713 2.85742H13.7141V13.7145C13.7141 14.0176 13.8345 14.3083 14.0488 14.5227C14.2632 14.737 14.5539 14.8574 14.857 14.8574C15.1601 14.8574 15.4508 14.737 15.6651 14.5227C15.8794 14.3083 15.9998 14.0176 15.9998 13.7145V3.28599C15.9998 3.17233 15.9547 3.06332 15.8743 2.98295C15.7939 2.90257 15.6849 2.85742 15.5713 2.85742Z" fill="white"/>
                            <path d="M14.2553 15.9196C13.7719 15.787 13.3454 15.4995 13.0412 15.1011C12.7371 14.7027 12.572 14.2155 12.5714 13.7143V0.428571C12.5714 0.314907 12.5263 0.205898 12.4459 0.125525C12.3655 0.0451528 12.2565 0 12.1428 0H0.428571C0.314907 0 0.205898 0.0451528 0.125525 0.125525C0.0451528 0.205898 0 0.314907 0 0.428571V14C0 14.5304 0.210713 15.0391 0.585785 15.4142C0.960857 15.7893 1.46956 16 2 16H14.2446C14.2547 16.0004 14.2647 15.997 14.2724 15.9904C14.2802 15.9839 14.2852 15.9747 14.2865 15.9647C14.2879 15.9546 14.2854 15.9444 14.2796 15.9361C14.2739 15.9278 14.2652 15.9219 14.2553 15.9196ZM2.28571 6.2857V2.85714H5.71427V6.2857H2.28571ZM10.2857 13.1428H2.28571V12H10.2857V13.1428ZM10.2857 10.8571H2.28571V9.71427H10.2857V10.8571ZM10.2857 8.57141H2.28571V7.42856H10.2857V8.57141ZM10.2857 6.2857H6.85713V5.14285H10.2857V6.2857ZM10.2857 3.99999H6.85713V2.85714H10.2857V3.99999Z" fill="white"/>
                        </svg>
                    </span>
                    <div>
                        <h3>{renderStats('total_responded')}</h3>
                        <h6>Responded</h6>
                    </div>
                </div>
                
                <div className="campaign_list_value response_rate d-flex align-items-center">
                    <span>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 0C3.58885 0 0 3.58885 0 8C0 12.4112 3.58885 16 8 16C12.4112 16 16 12.4112 16 8C16 3.58885 12.4112 0 8 0ZM9.85346 4.67L10.7804 5.48L7.94923 8.71461L7.02231 7.82769L9.85346 4.67ZM5.53577 11.3319L2.82192 8.61539L3.69231 7.745L6.40654 10.4615L5.53577 11.3319ZM8.03577 11.3623L5.29038 8.61539L6.16115 7.74539L7.975 9.56115L12.2492 4.67L13.1762 5.48L8.03577 11.3623Z" fill="white"/>
                        </svg>
                    </span>
                    <div>
                        <h3>{(campaign.stats != undefined && campaign.stats != null) ? Helper.isEmpty(campaign.stats.total_contacted) ? 0 : campaign.stats.total_contacted > 0 ? Number(campaign.stats.total_responded/campaign.stats.total_contacted * 100).toFixed(2) : 0 : 0}%</h3>
                        <h6>Response Rate</h6>
                    </div>
                </div>
                
                <div className="campaign_list_value lead_flow d-flex align-items-center cursor-pointer"  onClick={() => goToTotalLeadFlow(campaign.id)}>
                    <span>
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 1.59375L6.375 9.5625V13.8125L10.625 15.4062V9.5625L17 1.59375H0Z" fill="white"/>
                        </svg>
                    </span>
                    <div>
                        <h3>{campaign.lead_flow_connected_count}</h3>
                        <h6>Lead flow</h6>
                    </div>
                </div>
                
                <div className="campaign_dropdown">
                    <a className='dropdown-trigger' data-target='campaign_list_dropdown' onClick={(e) => setAnchorActionMenu(e.currentTarget)}>
                        <svg width="8" height="30" viewBox="0 0 8 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.56293 18.4612C5.50128 18.4612 7.07263 16.9114 7.07263 14.9996C7.07263 13.0879 5.50128 11.5381 3.56293 11.5381C1.62457 11.5381 0.0532227 13.0879 0.0532227 14.9996C0.0532227 16.9114 1.62457 18.4612 3.56293 18.4612Z" fill="#133159"/>
                            <path d="M3.56293 30.0002C5.50128 30.0002 7.07263 28.4504 7.07263 26.5387C7.07263 24.6269 5.50128 23.0771 3.56293 23.0771C1.62457 23.0771 0.0532227 24.6269 0.0532227 26.5387C0.0532227 28.4504 1.62457 30.0002 3.56293 30.0002Z" fill="#133159"/>
                            <path d="M3.56293 6.92308C5.50128 6.92308 7.07263 5.37329 7.07263 3.46154C7.07263 1.54978 5.50128 0 3.56293 0C1.62457 0 0.0532227 1.54978 0.0532227 3.46154C0.0532227 5.37329 1.62457 6.92308 3.56293 6.92308Z" fill="#133159"/>
                        </svg>
                    </a>

                    <Menu
                        id="action-menu"
                        anchorEl={anchorActionMenu}
                        open={Boolean(anchorActionMenu)}
                        onClose={() => setAnchorActionMenu(null)}
                        className="campaign__list_dropdown"
                    >
                        {renderActionMenu()}
                    </Menu>                
                
                </div>
            
                {showFullItemLoader &&
                <div>
                    <Loading/>
                </div>
                }

                {isLoading &&
                <div className="Custome__loadar_2">
                    {loadingText == '' ? <Loading /> : loadingText}
                </div>
                }
            </div>
            }

            {openAddNewContactModal &&
                <AddContactToCampaign 
                    handleAddNewContactModal={handleAddNewContactModal} 
                    campaign={campaign} 
                    open={openAddNewContactModal}
                    onClose={() => handleAddNewContactModal(false)}
                />
            }

            {openShareModal &&
            <ShareCampaign 
                handleSharePopUpModal={handleSharePopUpModal} 
                campaign={campaign} 
                open={openShareModal}
                onClose={() => handleSharePopUpModal(false)}
            />
            }
        
            {Utils.checkEmptyJson(campaign) &&
                <CampaignStartModal 
                    handleCampaignStartModal={handleCampaignStartModal}
                    campaign={campaign} 
                    totalContact={campaign.stats == null ? 0 : campaign.stats.total_contact}
                    isLoading={isProcessingStatus}
                    handleStartCampaign={startCampaignStatus}
                    open={isOpenCampaignStartModal}
                    onClose={() => handleCampaignStartModal(false)}
                />
            }   
        </>
    );
}


const mapStateToProps = state => ({
    marketing: state.marketingReducer
});

const mapDispatchToProps = dispatch => ({
    deleteCampaign: (params, callback) => dispatch(deleteCampaign(params, callback)),
    copyCampaign: (params, callback) => dispatch(copyCampaign(params, callback)),
    getCampaignBasicInfo: (params, callback) => dispatch(getCampaignBasicInfo(params, callback)),
    campaignCheckToStart: (params, callback) => dispatch(campaignCheckToStart(params, callback)),
    campaignStart: (params, callback) => dispatch(campaignStart(params, callback)),
    campaignPause: (params, callback) => dispatch(campaignPause(params, callback)),
})

const CampaignItem = connect(mapStateToProps, mapDispatchToProps)(App);
export default CampaignItem;